<div class="main-div">
    <div class="mb-3 float-container d-flex justify-content-around mt-2"
    *ngIf="!isNewServiceTicket && !isNewSalesOrder && !showCallHistory">
        <div class="float-left call-log-section">
            <div class="call_from-header">
                Call From : <span class="pull-right">{{!wh_CallId? session.remoteIdentity.uri.user :
                    session.remoteIdentity.uri.user|phoneNumber }}</span>
            </div>

            <div class="call_from-body">
                <div class="container mt-2">
                    <div class="row d-flex align-items-center">
                        <div class="col-5"><button class="btn btn-sm"
                                [ngClass]="wh_CallId&&call_Details&&call_Details.contact_name?'btn-warning':'btn-primary'">{{wh_CallId&&call_Details&&call_Details.contact_name?
                                'Customer':'Unknown'}}</button></div>
                        <div class="col-7 tenent_customerName">
                            <b>{{wh_CallId&&call_Details&&call_Details.tenant_customer_name?
                                call_Details.tenant_customer_name :''}}</b></div>
                    </div>
                </div>

                <div class="container mt-2">
                    <div class="row" *ngIf="wh_CallId&&call_Details">
                        <div class="col-1"><img src="assets/img/caller assets/avatar.svg" height="15" width="15" alt="">
                        </div>
                        <div class="col-8"><b>{{call_Details.contact_name}}</b></div>
                        <div class="col"><img src="assets/img/gallery/user-avatar.jpg" class="avatar position-absolute"
                                height="35" width="35" alt=""></div>
                        <div class="w-100"></div>
                        <div class="col-1"><img src="assets/img/caller assets/phone_icon.svg" height="15" width="15"
                                alt=""></div>
                        <div class="col">{{call_Details.call_from|phoneNumber}}</div>
                        <div class="w-100"></div>
                        <div class="col-1"><img src="assets/img/caller assets/email_icon.svg" height="15" width="15"
                                alt=""></div>
                        <div class="col-10" style="word-wrap: break-word;">{{call_Details.contact_email}}</div>
                        <div class="w-100"></div>
                        <div class="col-1"><img src="assets/img/caller assets/location_icon.svg" height="15" width="15"
                                alt=""></div>
                        <div class="col-10"><span *ngIf="call_Details.location_name"
                                style="display: inline-block;">{{call_Details.location_name}}</span><br><span
                                *ngIf="call_Details.location_address">{{call_Details.location_address}},</span><span
                                *ngIf="call_Details.location_city"> {{call_Details.location_city}}</span><span
                                *ngIf="call_Details.location_state">, {{call_Details.location_state}}</span><span
                                *ngIf="call_Details.location_zip">, {{call_Details.location_zip}}</span></div>
                    </div>
                </div>
            </div>
            <button *ngIf="!call_Details?.contacts_id"
                class="call_from-footer text-white  d-flex justify-content-center"
                [disabled]="!wh_CallId||!call_Details">
                <span (click)="openFormNew()" style="margin-right: 8px;"><img src="assets/img/caller assets/add.svg"
                        alt="">
                    Create New Contact </span>

                <div class="custom-switch custom-switch-success custom-control mb-2 mb-xl-0"
                    style="padding-left: 3.7rem;font-size: 13px;">

                    <input type="checkbox" [(ngModel)]="callCenterService.isalwaysOpenAddContact"
                        (change)="autoOpenAddContactToggled()" name="is_active" class="custom-control-input"
                        id="color-switch-3" checked>
                    <label class="custom-control-label mr-1" for="color-switch-3" style="padding-top: 2px;">
                        <span>Auto Open</span>
                    </label>
                </div>
            </button>



            <button *ngIf="call_Details?.contacts_id" (click)="editContact(call_Details.contacts_id)"
                class="call_from-footer text-white  d-flex justify-content-center"
                [disabled]="!wh_CallId||!call_Details"><img src="assets/img/caller assets/edit.svg" class="mr-2"
                    alt="">Edit Contact</button>
            <!-- <ng-template #FormModal let-c="close" let-d="dismiss">
                <div class="modal-header border-0">
                    <h5 class="modal-title">{{bindingModel ? 'Edit Contact' : 'New Contact'}}</h5>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <tenant-customer-contact-form [tenantCustomerId]="tenantCustId" [bindingModel]="bindingModel" [tenentSelection]="false"
                                      (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)"
                                      (cancel)="onCancel()">
                    </tenant-customer-contact-form>
                </div>
            </ng-template> -->
        </div>
        <div class="float-left call-log-section">
            <div class="call_to-header">
                Call To : <span class="pull-right" *ngIf="wh_CallId&&call_Details">
                    {{call_Details.call_to|phoneNumber}}</span>
            </div>
            <div class="call_to-body mt-3">
                <div class="container">
                    <div class="row" *ngIf="(wh_CallId&&call_Details)">
                        <div class="col-3">ORG </div>
                        <div class="col"><b>{{call_Details.call_to_suborg_name}}</b></div>
                        <div class="w-100"></div>
                        <div class="col-3">Name </div>
                        <div class="col"><b>{{call_Details.call_to_did_name}}</b></div>
                    </div>
                </div>
            </div>
            <button (click)="openHistory()" class="call_to-footer text-white"
                [disabled]="!wh_CallId||!call_Details">Click here to see full call history</button>
<<<<<<< HEAD

=======
>>>>>>> restucture_merge_fix
        </div>
        <div class="float-left call-log-section">
            <div class="documents-header">
                <p>Documents <img src="assets/img/caller assets/hang_click.svg" height="20" width="20" alt=""></p>
            </div>
            <div class="documents-body" style="cursor: pointer;scrollbar-width: none;">
                <div class="card p-0" *ngIf="wh_CallId&&call_Details">
                    <div class="card-body p-0"
                        *ngFor="let document of call_Details?.documents?.sort(sortFunc).reverse()">
                        <div class="container" (click)="documentEdit(document)">
                            <div class="row">
                                <div class="col-6"><b>{{document.unique_number}}</b></div>
                                <div class="col-6">Date:{{document.created_on| dateTz:'MM/DD/YYYY' }}</div>
                                <div class="w-100"></div>
                                <div class="col">{{document.type=='SERVICE TICKET'? 'Reason: ':''}}{{document.reason}}
                                </div>
                                <div class="col-4 doc-time">{{document.created_on| dateTz:'h:mm a' }}</div>
                            </div>
                        </div>
                        <div class="horizontal_line mb-2 mt-2"></div>
                    </div>
                </div>

            </div>
            <button (click)="!isNewSalesOrder&&addNewSalesOrder()" class="documents-footer1 text-white"
                [disabled]="!wh_CallId||!call_Details"><img src="assets/img/caller assets/add.svg" class="mr-1"
                    alt="">New Sales Order</button>
            <button (click)="!isNewServiceTicket&&addNewServiceTicket()" class="documents-footer2 text-white"
                [disabled]="!wh_CallId||!call_Details"><img src="assets/img/caller assets/add.svg" class="mr-1"
                    alt="">New Service Ticket</button>
        </div>
    </div>
    <!-- footer -->
    <div class="d-flex justify-content-center" style="height: 3.2rem;">
        <div class="call-buttons d-flex">
            <button type="button" class="btn btn-success" (click)="pickkCall()" *ngIf="showAcceptButton(session)"
                [disabled]="session.state=='Establishing'">
                <img src="assets/img/caller assets/accept.svg" alt="">&nbsp;Accept
            </button>
            <!-- <button type="button" class="btn btn-warning">
                <img src="assets/img/caller assets/message.svg" alt="">&nbsp;Message
            </button> -->
            <button type="button" class="btn-secondary mute-btn text-white"
                [ngClass]="{'blinkMuteButton':sipInboundFnService.isMuted}" (click)="muteCall()"
                *ngIf="showMuteButton(session)">
                <img src="{{sipInboundFnService.isMuted ? 'assets/img/caller assets/mute.svg':'assets/img/caller assets/unmute.svg' }}"
                    alt="">&nbsp;{{sipInboundFnService.isMuted?'Unmute Call':'Mute Call'}}
            </button>
            <!-- commented for pushing to production
                 after production uncomment this -->
            <button type="button" class="btn-secondary text-white hold-btn"
                [ngClass]="{'blinkActiveButton':session.customValues.isCallOnHold}" (click)="holdCall()"
                *ngIf="showHoldButton(session)" [disabled]="hideUnholdButton(session)">
                <img src="{{session.customValues.isCallOnHold ? 'assets/img/caller assets/unhold.svg':'assets/img/caller assets/hold.svg' }}"
                    alt="">&nbsp;{{session.customValues.isCallOnHold?'Unhold Call':'Hold Call'}}
            </button>
            <button type="button" class="btn btn-danger" (click)="endCall(session)" *ngIf="showEndButton(session)">
                <img src="assets/img/caller assets/decline.svg" alt="">&nbsp;End Call
            </button>
            <button type="button" class="btn btn-danger" (click)="rejectCall(session)" *ngIf="showRejectButton(session)"
                [disabled]="session.state=='Establishing'">
                <img src="assets/img/caller assets/decline.svg" alt="">&nbsp;Reject
            </button>


            <div *ngIf="wh_CallId&&call_Details && !isNewServiceTicket && !isNewSalesOrder && !showCallHistory">
                <div *ngIf="!block_id&&hasPermission(permission.CREATE)"
                    class="custom-switch custom-switch-success custom-control block-switch">
                    <label style="position: relative; top:47%; left:-11px;">Block</label>
                    <input type="checkbox" [(ngModel)]="blockCall" (change)="addToBlockList()" name="block"
                        class="custom-control-input" id="blockToggle">
                    <label class="custom-control-label mr-1" for="blockToggle">
                        <span></span>
                    </label>
                </div>
                <button class="badge bg-light-danger unBlock-switch" *ngIf="block_id&&hasPermission(permission.DELETE)"
                    (click)="unblockNumber()">
                    Unblock
                </button>
            </div>



            <div class="custom-switch custom-switch-success custom-control mb-2 mb-xl-0 auto-open">
                <label style="position: relative; top:47%;">Auto Open</label>
                <input type="checkbox" [(ngModel)]="callCenterService.isalwaysOpen" (change)="autoOpenToggled()"
                    name="is_active" class="custom-control-input" id="color-switch-2" checked>
                <label class="custom-control-label mr-1" for="color-switch-2">
                    <span></span>
                </label>
            </div>
        </div>
        <span class="connectingCallLoader" *ngIf="session?.state=='Establishing'">connecting . .&nbsp;<i
                class="fa fa-refresh fa-spin"></i></span>
    </div>
    <div class="mb-3 float-container d-flex justify-content-around mt-3"
        *ngIf="isNewServiceTicket || isNewSalesOrder || showCallHistory">

        <div *ngIf="isNewSalesOrder" class="open_panel_container">
            <div class="new_panel_container"
                [ngClass]="{'new_panel_container_md': session?.state !='Terminated','new_panel_container_full':session?.state=='Terminated'}">
                <button type="button" class="close ml-1 text-right" aria-label="Close" (click)="closeNewPanels()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <app-sales-orders-add [callCenter]="true" callType="Outbound" [incomingcall_Details]="call_Details"
                    [model]="modelSo" [soId]="soId"  [suborgId] = "call_Details.call_to_suborg_id"
                     (isCancelClicked) = "onSalesOrderCancel()" (isSubmitted) = "afterSalesSOSave()"></app-sales-orders-add>
            </div>

        </div>

        <div *ngIf="isNewServiceTicket" class="open_panel_container">
            <div class="new_panel_container"
                [ngClass]="{'new_panel_container_md': session?.state !='Terminated','new_panel_container_full':session?.state=='Terminated'}">
                <button type="button" class="close ml-1 text-right" aria-label="Close" (click)="closeNewPanels()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <app-add-service-ticket [callCenter]="true" [serviceTicketId]="serviceTicketId" [model]="modelServiceTicket" (isCancelClicked) = "onServiceTicketCancel()"
                [suborgId] = "call_Details.call_to_suborg_id" [incomingcall_Details]="call_Details" [from_Number_name] = "from_Number_name" callType="Outbound"
                (isSubmitted) = "afterServiceSTSave()"></app-add-service-ticket>
            </div>
        </div>

        <div *ngIf="showCallHistory" class="open_panel_container">
            <div class="new_panel_container"
                [ngClass]="{'new_panel_container_md': session?.state !='Terminated','new_panel_container_full':session?.state=='Terminated'}">
                <button type="button" class="close ml-1 text-right" aria-label="Close" (click)="closeNewPanels()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <app-call-reports [showCount]="false" [showGridFilter]="false" [enableViewDetails]="false"
                    [baseFilter]="[]" [rows2]="callHistoryList" [page]="page"></app-call-reports>
            </div>
        </div>

    </div>
    
</div>


<ng-template #FormModal let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title">Edit Contact</h5>

    </div>
    <div class="modal-body pt-0">
        <tenant-customer-contact-form [tenantCustomerId]="call_Details.tenant_customer_id" [bindingModel]="bindingModel"
            [tenentSelection]="true" [suborg_id]="call_Details.call_to_suborg_id"
            (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()"
            [disable_change]='true' [incomingcall_Details]="call_Details" *ngIf="bindingModel">
        </tenant-customer-contact-form>
    </div>
</ng-template>

<ng-template #newContactForm let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title">New Contact</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-0">
        <app-call-center-quick-contact-form (edit)="editContact($event.contact_id)" (cancel)="onCancelNew()"
            (error)="onDataError($event)" (success)="onDataSubmittedSuccess($event)"
            [incomingcall_Details]="call_Details">
        </app-call-center-quick-contact-form>
    </div>
</ng-template>
<section class="dashlet-container d-flex flex-row flex-wrap mb-2" *ngIf="dashValues">
    <div class="status-tile" (click)="setFilter('',null)">
        <div class="card m-0 h-100 gradient-mint">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.total || 0}}</h3>
                            <span>All Numbers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('forward_bin_sid_resolved',null)">
        <div class="card m-0 h-100 unbilled_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.unassigned || 0}}</h3>
                            <span>Unassigned</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('did_type', 'Local')">
        <div class="card m-0 h-100 invoiced_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.local || 0}}</h3>
                            <span>Local Numbers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('did_type', 'Toll-Free')">
        <div class="card m-0 h-100 paid_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.toll_free || 0}}</h3>
                            <span>Toll Free Numbers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataFilter [searchFilters]="searchFilters" [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [expanded]="expanded"
                                     (apply)="applyFilter($event)"
                                     [export]="UIPermissions.export"
                                     [module]="module"
                                     (exportButtonClick)="bulkexport()"
                                     [bulk_update_btn]="isSuperAdmin||UIPermissions.edit"
                                     [newButtonText]="'Bulk Update'"
                                     (newButtonClick)="openBulkUpdateModal(bulk_update)"
                                     [bulk_delete_btn]="isSuperAdmin||UIPermissions.edit"
                                     (bulkDeleteClick)="openBulkUpdateModal(bulk_delete)">

                        <ng-template filterControl [column]="'area_code'" let-row="row">
                            <area-code-select class="ng-select-sm" [(value)]="row.value"></area-code-select>
                        </ng-template>
                        <ng-template filterControl [column]="'customer'" let-row="row">
                            <app-customer-select class="ng-select-sm" [(value)]="row.value"
                                                 (change)="dataFilter.controlValueChanged('customer')">
                            </app-customer-select>
                        </ng-template>
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value"
                                           [tenantId]="row.dependentValue"
                                           (change)="dataFilter.controlValueChanged('suborg')">
                            </suborg-select>
                        </ng-template>
                        <ng-template filterControl [column]="'forward_bin_sid_resolved'" let-row="row">
                            <forward-bin-select class="ng-select-sm" [(value)]="row.value"
                                                [suborgId]="row.dependentValue?row.dependentValue:didService.suborgId" [show_RouteType]="true"></forward-bin-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                                       [scrollbarH]="scrollbarH"
                                       class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [rows]="rows"
                                       [selectionType]="SelectionType.single"
                                       (select)="onSelect($event)"
                                       [count]="page.count"
                                       [externalPaging]="true"
                                       [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit"
                                       [trackByProp]="'sid'"
                                       (page)="setPage($event)" (sort)="onSort($event)"
                                       [sorts]="[{prop:'purchased_on',dir:'desc'}]">

                            <ngx-datatable-column name="Number" prop="did">
                                <ng-template let-did="value" ngx-datatable-cell-template>
                                    {{did | phoneNumber }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Number Type" prop="did_type"
                                                  [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Area Code" prop="area_code">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Incoming Call Routes" prop="forward_bin_name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.forward_bin_name}}</div>
                                    <div class="bold" *ngIf="row.forward_number">
                                        <span
                                              *ngFor="let num of row.forward_number.split(',') as numList; let i = index">
                                            <span>{{num | phoneNumber}}</span>
                                            <span *ngIf="i !== numList.length-1">, </span>
                                        </span>
                                    </div>
                                    <div class="bold" *ngIf="row.sip">
                                        <span
                                              *ngFor="let num of row.sip.split(',') as numList; let i = index">
                                            <span>{{num}}</span>
                                            <span *ngIf="i !== numList.length-1">, </span>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Incoming Chats Routes" prop="message_route_name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.message_route_name}}</div>
                                    <div class="bold" *ngIf="row.message_route_user_name">
                                        <span>{{row.message_route_user_name}}</span>
                                    </div>
                                    <div class="bold" *ngIf="row.message_route_user_group_name">
                                        <span>{{row.message_route_user_group_name}}</span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Purchased On" prop="purchased_on">
                                <ng-template let-purchase="value" ngx-datatable-cell-template>
                                    {{purchase | dateTz}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && deletedRecords" name="Deleted On"
                                                  prop="modified_on">
                                <ng-template let-purchase="value" ngx-datatable-cell-template>
                                    {{purchase | dateTz}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && deletedRecords" name="Deleted By"
                                                  prop="modified_by"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg"
                                                  prop="suborg_name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.customer_name}}</div>
                                    <div>Sub org : {{row.suborg_name}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Outbound SMS" prop="is_sms_outbound_active">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge" style="width: 5.5rem;"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Activated' : ' Deactivated' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && !isSuperAdmin" name="Suborg" prop="suborg_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Action" prop="sid" [sortable]="false"
                                                  [canAutoResize]="false" [width]="80">
                                <ng-template let-sid="value" let-current="row" ngx-datatable-cell-template>
                                    <div *ngIf="UIPermissions.viewInfo || canDelete(current.purchased_on)" ngbDropdown
                                         container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="UIPermissions.viewInfo" class="w-100"
                                                    (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>
                                            <button *ngIf="canDelete(current.purchased_on) && !deletedRecords"
                                                    (click)="didDelete(sid)"
                                                    class="w-100 bg-light-danger" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                             let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                                     [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                     [size]="pageSize" [count]="rowCount"
                                                     [hidden]="!((rowCount / pageSize) > 1)"
                                                     (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="warehouse-details">
                        <div class="d-flex flex-row mt-2">
                            <h5 class="item-title m-0">
                                {{selected.did | phoneNumber }}
                                <span #copyBtn ngxClipboard
                                      [cbContent]="selected.did"
                                      ngbTooltip="copied"
                                      triggers="click:blur"
                                      class="ft-copy copy-btn ml-1">
                                </span>
                            </h5>
                            <div class="d-flex justify-content-end align-items-start">
                                <button *ngIf="canDelete(selected.purchased_on) && !deletedRecords"
                                        class="btn btn-sm bg-light-danger ml-1"
                                        (click)="didDelete(selected.sid)">
                                    <i class="ft-trash-2 mr-1"></i> Delete
                                </button>
                                <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3"
                                        (click)="closeDetailedView()">
                                    <i class="ft-x"></i>
                                </button>
                            </div>
                        </div>
                        <div class="group mt-3">
                            <div class="group-header d-flex justify-content-between">
                                Overview
                                <div class="status-toggle-btn" *ngIf="isSuperAdmin">
                                    <small class="title">SMS Outbound Active :</small>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [(ngModel)]="selected.is_sms_outbound_active"
                                               id="sms_outbound" name="active"
                                               class="custom-control-input"
                                               (change)="changeSmsOutboundStatus()">
                                        <label class="custom-control-label" for="sms_outbound">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="group-body">
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Internal Id</label>
                                    <label class="item-label-value">{{selected.sid}}</label>
                                </div>
                                <div class="ov-row" *ngIf="UIPermissions.edit && !deletedRecords else didName">
                                    <label class="col-md-3 p-0 item-label">Name</label>
                                    <label class="item-label-value">
                                        <div class="d-flex flex-row">
                                            <input class="form-control form-control-sm did-name-input mr-1" maxlength="255"
                                                   placeholder="Enter name" type="text" [(ngModel)]="didname_input">
                                            <button [disabled]="selected.name == didname_input"
                                                    class="btn btn-success btn-sm"
                                                    (click)="changeName()">
                                                Save
                                            </button>
                                        </div>
                                        <small class="form-text text-muted danger" *ngIf="didname_input">
                                            <div *ngIf="didname_input.length==255">
                                                maximum allowed is 255 characters.
                                            </div>
                                        </small>
                                    </label>
                                </div>
                                <ng-template #didName>
                                    <div class="ov-row">
                                        <label class="col-md-3 p-0 item-label">Name</label>
                                        <label class="item-label-value">{{selected.name || ''}}</label>
                                    </div>
                                </ng-template>
                                <div class="ov-row" *ngIf="selected.did_type">
                                    <label class="col-md-3 p-0 item-label">Phone Number Type</label>
                                    <label class="item-label-value">{{selected.did_type}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected.area_code">
                                    <label class="col-md-3 p-0 item-label">Area Code</label>
                                    <label class="item-label-value">{{selected.area_code}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected.purchased_on">
                                    <label class="col-md-3 p-0 item-label">Purchased On</label>
                                    <label
                                           class="item-label-value">{{selected.purchased_on | dateTz}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected.created_by">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value">{{selected.created_by}}</label>
                                </div>
                                <div class="ov-row" *ngIf="isSuperAdmin">
                                    <label class="col-md-3 p-0 item-label">Tenant</label>
                                    <label class="item-label-value">{{selected.customer_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected.suborg_name">
                                    <label class="col-md-3 p-0 item-label">Sub Org</label>
                                    <label class="item-label-value">{{selected.suborg_name}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="group mt-2">
                            <div class="group-header">
                                Incoming Route For Calls
                                <a class="text-info ml-2 reassign-btn" (click)="open(content)"
                                   *ngIf="UIPermissions.edit && !deletedRecords">
                                    <i class="ft-rotate-cw"></i> Reassign
                                </a>
                            </div>
                            <div class="group-body" *ngIf="selected.forward_number||selected.sip">
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Internal Id</label>
                                    <label class="item-label-value">{{selected.forward_bin_sid}}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Incoming Route</label>
                                    <label class="item-label-value" *ngIf="selected.forward_number">
                                        <span class="badge bg-light-secondary mr-1"
                                              *ngFor="let num of selected.forward_number.split(',')">
                                            {{num | phoneNumber}}
                                        </span>
                                    </label>
                                    <label class="item-label-value" *ngIf="selected.sip">
                                        <span class="badge bg-light-secondary mr-1"
                                              *ngFor="let num of selected.sip.split(',')">
                                            {{num}}
                                        </span>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="selected.forward_bin_name">
                                    <label class="col-md-3 p-0 item-label">Incoming Route Name</label>
                                    <label class="item-label-value">
                                        <a *ngIf="hasBinViewInfoPermission() else binGeneral"
                                           target="_blank"
                                           [routerLink]="['/ring2voice/incoming-routes', selected.forward_bin_sid_resolved,'view']"
                                           class="text-info">
                                            {{selected.forward_bin_name}}
                                        </a>
                                    </label>
                                    <ng-template #binGeneral><span data-toggle="tooltip" data-placement="right" title="Global Route">{{selected.forward_bin_name}}</span></ng-template>
                                </div>
                            </div>
                            <div class="group-body" *ngIf="!selected.forward_number&&!selected.sip">
                                {{selected.did}} not assigned to any Incoming Routes
                            </div>
                        </div>

                        <div class="group mt-2">
                            <div class="group-header">
                                Incoming Route For Chats
                                <a class="text-info ml-2 reassign-btn" (click)="open(chatModal)"
                                   *ngIf="UIPermissions.edit && !deletedRecords">
                                    <i class="ft-rotate-cw"></i> Reassign
                                </a>
                            </div>
                            <div class="group-body" *ngIf="selected.message_route_user_group_name||selected.message_route_user_name">
                                
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Incoming Route</label>
                                    <label class="item-label-value" *ngIf="selected.message_route_user_or_group">
                                        <span class="badge bg-light-secondary mr-1">
                                            {{selected.message_route_user_group_name}}
                                        </span>
                                    </label>
                                    <label class="item-label-value" *ngIf="!selected.message_route_user_or_group">
                                        <span class="badge bg-light-secondary mr-1">
                                            {{selected.message_route_user_name}}
                                        </span>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="selected.message_route_name">
                                    <label class="col-md-3 p-0 item-label">Incoming Route Name</label>
                                    <label class="item-label-value">
                                        <a *ngIf="hasBinViewInfoPermission() else defaultView"
                                           target="_blank"
                                           [routerLink]="['/ring2voice/incoming-routes/chats', selected.message_route_id,'view']"
                                           class="text-info">
                                            {{selected.message_route_name}}
                                        </a>
                                    </label>
                                    <ng-template #defaultView><span data-toggle="tooltip" data-placement="right" title="Global Route">{{selected.message_route_name}}</span></ng-template>
                                </div>
                            </div>
                            <div class="group-body" *ngIf="!selected.message_route_user_group_name&&!selected.message_route_user_name">
                                {{selected.did}} not assigned to any Incoming Chat Routes
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Reassign Incoming Routes For Calls</h5>
            <button type="button" class="close" aria-label="Close" (click)="dismissModal(modal)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="d-flex justify-content-between">
                    <label>Incoming Routes</label>
                    <label class="status-toggle-btn">
                        <label class="title">Global Routes :</label>
                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control mb-1">
                            <input type="checkbox" [(ngModel)]="selected.forward_bin_global_route" id="GlobalRoute"
                                name="active"
                                class="custom-control-input">
                            <label class="custom-control-label" for="GlobalRoute">
                                <span></span>
                            </label>
                        </div>
                    </label>
                    <button type="button" class="btn btn-sm gradient-pomegranate pull-right" style="position: relative; bottom: 6px;" (click)="frwdbin.openModal(frwdbin.modalContent)">&#x2B; New</button>
                </div>
                <forward-bin-select [suborgId]="selected.sub_org" #frwdbin [(value)]="forwardBinSid" [isGlobal_Route]="selected.forward_bin_global_route"></forward-bin-select>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" [disabled]="!forwardBinSid" class="btn gradient-pomegranate"
                    (click)="reAssignBin()">Save</button>
            <button type="button" class="btn" (click)="dismissModal(modal)">Cancel</button>
        </div>
    </ng-template>



    <ng-template #bulk_update let-bulkModal let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title bulk_update_title">Bulk <span>Update</span></h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');didService.currentStep=1">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

            <div class="modal-body" style="overflow: hidden;">
                <dids-bulk-update #bulk_update_component [totalCount]="totalPhoneNumbers"
                                (emmitTotalCount)="getTotalCounttoUpdate($event)" 
                                (emmitSelectedCount)="getSelectedCounttoUpdate($event)" 
                                (closeBulkupdateModal)="dismissModalBulk(bulkModal)">
                </dids-bulk-update>
            </div>
            <div class="modal-footer justify-content-between ">
                <div>selected {{countofSelectedPhoneNumbers}} of {{countofPhoneNumberstoUpdate}}</div>
                <div class="justify-content-end">
                    <button type="button" class="btn btn-secondary" *ngIf="didService.currentStep==1" (click)="d('Cross click')">Cancel</button>
                    <button type="button" class="btn btn-secondary" *ngIf="didService.currentStep!=1" (click)="bulk_update_component.previousBtnClicked()">Previous</button>
                    <button type="button" class="btn gradient-pomegranate" *ngIf="didService.currentStep==3" (click)="bulk_update_component.onFinish()">Finish</button>
                    <button type="button" *ngIf="didService.currentStep!=3" class="btn gradient-pomegranate" (click)="bulk_update_component.nextBtnClicked()">Next</button>
                </div>
            </div>
    </ng-template>

    <ng-template #chatModal let-chatmodal>
        <div class="modal-header">
            <h5 class="modal-title">Reassign Incoming Routes For Chats</h5>
            <button type="button" class="close" aria-label="Close" (click)="dismissModal(chatmodal)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="d-flex justify-content-between">
                    <label>Incoming Routes</label>
                    <button type="button" class="btn btn-sm gradient-pomegranate pull-right" style="position: relative; bottom: 6px;" (click)="chatbin.openModal(chatbin.modalContent)">&#x2B; New</button>
                </div>
                <chat-bin-select #chatbin [suborgId]="selected.sub_org" [(value)]="chatBinSid" [addButton]="true"></chat-bin-select>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" [disabled]="!chatBinSid" class="btn gradient-pomegranate"
                    (click)="reAssignChatBin()">Save</button>
            <button type="button" class="btn" (click)="dismissModal(chatmodal)">Cancel</button>
        </div>
    </ng-template>
    <!-- delete -->
    <ng-template #bulk_delete let-bulkModal let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title bulk_update_title">Bulk <span>Delete</span></h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');didService.currentStep=1">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
            <div class="modal-body" style="overflow: hidden;">
                <dids-bulk-delete #bulk_delete_component [totalCount]="totalPhoneNumbers"
                                (emmitTotalCount)="getTotalCounttoUpdate($event)"
                                (emmitSelectedCounttoDelete)="getSelectedCounttoUpdate($event)"
                                (closeBulkupdateModaltoDelete)="dismissModalBulk(bulkModal)">
                </dids-bulk-delete>
            </div>
            <div class="modal-footer" [ngClass]="bulk_delete_component.selectedCard=='selectNumbers'?'justify-content-between':'justify-content-end'">
                <div *ngIf="bulk_delete_component.selectedCard=='selectNumbers'">selected {{countofSelectedPhoneNumbers}} of {{countofPhoneNumberstoUpdate}}</div>
                <div class="justify-content-end">
                    <button type="button" class="btn btn-secondary" *ngIf="didService.currentStep==1&&!bulk_delete_component.submitted" (click)="d('Cross click')">Cancel</button>
                    <button type="button" class="btn btn-secondary" *ngIf="didService.currentStep!=1&&!bulk_delete_component.submitted" (click)="bulk_delete_component.previousBtnClicked()">Previous</button>
                    <button type="button" class="btn gradient-pomegranate" [disabled]="!bulk_delete_component.terms" *ngIf="didService.currentStep==3&&!bulk_delete_component.submitted" (click)="bulk_delete_component.onFinish()">Finish</button>
                    <button type="button" *ngIf="didService.currentStep!=3&&!bulk_delete_component.submitted" class="btn gradient-pomegranate" (click)="bulk_delete_component.nextBtnClicked()">Next</button>
                    <button type="button" *ngIf="bulk_delete_component.submitted" class="btn btn-secondary" (click)="onBulkDeteClose(bulk_delete_component)">Close</button>
                </div>
            </div>
    </ng-template>
    <!-- -- -->

</section>

<section>
    <datagrid-filter #dataGridFilter *ngIf="showFilter"
                    [searchFilters]="searchFilters"
                    [columns]="columns"
                     [newButton]="hasPermission(Permissions.CREATE)&&newButton"
                     newButtonText="Add Contact"
                     [refresh]="false"
                     [advanceFilter]="advanceFilter"
                     [dontDisableIfAllsuborg]="true"
                     (newButtonClick)="openForm()"
                     (apply)="applyFilter($event)">
        <ng-template filterControl [column]="'created_user'" let-row="row">
            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
        </ng-template>
        <ng-template filterControl [column]="'location'" let-row="row">
          <tenant-customer-location-select [tenantCustomerId]="tenantCustId" [addButton]="false" class="ng-select-sm" [(value)]="row.value"></tenant-customer-location-select>
      </ng-template>
    </datagrid-filter>
    <ul class="alphabets" [ngClass]="showFilter?'mt-2':''" *ngIf="showAlphabeticFilter">
        <li [ngClass]="{'active':selectedLetter==alphabet}"
            (click)="filterContactByAlphabet(alphabet)"
            *ngFor="let alphabet of alphabets">{{alphabet}}</li>
    </ul>
</section>

<div class="dt-container mt-2" *ngIf="showDataTableSection">

    <!-- <h1 #testTitle>Test title</h1> -->
    
    <ngx-datatable 

        #dataTable 
        class="bootstrap core-bootstrap row-detail-custom"
        [scrollbarH]="false"
        [footerHeight]="50"
        [headerHeight]="40"
        rowHeight="auto"
        [columnMode]="'force'"
        [rows]="rows"
    

        [selectionType]="SelectionType.single"
        (select)="onSelect($event)"
        [count]="page.count"
        [externalPaging]="true"
        [offset]="page.offset"
        [externalSorting]="true"
        [limit]="page.limit"
        (page)="setPage($event)"
        (sort)="onSort($event)"
        [sorts]="[{prop:'first_name',dir:'asc'}]">
        <ngx-datatable-row-detail [rowHeight]="100" #myDetailRow>
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div class="contact-detail d-flex flex-row pt-1">
                    <div class="col-6">
                        <span class="badge-pill"
                              [ngClass]="{'bg-light-success':row.portal_access,'bg-light-secondary':!row.portal_access}">
                            {{ row.portal_access ? 'Portal access' : 'No portal access' }}
                        </span>
                        <div class="d-flex mt-2" *ngIf="row.department">
                            <div class="detail-key">Department</div>
                            <div class="detail-value">{{row.department}}</div>
                        </div>
                        <div class="d-flex" *ngIf="row.fax">
                            <div class="detail-key">Fax</div>
                            <div class="detail-value">{{row.fax | mask:'(000) 000-0000'}}</div>
                        </div>
                        <ng-container *ngFor="let field of row.additional_fields | customfields as custFields">
                            <div class="d-flex">
                                <div class="detail-key" [ngbTooltip]="field.name">{{field.name}}</div>
                                <div class="detail-value">{{field.value || 'NIL'}}</div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-6">
                        <div class="d-flex" *ngIf="row.twitter">
                            <div class="detail-key"><i class="ft-twitter"></i> Twitter</div>
                            <a target="_blank" [href]="row.twitter" class="detail-value">{{row.twitter}}</a>
                        </div>
                        <div class="d-flex" *ngIf="row.instagram">
                            <div class="detail-key"><i class="ft-instagram"></i> Instagram</div>
                            <a target="_blank" [href]="row.instagram" class="detail-value">{{row.instagram}}</a>
                        </div>
                        <div class="d-flex" *ngIf="row.facebook">
                            <div class="detail-key"><i class="ft-facebook"></i> Facebook</div>
                            <a target="_blank" [href]="row.facebook" class="detail-value">{{row.facebook}}</a>
                        </div>
                        <div class="d-flex" *ngIf="row.linkedin">
                            <div class="detail-key"><i class="ft-linkedin"></i> LinkedIn</div>
                            <a target="_blank" [href]="row.linkedin" class="detail-value">{{row.linkedin}}</a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-row-detail>
        <ngx-datatable-column *ngIf="!readOnly" [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false"
                              [canAutoResize]="false">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
                   [class.datatable-icon-down]="expanded"
                   title="Expand/Collapse Row" (click)="$event.stopPropagation();toggleExpandRow(row)"></a>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Name" *ngIf="showColumn('first_name')" prop="first_name" [minWidth]="150">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                    <div *ngIf="row.picture > 0 ; else customAvatar">
                        <img class="rounded-circle mr-1" height="32" width="32" alt="datatable-avatar" />
                    </div>
                    <ng-template #customAvatar>
                        <div class="avatar mr-1 ml-0" [class]="randomDpBackground(row)">
                            <div class="avatar-content">{{ row.first_name + ' ' + (row.last_name || '') | shortName}}
                            </div>
                        </div>
                    </ng-template>
                    <div class="cell-line-height">
                        <p class="line-height-1 mb-0">{{ row.first_name + ' ' + (row.last_name || '')}}</p>
                        <span class="text-muted font-small-2">{{ row.title }}</span>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Customer" *ngIf="showColumn('tenant_customer_name_name')" prop="tenant_customer_name">
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Email" *ngIf="showColumn('email')" prop="email">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <a (click)="$event.stopPropagation()" [href]="'mailto:'+value">{{value}}</a>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Phone" *ngIf="showColumn('phone')" prop="phone">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div *ngIf="!enableCall">
                    {{value | mask:'(000) 000-0000'}}
                </div>
                <div class="call-number-box" *ngIf="enableCall" (click)="callNumber(value)">
                    <div class="call-pretext">CALL:</div>
                    <div class="call-number">{{value | mask:'(000) 000-0000'}} </div>

                    <img *ngIf="value!=''"  title="Call Back"
                    src="assets/img/svg/callback.svg"/>
                </div>


                 <span *ngIf="row.extension">Ext {{row.extension}}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Mobile" *ngIf="showColumn('mobile')" prop="mobile">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngIf="!enableCall">
                    {{value | mask:'(000) 000-0000'}}
                </div>
                <div class="call-number-box" *ngIf="enableCall" (click)="callNumber(value)">
                    <div class="call-pretext">CALL:</div>
                    <div class="call-number">{{value | mask:'(000) 000-0000'}} </div>

                    <img *ngIf="value!=''"  title="Call Back"
                    src="assets/img/svg/callback.svg"/>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Location" *ngIf="showColumn('location_name')" prop="location_name"></ngx-datatable-column>
        <ngx-datatable-column name="Portal Access" *ngIf="showColumn('portal_access')" prop="portal_access">
            <ng-template let-row="row" let-portal_access="value" ngx-datatable-cell-template>
                <span class="badge bg-warning" *ngIf="portal_access">Yes</span>
                <span class="ml-1" *ngIf="portal_access&&row.two_factor_authentication">2FA<strong><i class="ft-check text-success"></i></strong></span>
                <span class="ml-1" *ngIf="portal_access&&!row.two_factor_authentication"><s>2FA</s><strong><i class="ft-x text-danger"></i></strong></span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!readOnly&&showColumn('action')" name="Action"  prop="id" [sortable]="false" [width]="60" [canAutoResize]="false">
            <ng-template let-id="value" let-row="row" ngx-datatable-cell-template>
                <div ngbDropdown container="body">
                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                            (click)="$event.stopPropagation()"
                            ngbDropdownToggle>
                        <i class="ft-more-vertical text-primary"></i>
                    </button>
                    <div ngbDropdownMenu>
                        <button (click)="onSelect({selected:[row]})" *ngIf="hasPermission(Permissions.MODIFY)"
                                class="w-100"
                                ngbDropdownItem>
                            <i class="ft-edit mr-1"></i> Edit
                        </button>
                        <button (click)="deleteRow(id)" *ngIf="hasPermission(Permissions.DELETE)"
                                class="w-100 bg-light-danger"
                                ngbDropdownItem>
                            <i class="ft-trash-2 mr-1"></i> Delete
                        </button>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
              <div class="page-count">
                  <span>{{this.page.pageNumber}}/{{rowCount}} </span>
              </div>
              <datatable-pager class="pr-1" [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                  [hidden]="!((rowCount / pageSize) > 1)" (change)="dataTable.onFooterPage($event)">
              </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>

<ng-template #FormModal let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title">{{bindingModel ? 'Edit Contact' : 'New Contact'}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-0">
        <tenant-customer-contact-form [tenantCustomerId]="tenantCustId" [suborg_id]="suborg_id" [bindingModel]="bindingModel" [tenentSelection]="true"
                                      (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)"
                                      (cancel)="onCancel()">
        </tenant-customer-contact-form>
    </div>
</ng-template>

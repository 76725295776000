import { Routes } from '@angular/router';
import { SuperAdminRouteGuard } from '../gaurd/super-admin.route.gaurd';
import { SUAdminRouteGuard } from '../gaurd/su-or-admin.route.gaurd';

//Route for content layout with sidebar, navbar and footer.

export const INNER_LAYOUT_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'tenants',
        loadChildren: () => import('src/app/modules/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [SuperAdminRouteGuard]
    },
    {
        path: 'users',
        loadChildren: () => import('src/app/modules/user/user.module').then(m => m.UserModule)
    },
    {
        path: 'roles',
        loadChildren: () => import('src/app/modules/roles/roles.module').then(m => m.RolesModule),
        canActivate: [SUAdminRouteGuard]
    },
    {
        path: 'permissions',
        loadChildren: () => import('src/app/modules/permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate: [SuperAdminRouteGuard]
    },
    {
        path: 'resources',
        loadChildren: () => import('src/app/modules/resources/resources.module').then(m => m.ResourcesModule),
        canActivate: [SuperAdminRouteGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'inventory',
        loadChildren: () => import('src/app/modules/inventory/inventory.module').then(m => m.InventoryModule)
    },
    {
        path: 'grids',
        loadChildren: () => import('src/app/modules/grid-profiling/grid-profiling.module').then(m => m.GridProfilingModule)
    },
    {
        path: 'customers',
        loadChildren: () => import('src/app/modules/tenant-customers/tenant-customers.module').then(m => m.TenantCustomersModule)
    },
    {
        path: 'service-tickets',
        loadChildren: () => import('src/app/modules/service-ticket/service-ticket.module').then(m => m.ServiceTicketModule)
    },
    {
        path: 'ring2voice',
        loadChildren: () => import('src/app/modules/ring2voice/ring2voice.module').then(m => m.Ring2VoiceModule)
    },
    {
        path: 'suborgs',
        loadChildren: () => import('src/app/modules/suborg/suborg.module').then(m => m.SuborgModule),
        canActivate: [SUAdminRouteGuard]
    },
    {
        path: 'sales',
        loadChildren: () => import('src/app/modules/sales/sales.module').then(m => m.SalesModule)
    },
    {
        path: 'dispatch',
        loadChildren: () => import('src/app/modules/dispatch-module/dispatch/dispatch.module').then(m => m.DispatchModule)
    },
    {
        path: 'automation',
        loadChildren: () => import('src/app/modules/automation-module/automation/automation.module').then(m => m.AutomationModule)
    },
    {
        path: 'payments',
        loadChildren: () => import('src/app/modules/payments/payments.module').then(m => m.PaymentsModule)
    },
    {
        path: 'accountings',
        loadChildren: () => import('src/app/modules/accounting/accounting.module').then(m => m.AccountingModule)
    }
];

import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from "@angular/core";
import { CallCenterService } from "../services/call-center.service";
import { SipOutBoundFunctionService } from "../services/sipoutboundfunctionservice";



@Directive({
    selector:'[callableNumber]'
})

export class callableNumberDirective{


    @Input() number:any;
    is_call_center_subscribed: boolean = false;


    constructor(private elementRef:ElementRef,
      private renderer:Renderer2,
      public callCenterService: CallCenterService,
      public outboundService: SipOutBoundFunctionService){

    }

    @HostListener('click', ['$event']) onClick($event){

        if(this.is_call_center_subscribed){
            const noToCall = this.number;
            this.callCenterService.popupOpenStatus.next({ isOpen: true, selectedTab: 2, })
            this.outboundService.setOutBoundNumber(noToCall)
        }
        
        
    }
    ngAfterViewInit(){
        
        if(!this.number){
            const hostElement: HTMLElement = this.elementRef.nativeElement;
            const content = hostElement.innerText;
            console.log('Content of the host element:', content);
            this.number = content;
        }

        this.is_call_center_subscribed = this.callCenterService.userCallcenterSubscriptionStatus;


        if(this.is_call_center_subscribed){
        this.elementRef.nativeElement.innerHTML=`
        <div class="call-number-box" >
        <div class="call-pretext" >CALL:</div>
                    <div class="call-number" >${this.number} </div>

                    <img  title="Call Back"
                    src="assets/img/svg/callback.svg"/>
        </div>`
        }else{

        this.elementRef.nativeElement.innerHTML=`
        <div>
        ${this.number}
        </div>`

        }

    }

    testClick(){
        console.log('click');
    }




}

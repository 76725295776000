<div class="container-fluid">
    <div class="row">
        <div class="col-2 pr-0">
            <app-r2v-settings [selectedSettings]="'TimeFrames'"></app-r2v-settings>
        </div>
        <div class="col-10 pl-0">
            <section class="common-grid-view">
                <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
                    <div class="d-flex flex-row flex-1 h-100">
                        <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}" style="overflow: auto;">
                            <div class="d-flex align-items-center pb-10 flex-space-bw"
                                 [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                                <datagrid-filter #dataFilter
                                                 [searchFilters]="search"
                                                 [expanded]="expanded"
                                                 [columns]="columns"
                                                 [defaultFilters]="defaults"
                                                 [module]="module"
                                                 [newButton]="true"
                                                 [newButtonUrl]="['/ring2voice/settings/time-frames/new']"
                                                 (apply)="applyFilter($event)">
            
                                    <ng-template filterControl [column]="'customer'" let-row="row">
                                        <app-customer-select class="ng-select-sm" [(value)]="row.value"
                                                             (change)="dataFilter.controlValueChanged('customer')">
                                        </app-customer-select>
                                    </ng-template>
                                    <ng-template filterControl [column]="'suborg'" let-row="row">
                                        <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue">
                                        </suborg-select>
                                    </ng-template>
                                    <ng-template filterControl column="forward_number_sid" let-row="row">
                                        <forward-bin-select class="ng-select-sm"
                                            [(value)]="row.value"
                                            [suborgId]="suborg_id">
                                        </forward-bin-select>
                                    </ng-template>
                                </datagrid-filter>
                            </div>
                            <div class="card-content">
                                <div [ngClass]="{'card-body':expanded}">
                                    <ngx-datatable #dataTable
                                                   [rows]="rows" class="bootstrap core-bootstrap"
                                                   [columnMode]="'force'"
                                                   [headerHeight]="50"
                                                   [footerHeight]="50"
                                                   rowHeight="auto"
                                                   [scrollbarH]="scrollbarH"
                                                   [selectionType]="SelectionType.single"
                                                   [count]="page.count"
                                                   [externalPaging]="true"
                                                   [offset]="page.offset"
                                                   [externalSorting]="true"
                                                   [limit]="page.limit"
                                                   trackByProp="id"
                                                   (select)="onSelect($event)"
                                                   (page)="setPage($event)"
                                                   (sort)="onSort($event)"
                                                   [sorts]="[{prop:'created_on',dir:'desc'}]">
                                        <ngx-datatable-column name="Name" prop="name" [sortable]="true">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Description" prop="description" [sortable]="false">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by"></ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Created On" prop="created_on">
                                            <ng-template let-created_on="value" ngx-datatable-cell-template>
                                                {{created_on | dateTz}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Type" prop="type">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div class="badge badge-secondary" *ngIf="row.type==='Always'" style="width: 100px;">
                                                    Always
                                                </div>
                                                <div class="badge badge-info" *ngIf="row.type==='Days/Time'" style="width: 100px;">
                                                    Days/Time
                                                </div>
                                                <div class="badge badge-warning" *ngIf="row.type==='Specific Date/Ranges'" style="width: 100px;">
                                                    Specific Dates
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
            
                                        <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false"
                                                              [canAutoResize]="false">
                                            <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                                <div ngbDropdown container="body">
                                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                            (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                        <i class="ft-more-vertical text-primary"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <button *ngIf="UIPermissions.viewInfo" class="w-100" (click)="onSelect({selected:[current]})"
                                                                ngbDropdownItem>
                                                            <i class="ft-book-open mr-1"></i> View
                                                        </button>
                                                        <a *ngIf="UIPermissions.edit"
                                                           [routerLink]="['/ring2voice/settings/time-frames',id,'edit']" class="w-100"
                                                           ngbDropdownItem>
                                                            <i class="ft-edit mr-1"></i> Edit
                                                        </a>
                                                        <button *ngIf="UIPermissions.delete" (click)="deleteTimeFrame(id)"
                                                                class="w-100 bg-light-danger" ngbDropdownItem>
                                                            <i class="ft-trash-2 mr-1"></i> Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
            
                                        <!-- <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false"> 
                                            <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                                <div ngbDropdown container="body">
                                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                        <i class="ft-more-vertical text-primary"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <button [routerLink]="['/users/groups',id,'edit']" class="w-100" ngbDropdownItem>
                                                            <i class="ft-edit mr-1"></i> Edit
                                                        </button>
                                                        <button class="w-100 bg-light-danger"  ngbDropdownItem>
                                                            <i class="fa fa-ban mr-1"></i> Add to Block
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column> -->
            
                                        <ngx-datatable-footer>
                                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                         let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                                <div class="page-count" *ngIf="expanded">
                                                    <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                                </div>
                                                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                                 [pagerRightArrowIcon]="'datatable-icon-right'"
                                                                 [pagerPreviousIcon]="'datatable-icon-prev'"
                                                                 [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                                 [size]="pageSize"
                                                                 [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                                                 (change)="dataTable.onFooterPage($event)">
                                                </datatable-pager>
                                            </ng-template>
                                        </ngx-datatable-footer>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
            
                        <div class="parellax-detail" *ngIf="!expanded && selected">
                            <div class="parellax-detail-content">
                                <div class="d-flex flex-row mt-2">
                                    <h5 class="item-title m-0">{{selected.name}}</h5>
                                    <div class="d-flex justify-content-end align-items-start">
                                        <a *ngIf="UIPermissions.edit"
                                           [routerLink]="['/ring2voice/settings/time-frames/',selected.id,'edit']" ngbTooltip="Edit"
                                           class="btn btn-sm bg-light-secondary">
                                            <i class="ft-edit"></i> Edit
                                        </a>
                                        <button *ngIf="UIPermissions.delete" class="btn btn-sm bg-light-danger ml-1"
                                                (click)="deleteTimeFrame(selected.id)">
                                            <i class="ft-trash-2 mr-1"></i> Delete
                                        </button>
                                        <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                            <i class="ft-x"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row flex-column">
                                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                        <li ngbNavItem>
                                            <a ngbNavLink class="d-flex align-items-center">
                                                <span class="d-none d-sm-block">Overview</span>
                                            </a>
                                            <ng-template ngbNavContent class="tab-content-pd">
                                                <div class="group">
                                                    <div class="group-header d-flex justify-content-between">
                                                        Overview
                                                    </div>
                                                    <div class="group-body">
                                                        <div class="ov-row" *ngIf="selected.id">
                                                            <label class="col-md-3 p-0 item-label">Time Frame Name</label>
                                                            <label class="item-label-value">{{selected.name}}</label>
                                                        </div>
                                                        <div class="ov-row" *ngIf="selected.type">
                                                            <label class="col-md-3 p-0 item-label">When</label>
                                                            <label class="badge" [ngClass]="selected.type==='Always'?'badge-secondary':selected.type==='Specific Date/Ranges'?'badge-warning':'badge-info'">{{selected.type.split("/R")[0]}}</label>
                                                        </div>
                                                        <div class="ov-row" *ngIf="selected.created_on">
                                                            <label class="col-md-3 p-0 item-label">Created On</label>
                                                            <label class="item-label-value">{{selected.created_on|dateTz}}</label>
                                                        </div>
                                                        <div class="ov-row" *ngIf="selected.created_by">
                                                            <label class="col-md-3 p-0 item-label">Created By</label>
                                                            <label class="item-label-value">{{selected.created_by}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="group-body mb-3" *ngIf="selected.type!='Always'">
                                                        
                                                        <div class="mt-3">
                                                            <ngx-datatable [rows]="selected_tfdetails&&selected_tfdetails?.length&&selected_tfdetails[0]?.day_name ? selected_tfdetails : selected_tfdetails[0]?.time_frame"
                                                                           class="bootstrap core-bootstrap"
                                                                           [scrollbarH]="true"
                                                                           rowHeight="auto"
                                                                           [columnMode]="'force'"
                                                                           [headerHeight]="40">
                                                                <ngx-datatable-column *ngIf="selected.type==='Days/Time'" [sortable]="false" [maxWidth]="110" name="Day"  prop="day_name"></ngx-datatable-column>
                                                                <ngx-datatable-column *ngIf="selected.type==='Days/Time'" [sortable]="false" prop="time_frame" name="Time Frame(s)">
                                                                    <ng-template let-rowIndex="rowIndex" let-row="row" let-val="value" ngx-datatable-cell-template >
                                                                        <label class="badge bg-light-info mr-1"  *ngFor="let time of val;let index=index">
                                                                            {{"2023-01-01 "+time.start_time|date:'shortTime'}} - {{"2023-01-01 "+time.end_time|date:'shortTime'}}
                                                                        </label> 
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column *ngIf="selected.type==='Specific Date/Ranges'" [sortable]="false" name="Start Date" prop="start_date">
                                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                                        <label class="badge bg-light-info mr-1">
                                                                            {{row.start_date| date:'MM/dd/yyyy'}}
                                                                        </label> 
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column *ngIf="selected.type==='Specific Date/Ranges'" [sortable]="false" name="Start Time" prop="start_time">
                                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                                        <label class="badge bg-light-info mr-1">
                                                                            {{"2023-01-01 "+row.start_time|date:'shortTime'}}
                                                                        </label> 
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column *ngIf="selected.type==='Specific Date/Ranges'" [sortable]="false" name="End Date" prop="end_date">
                                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                                        <label class="badge bg-light-info mr-1">
                                                                            {{row.end_date| date:'MM/dd/yyyy'}}
                                                                        </label> 
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column *ngIf="selected.type==='Specific Date/Ranges'" [sortable]="false" name="End Time" prop="end_time">
                                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                                        <label class="badge bg-light-info mr-1">
                                                                            {{"2023-01-01 "+row.end_time|date:'shortTime'}}
                                                                        </label> 
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                            </ngx-datatable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </li>
                                    </ul>
                                    <div [ngbNavOutlet]="nav"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>



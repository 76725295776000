<section class="add-user-group">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">
                {{userGroupId>0 ? 'Edit User Group' : 'New User Group'}}
            </h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form name="form" #userForm="ngForm" (ngSubmit)="userForm.form.valid && submit()" novalidate>
                    
                    <div class="row">

                    <div class="col-12 col-md-6">   
                        <div class="form-group">
                            <label class="val-required">Name</label>
                            <input type="text" [(ngModel)]="model.name" #fn="ngModel" name="name" class="form-control" required>
                            <div class="form-text text-muted danger" *ngIf="fn.invalid && (fn.dirty || fn.touched)">
                                <small *ngIf="fn.errors.required">
                                    Name is required.
                                </small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea [(ngModel)]="model.description" name="description" class="form-control"></textarea>
                        </div>
                        <div class="form-group col-12 col-md-5 col-lg-5 p-0">
                            <div class="status-toggle-btn">
                                <label class="title">Dispatch Group :</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="model.is_dispatch" id="is_dispatch" name="is_dispatch" class="custom-control-input">
                                    <label class="custom-control-label" for="is_dispatch">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div class="form-group col-12 col-md-5 col-lg-5 p-0">
                            <div class="status-toggle-btn">
                                <label class="title">Smart SIP group:</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="model.smart_sip_group" id="smart_sip_group" name="active" class="custom-control-input">
                                    <label class="custom-control-label" for="smart_sip_group">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        
                        </div> 
                    </div>

                    <div class="col-12 col-md-6 group1"> 
                        
                        <div class="d-flex" style="justify-content: space-between;">
                            <div class="form-group d-flex align-items-center" >
                                <label class="m-0 mr-2">VoiceMail Enabled :</label>
                                <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                                    <input type="checkbox" [(ngModel)]="model.is_voicemail_enabled" name="is_voice_mail" class="custom-control-input"
                                           id="color-switch-4" checked>
                                    <label class="custom-control-label mr-1" for="color-switch-4">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
            
                            <div class="form-group d-flex align-items-center" *ngIf="model.is_voicemail_enabled">
                                <label class="m-0 mr-2">VoiceMail Send :</label>
                                <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                                    <input type="checkbox" [(ngModel)]="model.is_voicemail_send" name="is_mail_send" class="custom-control-input"
                                           id="color-switch-5" checked>
                                    <label class="custom-control-label mr-1" for="color-switch-5">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        
            
                        </div> 


                        <div class="row" style="margin-top: 10px;margin-bottom: 10px;">

                            <div class="col-12 col-md-6" *ngIf="model.is_voicemail_enabled">
                                <label class="m-0 mr-2">VoiceMail Voice :</label>
                                <ng-select
                                name="voicemail_voice"
                                placeholder="Select voice"
                                [items]="voices | async"
                                bindLabel="name"
                                bindValue="value"
                                [(ngModel)]="model.voicemail_voice">
                                </ng-select>
                                <!--<ng-select   placeholder="Select a Type" class="form-select " [ngModelOptions]="{standalone: true}"  [(ngModel)]="model.voicemail_voice" >
                                    
                                    <ng-option value="male">Male</ng-option>
                                    <ng-option value="female">Female</ng-option>
                                </ng-select>-->
                            </div>
                            
                            
                            <div class="col-12 col-md-6" *ngIf="model.is_voicemail_enabled && model.is_voicemail_send">
                                <label class="val-required" style="margin: 0;">E-MAIL</label>
                                <input type="email" [(ngModel)]="model.voicemail_email" #fn="ngModel" name="voicemail_email" class="form-control" email required>
                                <div class="form-text text-muted danger" *ngIf="fn.invalid && (fn.dirty || fn.touched)">
                                    <small *ngIf="fn.errors.required">
                                        Email is required.
                                    </small>
                                    <small *ngIf="fn.errors?.email">
                                        Please enter a valid email address.
                                    </small>
                                </div>
                            </div>

                        </div>




                        <div class="form-group " *ngIf="model.is_voicemail_enabled">
                            <label>Group message</label>
                            <textarea [(ngModel)]="model.voicemail_message" name="voicemail_message" class="form-control"></textarea>
                        </div>
                    </div>
                    </div>



                    <div class="col-md-12 p-0">
                        <label class="val-required">Users</label>
                       
                        <user-select-grid [userGroupId]="userGroupId" #userSelectGrid [(value)]="model.users" [smart_sip_group]="model.smart_sip_group"></user-select-grid>
                    </div>
                    <div class="col-md-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                        <button type="submit" [disabled]="!userForm.form.valid || this.model.users.length <= 0" class="btn gradient-pomegranate mb-2 mb-sm-0">
                            {{userGroupId > 0 ? 'Save Changes' : 'Create'}}
                        </button>
                        <a type="button" (click)="cancel()" *ngIf="cancel" class="btn btn-secondary ml-sm-2">Cancel</a>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</section>
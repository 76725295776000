<div class="settings-div d-flex mt-2">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 settings-menu">
                <h6 class="title"><i class="ft ft-settings"></i> Settings</h6>
                <ul class="menu-options">
                    <li class="" [ngClass]="selectedSettings=='TimeFrames'?'active':'inactive'"><a (click)="navigateSettings('TimeFrames')">Time Frames</a></li>
                    <li class="" [ngClass]="selectedSettings=='HoldMusic'?'active':'inactive'"><a (click)="navigateSettings('HoldMusic')">Hold Music</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="col-12 col-md-8">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">{{id ? 'Edit Muisc On Hold' : 'Add Muisc On Hold'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <r2v-hold-music-form
                    [selected_Id]="id"
                    (success)="onDataSuccess($event)"
                    (error)="onDataError($event)"
                    (cancel)="onCancel()">
                </r2v-hold-music-form>
            </div>
        </div>
    </div>
</section>

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { DIDNumber } from '../../models/dids.models';
import { DidsService } from '../../services/dids.service';

@Component({
    selector: 'forward-bin-dids',
    templateUrl: './forward-bin-dids.component.html',
    styleUrls: ['./forward-bin-dids.component.scss']
})
export class ForwardBinDidsComponent implements OnInit, OnDestroy {

    private _binsid: string;

    private _chatbinsid: string;

    get forwardBinSid() { return this._binsid; }

    @Input() set forwardBinSid(val: string) {
        if (val !== this._binsid) {
            this._binsid = val;
            this.getData();
        }
    }

    public rows: DIDNumber[];

    @Input() globalRoute:boolean

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    get chatBinSid() { return this._chatbinsid; }

    @Input() set chatBinSid (val: string){
        if(val !== this._chatbinsid){
            this._chatbinsid = val;
            this.getData();
        }
    }

    public page = { count: 0, limit: 25, offset: 0, pageNumber: '0-0' };

    private subs: SubSink = new SubSink();

    public sort: GSortParam[] = [{ colname: 'purchased_on', direction: 'desc' }];

    public sortLookup = {
        did: 'did',
        name: 'name',
        area_code: 'area_code',
        purchased_on: 'purchased_on'
    };

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Number or Name', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Phone Number', colname: 'did', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                { displayName: 'Phone Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' }
            ],
        },
        { displayName: 'Area Code', colname: 'area_code', condition: 'contains', value: '', operator: 'OR' },
    ];

    public columns: GFColumn[] = [
        { displayName: 'Number', columnName: 'did', columnType: GFColumnTypes.phone },
        { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
        {
            displayName: 'Number Type',
            columnName: 'did_type',
            columnType: GFColumnTypes.select,
            optionValueType: GFColumnTypes.ref,
            options: [
                { displayName: 'Local', value: 'Local' },
                { displayName: 'Toll-Free', value: 'Toll-Free' },
            ]
        },
        { displayName: 'Area Code', columnName: 'area_code', columnType: GFColumnTypes.ref },
        { displayName: 'Purchased on', columnName: 'purchased_on', columnType: GFColumnTypes.date }
    ];

    constructor(private didService: DidsService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService) { }

    ngOnInit(): void { }

    getData(filters: GFilterParam[] = null, offset: number = 0) {

        let request = {
            base_filters: [
                { colname: 'is_delete', condition: 'is', value: 'false', operator: 'AND' },
                // { colname: 'forward_bin_sid_resolved', condition: 'equalto', value: this.forwardBinSid, operator: 'AND' }
            ],
            filters: filters,
            sort: this.sort
        };
        if(this.forwardBinSid){
            request.base_filters.push(...[
                { colname: 'forward_bin_sid_resolved', condition: 'equalto', value: this.forwardBinSid, operator: 'AND' }
            ]);
        }else if(this.chatBinSid){
            request.base_filters.push(...[
                { colname: 'message_route_id', condition: 'equalto', value: this.chatBinSid, operator: 'AND' }
            ]);
        }

        this.subs.sink = this.didService.getAllNumbers(this.forwardBinSid,request, offset, this.page.limit,this.chatBinSid,this.forwardBinSid).pipe(
            catchError(err => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        },
        error=>this.toastr.error("Failed to load phone number(s)"));
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) { this.getData(params); }

    export() {
        let request = {
            base_filters: [
                { colname: 'forward_bin_sid_resolved', condition: 'equalto', value: this.forwardBinSid, operator: 'AND' },
                { colname: 'is_delete', condition: 'is', value: 'false', operator: 'AND' }
            ],
            filters: this.dataFilter.getDataFilters(),
            sort: this.sort
        }
        this.subs.sink = this.didService.didExport(request).subscribe(
            resp => {
                var downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(resp);
                downloadLink.download = `Ring2Voice_${moment().format('MM-DD-YYYY-hh_mm')}.csv`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },
            () => {
                this.toastr.error("Unable to export");
            }
        )
    }

    ngOnDestroy(): void { this.subs.unsubscribe(); }
}

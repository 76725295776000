<section class="w-100">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="tabName" (navChange)="onNavChange($event)">
        <li ngbNavItem="calls">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Incoming Routes For Calls</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <app-forward-numbers *ngIf="tabName=='calls'" class="w-100"></app-forward-numbers>
            </ng-template>
        </li>
        <li ngbNavItem="chats">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Incoming Routes For Chats</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <r2v-chat-routes *ngIf="tabName=='chats'" class="w-100"></r2v-chat-routes>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</section>

import { ChangeDetectorRef, ViewChild, ElementRef, Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { SalesService } from '../../../sales/services/sales.service';
import * as _ from 'lodash'
import { Email, EmailHistory, InvoiceItemsList, InvoiceItemsType, InvoiceKitsList, InvoiceList, InvoiceStatus, ItemRow, Section } from '../../../sales/models/invoice.models';
import { InvoiceService } from '../../../sales/services/invoice-service';
import { environment } from 'src/environments/environment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemType } from 'src/app/modules/inventory/models/items';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import * as popup from 'src/app/core/utils/popup.functions';
import { AuthService } from 'src/app/core/services/auth.service';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { CurrencyPipe } from '@angular/common';
import { PaymentsService } from 'src/app/modules/payments/services/payments.service';
import { InvoiceMaterialGridComponent } from '../invoice-material-grid/invoice-material-grid.component';
import { InvoiceTimesheetGridComponent } from '../invoice-timesheet-grid/invoice-timesheet-grid.component';
import { TimeSheet } from '../../../service-ticket/models/common.models';

@Component({
  selector: 'sales-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

    @ViewChild(InvoiceMaterialGridComponent) materials_grid: InvoiceMaterialGridComponent;

    @ViewChild(InvoiceTimesheetGridComponent) timesheet_grid: InvoiceTimesheetGridComponent;

    public  defaultModules = {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['10px', false, '18px', '32px'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [' sans-serif', 'monospace', 'serif']}],
          [{ 'align': '' }],
          ['clean'],
          ['link', 'image', 'video']
        ]
      };

    _item:InvoiceList;
    Pdf_instance: any;
    sendMailActive: boolean;
    isdownload: boolean;

    get item():InvoiceList{
        return this._item
    }

    @Input() set item(value:InvoiceList){
        this._item = value;
    }

    @Input() showEditButton:boolean = true;

    @Input() showDeleteButton:boolean = true;

    @Input() showSendToButton:boolean = true;

    @Input() isReadOnly: boolean = false;

    @Output() onAfterSendEmail =  new EventEmitter()

    @Output() onDelete = new EventEmitter<InvoiceList>();

    @Output() onClose = new EventEmitter();

    public siteUrl: string = environment.siteUrl;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public itemList:InvoiceItemsList[];

    public itemKitsList:ItemRow[];

    public invItemType=InvoiceItemsType;

    public kitList:InvoiceKitsList[];

    public sections:Section[] = [];

    public timesheets: TimeSheet[] = [];

    public sc:InvoiceList;

    public itemSlNo:number=0;

    public invStatus=InvoiceStatus;

    public subs: SubSink = new SubSink();

    public coverageType={1:"Meterial only", 2:"Labor only", 3:"Meterial & Labor"};


    @ViewChild('pdfTable') pdfTable: ElementRef;


    public statusButton:number=3;

    public dropdownStatus=[
                    // {"id":1,"value":"Send to Customer"},
                    {"id":2,"value":"Mark as void"},
                    {"id":3,"value":"Mark as write off"}
                    ];

    public statusActionId=1;
        // email: Email;
        config = {
            placeholder: '',
            tabsize: 2,
            // height: '200px',
            toolbar: [
                ['misc', ['undo', 'redo']],
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['bold', 'italic', 'underline', 'strikethrough']],
                ['fontsize', ['fontname', 'fontsize', 'color']],
                ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
            ],
            fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
        }

    public email = new Email()
    userEmail: string;
    userSesVerified: boolean;

    public viewHistory:boolean=false;

    public email_history: EmailHistory[];

    public email_view_count: number;

    public UIPermissions: UIPermission;

    public get activeDropdownStatus(){
        let test=this.statusActionId;
        return _.find(this.dropdownStatus,function(val)  {
            return val.id==test?val.value:'';
        });
    }

    constructor(private service: InvoiceService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        public salesService: SalesService,
        private modalService: NgbModal,
        private router: Router,
        private authservice:AuthService,
        private ra: ResourceAccessService,
        private currencyPipe: CurrencyPipe,
        private paymentServices: PaymentsService) {
            this.userEmail = this.service.userEmail;
            this.userSesVerified = this.service.userSesVerified;
            this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_INVOICES, ResourceConstants.SALES_MODULE);


        }


    ngOnInit(): void {
        if(this.item.client_viewed)
            this.getViewedHistory()
    }

    setMaterials(_sections: Section[]){
        this.sections= _sections;
        this.item.material_total = Number(this.calculateMaterialsTotal());
    }
    setTimesheets(_timesheets: TimeSheet[]){
        this.timesheets= _timesheets;
        this.item.timesheet_total = Number(this.calculateTimesheetTotal());
    }

    getViewedHistory(){
        this.subs.sink=this.service.getEmailViewedHistory(this.item.unique_number).subscribe(
            response=>{
                this.email_history = response["result"];
                this.email_view_count = this.email_history.map(o => o.dcount).reduce((a, c) => { return a + c });
                this.cdr.markForCheck();
            },
            ()=>{
            this.toastr.error("Unable to get history");
            }
        );
    }

    updateSlNo(){
        this.itemSlNo=this.itemSlNo+1;
    }

    getStatusClass(status:number){


        let soStatus=InvoiceStatus[status];
        let statusClass="";

        switch(soStatus){
        case "New": {
            statusClass="new";
            break;
        }
        case "Draft":{
            statusClass="draft";
            break;
        }
        case "Sent": {
            statusClass="sent-to-customers"
            break;
        }
        case "Paid_in_Full":{
            statusClass="paid_in_full";
            break;
        }
        case "Unpaid":{
            statusClass="Unpaid";
            break;
        }
        case "Partially_Paid":{
            statusClass="partially_paid";
            break;
        }
        case "Void":{
            statusClass="void";
            break;
        }
        case "Write_Off":{
            statusClass="write_off";
            break;
        }
        }

        return statusClass;
    }

    trimTime(date:string){
        if(date&&date.length>0){
        let data=date.split('T');
        return data[0];
        }
        else{
        return '';
        }
    }

    openEmailGetToken(content: any){
        this.paymentServices.getInvoiceToken(this.item.id).subscribe(
            resp => {
                let invoiceToken = resp.uuid;
                this.openEmail(content,invoiceToken);
            },
            err => {
            }
        );
    }

    openEmail(content: any, invoiceToken:string){
        let temp = `<!doctype html>
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
            <head>
                <title>

                </title>
                <!--[if !mso]><!-->
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <!--<![endif]-->
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1">
                <style type="text/css">
                #outlook a { padding:0; }
                body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
                table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
                img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
                p { display:block;margin:13px 0; }
                </style>
                <!--[if mso]>
                <noscript>
                <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
                </xml>
                </noscript>
                <![endif]-->
                <!--[if lte mso 11]>
                <style type="text/css">
                .mj-outlook-group-fix { width:100% !important; }
                </style>
                <![endif]-->

                <!--[if !mso]><!-->
                    <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
                    <style type="text/css">
                    @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
                    </style>
                <!--<![endif]-->



                <style type="text/css">
                @media only screen and (min-width:480px) {
                    .mj-column-per-100 { width:100% !important; max-width: 100%; }
            .mj-column-per-33-333333333333336 { width:33.333333333333336% !important; max-width: 33.333333333333336%; }
                }
                </style>
                <style media="screen and (min-width:480px)">
                .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
            .moz-text-html .mj-column-per-33-333333333333336 { width:33.333333333333336% !important; max-width: 33.333333333333336%; }
                </style>


                <style type="text/css">



                @media only screen and (max-width:480px) {
                table.mj-full-width-mobile { width: 100% !important; }
                td.mj-full-width-mobile { width: auto !important; }
                }

                </style>
                <style type="text/css">

                </style>

            </head>
            <body style="word-spacing:normal;background-color:#FFFFFF;">


                <div
                    style="background-color:#FFFFFF;"
                >


                <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#370D7E" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


                <div  style="background:#370D7E;background-color:#370D7E;margin:0px auto;max-width:600px;">

                    <table
                    align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#370D7E;background-color:#370D7E;width:100%;"
                    >
                    <tbody>
                        <tr>
                        <td
                            style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"
                        >
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->

                <div
                    class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
                >

                <table
                    border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
                >
                    <tbody>

                        <tr>
                            <td
                            align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-bottom:0px;word-break:break-word;"
                            >

                <div
                    style="font-family:Helvetica,Arial,sans-serif;font-size:24px;line-height:120%;text-align:center;color:#D52095;"
                >INVOICE <strong style="text-decoration:none;color:#FFFFFF; "># ${this.item.unique_number}</strong>
                </div>

                            </td>
                        </tr>

                    </tbody>
                </table>

                </div>

                    <!--[if mso | IE]></td></tr></table><![endif]-->
                        </td>
                        </tr>
                    </tbody>
                    </table>

                </div>


                <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


                <div  style="margin:0px auto;max-width:600px;">

                    <table
                    align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                    >
                    <tbody>
                        <tr>
                        <td
                            style="direction:ltr;font-size:0px;text-align:center;"
                        >
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->

                <div
                    class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
                >

                <table
                    border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:#FFFFFF;vertical-align:top;" width="100%"
                >
                    <tbody>

                        <tr>
                            <td
                            align="left" style="font-size:0px;padding:10px 25px;padding-top:25px;padding-bottom:25px;word-break:break-word;"
                            >

                <div
                    style="font-family:Helvetica,Arial,sans-serif;font-size:14px;line-height:120%;text-align:left;color:#000000;"
                >Dear ${this.item.tenant_customer_contact_name};</div>

                            </td>
                        </tr>

                        <tr>
                            <td
                            align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-bottom:25px;word-break:break-word;"
                            >

                <div
                    style="font-family:Helvetica,Arial,sans-serif;font-size:14px;line-height:120%;text-align:left;color:#000000;"
                >As discussed yesterday, please see attached proposals. I have also attached recurring payment forms for the monthly amounts. Please complete and email back to me.
            Please let me know if you have any questions.</div>

                            </td>
                        </tr>

                    </tbody>
                </table>

                </div>

                    <!--[if mso | IE]></td></tr></table><![endif]-->
                        </td>
                        </tr>
                    </tbody>
                    </table>

                </div>


                <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


                <div  style="margin:0px auto;max-width:600px;">

                    <table
                    align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                    >
                    <tbody>
                        <tr>
                        <td
                            style="direction:ltr;font-size:0px;text-align:center;"
                        >
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->

                <div
                    class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
                >

                <table
                    border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
                >
                    <tbody>

                        <tr>
                            <td
                            align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                            >

                <div
                    style="font-family:Helvetica,Arial,sans-serif;font-size:18px;line-height:1;text-align:center;color:#D52095;"
                >INVOICE AMOUNT<strong style="text-decoration:none;color:#370D7E;margin-left: 1em;">${this.currencyPipe.transform(this.item.total_price, 'USD')}</strong></div>

                            </td>
                        </tr>

                    </tbody>
                </table>

                </div>

                    <!--[if mso | IE]></td></tr></table><![endif]-->
                        </td>
                        </tr>
                    </tbody>
                    </table>

                </div>


                <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#F6F5FD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


                <div  style="background:#F6F5FD;background-color:#F6F5FD;margin:0px auto;max-width:600px;">

                    <table
                    align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F6F5FD;background-color:#F6F5FD;width:100%;"
                    >
                    <tbody>
                        <tr>
                        <td
                            style="direction:ltr;font-size:0px;padding:20px 20px;text-align:center;"
                        >
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td align="left" class="" style="" ><![endif]-->

                <div
                    style="font-family:Helvetica,Arial,sans-serif;font-size:15px;font-weight:bold;line-height:12px;text-align:left;color:#000000;"
                ><div>Invoice : <span href="" style="text-decoration:none;color:#370D7E"># ${this.item.unique_number}
            </span></div></br>
            <div style="margin-top: 12px;">Invoice Date: <span style="text-decoration:none;color:#370D7E"> ${new Date(this.salesService.trimTime(this.item.created_on)).toDateString()}
            </span></div></br>
            <div style="margin-top: 12px;">Due Date: <span style="text-decoration:none;color:#370D7E"> ${new Date(this.salesService.trimTime(this.item.due_date?.toString())).toDateString()}
            </span></div>
            </div>

                    <!--[if mso | IE]></td></tr></table><![endif]-->
                        </td>
                        </tr>
                    </tbody>
                    </table>

                </div>


                <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


                <div  style="margin:0px auto;max-width:600px;">

                    <table
                    align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                    >
                    <tbody>
                        <tr>
                        <td
                            style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"
                        >
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


                <div  style="margin:0px auto;max-width:600px;">

                    <table
                    align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                    >
                    <tbody>
                        <tr>
                        <td
                            style="direction:ltr;font-size:0px;padding:10px 0;text-align:center;"
                        >
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:200px;" ><![endif]-->


                    <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:200px;" ><![endif]-->

                <div
                    class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
                >

                <table
                    border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
                >
                    <tbody>

                        <tr>
                            <td
                            align="center" vertical-align="middle" style="font-size:0px;padding:5px 2px;word-break:break-word;"
                            >

                <table
                    border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;"
                >
                    <tbody>
                    <tr>
                        <td
                        align="center" bgcolor="#D52095" role="presentation" style="border:none;border-radius:3px;cursor:auto;height:50px;mso-padding-alt:10px 25px;background:#D52095;" valign="middle"
                        >
                        <a
                         href="${this.siteUrl}paynow/${invoiceToken}" style="display:inline-block;background:#D52095;color:#ffffff;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"
                      >
                        <span style="letter-spacing: 2px;">PAY NOW</span>
                      </a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                            </td>
                        </tr>

                    </tbody>
                </table>

                </div>

                    <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:200px;" ><![endif]-->



                    <!--[if mso | IE]></td></tr></table><![endif]-->
                        </td>
                        </tr>
                    </tbody>
                    </table>

                </div>


                <!--[if mso | IE]></td></tr></table></td><td class="" style="" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


                <div  style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">

                    <table
                    align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;"
                    >
                    <tbody>
                        <tr>
                        <td
                            style="direction:ltr;font-size:0px;padding:20px 0;padding-top:20px;text-align:center;"
                        >
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->

                <div
                    class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
                >

                <table
                    border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
                >
                    <tbody>

                        <tr>
                            <td
                            align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                            >

                <table
                    border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
                >
                    <tbody>
                    <tr>
                        <td  style="width:100px;">
                    <center>
                <img
                    alt="" height="auto" src="https://simplyfuse.com/wp-content/uploads/2023/01/simplyfuse-logo-dark.svg" style="border:none;display:block;outline:none;text-decoration:none;height:auto;width:100px;font-size:13px;" width="100"
                /></center>

                        </td>
                    </tr>
                    </tbody>
                </table>

                            </td>
                        </tr>

                        <tr>
                            <td
                            align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                            >

                <div
                    style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:1;text-align:center;color:#000000;"
                >Powered by</div>

                            </td>
                        </tr>

                    </tbody>
                </table>

                </div>

                    <!--[if mso | IE]></td></tr></table><![endif]-->
                        </td>
                        </tr>
                    </tbody>
                    </table>

                </div>


                <!--[if mso | IE]></td></tr></table></td></tr></table><![endif]-->
                        </td>
                        </tr>
                    </tbody>
                    </table>

                </div>


                <!--[if mso | IE]></td></tr></table><![endif]-->


                </div>

            </body>
            </html>`
        this.email.body = temp;
        this.email.from = this.userEmail;
        this.email.subject = "Invoice #"+this.item.unique_number
        this.email.to = this.item.tenant_customer_contact_email
        this.modalService.open(content, { centered: true, scrollable: true, size: "xl" })
    }

    checkSesVerified(content: any){
        if(!this.userSesVerified){
            this.subs.sink = this.authservice.checkSesVerified().subscribe(
                (resp) => {
                    let title;
                    if(Object.keys(resp['VerificationAttributes']).length === 0){
                        title = `your email address ${this.userEmail} is not verified to send emails`;
                        this.sesVerify(title);
                    }
                    else if(resp['VerificationAttributes'][this.userEmail].VerificationStatus == 'Pending'){
                        this.toastr.error(`Already verification mail send to ${this.userEmail}. It will expire soon..Please confirm it..`);
                    }
                    else if(resp['VerificationAttributes'][this.userEmail].VerificationStatus == 'Failed'){
                        title = `Your Verification link  to ${this.userEmail} Expired.`;
                        this.sesVerify(title);
                    }
                    else{
                        this.openEmailGetToken(content);
                    }
                },
                () => this.toastr.error('Failed')
            );
        }
        else
            this.openEmailGetToken(content);
    }

    sesVerify(title:string) {
        let option: SweetAlertOptions = {
            title: title,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Click here to verify',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn',
                htmlContainer: 'font-small-3',
            },
            buttonsStyling: false,
        };
        popup.OpenConfirmBox(option, (response) => {
            if (response.isConfirmed) {
                this.subs.sink = this.authservice.sendSesVerificationEmailByUser().subscribe(
                    (resp) => {
                        this.toastr.success('Verification Email send successfully');
                    },
                    () => this.toastr.error('Failed')
                );
            }
        });
    }

    modifyInvoiceforPDF(){
        this.item["image_url"] = this.fileUrl + this.item.suborg_primary_image;
        this.sections.forEach((sec, index)=>{
            if(sec&&sec.rows&&sec.rows.length>0){
                sec.rows.forEach((row,i)=>{
                    row.row_total = (row.price*row.quantity).toFixed(2);
                    row.image_link = this.fileUrl + row.primary_image
                })
                sec.section_total = this.priceSummaryFunc(sec.rows);
            }
        });

        if (this.sendMailActive)
            this.sendMailFunc();
        else
            this.generatePdf()
    }

    priceSummaryFunc(rows: ItemRow[]) {
        return rows.map(row => (!isNaN(row.price) && !row.is_delete) ? row.price * row.quantity : 0)?.reduce((a, b) => a + b, 0.00)?.toFixed(2);
    }

    sendMail(){
        this.sendMailActive = true;
        this.modifyInvoiceforPDF();
    }

    sendMailFunc(){
        this.subs.sink = this.service.sendMail(this.email, this.item, this.sections, this.timesheets).subscribe(
            response => {
                this.toastr.success("mail sent succesfully")
                this.modalService.dismissAll()
                this.onAfterSendEmail.emit()
                this.cdr.markForCheck();
            },
            ()=>{
                this.toastr.error("Unable to send mail");
            }
        );
    }

    download(isdownload: boolean){
        this.isdownload = isdownload;
        this.modifyInvoiceforPDF();
    }

    generatePdf(){
        this.subs.sink = this.service.generate_pdf(this.item, this.sections, this.timesheets).subscribe(resp => {
            this.toastr.success("Generated..............")
            var blob = new Blob([resp], {type: "application/pdf"});
                var blob_url = window.URL.createObjectURL(blob);
                if(this.isdownload){
                    var link = document.createElement('a');
                    link.href = blob_url
                    link.download = this.item.unique_number;
                    link.click();
                }
                else{
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = blob_url;
                    document.body.appendChild(iframe);
                    iframe.contentWindow.print();
                    // document.body.removeChild(iframe)
                }
                window.URL.revokeObjectURL(blob_url);
        });
    }

    comingInFuture(){
        window.alert("Sit tight, this feature is coming soon");
    }

    addSpaceAfterComa(str:string){
        var re = /,/gi;
        if(str){
        return str.replace(re,', ');
        }
        else{
        return str;
        }
    }

    dateDiff(date:Date){
        if(date!=null){
        let dueDate = new Date(date);
        let currentDate = new Date();

        let days = Math.floor((dueDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
        if(days>=0){
        return days;

        }
        else{
        return 0;
        }
        }
        else{
        return null;
        }

    }

    closeDetailedView() {
        this.onClose.emit()
    }

    deleteIN(item: any){
        this.onDelete.emit(item)
    }

    calculateMaterialsTotal() {
        let total = 0;
        this.sections.forEach((sec, index) => {
            if (sec && sec.rows && sec.rows.length > 0) {
                sec.rows.forEach((row, i) => {
                    if (!row.is_delete) {
                        total += Number(!isNaN(row.price) ? row.price : 0) * row.quantity;

                    }
                })
            }
        });
        return total.toFixed(2);
    }

    calculateTimesheetTotal() {
        let total = 0;
        this.timesheets.forEach((row, index) => {
            if (!row.is_delete) {
                total += Number(!isNaN(row.total_amount) ? row.total_amount : 0);
            }
        });
        return total.toFixed(2);
    }

    calculateTotal(){
        return Number(this.calculateMaterialsTotal()) + Number(this.calculateTimesheetTotal());
    }

    calculateGrandTotal() {
        return (Number(this.calculateTotal()) + Number(this.calculateTax())).toFixed(2);
    }

    calculateTax() {
        return this.item.tax > 0 ? Number((Number(this.calculateTotal()) * this.item.tax) / 100).toFixed(2) : 0;
    }

    onUpdateMaterials(){
        this.sections = this.materials_grid.sections;
        this.timesheets = this.timesheet_grid.rows;
        this.item.material_total = Number(this.calculateMaterialsTotal());
        this.item.timesheet_total = Number(this.calculateTimesheetTotal());
        this.item.subtotal = Number(this.calculateTotal());
        this.item.total_price = Number(this.calculateGrandTotal());
        this.item.tax_amount = Number(this.calculateTax());
        this.item.balance_due = this.item.total_price - this.item.payments_made;
        this.onSaveInvoice();
        this.cdr.detectChanges();
    }

    onSaveInvoice(message?: string){
        this.service.updateIN(this.item, this.item.id).subscribe(
            resp => {
                if(message){
                    this.toastr.success(message)
                }
            },
            () => {
                this.toastr.error("Error occured...");
            }
        );
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

}

<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter 
                                #dataGridFilter 
                                [defaultFilters]="defaults"
                                [columns]="columns"
                                [searchFilters]="search"
                                [newButton]="UIPermissions.create" 
                                [newButtonUrl]="['/users/new']"
                                [newButtonEnabled]="true"
                                [expanded]="expanded"
                                (apply)="getUsers($event)">

                        <ng-template filterControl [column]="'role'" let-row="row">
                                 <app-role-select class="ng-select-sm" [(value)]="row.value" [customerId]="customerId" ></app-role-select>
                        </ng-template>

                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH"
                                       [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
                                       rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single"
                                       [limit]="50" [sorts]="[{ prop: defaultSort, dir: 'asc' }]"
                                       (select)="onSelect($event)">
                            <ngx-datatable-column name="Name" prop="first_name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.first_name}} {{row.last_name}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Email" prop="email"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Role" prop="rolename"></ngx-datatable-column>
                            <ngx-datatable-column [width]="110" [maxWidth]="120" *ngIf="expanded" cellClass="dispatch-user-column" name="Subscriptions" prop="is_dispatch">
                                <ng-template let-dispatch="value" class="m-0" let-row="row" ngx-datatable-cell-template >
                                    <!-- -{{dispatch}} -->
                                    
                                        <span class="badge badge-success mr-1 mb-1" *ngIf="row.subscription">Call Center</span>
                                        <span class="badge badge-info mr-1 mb-1" *ngIf="dispatch">Dispatch</span>
                                    
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Account" prop="custname">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="2FA" prop="two_factor_enabled"
                                                  [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Enabled' : 'Disabled' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active"
                                                  [sortable]="false">
                                <ng-template let-status="value" let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="!row.is_delete" class="badge"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'Inactive' }}
                                    </div>
                                    <div *ngIf="row.is_delete" class="badge bg-light-danger">
                                            Deleted
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" [name]="isDeleteFilterActive?'Deleted On':'Modified On'" prop="modified_on"
                            [sortable]="false">
                                <ng-template let-date="value" let-row="row" ngx-datatable-cell-template>
                                    {{date|date:'MM/dd/YYYY hh:mm a'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" [name]="isDeleteFilterActive?'Deleted By':'Modified By'" prop="modified_by"
                            [sortable]="false">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <user-card [name]="value" *ngIf="value&&value!=''&&value!=' '"></user-card>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Action" [sortable]="false" prop="id">
                                <ng-template let-id="value" let-row="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body" *ngIf="!row.is_delete">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <a class="w-100" [routerLink]="['/users',id,'edit']" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <a class="w-100 bg-light-danger" *ngIf="isAdmin||isSuperAdmin" (click)="deleteUser(id)" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </a>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">{{selected.first_name}} {{selected.last_name}}</h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a [routerLink]="['/users',selected.id,'edit']" placement="bottom" ngbTooltip="Edit"
                               class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button ngbTooltip="Delete" placement="bottom" *ngIf="isAdmin||isSuperAdmin" (click)="deleteUser(selected.id)" class="btn btn-sm bg-light-danger ml-1"><i _ngcontent-bap-c219="" class="ft-trash-2 mr-1"></i> Delete </button>

                            <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row flex-column">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <div class="group-header d-flex justify-content-between">
                                            Overview
                                            <div class="status-toggle-btn">
                                                <label class="title">Status :</label>
                                                <div
                                                     class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                    <input type="checkbox" [(ngModel)]="selected.is_active"
                                                           id="status" name="active"
                                                           [disabled]="selected.rolename === systemRoles.SuperAdmin"
                                                           class="custom-control-input"
                                                           (change)="changeStatus()">
                                                    <label class="custom-control-label" for="status">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group-body">
                                            <div class="ov-row" *ngIf="selected.custname">
                                                <label class="col-md-3 p-0 item-label">Account</label>
                                                <label class="item-label-value"><b>{{selected.custname}}</b></label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.date_of_birth">
                                                <label class="col-md-3 p-0 item-label">DOB</label>
                                                <label
                                                       class="item-label-value">{{selected.date_of_birth | date:'MM/dd/YYYY'}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.rolename">
                                                <label class="col-md-3 p-0 item-label">Role</label>
                                                <label class="item-label-value">
                                                    {{selected.rolename}}
                                                    <a class="ml-2" target="_blank"
                                                       [routerLink]="['/roles',selected.role,'view']">
                                                        <i class="ft-external-link"></i>
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="group">
                                        <div class="group-body">
                                            <div class="ov-row" *ngIf="selected.email">
                                                <label class="col-md-3 p-0 item-label">Email</label>
                                                <label class="item-label-value">
                                                    <a [href]="'mailto:' + selected.email"
                                                       class="text">{{selected.email}}</a>
                                                    <div class="verification">
                                                        <div class="mt-1"
                                                             [ngClass]="{'badge bg-light-success': selected.email_verified,'text-danger-bold':!selected.email_verified}">
                                                            {{selected.email_verified ? 'Email Verified' : 'Email not Verified'}}
                                                        </div>
                                                        <button *ngIf="!selected.email_verified"
                                                                (click)="sendVerificationLink()"
                                                                class="btn btn-sm btn-info mt-1">
                                                            Send Activation Link
                                                        </button>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                           



                                    <div class="group mt-3">
                                        <div class="group-body">
                                            <user-settings [userId]="selected.id" [user]="selected">
                                            </user-settings>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Privileges</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <div class="mb-2 d-flex flex-row justify-content-between">
                                            <div>
                                                Role : <b>{{selected.rolename}}</b>
                                                <a class="ml-2" target="_blank"
                                                   [routerLink]="['/roles',selected.role,'view']">
                                                    <i class="ft-external-link"></i>
                                                </a>
                                            </div>
                                            <div>Total Previleges : {{currentRolePrevCount || 0 }}</div>
                                        </div>
                                        <div class="group-body group-body-bordered">
                                            <role-previleges [roleId]="selected.role" [(count)]="currentRolePrevCount"></role-previleges>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Subscription</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <div class="group-header d-flex justify-content-between">
                                            Subscription
                                        </div>
                                        <div class="group-body">
                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">Call center</label>
                                                <label class="item-label-value">
                                                    <div class="status-toggle-btn">
                                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control tooltip" [ngStyle]="{'opacity': !selected.customer_is_subscribed?'.7':'1'}">
                                                            <span class="tooltiptext" *ngIf="!selected.customer_is_subscribed">Feature disabled by Tenent</span>
                                                            <input type="checkbox" [disabled]="!selected.customer_is_subscribed" [(ngModel)]="selected.subscription" id="status" name="active" class="custom-control-input" (change)="subscriptionChange('subscription')"/>
                                                            <label class="custom-control-label" for="status" [ngStyle]="{'cursor':!selected.customer_is_subscribed?'not-allowed':'default'}">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="group-body">
                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">Dispatch</label>
                                                <label class="item-label-value">
                                                    <div class="status-toggle-btn">
                                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control tooltip" [ngStyle]="{'opacity': !selected.is_dispatch?'.7':'1'}">
                                                            <span class="tooltiptext" *ngIf="!selected.is_dispatch">Feature disabled by Tenent</span>
                                                            <input type="checkbox" [disabled]="!selected.is_dispatch" [(ngModel)]="selected.is_dispatch" id="dispatch" name="active" class="custom-control-input" (change)="subscriptionChange('is_dispatch')"/>
                                                            <label class="custom-control-label" for="dispatch" [ngStyle]="{'cursor':!selected.is_dispatch?'not-allowed':'default'}">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>



                            <li ngbNavItem >
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">VoiceMail</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">

                                    

                                    <voice-mail-list [user]="selected" (submit)="submit()" [voice_mail_user_id]="selected.id"></voice-mail-list>
                                </ng-template>
                            </li>

                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">HR</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">

                                    <div class="group">
                                        <div class="group-header d-flex justify-content-between">
                                            <div>Working time</div>
                                            <button class="btn btn-primary btn-sm" (click)="addTimeModel()"><i class="ft-plus"></i> Add</button>
                                        </div>
                                        <div class="group-body mb-3">
                                            
                                            <div class="mt-3">

                                                <ngx-datatable
                                                [rows]="uWorkingtime"
                                                class="bootstrap core-bootstrap"
                                                [scrollbarH]="true"
                                                rowHeight="auto"
                                                [columnMode]="'force'"
                                                [headerHeight]="40"

                                                >
                                                <ngx-datatable-column [sortable]="false" [maxWidth]="150" name="Day"  prop="day_name"></ngx-datatable-column>
                                                <ngx-datatable-column [sortable]="false"  prop="availabilty" class="time-column">
                                                    <ng-template let-rowIndex="rowIndex" let-row="row" let-val="value" ngx-datatable-cell-template>
                                                        <!-- {{val|json}} -->
                                                        <label *ngIf="val.length==0" class="text-muted mt-2 mr-2">No working time added for {{row.day_name}}</label>
                                                        <label class="badge bg-light-info mr-1" *ngFor="let time of val;let index=index">
                                                            {{"2023-01-01 "+time.start_time|date:'shortTime'}} - {{"2023-01-01 "+time.end_time|date:'shortTime'}} <a (click)="deleteWorkingTime(time.id, rowIndex, index)"><i class="ft-x ml-1 cursor-pointer"></i></a>
                                                        </label> 
                                                        <button class="btn btn-sm btn-outline-primary custom-time-add" (click)="addTimeModel(row.day_name)"><i class="ft-plus"></i></button>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>

                                            </div>

                                        </div>

                                        <div class="group-body mb-3">
                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">Works overtime?</label>
                                                <label class="item-label-value">
                                                    <div class="status-toggle-btn">
                                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control tooltip" [ngStyle]="{'opacity': !selected.customer_is_subscribed?'.7':'1'}">
                                                            <input type="checkbox" [disabled]="!selected.customer_is_subscribed" [(ngModel)]="selected.is_overtime" (ngModelChange)="updateWorkingOvertimeStatus()" id="status" name="active" class="custom-control-input" />
                                                            <label class="custom-control-label" for="status" [ngStyle]="{'cursor':!selected.customer_is_subscribed?'not-allowed':'default'}">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="group-body pt-1">
                                            
                                            <h6 class="mt-3">Working Rates</h6>
                                             
                                                <ngx-datatable
                                                [rows]="workingRates"
                                                class="bootstrap core-bootstrap"
                                                [scrollbarH]="true"
                                                rowHeight="auto"
                                                [columnMode]="'force'"
                                                [headerHeight]="40"
                                                >
                                                <ngx-datatable-column [sortable]="false" [maxWidth]="200" name="Type"  prop="timesheet_type_name"></ngx-datatable-column>
                                                <ngx-datatable-column [sortable]="false"  name="Normal Rates"  prop="normal_rate">
                                                    <ng-template let-rowIndex="rowIndex" let-normalRate="value"  ngx-datatable-cell-template>
                                                        <div class="input-group price-input-group input-group-sm mb-3" >
                                                            <div class="input-group-prepend">
                                                              <span class="input-group-text">$</span>
                                                            </div>
                                                            <input type="number" class="form-control" (change)="checkForPriceChange()" [(ngModel)]="workingRates[rowIndex].normal_rate" placeholder="Amount" aria-label="Amount (to the nearest dollar)">
                                                            <div class="input-group-append">
                                                              <span class="input-group-text">/Hour</span>
                                                            </div>
                                                          </div>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column [sortable]="false"  name="Type" name="Overtime rates"  prop="overtime_rate" *ngIf="selected.is_overtime">
                                                    <ng-template let-rowIndex="rowIndex" let-overtimeRate="value" ngx-datatable-cell-template>
                                                        <div class="input-group price-input-group input-group-sm mb-3" >
                                                            <div class="input-group-prepend">
                                                              <span class="input-group-text">$</span>
                                                            </div>
                                                            <input type="number" class="form-control" (change)="checkForPriceChange()" [(ngModel)]="workingRates[rowIndex].overtime_rate" placeholder="Amount" aria-label="Amount (to the nearest dollar)">
                                                            <div class="input-group-append">
                                                              <span class="input-group-text">/Hour</span>
                                                            </div>
                                                          </div>
                                                    </ng-template>
                                                </ngx-datatable-column>

                                                </ngx-datatable>
                                       
                                        </div>
                                        <div class="mt-2">
                                            <div>
                                                <button class="btn btn-sm btn-info" [disabled]="!enablePriceSaveBtn" (click)="saveRates()">Save changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Masking</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <div class="group-header d-flex justify-content-between">
                                            Mobile
                                        </div>
                                        <div class="group-body">
                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">Mask Mobile Number</label>
                                                <label class="item-label-value">
                                                    <div class="status-toggle-btn">
                                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                            <input type="checkbox" [(ngModel)]="selected.is_mask_contact" id="maskNumber" name="active" class="custom-control-input" (change)="MobileOnChange()"/>
                                                            <label class="custom-control-label" for="maskNumber">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #addTime let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h4 class="modal-title pull-left">Add new time</h4>
        <button type="button" class="close stock-close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div>
            <ul class="day-selection-ul d-flex flex-wrap mb-3">
                <!-- <li> <input type="checkbox" />  All week</li> -->
                <li class="cursor-pointer"> <input type="checkbox" name="Monday" [(ngModel)]="weekDays[0].checked" [checked]="true"/> Monday</li>
                <li> <input type="checkbox" name="Tuesday" [(ngModel)]="weekDays[1].checked" [checked]="true"/> Tuesday</li>
                <li> <input type="checkbox" name="Wednesday" [(ngModel)]="weekDays[2].checked" [checked]="true"/> wednesday</li>
                <li> <input type="checkbox" name="Thursday" [(ngModel)]="weekDays[3].checked" [checked]="true"/> Thursday</li>
                <li> <input type="checkbox" name="Friday" [(ngModel)]="weekDays[4].checked" [checked]="true"/> Friday</li>
                <li> <input type="checkbox" name="Saturday" [(ngModel)]="weekDays[5].checked" [checked]="true"/> Saturday</li>
                <li> <input type="checkbox" name="Sunday" [(ngModel)]="weekDays[6].checked" [checked]="false"/> Sunday</li>
           </ul>
           <div class="mb-2 d-flex justify-content-between align-items-center timeranage-div" *ngFor="let time of workingTimes;let index=index">
                
                <ngb-timepicker size="small" [(ngModel)]="time.startTime" [meridian]="true" ></ngb-timepicker>
                <div class="">To</div>
                <ngb-timepicker size="small" [(ngModel)]="time.endTime" [meridian]="true"></ngb-timepicker>
                <a (click)="removeTimeRow(index)"><i class="ft-trash-2"></i></a>
           </div>
           <div class="mt-2">
            <button class="btn btn-sm btn-info" (click)="addNewTime()">Add time</button>
           </div>
    
        </div>

      </div>
      <div class="modal-footer">
         <button class="btn btn-secondary" (click)="d('cancel click')">Cancel</button>
         <button class="btn btn-primary" (click)="saveNewTime()">Add</button>

      </div>



</ng-template>

import { PaymentTermsComponent } from './pages/payment-terms/payment-terms.component';
import { IndustriesComponent } from './pages/industries/industries.component';
import { CurrenciesComponent } from './pages/currencies/currencies.component';
import { CustomerTypesComponent } from './pages/customer-types/customer-types.component';
import { AddTenantCustomerComponent } from './pages/add-tenant-customer/add-tenant-customer.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TenantCustomersComponent } from './pages/tenant-customers/tenant-customers.component';
import { RouteGuard } from 'src/app/core/gaurd/route.guard';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { LicenseNumberComponent } from './pages/license-number/license-number.component';
import { CustomerSystemTypesComponent } from './pages/customer-system-types/customer-system-types.component';
import { DistributorsComponent } from './pages/distributors/distributors.component';
import { AddDistributorComponent } from './pages/add-distributor/add-distributor.component';
import { LocationTypesComponent } from './pages/location-types/location-types.component';
import { LocationSystemTypesComponent } from './pages/location-system-types/location-system-types.component';
import { ImportantMessagesComponent } from './pages/important-messages/important-messages.component';
import { ShippingMethodsComponent } from './pages/shipping-methods/shipping-methods.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: TenantCustomersComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMERS,
      permissions: [PermissionConstants.VIEW],
      title: 'Customers'
    }
  },
  {
    path: ':id/view',
    component: TenantCustomersComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMERS,
      permissions: [PermissionConstants.VIEW_INFO],
      title: 'Customer Info'
    }
  },
  {
    path: ':id/view/important-messages/:imId/view',
    component: TenantCustomersComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMERS_IMPORTANT_MESSAGES,
      permissions: [PermissionConstants.VIEW_INFO],
      title: 'Customer Info'
    }
  },
  {
    path: ':id/view/important-messages/:imId/edit',
    component: TenantCustomersComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMERS_IMPORTANT_MESSAGES,
      permissions: [PermissionConstants.MODIFY],
      title: 'Customer Info'
    }
  },
  {
    path: 'new',
    component: AddTenantCustomerComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMERS,
      permissions: [PermissionConstants.CREATE],
      title: 'Add Customer'
    }
  },
  
  {
    path: ':id/edit',
    component: AddTenantCustomerComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMERS,
      permissions: [PermissionConstants.MODIFY],
      title: 'Modify Customer'
    }
  },
  {
    path: 'types',
    component: CustomerTypesComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_TYPES,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer Types'
    }
  },
  {
    path: 'system-types',
    component: CustomerSystemTypesComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_SYSTEM_TYPES,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer System Types'
    }
  },
  {
    path: 'currency',
    component: CurrenciesComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_CURRENCY,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer Currencies'
    }
  },
  {
    path: 'industries',
    component: IndustriesComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_INDUSTRY,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer Industries'
    }
  },
  {
    path: 'payment-terms',
    component: PaymentTermsComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_PAYMENT_TERMS,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer Payment Terms'
    }
  },
  {
    path: 'shipping-methods',
    component: ShippingMethodsComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_SHIPPING_METHODS,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer Payment Terms'
    }
  },
  {
    path: 'license-numbers',
    component: LicenseNumberComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_LICENSE_NUMBERS,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer License Numbers'
    }
  },
  {
    path: 'distributors',
    component: DistributorsComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.DISTRIBUTORS,
      permissions: [PermissionConstants.VIEW],
      title: 'Distributors'
    }
  },
  {
    path: 'distributors/:id/view',
    component: DistributorsComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.DISTRIBUTORS,
      permissions: [PermissionConstants.VIEW_INFO],
      title: 'Distributors : View Info'
    }
  },
  {
    path: 'distributors/new',
    component: AddDistributorComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.DISTRIBUTORS,
      permissions: [PermissionConstants.CREATE],
      title: 'Distributors : Create'
    }
  },
  {
    path: 'distributors/:id/edit',
    component: AddDistributorComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.DISTRIBUTORS,
      permissions: [PermissionConstants.MODIFY],
      title: 'Distributors : Edit'
    }
  },
  {
    path: 'location-types',
    component: LocationTypesComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_LOCATION_TYPES,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer Location Types'
    }
  },
  {
    path: 'location-system-types',
    component: LocationSystemTypesComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_LOCATION_SYSTEM_TYPES,
      permissions: [PermissionConstants.VIEW],
      title: 'Customer Location System Types'
    }
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    pathMatch: 'full',
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.CUSTOMER_MODULE,
      resource: ResourceConstants.CUSTOMER_CONTACTS,
      permissions: [PermissionConstants.VIEW],
      title: 'Contacts'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TenantCustomersRoutingModule { }


export enum ResourceConstants {

    USERS = "USERS",
    ROLES = "ROLES",
    VIEWS = "VIEWS",

    INV_MODULE = "INVENTORY_MODULE",
    INV_ITEMS = "INVENTORY_ITEMS",
    INV_ITEMS_PRICE = "INVENTORY_ITEMS_PRICE",
    INV_ITEMS_SUPPLIERS = "INVENTORY_ITEMS_SUPPLIERS",
    INV_ITEMS_SUPPLIERS_PN = "INVENTORY_ITEMS_SUPPLIERS_PARTNUMBER",
    INV_ITEMS_SUPPLIERS_COST = "INVENTORY_ITEMS_SUPPLIERS_COST",
    INV_ITEMS_STOCK = "INVENTORY_ITEMS_STOCK",
    INV_ITEMS_INVENTORIED = "INVENTORY_ITEMS_INVENTORIED",


    INV_CATEGORIES = "INVENTORY_CATEGORIES",
    INV_ITEMTYPES = "INVENTORY_ITEMTYPES",
    INV_UNITS = "INVENTORY_UNITS",
    INV_SUPPLIERS = "INVENTORY_SUPPLIERS",
    INV_MANUFACTURERS = "INVENTORY_MANUFACTURERS",
    INV_STATE_TAX = "INVENTORY_TAX",
    INV_SALES_ACCOUNTS = "INVENTORY_SALES_ACCOUNTS",
    INV_WAREHOUSE = "INVENTORY_WAREHOUSE",

    INV_KITS = "INVENTORY_ASSEMBLIES",
    INV_KITS_SELL_PRICE = "INVENTORY_ASSEMBLY_SELL_PRICE",
    INV_KITS_COST_PRICE = "INVENTORY_ASSEMBLY_COST_PRICE",
    INV_KITS_ITEMS = "INVENTORY_ASSEMBLY_ITEMS",
    INV_KITS_STOCK = "INVENTORY_ASSEMBLY_STOCK",
    INV_KITS_INVENTORIED = "INVENTORY_ASSEMBLY_INVENTORIED",

    INV_KITS_ASSEMBLE = "INVENTORY_BUILT_ASSEMBLIES",
    INV_KITS_ASSEMBLE_ITEMS = "INVENTORY_BUILT_ASSEMBLY_ITEMS",
    INV_KITS_ASSEMBLE_WAREHOUSE = "INVENTORY_BUILT_ASSEMBLY_WAREHOUSE",

    INV_LABELS = "INV_LABELS",
    INV_LABELS_SETTINGS = "INV_LABELS_SETTINGS",
    INV_LABELS_PRINT = "INV_LABELS_PRINT",

    INV_CATALOG = "INV_CATALOG",
    INV_CATALOG_COMPONENTS = "INV_CATALOGUE_COMPONENTS",

    INV_PRODUCT_LINES = "INVENTORY_PRODUCT_LINES",
    INV_MATERIALS = "INVENTORY_MATERIALS",
    INV_ADDITIONAL_FIELDS = "INV_ADDITIONAL_FIELDS",



    CUSTOMER_MODULE = "CUSTOMER_MODULE",
    CUSTOMERS = "CUSTOMERS",
    CUSTOMER_TYPES = "CUSTOMER_TYPES",
    CUSTOMER_CURRENCY = "CUSTOMER_CURRENCY",
    CUSTOMER_PAYMENT_TERMS = "CUSTOMER_PAYMENT_TERMS",
    CUSTOMER_INDUSTRY = "CUSTOMER_INDUSTRY",
    CUSTOMER_LOCATIONS = "CUSTOMER_LOCATIONS",
    CUSTOMER_LOCATION_TYPES = "CUSTOMER_LOCATION_TYPES",
    CUSTOMER_LOCATION_SYSTEM_TYPES = "CUSTOMER_LOCATION_SYSTEM_TYPES",
    CUSTOMER_CONTACTS = "CUSTOMER_CONTACTS",
    CUSTOMER_LICENSE_NUMBERS = "CUSTOMER_LICENSE_NUMBERS",
    CUSTOMER_SYSTEM_TYPES = "CUSTOMER_SYSTEM_TYPES",
    CUSTOMERS_IMPORTANT_MESSAGES="CUSTOMERS_IMPORTANT_MESSAGES",
    CUSTOMER_SHIPPING_METHODS="CUSTOMER_SHIPPING_METHODS",

    DISTRIBUTORS = "DISTRIBUTORS",
    DISTRIBUTOR_CONTACTS = "DISTRIBUTOR_CONTACTS",
    DISTRIBUTOR_LOCATIONS = "DISTRIBUTOR_LOCATIONS",

    SERVICE_TICKET_MODULE = "SERVICE_TICKET_MODULE",
    SERVICE_TICKETS = "SERVICE_TICKETS",
    ST_PRODUCT_TYPES = "SERVICE_TICKET_PRODUCT_TYPES",
    ST_REASONS_FOR_SERVICE = "SERVICE_TICKET_REASONS_FOR_SERVICE",
    ST_PRIORITIES = "SERVICE_TICKET_PRIORITIES",
    ST_ACTIONS = "SERVICE_TICKET_ACTIONS",
    ST_ESCALATION_PERIODS = "SERVICE_TICKET_ESCALATION_PERIODS",
    ST_TIMSHEET = "SERVICE_TICKET_TIMESHEETS",
    ST_TIMSHEET_DATE = "SERVICE_TICKET_TIMESHEET_DATE",
    ST_TIMSHEET_TYPE = "ST_TIMSHEET_TYPE",

    SALES_MODULE="SALES_MODULE",
    SALES="SALES",
    SALES_INVOICES="SALES_INVOICES",
    SALES_WARRANTY="SALES_WARRANTY",
    SALES_SETTINGS="SALES_SETTINGS",
    SALES_TERMS="SALES_TERMS",
    SALES_SERVICE_CONTRACTS="SALES_SERVICE_CONTRACTS",
    SALES_SALES_ORDERS="SALES_SALES_ORDERS",
    SALES_CATALOG="SALES_CATALOG",

    AUTOMATION_MODULE = "AUTOMATION_MODULE",
    AUTOMATION = "AUTOMATION",
    AUTOMATION_RULES = "AUTOMATION_RULES",
    AUTOMATION_TEMPLATES = "AUTOMATION_TEMPLATES",
    AUTOMATION_SETTINGS = "AUTOMATION_SETTINGS",

    R2V_MODULE = "RING2VOICE_MODULE",
    R2V_PHONE_NUMBERS = "RING2VOICE_PHONE_NUMBERS",
    R2V_PHONE_NUMBER_PURCHASE = "RING2VOICE_PHONE_NUMBER_PURCHASE",
    R2V_FORWARD_NUMBERS = "RING2VOICE_FORWARD_NUMBERS",
    R2V_REPORTS = "RING2VOICE_REPORTS",
    R2V_CHAT = "RING2VOICE_CHAT",
    R2V_SIP_ENDPOINTS = "RING2VOICE_SIP_ENDPOINTS",
    R2V_BLOCK_LIST = 'RING2VOICE_BLOCK_LIST',
    R2V_AUTOMATED_BLOCK_LIST = 'RING2VOICE_AUTOMATED_BLOCK_LIST',
    R2V_TIME_FRAME = 'RING2VOICE_TIME_FRAME',
    R2V_AUTOMATED_ALLOW_LIST = 'R2V_AUTOMATED_ALLOW_LIST',
    R2V_MUSIC_ON_HOLD = 'RING2VOICE_MUSIC_ON_HOLD',
    R2V_SETTINGS = 'RING2VOICE_SETTINGS',

    DISPATCH_MODULE='DISPATCH_MODULE',
    DISPATCH_BOARD='DISPATCH_BOARD',
    DISPATCH_MYBOARD='DISPATCH_MYBOARD',
    DISPATCH_SETTINGS='DISPATCH_SETTINGS',
    DISPATCH_SETTINGS_GENERAL='DISPATCH_SETTINGS_GENERAL'
    
}


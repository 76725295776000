import { TimeSheetsComponent } from './pages/time-sheets/time-sheets.component';
import { EscalationPeriodComponent } from './pages/escalation-period/escalation-period.component';
import { ActionsComponent } from './pages/actions/actions.component';
import { AddServiceTicketComponent } from './pages/add-service-ticket/add-service-ticket.component';
import { ProductTypesComponent } from './pages/product-types/product-types.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallReasonsComponent } from './pages/call-reasons/call-reasons.component';
import { PrioritiesComponent } from './pages/priorities/priorities.component';
import { ServiceTicketsComponent } from './pages/service-tickets/service-tickets.component';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { RouteGuard } from 'src/app/core/gaurd/route.guard';
import { ChecklistComponent } from './pages/checklist/checklist.component';
import { TimesheetTypesComponent } from './pages/timesheet-types/timesheet-types.component';
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ServiceTicketsComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.SERVICE_TICKETS,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Tickets'
    }
  },
  {
    path: ':id/view',
    pathMatch: 'full',
    component: ServiceTicketsComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.SERVICE_TICKETS,
      permissions: [PermissionConstants.VIEW_INFO],
      title: 'Service Ticket Info'
    }
  },
  {
    path: 'new',
    pathMatch: 'full',
    component: AddServiceTicketComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.SERVICE_TICKETS,
      permissions: [PermissionConstants.CREATE],
      title: 'Add Service Ticket'
    }
  },
  {
    path: ':id/edit',
    pathMatch: 'full',
    component: AddServiceTicketComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.SERVICE_TICKETS,
      permissions: [PermissionConstants.MODIFY],
      title: 'Modify Service Ticket'
    }
  },
  {
    path: 'time-sheets',
    pathMatch: 'full',
    component: TimeSheetsComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.ST_TIMSHEET,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Ticket : Time Sheets'
    }
  },
  {
    path: 'product-types',
    pathMatch: 'full',
    component: ProductTypesComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.ST_PRODUCT_TYPES,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Ticket : Product Types'
    }
  },
  {
    path: 'reasons-for-service',
    pathMatch: 'full',
    component: CallReasonsComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.ST_REASONS_FOR_SERVICE,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Ticket : Reason for Service'
    }
  },
  {
    path: 'priorities',
    pathMatch: 'full',
    component: PrioritiesComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.ST_PRIORITIES,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Ticket : Priorities'
    }
  },
  {
    path: 'actions',
    pathMatch: 'full',
    component: ActionsComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.ST_ACTIONS,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Ticket : Actions'
    }
  },
  {
    path: 'escalations',
    pathMatch: 'full',
    component: EscalationPeriodComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.ST_ESCALATION_PERIODS,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Ticket : Escalations'
    }
  },
  {
    path: 'timesheet-type',
    pathMatch: 'full',
    component: TimesheetTypesComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.ST_TIMSHEET_TYPE,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Ticket : Timesheet types'
    }
  },
  {
    path: 'tasklist',
    pathMatch: 'full',
    component: ChecklistComponent,
    canActivate: [RouteGuard],
    data: {
      globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
      resource: ResourceConstants.ST_ESCALATION_PERIODS,
      permissions: [PermissionConstants.VIEW],
      title: 'Service Ticket : Escalations'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceTicketRoutingModule { }

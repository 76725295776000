<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataFilter [searchFilters]="search"
                                     [newButton]="UIPermissions.create"
                                     [newButtonUrl]="['/ring2voice/incoming-routes/chats/new']" [expanded]="expanded"
                                     [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [module]="module"
                                     (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'customer'" let-row="row">
                            <app-customer-select class="ng-select-sm" [(value)]="row.value" (change)="dataFilter.controlValueChanged('customer')"></app-customer-select>
                        </ng-template>
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue">
                            </suborg-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                                       [rows]="rows"
                                       class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [scrollbarH]="scrollbarH"
                                       [selectionType]="SelectionType.single"
                                       [sorts]="[{prop:'name',dir:'asc'}]"
                                       [count]="page.count"
                                       [externalPaging]="true"
                                       [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit"
                                       [trackByProp]="'sid'"
                                       (select)="onSelect($event)"
                                       (page)="setPage($event)"
                                       (sort)="onSort($event)">

                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Incoming Routes" [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <ng-container *ngIf="!row.user_or_group">
                                        {{row.user_name}}
                                    </ng-container>
                                    <ng-container *ngIf="row.user_or_group">{{row.usergroup_name}}</ng-container>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column *ngIf="expanded" name="Type" prop="user_or_group" [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="badge bg-info" *ngIf="row.user_or_group" style="width: 75px;">
                                        User Group
                                    </div>
                                    <div class="badge bg-success" *ngIf="!row.user_or_group" style="width: 75px;">
                                        User
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            
                            <ngx-datatable-column *ngIf="expanded" name="Created On" prop="created_on">
                                <ng-template let-created_on="value" ngx-datatable-cell-template>
                                    {{created_on | dateTz}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Modified On" prop="modified_on">
                                <ng-template let-modified_on="value" ngx-datatable-cell-template>
                                    {{modified_on | dateTz}}
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : ' Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column> -->
                            <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg" [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.customer_name}}</div>
                                    <div>Sub org : {{row.suborg_name}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && !isSuperAdmin && isAllSuborg" name="Suborg" prop="suborg_name"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false"
                                                  [canAutoResize]="false" [width]="80">
                                <ng-template let-sid="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="UIPermissions.viewInfo" class="w-100" (click)="onSelect({selected:[current]})"
                                                    ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>
                                            <a *ngIf="UIPermissions.edit"
                                               [routerLink]="['/ring2voice/incoming-routes/chats',sid,'edit']" class="w-100"
                                               ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button *ngIf="UIPermissions.delete" (click)="deleteForwardBin(sid)"
                                                    class="w-100 bg-light-danger" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                             let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                                     [pagerNextIcon]="'datatable-icon-skip'"
                                                     [page]="curPage" [size]="pageSize" [count]="rowCount"
                                                     [hidden]="!((rowCount / pageSize) > 1)"
                                                     (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>

            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">{{selected.name}}</h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a *ngIf="UIPermissions.edit"
                               [routerLink]="['/ring2voice/incoming-routes/chats/',selected.id,'edit']" ngbTooltip="Edit"
                               class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button *ngIf="UIPermissions.delete" class="btn btn-sm bg-light-danger ml-1"
                                    (click)="deleteForwardBin(selected.id)">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row flex-column">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">

                                        <div class="group-header d-flex justify-content-between">
                                            Overview
                                            <!-- <div class="toggle_set">
                                                <div class="status-toggle-btn justify-content-end mb-2">
                                                    <img src="../../../../../assets/img/svg/info_icon.svg" class="mr-1" alt="" ngbTooltip="Turns on or off all the phone number lines assigned to this route">
                                                    <span class="title">Status :</span>
                                                    <div
                                                        class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                        <input type="checkbox" [(ngModel)]="selected.is_active" id="status"
                                                            [disabled]="selected.is_active ? !UIPermissions.deactivate:!UIPermissions.activate"
                                                            name="active"
                                                            class="custom-control-input"
                                                            (change)="changeStatus()">
                                                        <label class="custom-control-label" for="status">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="status-toggle-btn justify-content-end mb-2">
                                                    <img src="../../../../../assets/img/svg/info_icon.svg" class="mr-1" alt="" ngbTooltip="When on, this route can be used with other companies">
                                                    <span class="title">Global Route :</span>
                                                    <div
                                                        class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                        <input type="checkbox" [(ngModel)]="selected.global_route" id="global_route"
                                                            [disabled]="selected.global_route ? !UIPermissions.deactivate:!UIPermissions.activate"
                                                            name="active"
                                                            class="custom-control-input"
                                                            (change)="changeGlobalRouteStatus()">
                                                        <label class="custom-control-label" for="global_route">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="status-toggle-btn justify-content-end mb-2">
                                                    <img src="../../../../../assets/img/svg/info_icon.svg" class="mr-1" alt="" ngbTooltip="For more advanced settings">
                                                    <span class="title">Call Center :</span>
                                                    <div
                                                        class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                        <input type="checkbox" [(ngModel)]="selected.call_center" id="call_center"
                                                            [disabled]="selected.call_center ? !UIPermissions.deactivate:!UIPermissions.activate"
                                                            name="active"
                                                            class="custom-control-input"
                                                            (change)="changeCallcenterStatus()">
                                                        <label class="custom-control-label" for="call_center">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        
                                        <div class="group-body position-relative">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-9 p-0">
                                                        <div class="ov-row" *ngIf="selected.name">
                                                            <label class="col-md-5 p-0 item-label">Name</label>
                                                            <label class="item-label-value">{{selected.name}}</label>
                                                        </div>
                                                        <div class="ov-row" *ngIf="selected.suborg_name">
                                                            <label class="col-md-5 p-0 item-label">Suborg</label>
                                                            <label class="item-label-value">{{selected.suborg_name}}</label>
                                                        </div>
                                                        <div class="ov-row" *ngIf="selected.created_on">
                                                            <label class="col-md-5 p-0 item-label">Created on</label>
                                                            <label class="item-label-value">{{selected.created_on | dateTz}}</label>
                                                        </div>
                                                        <div class="ov-row" *ngIf="selected.created_by">
                                                            <label class="col-md-5 p-0 item-label">Created by</label>
                                                            <label class="item-label-value">{{selected.created_by}}</label>
                                                        </div>
                                                        <div class="ov-row" *ngIf="selected.modified_on">
                                                            <label class="col-md-5 p-0 item-label">Modified on</label>
                                                            <label class="item-label-value">{{selected.modified_on | dateTz}}</label>
                                                        </div>
                                                        <div class="ov-row" *ngIf="selected.modified_by">
                                                            <label class="col-md-5 p-0 item-label">Modified by</label>
                                                            <label class="item-label-value">{{selected.modified_by}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Phone Numbers</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                  <forward-bin-dids [chatBinSid]="selected.id.toString()" [globalRoute]="false"></forward-bin-dids>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


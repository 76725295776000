import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HoldMusicResponse } from '../../../models/hold-music.model';

@Component({
  selector: 'app-add-hold-music',
  templateUrl: './add-hold-music.component.html',
  styleUrls: ['./add-hold-music.component.scss']
})
export class AddHoldMusicComponent implements OnInit {

  @Input() id;

  constructor(
    private router:Router,
    private toastr: ToastrService,
    public location: Location) { }

  ngOnInit(): void {
  }

  onDataSuccess(result: { response: HoldMusicResponse, message: string }): void {
    this.toastr.success(result.message);
    this.router.navigate(['ring2voice/settings/music-on-hold', result.response.id,'view']);
  }

  onDataError(error: any): void {
      console.error(error);
      this.toastr.error('Operation failed');
  }

  onCancel(): void {
      this.location.back();
  }

}

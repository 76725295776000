import { KitService } from './../../../inventory/services/kit.service';
import { ItemService } from './../../../inventory/services/item.service';
import { Kit, KitWrapper } from './../../../inventory/models/kit.models';
import { TenantCustomerService } from './../../../tenant-customers/services/tenant-customer.service';
import { Observable } from 'rxjs';
import { CustomerContact } from 'src/app/modules/tenant-customers/models/tenant-customer';
import { ServiceTicketService } from './../../services/service-ticket.service';
import { ServiceTicket, STStatusHelper, ServiceTicketStatus, STStatusMapItem, STDashBoardData, STBillingStatus } from './../../models/service-ticket';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { UIPermission } from 'src/app/core/models/common.models';
import { GFilterParam, GFColumn, GFColumnTypes, GSortParam } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { Location } from '@angular/common';
import { SweetAlertOptions } from 'sweetalert2';
import { Item, ItemWrapper } from 'src/app/modules/inventory/models/items';
import { ServiceTicketDataHelper } from '../../helper/service-ticket-data.helper';
import { ChecklistService } from '../../services/checklist.service';
import { StaticFile } from 'src/app/core/models/files.models';
import { StListComponent } from '../../components/st-list/st-list.component';

@Component({
  selector: 'app-service-tickets',
  templateUrl: './service-tickets.component.html',
  styleUrls: ['./service-tickets.component.scss']
})
export class ServiceTicketsComponent implements OnInit, OnDestroy {

  public expanded: boolean = true;

  public Permissions = PermissionConstants;

  public Resources = ResourceConstants;

  public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

  public module: ModuleConstants = ModuleConstants.SERVICE_TICKETS;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public UIPermissions: UIPermission;

  public StatusMap = STStatusHelper.maping;

  public selectedStatus: STStatusMapItem;

  public STStatus = ServiceTicketStatus;


  @ViewChild('stList') stList:StListComponent;

  public dashlet: STDashBoardData;




  public billingStatus = STBillingStatus;


  constructor(private ticketService: ServiceTicketService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    public location: Location,
) {


}

  ngOnInit(): void {

    this.getDashlet();

  }

  getDashlet() {
    this.subs.sink = this.ticketService.getDashBoardData().subscribe(resp => {
      this.dashlet = resp;
      this.cdr.markForCheck();
    });
  }



  updateDashlet(newValue: number, oldStatus: number) {
    this.dashlet[this.getDashletProp(newValue)] += 1;
    this.dashlet[this.getDashletProp(oldStatus)] -= 1;
  }

  getDashletProp(value: number) {

    if (value === this.STStatus.New) {
      return 'new_tickets';
    }
    else if (value === this.STStatus.In_Progress) {
      return 'in_progress';
    }
    else if (value === this.STStatus.Waiting) {
      return 'waiting';
    }
    else if (value === this.STStatus.Escalated_To_Manager) {
      return 'escalated_to_manager';
    }
    else if (value === this.STStatus.Closed) {
      return 'closed';
    }
    return null;
  }



  setFilter(colname: string, value: any) {
    this.stList.setFilter(colname, value);
  }



    ngOnDestroy(): void {
      this.subs?.unsubscribe();
    }

}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatBinPayload, ChatRouteBin } from '../../models/forward-bin.models';
import { SubSink } from 'subsink';
import { Ring2voiceService } from '../../services/ring2voice.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'r2v-chat-route-form',
  templateUrl: './chat-route-form.component.html',
  styleUrls: ['./chat-route-form.component.scss']
})
export class ChatRouteFormComponent implements OnInit {

  public model: ChatBinPayload =new ChatBinPayload();

  public nameError: boolean = false;

  @Input() suborg_id:number;

  @Input() id: string;

  @Output() success = new EventEmitter<{ response: ChatBinPayload, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  private subs: SubSink = new SubSink();

  public isSuperAdmin: boolean;

  constructor(private ring2voiceService: Ring2voiceService,
    private cdr: ChangeDetectorRef,
    public toaster: ToastrService) {
    this.isSuperAdmin = ring2voiceService.isSuperUser;
  }

  ngOnInit(): void {
    if (this.id) {
        this.subs.add(this.ring2voiceService.getChatBinById(this.id).subscribe(bin => { //check suborg
            this.model.name = bin.name;
            this.model.user = bin.user;
            this.model.usergroup = bin.usergroup;
            this.model.suborg = bin.suborg;
            this.model.user_or_group = bin.user_or_group;
            this.model.customer = bin.customer;
            this.cdr.markForCheck();
        }));
    }else{
      this.model = new ChatBinPayload();
      this.model.customer = this.ring2voiceService.customerId;
      this.model.suborg = this.suborg_id ? this.suborg_id : this.ring2voiceService.suborgId;
    }
  }

  onSubmit() {

    if ((!this.model.user_or_group && !this.model.user) || (this.model.user_or_group&&!this.model.usergroup)) {
      this.toaster.warning("Fill All Fields");
        return;
    }

    if(!this.model.user_or_group){
      this.model.usergroup = null;
    }else{
      this.model.user = null;
    }

    let api: Observable<ChatBinPayload> = this.id ? this.ring2voiceService.updateChatBin(this.id, this.model)
        : this.ring2voiceService.createChatBin(this.model);

    this.subs.sink = api.subscribe(
        resp => {
            this.nameError = false;
            let msg = this.id ? `Incoming Routes info updated` : `New Incoming Routes created`;
            this.success.emit({ response: resp, message: msg });
        },
        error => {
            if (error.error === 'NAME_DUPLICATION') {
                this.nameError = true;
            }
            else {
                this.nameError = false;
                this.error.emit(error);
            }
        }
    )
  }

  onCancelClicked(): void { this.cancel.emit(); }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

}

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { BaseService } from 'src/app/core/services/base.service';
import { WEBSOCKET_EVENT } from '../web.socket.enum';



@Injectable({
  providedIn: 'root'
})
export class WebSockectService extends BaseService {

  public socket$!: WebSocketSubject<any>;

  public socketMsg$=new BehaviorSubject<any>(null);

  public socketOpen$ = new BehaviorSubject<boolean>(false);

  public pongTimeStamp: any;

  public connectingSocket$ = new BehaviorSubject<boolean>(null);

  worker: Worker;

  constructor() {
    super();
  }

  connect() {

  
    console.log('in connect')
    let tis = this
    this.worker = new Worker('../web.worker', { type: 'module' });
    this.connectingSocket$.next(true);
    this.worker.onmessage = ({ data }) => { 

      if (data.type === WEBSOCKET_EVENT.OPEN) {

        console.warn("<======Connection established with socket======>");
        tis.socketOpen$.next(true);
        tis.connectingSocket$.next(false);
        let time = moment().format('YYYY-MM-DD HH:mm:ss');
        tis.pongTimeStamp = moment.utc(time, 'YYYY-MM-DD HH:mm:ss');
        
      }

      if (data.type === WEBSOCKET_EVENT.CLOSE) {
        console.warn("<=====Connection with socket is closed=====>")
        tis.socketOpen$.next(false); 
        tis.connectingSocket$.next(false);
    
      }
      if (data.type === WEBSOCKET_EVENT.MESSAGE) {
        console.log('message received website service: ',data.message)
        // tis.socket$.next(data.body);

        if(this.isJson(data.body)){
          tis.socketMsg$.next(JSON.parse(data.body));
        }
        else{
          console.log("-----> Not a json format")
        }


      }

      if (data.type === WEBSOCKET_EVENT.ERROR) {
        tis.connectingSocket$.next(false);
        console.error("<===========Error in socket===========>", data);
      }

      // if(data&&data.type){
      //   tis.socketMsg$.next(data);
      // }


    };


      console.warn('<----Connecting socket---->');
      this.worker.postMessage({ event: WEBSOCKET_EVENT.OPEN });
  }


  // connect() { 


  //   this.connectingSocket$.next(true);
  //   console.warn('<----Connecting socket---->');
  //   this.socket$ = webSocket({
  //     url:this.callcenterSocketURL,
  //     openObserver: {
  //       next: (event) => {
  //         console.warn("<======Connection established with socket======>", event);
  //         this.socketOpen$.next(true);
  //         this.connectingSocket$.next(false);
  //         let time=moment().format('YYYY-MM-DD HH:mm:ss');
  //         this.pongTimeStamp=moment.utc(time, 'YYYY-MM-DD HH:mm:ss');
  //       }
  //     },
  //     closeObserver: {
  //       next:(event)=> {
  //         console.warn("<=====Connection with socket is closed=====>", event)
  //         this.socketOpen$.next(false);
  //         this.connectingSocket$.next(false);
  //       }
  //     }
  //   }); 

  //   this.socket$.subscribe({
  //     next: msg => console.log('message received: ',msg), 
  //     error: err => {
  //       this.connectingSocket$.next(false);
  //       console.error("<===========Error in socket===========>", err);
  //     }, 
  //     complete: () => {
  //       this.socketOpen$.next(false);
  //       this.connectingSocket$.next(false);
  //     }
  //    });

  // } 





  disconnect() {

   this.worker.postMessage({ event: WEBSOCKET_EVENT.CLOSE });
    // console.warn('<--- Disconnecting socket --->');
   // this.socket$.complete();
  }

  isConnected(): boolean {
   // return (this.socket$ == null || undefined ? false : !this.socket$?.closed);
   return this.socketOpen$.getValue()
  }

  onMessage(): Observable<any> {

    return this.socket$!.asObservable().pipe(
      map(message => message)
    );
  }

  send(message: any) {

    console.log('socket status', this.socketOpen$.getValue());

    if (this.socketOpen$.getValue()) {
      console.warn('<---- connected & Sending msg to socket ---->');
     // this.socket$.next(message);

      this.worker.postMessage({event: WEBSOCKET_EVENT.MESSAGE, message: message});
    }


  }


  isJson(text) {
    if (typeof text !== "string") {
        return false;
    }
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
}


}

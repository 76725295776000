import { Injectable } from '@angular/core';
import { Registerer, UserAgent, UserAgentOptions } from 'sip.js';
import { CallCenterService } from './call-center.service';
import { SIPdetailsResponse } from '../models/call-center.models';
import { BehaviorSubject, Subject } from 'rxjs';
import { CallcenterSharedService } from './call-center-shared.service';

@Injectable({
  providedIn: 'root'
})
export class SipConnectionService {




  public agentStatusChange: Subject<boolean> = new Subject<boolean>();



  constructor(private callcenterSharedService:CallcenterSharedService) {
  }

  async initialiseConnection() {

    console.log('sipDetails-',this.callcenterSharedService.sip_details)
    const transportOptions = {
      server: this.callcenterSharedService.sip_details.websocket_uri,
    };
    const uri = UserAgent.makeURI('sip:' + this.callcenterSharedService.sip_details.sip_uri);
    const userAgentOptions: UserAgentOptions = {
      authorizationPassword: this.callcenterSharedService.sip_details.password,
      authorizationUsername: this.callcenterSharedService.sip_details.agent_name,
      transportOptions,
      uri,
      sessionDescriptionHandlerFactoryOptions: {
        earlyMedia: true,
        inviteWithSdpTimeout: 4000,
        iceCheckingTimeout: 1000,
        iceGatheringTimeout: 1000,
        inviteWithoutSdp: true,
        alwaysAcquireMediaFirst: true,
        iceServers: [
          {
            urls: 'stun:stun.l.google.com:19302'
          }
        ]
      }
    };
    this.callcenterSharedService.connection = new UserAgent(userAgentOptions);
    // this.sipOutBoundFnService.connection = this.connection


    // console.log('connection_data', this.sipOutBoundFnService.connection)
    return new Promise((resolve, reject) => {

      this.callcenterSharedService.connection.start().then(() => {
        const registerer = new Registerer(this.callcenterSharedService.connection);
        registerer.register().then(() => {
          registerer.stateChange.addListener((state) => {

            resolve(state)
          });



        }).catch((errorResponse) => {
          console.log('errorResponse : ' + errorResponse);
          reject()

          this.callcenterSharedService.isSIPRegistered$.next(false)
          alert("Registration Failed. Try again")
        });
      }).catch(() => {
        alert("Connection error. Reload and try again")
      })

    });


  }

  


}

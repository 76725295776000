import { ChangeDetectorRef, ViewChild, ElementRef, Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { WarrantyItemsList, WarrantyKitsList, WarrantyList } from '../../../sales/models/warranty.models';
import { WarrantyService } from '../../../sales/services/warranty.service';

import jsPDF from 'jspdf';
// // import pdfMake from 'pdfmake/build/pdfmake';
// // import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import htmlToPdfmake from 'html-to-pdfmake';

import { SalesService } from '../../../sales/services/sales.service';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';


@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.scss']
})

export class WarrantyComponent implements OnInit {

  @Input() item:WarrantyList;

  @Input() showEditButton:boolean = true;

  @Input() showDeleteButton:boolean = true;

  @Output() onDelete = new EventEmitter<WarrantyList>();

  @Output() onClose = new EventEmitter();

  public itemList:WarrantyItemsList[];

  public kitList:WarrantyKitsList[];

  public warranty:WarrantyList;

  public itemSlNo:number=0;

  public subs: SubSink = new SubSink();

  public coverageType={1:"Meterial only", 2:"Labor only", 3:"Meterial & Labor"};

  title = 'htmltopdf';

  @ViewChild('pdfTable') pdfTable: ElementRef;

  public UIPermissions: UIPermission;

  constructor(private WarrantyService: WarrantyService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef, 
    public salesService: SalesService,
    private ra: ResourceAccessService) {

      this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_WARRANTY, ResourceConstants.SALES_MODULE);

   }

   ngOnChanges(changes: SimpleChanges): void {
    if (changes.item.currentValue&&changes.item.previousValue&&changes.item.currentValue.id&&changes.item.currentValue.id>0&&changes.item.previousValue.id&&changes.item.previousValue.id!=changes.item.currentValue.id) {
      this.getItems();
      this.getKits();
    }
  }

  ngOnInit(): void {
    this.getItems();
    this.getKits();
  }

  public downloadAsPDF() {
    // const doc = new jsPDF();
   
    // const pdfTable = this.pdfTable.nativeElement;
   
    // var html = htmlToPdfmake(pdfTable.innerHTML);
     
    // const documentDefinition = { content: html };
    // pdfMake.createPdf(documentDefinition).open(); 
     
  }

  getItems(){
    this.subs.sink=this.WarrantyService.itemsList(this.item.id).subscribe(
      response=>{
          this.itemList=response;
          this.cdr.markForCheck();

      },
      ()=>{
        this.toastr.error("Unable to get items");
      }
    );
  }

  getKits(){
    this.subs.sink=this.WarrantyService.kitsList(this.item.id).subscribe(
      response=>{

        //  window.alert("Got the response");
          this.kitList=response;
          this.cdr.markForCheck();

      },
      ()=>{
        this.toastr.error("Unable to get items");
      }
    );
  }

  trimString(string:string){
    if(string&&string.length>100){
      return string.substring(0, 100)+"..";
    }
    else{
      return string;
    }
  }

  updateSlNo(){
    this.itemSlNo=this.itemSlNo+1;
  }

  openTerms(){

  }

  getStatusClass(status:string){

    let statusClass="";
    switch(status){
      case "Active": {
        statusClass="active";
        break;
      }
      case "Pending": {
        statusClass="pending"
        break;
      }
      case "Draft":{
        statusClass="draft";
        break;
      }
      case "Expired":{
        statusClass="expired";
        break;
      }
    }

    return statusClass;
  }
  addSpaceAfterComa(str:string){
    var re = /,/gi; 
    if(str){
      return str.replace(re,', ');
    }
    else{
      return str;
    }
  }
  deleteSC(item: any) {
    this.onDelete.emit(item)
  }

  closeDetailedView() {
    this.onClose.emit()
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}

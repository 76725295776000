<div class="fup-container d-flex flex-column w-100 h-100 position-relative" [ngClass]="{'readonly-view': readonly}">
    <div class="drop-zone h-100">
        <div class="drop-zone-action h-100" [hidden]="thumbNails.length > 0" [ngClass]="{'blur':!isActive}">
            <input type="file" [accept]="accept" [multiple]="multiple" (change)="fupOnChange($event)" #fileInput />
            <h3>Drag and drop {{placeholder}} here</h3>
            <h5>or</h5>
            <label for="fileDropRef">Browse for {{placeholder}}</label>
        </div>
        <div class="preview h-100" *ngIf="thumbNails.length > 0">
            <button *ngIf="!readonly && upload_image_only" type="button" class="set-primary-btn" [ngClass]="{'btn-info':preview.isPrimary}"
                (click)="setPrimary()">
                {{preview.isPrimary ? 'Primary' : 'Set as Primary'}}
            </button>
            <div class="preview-img-wrapper position-relative">
                <img *ngIf="preview.type=='image/png' || preview.type=='image/jpeg'" class="thumbail-preview" [src]="preview.url" />
                <img *ngIf="preview.type=='video/mp4'" class="thumbail-preview" src="assets/img/gallery/video-icon.png"/>
                <!-- <img *ngIf="preview.type=='application/pdf'" class="thumbail-preview"  src="assets/img/gallery/pdf-icon.png"/> -->
                <audio *ngIf="preview.type=='audio/mpeg'|| preview.type=='audio/wav'|| preview.type=='audio/mp3'" controls class="audio-control" #audioElement (play)="audioPlayerPlay(audioElement)">
                    <source [src]="sanitized.bypassSecurityTrustResourceUrl(preview.url)" type="audio/wav">
                        not supported
                </audio>
                <iframe *ngIf="preview.type=='application/pdf'" [src]="sanitized.bypassSecurityTrustResourceUrl(preview.url)"></iframe>
                <div *ngIf="showPreview" class="hover-fade" (click)="openFullScreen(content)">
                    <i class="ft-search"></i>
                </div>
            </div>
            <button *ngIf="!readonly" type="button" class="delete-btn" (click)="deleteThumbNail()">
                <i class="ft-trash-2"></i>
            </button>
        </div>
    </div>
    <div class="file-list-content">
        <div class="file-list-body">
            <div class="thumbnail" *ngFor="let thumb of thumbNails; let i = index">
                <img *ngIf="thumb.type=='image/png' || thumb.type=='image/jpeg'" class="thumbnail-img" (click)="onImageCliked(thumb,i)" [src]="thumb.url" class="w-100 h-100" />
                <img *ngIf="thumb.type=='video/mp4'" class="w-100 h-100 thumbnail-img" (click)="onImageCliked(thumb,i)" src="assets/img/gallery/video-icon.png"/>
                <img *ngIf="thumb.type=='application/pdf'" class="w-100 h-100 thumbnail-img" (click)="onImageCliked(thumb,i)" src="assets/img/gallery/pdf-icon.png"/>

            </div>
            <div (click)="fileInput.click()" class="thumbnail plus-thumb"
                *ngIf="thumbNails.length > 0 && thumbNails.length < limit && multiple">
                <i class="ft-plus"></i>
            </div>
        </div>
    </div>
    <div class="cs-fade position-absolute w-100 h-100" *ngIf="!isActive"></div>
    <div *ngIf="!isActive"
        class="cs-fade-content d-flex align-items-center justify-content-center position-absolute w-100 h-100">
        <span>Coming Soon</span>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header" style="border:none">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="preview-container">

           
            <img *ngIf="fullScreenPreview.type=='image/png' || fullScreenPreview.type=='image/jpeg'" [src]="fullScreenPreview.url" />
            <video controls *ngIf="fullScreenPreview.type=='video/mp4'">
                <source [src]="fullScreenPreview.url" type="video/mp4" />
                Browser not supported
            </video>
            <!-- <img  style="height: 300px;width: 300px;" (click)="showPdf(fullScreenPreview)" src="assets/img/gallery/pdf-icon.png"/> -->
             
            <iframe *ngIf="fullScreenPreview.type=='application/pdf'" style="width:100%"  height="500px" [src]="sanitized.bypassSecurityTrustResourceUrl(fullScreenPreview.url)"></iframe>
            
            <div class="actions d-flex position-absolute w-100 pl-2 pr-2">
                <button *ngIf="findex > 0" class="btn btn-info btn-icon round" (click)="prev()" type="button">
                    <i class="ft-chevron-left"></i>
                </button>
                <button *ngIf="findex < thumbNails.length - 1" class="btn btn-info btn-icon round ml-auto"
                    (click)="next()" type="button">
                    <i class="ft-chevron-right"></i>
                </button>
            </div>
        </div>
    </div>
</ng-template>

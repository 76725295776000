import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { addSC, SCItemAdd, SCItemsList, SCKitsAdd, SCKitsList, SCList } from '../../models/service-contract.models';
import { FormControl, FormGroup, NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { CustomerLocationSelectComponent } from './../../../tenant-customers/components/select/customer-location-select/customer-location-select.component';
import { CustomerContact, CustomerLocation, TenantCustomer } from 'src/app/modules/tenant-customers/models/tenant-customer';
// import { WarrantyKitItemsComponent } from '../../components/kit-items/kit-items.component';
import { KitItemsComponent } from 'src/app/modules/inventory/components/kit-items/kit-items.component';
import { TermsService } from '../../services/terms.service';
import { getTermsCat, TermsCatResponse } from '../../models/terms.models';
import { first } from 'rxjs/operators';
import { formatDate, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Kit } from 'src/app/modules/inventory/models/kit.models';
import { KitService } from 'src/app/modules/inventory/services/kit.service';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { CustomField } from 'src/app/core/models/custom-field';
import { Observable } from 'rxjs';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UIPermission } from 'src/app/core/models/common.models';
import { ScItemsComponent } from '../../components/sc-items/sc-items.component';
import { WarrantyList } from '../../models/warranty.models';
import { SalesService } from '../../services/sales.service';
import { SalesOrdersService } from '../../services/sales-orders.service';
import { addSO, ItemAdd, SOItemAdd, SOItemsList, SOKitsAdd, SOKitsList } from '../../models/sales-orders.models';
import { SoItemsComponent } from '../../components/so-items/so-items.component';
import { CatalogueService } from 'src/app/modules/inventory/services/catalogue-service.service';
import { CatalogCartService } from '../../services/catalog-cart.service';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';



@Component({
  selector: 'app-sales-orders-add',
  templateUrl: './sales-orders-add.component.html',
  styleUrls: ['./sales-orders-add.component.scss']
})
export class SalesOrdersAddComponent implements OnInit {

  public addData: addSC = new addSC();

  @ViewChild('locationSelect') locationSelect: CustomerLocationSelectComponent;

  @ViewChild('serviceContractForm') public scForm: NgForm;


  public userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));


  public catId: number = 3;

  public kitModel: Kit = new Kit();

  @Input() soId: number = 0;

  @Input() incomingcall_Details;

  @Input() callCenter: boolean = false; // to add service with minimal data

  @Input() callType: string;

  public termsList;

  public termSelection;

  public termsCategory = "";

  public warrantyForm;

  public itemData: SOItemAdd = new SOItemAdd();

  public kitData: SOKitsAdd = new SOKitsAdd();

  public subs: SubSink = new SubSink();

  // @ViewChild('kitItemGrid') kitItemsGrid: KitItemsComponent;

  @Input() model: addSO = new addSO();

  public getTermsData: getTermsCat = new getTermsCat();

  public itemList: SOItemsList[];

  public kitList: SOKitsList[];

  public additionalFields: CustomField[];

  public api;

  public Resource = ResourceConstants;

  public Permissions = PermissionConstants;

  public submitted: boolean = false;

  public formError: boolean = false;

  public UIPermissions: UIPermission;

  public shippingMinDate: NgbDateStruct = { day: 1, month: 3, year: 2022 };

  public _saveAsDraft: boolean = false;

  public tax: number = 0;
  public cartItems: any = localStorage.getItem('catalog-cart-items') !== null ? JSON.parse(localStorage.getItem('catalog-cart-items')) : [];

  public shippingLocation;

  public customerLogged: boolean = false;

  public isCustomer: boolean

  public from_Number_name: string;

  @Input() suborgId: number;

  @Output() isCancelClicked : EventEmitter<boolean> = new EventEmitter<boolean>()

  @Output() isSubmitted : EventEmitter<boolean> = new EventEmitter()



  public formData = new FormGroup({
    customer: new FormControl(this.userAuth.custid),
    title: new FormControl(),
    unique_number: new FormControl(),
    tenant_customer: new FormControl(),
    tenant_customer_contact: new FormControl(),
    tenant_customer_location: new FormControl(),
    starting_date: new FormControl(),
    ending_date: new FormControl(),
    terms_and_condition: new FormControl(),
  });



  constructor(private Service: SalesOrdersService,
    private toaster: ToastrService,
    public location: Location,
    private TermsService: TermsService,
    public datepipe: DatePipe,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private ra: ResourceAccessService,
    private catalogService: CatalogueService,
    private cartService: CatalogCartService,
    public salesService: SalesService,
    private phoneNumberPipe: PhoneNumberPipe) {
    this.subs.sink = activeRoute.params.subscribe(params => this.soId = params.id || 0);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_SALES_ORDERS, ResourceConstants.SALES_MODULE);
    this.isCustomer = this.catalogService.isCustomer
  }

  ngOnInit(): void {
      let test=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
      this.model.suborg=parseInt(localStorage.getItem(AppConstants.CURRENT_SUBORG));
      this.model.coverage_type=3;

    if (this.isCustomer) {
      this.model.tenant_customer = this.userAuth.tenant_customer_id
      this.model.tenant_customer_location = this.userAuth.location_id

    }

    if (this.soId != 0) {
      this.getById(this.soId);
    }
    if (this.callCenter == true) { //from call center

      this.model.suborg = this.suborgId
      this.model.tenant_customer = this.incomingcall_Details.tenant_customer_id;
      this.model.tenant_customer_contact = this.incomingcall_Details.contacts_id;
      this.model.tenant_customer_location = this.incomingcall_Details.location_id;
      if (this.callType == 'Inbound') {
        this.from_Number_name = this.phoneNumberPipe.transform(this.incomingcall_Details.call_from) + ' - ' + this.incomingcall_Details.contact_name;
      }
      else if( (this.callType == 'Outbound') ){
        this.from_Number_name = this.phoneNumberPipe.transform(this.incomingcall_Details.call_to) + ' - ' + this.incomingcall_Details.contact_name;
      }

    }


    this.shippingMinDate.day = Number(this.datepipe.transform((new Date), 'dd'));
    this.shippingMinDate.month = Number(this.datepipe.transform((new Date), 'MM'));
    this.shippingMinDate.year = Number(this.datepipe.transform((new Date), 'yyyy'));

    if(this.cartItems != null && this.soId == 0)
      {
        if(this.cartItems.length>0)
        {
          this.model.from_module = 15
        }
      }
  }

  getById(id: number) {

    this.subs.sink = this.Service.viewSC(id).subscribe(
      response => {
        // this.warranty=response;
        this.model = response;
        this.cdr.markForCheck();
        this.model.shipping_date = this.salesService.trimTime(this.model.shipping_date);

        this.tax = (this.model && this.model.tax) ? this.model.tax : 0;

        // this.setDates(this.model.starting_date, this.model.ending_date);
      },
      () => {
        this.toaster.error("Error occured during fetching data");
      }
    );

  }


  addSO(saveAsDraft:boolean=false, status=null){

    this.model.customer = this.userAuth.custid;

    this._saveAsDraft = saveAsDraft;

    this.submitted = true;

    this.formError = false;

    if (this.checkValidation(saveAsDraft)) {
      if (!saveAsDraft)
        this.toaster.error("You should enter all mandatory fields");
      return;
    }
    else if (this.calculateDiff(this.model.shipping_date, new Date()) > 0) {
      this.formError = true;
      this.toaster.error("Shipping date can't be a past date");
      return;
    }
    else if (!this.model.payment_terms) {
      this.formError = true;
      this.toaster.error("You should select payment terms");
      return;
    }
    this.model.status = saveAsDraft ? 2 : this.soId == 0 ? 1 : status || this.model.status;
    // if(saveAsDraft){
    //   this.model.status=2;
    // }
    // else{
    //   this.model.status=1;
    // }

    if (this.model.shipping_date && this.model.shipping_date != "") {
      this.model.shipping_date = this.model.shipping_date != undefined ? this.model.shipping_date + 'T00:00:00Z' : undefined;
    }
    else {
      this.model.shipping_date = null;
    }

    this.api = this.soId > 0 ? this.Service.updateSC(this.model, this.soId) : this.Service.addSC(this.model);

    this.subs.sink = this.api.subscribe(
      response => {
        this.cartService.clearCart()

        if (this.hasPermission(this.Permissions.VIEW_INFO)) {
          this.router.navigate(['/sales/sales-orders/' + response.id + '/view']);
        }
        else {
          this.location.back();
        }

        this.isSubmitted.emit(true)
        this.toaster.success(this.soId>0?"Sales orders updated":"Sales Order Added successfully");
        this.cartService.clearCart()
        this.cartItems = []

      },
      () => {
        this.toaster.error("Sorry error occured during process");
      }
    );
  }


  checkValidation(saveAsDraft: boolean = false) {
    console.log(this.model.tenant_customer_location);

    if (saveAsDraft) {

      if (this.model.tenant_customer == undefined) {
        this.toaster.error("You should select atleast customer field to save as draft");
        return true;
      }
      else {
        return false;
      }

    }
    else {

            if(this.model.tenant_customer==undefined||
              this.model.tenant_customer_contact==undefined||
              this.model.tenant_customer_location==undefined){

        this.formError = true;

      }
      console.log("diff" + this.calculateDiff(this.model.shipping_date, new Date()));

          }

    if (this.formError) {
      return true;
    }
    else {
      return false;
    }

  }


  calculateDiff(startDate, endDate) {
    endDate = new Date(endDate);
    startDate = new Date(startDate);
    let test = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);

    return test;
}

  //On customer change

  customerChanged(customer: TenantCustomer) {
    console.log("Customer just changed");
    this.model.tenant_customer_location = null;
    this.model.tenant_customer_contact = null;
    this.model.tax = this.tax = (customer && customer.tax_percentage) ? customer.tax_percentage : 0;
    this.model.payment_terms = (customer && customer.payment_terms) ? customer.payment_terms : null;
    this.model.shipment_method = (customer && customer.shipping_method) ? customer.shipping_method : null;
    this.cdr.markForCheck();
  }

  customerContactChange(customer: CustomerContact) {
    console.log('Customer contact just changed');
    console.log(customer);
    this.model.tenant_customer_location = null;
    if (this.model.tenant_customer_contact && customer && customer.location) {
      this.model.tenant_customer_location = customer.location;
    }
  }




  termsSelected(event) {

    this.model.conditions = event.target.value;
    this.model.terms_and_condition = event.target.id;

  }

  termsValueChange(value) {
    this.model.terms_and_condition = value;
  }





  termsChange(terms){
    this.model.conditions=terms ? terms.description : null;
  }








  onCustomerLocationAdded() { this.locationSelect?.reload(); }

  hasPermission(permission: string) {
    return this.ra.hasPermission(this.Resource.SALES_SALES_ORDERS, [permission, this.Permissions.GLOBAL]);
  }

  hasPermissionWR(resource: string, permission: string) {
    return this.ra.hasPermission(resource, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(this.Resource.SALES_SALES_ORDERS, [this.Permissions.GLOBAL]);
  }

  onCancel()
  {
      if(!this.callCenter)
      {
          this.location.back();
      }
      this.isCancelClicked.emit(true)
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


}


export class DIDNumber {

  public sid: string;

  public did: string;

  public name: string;

  public purchased_on: Date;

  public purchase_rate: number;

  public area_code: string;

  public forward_bin_sid: string;

  public did_type: string;

  public created_on: Date;

  public created_by: string;

  public modified_on: Date;

  public modified_by: string;

  public customer: number;

  public is_delete: boolean;

  public sub_org: number;

  public is_sms_outbound_active:boolean = false;

  suborg_name: string;

  customer_name: string;

  forward_bin_name: string;

  forward_number: string;

  forward_bin_sid_resolved: string;

  forward_bin_suborg_id: number;

  forward_bin_global_route: boolean;

  sip: string;

  message_route_id: string;

  message_route_name: string;

  message_route_user_group_name: string;

  message_route_user_name: string;

  message_route_user_or_group: boolean;

  message_route_suborg_id: number;

}

export class DIDType {

  public id: number;

  public name: string;

  public created_on: Date;

  public created_by: string;

  public modified_on: Date;

  public modified_by: string;

  public customer: number;

  public is_delete: boolean;
}

export class DIDRequest {

  public area_code: any;

  public random: boolean = true;

  public quantity: number = 0;

  public number_type: string = DidTypes.LOCAL;

  public suborg_id: number;
}


export class DIDPurchaseRequest {

  phone_numbers: string[];

  did_type: string;

  forward_bin: string;

  suborg_id: number;
}

export interface IDidPurchaseResponse {

  purchased_numbers: any[];

  non_purchased_numbers: any[]
  
  background: boolean;
}


export class DIDNumberReport extends DIDNumber {

  public call_duration: number;

  public call_count: number;
}

export interface IPhoneNumberAvail {

  phone_number: string;

  selected: boolean;
}

export interface IAvailableDidResponse {

  numbers: IPhoneNumberAvail[];

  purchase_rate: number;
}


export enum DidTypes {
  LOCAL = 'Local',
  TOLLFREE = 'Toll-Free'
}


export interface CallsDashBoardData {

  today: number;

  yesterday: number;

  current_week: number;

  last_week: number;

  current_month: number;

  last_month: number;
}

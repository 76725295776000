import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WorkingTimeResponse } from 'src/app/modules/ring2voice/models/time-frame.model';

@Component({
  selector: 'app-add-tf',
  templateUrl: './add-tf.component.html',
  styleUrls: ['./add-tf.component.scss']
})
export class AddTfComponent {

  public sid: number;

  constructor(route: ActivatedRoute,
    private router:Router,
    private toastr: ToastrService,
    public location: Location) {
      this.sid = Number(route.snapshot.paramMap.get('id'));
     }

  onDataSuccess(result: { response: WorkingTimeResponse, message: string }): void {
    this.toastr.success(result.message);
    this.router.navigate(['ring2voice/settings/time-frames', result.response.id,'view']);
  }

  onDataError(error: any): void {
      console.error(error);
      this.toastr.error('Operation failed');
  }

  onCancel(): void {
      this.location.back();
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { HoldMusicPayload, HoldMusicResponse, holdMusicSettingsResp } from '../models/hold-music.model';
import { StaticFile } from 'src/app/core/models/files.models';

@Injectable({
  providedIn: 'root'
})
export class HoldMusicService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString())
    let url = `${this.baseURL}/hold_music/filter/`;
    return this.http.post<DataFilterResponse<HoldMusicResponse[]>>(url, request, { params });
  }

  getById(id:number){
    return this.http.get<HoldMusicResponse>(`${this.baseURL}/hold_music/${id}`);
  }

  createMusic(body:HoldMusicPayload){
    return this.http.post<{status:boolean,message:string,result:HoldMusicResponse}>(`${this.baseURL}/hold_music/`,body);
  }

  updateMusic(id:number,body){
    return this.http.put<{status:boolean,message:string,result:HoldMusicResponse}>(`${this.baseURL}/hold_music/${id}`,body)
  }

  delete(id:number){
    return this.http.delete(`${this.baseURL}/hold_music/${id}`);
  }

  getSettings(userId:number){
    return this.http.get<holdMusicSettingsResp>(`${this.baseURL}/hold_music/${userId}/settings/`);
  }

  SaveSettings(id:number,body:{always_play:number,random_music:boolean}){
    return this.http.post(`${this.baseURL}/hold_music/${id}/settings/`,body);
  }

  _getHoldAudio(id: number) {
    return this.http.get<StaticFile[]>(`${this.baseURL}/holdmusic/${id}/staticfiles/`);
  }
}

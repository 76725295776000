import { WHBaseModel } from "src/app/core/models/common.models";
import { BillingStatus } from "./enums";
import { StaticFile } from "src/app/core/models/files.models";

export class ProductType extends WHBaseModel {

    public id: number;

    public name: string;

    public description: string;

    public is_global: boolean;
}

export class ReasonForCall extends WHBaseModel {

    public id: number;

    public name: string;

    public description: string;

    public required_confirmation:boolean=false;

    public show_message:boolean=false;

    public message:string;

    public priority:number;

    public priority_color:string;

    public priority_name:string;

    public is_global: boolean;

    public is_dispatch_required:boolean;
}

export class Priority extends WHBaseModel {

    public id: number;

    public name: string;

    public color: string;

    public description: string;

    public escalation_period: number;

    public is_global: boolean;

}

export class Action extends WHBaseModel {

    public id: number;

    public name: string;

    public description: string;

    public is_global: boolean;
}

export class EscalationPeriod extends WHBaseModel {

    public id: number;

    public name: string;

    public description: string;

    public start_time: number = 0;

    public start_user: number;

    public start_user_group: number;

    public close_time: number = 0;

    public close_user: number;

    public close_user_group: number;

    //resolved

    start_user_name: string;

    start_user_group_name: string;

    close_user_name: string;

    close_user_group_name: string;

    public is_global: boolean;
}

export class TimeSheet extends WHBaseModel {

    public id: number;

    public unique_number: string;

    public service_ticket: number;

    public tenant_customer: number;

    public tenant_customer_location: number;

    public tenant_customer_contact:number;

    public starting_time: string;

    public closing_time: string;

    public time: number;

    public is_billable: boolean = false;

    public billing_status: number;

    public comments: string;

    public assigned_date: Date;

    public total_amount: number;

    public created_by: string;

    public created_on: Date;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public customer: number;

    public is_active: boolean;

    public is_delete: boolean;

    public is_show_in_pdf: boolean = true;

    customer_name: string;

    location_name: string;

    ticket_number: string;

    contact_name:string;

    row_id: number // Row id of Invoice Item kits table

    pk_id: number // Id of Invoice Item kits table

    order: number;
}

export class Task extends WHBaseModel{

    public id: number;

    public name: string;

    public sub_tasks:SubTask[]=[];

    public is_global: boolean;
}

export class SubTask extends WHBaseModel{

    public id: number;

    public task: number;

    public name: string;

    public description: string;

    public is_mandatory: boolean;

}

export class STSubTask extends SubTask{

    public service_ticket: number;

    public sub_task: number;

    public is_checked?: boolean;

    public images?: string[] = [];

    public images_list?: StaticFile[] = [];

    public comments?: string;

    public is_render_in_pdf?: boolean;
}

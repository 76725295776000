import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { UIPermission } from 'src/app/core/models/common.models';
import { DataFilterRequest, GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { SubSink } from 'subsink';
import { Ring2voiceService } from '../../../services/ring2voice.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from 'src/app/core/services/window.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { Location } from '@angular/common';
import * as popup from 'src/app/core/utils/popup.functions';
import { ChatRouteBin } from '../../../models/forward-bin.models';

@Component({
  selector: 'r2v-chat-routes',
  templateUrl: './chat-routes.component.html',
  styleUrls: ['./chat-routes.component.scss']
})
export class ChatRoutesComponent implements OnInit {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  public rows: ChatRouteBin[];

  public selectedSid: number;

  public selected: ChatRouteBin;

  public expanded: boolean = true;

  public isSuperAdmin: boolean;

  public subs: SubSink = new SubSink();

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public SelectionType = SelectionType;

  public columns: GFColumn[] = [
    { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
    // { displayName: 'Incoming Routes', columnName: 'forward_number', columnType: GFColumnTypes.phone },
    { displayName: 'Created on', columnName: 'created_on', columnType: GFColumnTypes.date }
  ];

  public defaults: GFilterParam[] = [
      { displayName: "All Incoming Routes", colname: '', condition: 'is', value: null, operator: 'AND' },
      // { displayName: "Active Incoming Routes", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
      // { displayName: "Inactive Incoming Routes", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
  ];

  public search: GFilterParam[] = [
      { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
      // { displayName: 'Incoming Routes', colname: 'forward_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone }
  ];

  public sort: GSortParam[] = [{colname:'created_on',direction:'desc'}];

  public sortLookup = {
      name: 'name',
      suborg: 'suborg',
      customer: 'customer',
      created_on:'created_on'
  };

  public module = ModuleConstants.RING2VOICE_FORWARD_BINS;

  public UIPermissions: UIPermission;

  public scrollbarH: boolean;

  public isAllSuborg: boolean = false;

  constructor(private ring2voiceService: Ring2voiceService,
    device: DeviceDetectorService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    public location: Location,
    route: ActivatedRoute,
    @Inject(WINDOW) private window: Window) {
      this.isSuperAdmin = ring2voiceService.isSuperUser
      this.isAllSuborg = ring2voiceService.isAllSuborg;
      if (this.isSuperAdmin) {
        this.columns.push(...[
            { displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref },
            { displayName: 'Sub Org (Choose Tenant First)', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentColumn: 'customer' }
        ]);
      }else if(this.isAllSuborg){
        this.columns.push(
          { displayName: 'Sub Org', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentValue: this.ring2voiceService.customerId });
      }
    this.scrollbarH = device.isMobile();
    this.subs.sink = route.params.subscribe(params => this.selectedSid = params.sid);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.R2V_FORWARD_NUMBERS, ResourceConstants.R2V_MODULE, true);
     }

  ngOnInit(): void {
    this.getData();
    if (this.selectedSid) {
        this.getById(this.selectedSid);
        this.cdr.markForCheck();
    }
  }

  getData(filters: GFilterParam[] = null, offset: number = 0) {

    let request: DataFilterRequest = { filters: filters, sort: this.sort }

    this.subs.sink = this.ring2voiceService.getAllChatBin(request, offset).pipe(
        catchError(() => of({ count: 0, result: [] }))
    ).subscribe(response => {
        this.rows = response.result;
        this.setPagination(offset, response.count);
        this.cdr.markForCheck();
    });
  }

  getById(id: number) {
    this.subs.sink = this.ring2voiceService.getChatBinById(id).subscribe(response => {
        this.selected = response;
        this.expanded = false;
        this.refreshDataTable();
        // this.cdr.markForCheck();
    },
    err=> {console.error('error',err);})
  }

  deleteForwardBin(id: number) {
    popup.ConfirmDelete(result => {
        if (result.isConfirmed) {
            this.subs.sink = this.ring2voiceService.deleteChatBin(id).subscribe(
                resp => {
                  popup.CompleteDelete('Incoming Routes has been deleted.');
                  this.rows = this.rows.filter(x => x.id !== id);
                  this.cdr.markForCheck();
                  this.closeDetailedView();
                },
                () => this.toastr.error('Unable to delete Incoming Routes')
            );
        }
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
        upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: ChatRouteBin = selected[0];
    this.selectedSid = current.id;
    if (current && this.UIPermissions.viewInfo) {
        this.getById(current.id);
        this.expanded = false;
        this.location.go(`/ring2voice/incoming-routes/chats/${current.id}/view`);
        this.refreshDataTable();
        this.cdr.markForCheck();
    }
    this.cdr.markForCheck();
  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go(`/ring2voice/incoming-routes/chats`);
    this.refreshDataTable();
    this.cdr.markForCheck();
  } 

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    let params = this.dataFilter?.getDataFilters() || [];
    this.getData(params, offset);
    this.scrollToTop()
  }

  onSort(event: any) {
      if (event.sorts && event.sorts.length > 0) {
          let current = event.sorts[0];
          if (!this.sortLookup[current.prop])
              return;
          let sort = new GSortParam();
          sort.colname = this.sortLookup[current.prop];
          sort.direction = current.dir;
          this.sort = [sort];
          let params = this.dataFilter?.getDataFilters() || [];
          this.getData(params, 0);
      }
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }

  refreshDataTable() {
    setTimeout(() => {
        this.rows = [...this.rows];
        this.cdr.markForCheck();
    });
  }

  changeStatus(){

  }

  scrollToTop() {
    this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.selectedSid = null;
  }

}

import { PriorityService } from '../../../service-ticket/services/priority.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, of, Subject } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Priority } from '../../../service-ticket/models/common.models';
import { filter, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'priority-select',
  templateUrl: './priority-select.component.html',
  styleUrls: ['./priority-select.component.scss']
})
export class PrioritySelectComponent implements OnInit {

  private _value: number;

  get value(): number {
    return this._value;
  }

  @Input() set value(val: number) {
    this._value = val;
    this.loadInitial();
      if (val > 0 && this.selected !== val) {
          this.setValue(val);
        }else{
          this._selected = val;
        }
  };

  private _selected: number;

  public get selected(): number {
    return this._selected;
  }

  public set selected(value: number) {
    this._selected = value;
    this.valueChange?.emit(value);
  }

  @Input() suborg_id: number;

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<Priority>();

  @Input() addButton: boolean = true;

  @Input() fetchWhenOpen: boolean = false;

  @Input() size:string='sm';

  @Output() clear=new EventEmitter<any>();

  private modalRef: NgbModalRef;

  public options: Observable<Priority[]>;

  public placeholder: string;

  public totalCount: number = 0;

  public currentCount: number = 0;

  public textInput = new Subject<string>();

  public initial: Priority;

  public firstOpen: boolean = true;

  constructor(private service: PriorityService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {

    this.placeholder = this.hasPermission() && this.addButton ? "Select or add Priority" : "Select Priority";
    if (!this.fetchWhenOpen) {
      this.loadInitial();
    }
  }

  loadInitial() {
    this.options = concat(this.getData(), this.textInput.pipe(
      filter(res => res !== null),
      distinctUntilChanged(),
      switchMap(term => this.getData(term))
    ));
  }

  getData(search?: string) {
    let option = {
      sort: [{ colname: 'priority_name', direction: 'asc' }],
      filters: search ? [{ colname: 'priority_name', condition: 'contains', value: search, operator: 'AND' }] : null,
      parent_filters: [{ colname: 'is_global', condition: 'is', value: "true", operator: 'AND' }]
    };
    return this.service.getFiltered(option,0,this.suborg_id,-1).pipe(
      map(resp => {
        if (this.initial && this.initial.id === this.selected) {
          resp.result = resp.result.filter(item => item.id !== this.selected).concat(this.initial);
        }
        this.totalCount = resp.count;
        this.currentCount = resp.result.length;
        return resp.result;
      })
    );
  }

  setValue(id: number) {
    this.options = this.service.getById(id).pipe(map(response => {
      this.initial = response;
      return [response];
    }));
    this._selected = id;
  }

  selectOpen() {
    if (this.fetchWhenOpen && (!this.options || this.firstOpen)) {
      this.loadInitial();
      this.firstOpen = false;
    }
  }

  trackByFn(item: Priority) { return item.id; }

  openModal(content: any): void { this.modalRef = this.modalService.open(content, { centered: true }); }

  onDataSubmittedSuccess(result: { response: Priority, message: string }): void {
    this.toastr.success(result.message);
    this.options = concat(this.options, of([result.response]));
    this.selected = result.response.id;
    this.modalRef?.dismiss();//this.modalService.dismissAll();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalRef?.dismiss();//this.modalService.dismissAll();
  }

  onChange(event){
    this.change?.emit(event);
    //this.modalService.dismissAll();
    this.modalRef?.dismiss();
  }

  onClear(){
    this.loadInitial();
  }

  onSearch(){
    this._selected = 0;
  }

  onCancel(): void {
    this.modalRef?.dismiss();//this.modalService.dismissAll();
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.ST_PRIORITIES, [PermissionConstants.CREATE, PermissionConstants.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.SERVICE_TICKET_MODULE, [PermissionConstants.GLOBAL]);
  }

  reset(){
    this.clear.emit();
  }
}

import { WHBaseModel } from "src/app/core/models/common.models";
import { id } from "src/app/core/utils/global.functions";
import { Sections } from "../../inventory/models/catalogue.models";
import { STSubTask, TimeSheet } from "./common.models";

export class ServiceTicket extends WHBaseModel {

    public id: number;

    public title: string;

    public unique_number: string;

    public tenant_customer: number;

    public tenant_customer_contact: number;

    public tenant_customer_location: number;

    public status: ServiceTicketStatus = ServiceTicketStatus.New;

    public escalation_period: number;

    public product_type: number;

    public priority: number;

    public associate_item: number;

    public associate_kit: number;

    public next_action: number;

    public assigned_user: number;

    public assigned_user_group: number;

    public is_billable: boolean;

    public sub_total: number;

    public reason_for_call: number;

    public reason_for_call_name: string;

    public reason_for_call_message:string;

    public reason_for_call_show:boolean;

    public message_isuser_confirmation:boolean;

    public confirmation_required:boolean;

    public images: string[] = [];

    public primary_image: string;


    public call_to_sid: string;

    public call_from: string;

    public client_viewed:boolean;

    public mail_sent_date :string;

    public client_viewed_date :string;

    public total_price:number;

    public invoiced_total:number;

    public balance_due:number;

    public material_total:number;

    public timesheet_total:number;

    public tax:number;

    public tax_amount:number;

    public from_unique_number: string;

    //resolved fields

    tenant_customer_name: string;

    tenant_customer_contact_name: string;

    tenant_customer_location_name: string;

    assigned_user_name: string;

    assigned_user_rolename: string;

    assigned_user_group_name: string;

    priority_name: string;

    product_type_name: string;

    escalation_period_name: string;

    next_action_name: string;

    associate_item_name: string;

    associate_kit_name: string;

    service_ticket_billable: boolean;

    color: string;

    task: number;

    task_name: string;

    public call_to_did: string;

    public call_to_did_name: string;
    public tenant_customer_contact_email:string;

    public tenant_customer_contact_phone:string;

    public tenant_customer_contact_mobile:string;

    public tenant_customer_location_address_2:string;

    public tenant_customer_location_address:string;

    public tenant_customer_location_state:string;

    public tenant_customer_location_city:string;

    public tenant_customer_location_zip:string;

    public status_name:string;

    public expected_service_start_date: Date;

    public expected_service_end_date:Date;


    public location_longitude:string;

    public location_latitude:string;

    public suborg_name:string;

    public suborg_address:string;

    public suborg_city:string;

    public suborg_zip:string;

    public suborg_state:string;

    public suborg_primary_image:string;

    public tenant_customer_is_vip:boolean;

    public call_sid?:string
    }

export enum ServiceTicketStatus {
    New = 1,
    In_Progress = 2,
    Waiting = 3,
    Escalated_To_Manager = 4,
    Closed = 5
}

export interface STStatusMapItem {
    displayName: string;
    value: any;
    class: string;
}

export class STStatusHelper {

    static get maping(): { [value in ServiceTicketStatus]: STStatusMapItem } {
        return {
            [ServiceTicketStatus.New]: {
                displayName: 'New',
                value: ServiceTicketStatus.New,
                class: 'new bg-warning'
            },
            [ServiceTicketStatus.In_Progress]: {
                displayName: 'In Progress',
                value: ServiceTicketStatus.In_Progress,
                class: 'in_progress bg-success'
            },
            [ServiceTicketStatus.Waiting]: {
                displayName: 'Waiting',
                value: ServiceTicketStatus.Waiting,
                class: 'waiting bg-light-danger'
            },
            [ServiceTicketStatus.Escalated_To_Manager]: {
                displayName: 'Escalated To Manager',
                value: ServiceTicketStatus.Escalated_To_Manager,
                class: 'escalated bg-danger'
            },
            [ServiceTicketStatus.Closed]: {
                displayName: 'Closed',
                value: ServiceTicketStatus.Closed,
                class: 'closed bg-info'
            },
        }
    }
}

export enum STBillingStatus {
    Not_Billable = 1,
    Un_Billed = 2,
    Partially_Invoiced = 3,
    Fully_Invoiced = 4
}

export class STBillingStatusHelper {
    static get maping(): { [value in STBillingStatus]: STStatusMapItem } {
        return {
            [STBillingStatus.Not_Billable]: {
                displayName: 'Not Billable',
                value: STBillingStatus.Not_Billable,
                class: 'not_billable not_billable_gradient'
            },
            [STBillingStatus.Un_Billed]: {
                displayName: 'Un Billed',
                value: STBillingStatus.Un_Billed,
                class: 'un_billed un_billed_gradient'
            },
            [STBillingStatus.Partially_Invoiced]: {
                displayName: 'Partially Invoiced',
                value: STBillingStatus.Partially_Invoiced,
                class: 'partially_invoiced partially_invoiced_gradient'
            },
            [STBillingStatus.Fully_Invoiced]: {
                displayName: 'Fully Invoiced',
                value: STBillingStatus.Fully_Invoiced,
                class: 'fully_invoiced fully_invoiced_gradient'
            }
        }
    }
}

export interface STDashBoardData {

    new_tickets: number;

    in_progress: number;

    waiting: number;

    escalated_to_manager: number;

    closed: number;

    un_billed: number;

    partially_invoiced: number;

    fully_invoiced:number;

    not_billable: number;
}

export class STComment {

    public id: number;

    public service_ticket: number;

    public comments: string;

    public parent: number;

    public customer: number;

    public created_by: string;

    public created_on: Date;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public is_delete: boolean;

    public is_active: boolean;

    public suborg: number;

    edit: boolean;

    inputRows: number = 1;
}

export enum STItemsType {
    ITEM = 'item',
    KIT = 'kit',
    TIMESHEET='timesheet'
}

export class Section{
    public title:string;
    public rows:ItemRow[]=[];
    public section_total:string;
    public is_edit?:boolean = false;
}

export class ItemRow {
    public id:number;
    public service_ticket:number;
    public unique_id:number;
    public created_on:string;
    public created_user:number;
    public modified_on:string;
    public modified_user:string;
    public is_delete:boolean;
    public is_active:boolean;
    public serial_number:string;
    public quantity:number=1;
    public section_title:string;
    public price:number;
    public type:STItemsType;
    public name:string;
    public customer:number;
    public part_number:string;
    public uom:string;
    public sku:string;
    public expanded:boolean = false;
    public children: ItemRow[];
    public primary_image:string;
    public row_total:string;
    public order:number;
    public level:number;
    public color:string;
    public partnumber:string;
    public image_link:string;
    public description:string;
    public invoiced_quantity:number = 0;
    public is_show_in_pdf: boolean;
    public is_billable: boolean;
    showErrorMessage: boolean;
    previous_quantity: number;
    total: number;
    from_module: number;
    from_unique_number:string;
    row_id: number;
    ordered_quantity : number = 0;
    service_ticket_quantity: number = 0;
    qty_tobe_service_ticket: number;
}

export class ItemAdd{
    public id:number;
    public service_ticket:number;
    public customer:number;
    public unique_id:number;
    public quantity:number;
    public section_title:string;
    public price:number;
    public type:string;
    public name:string;
    public serial_number:string;
    public is_delete:boolean;
    public from_unique_number: string
    public ordered_quantity: number = 0;
    public invoiced_quantity: any;
    part_number: string;
    sku: string;
    uom: string;
    primary_image: string;
    description: string;
    is_show_in_pdf: boolean;
    is_billable: boolean;
    suborg: number;
    order: number;
    total: string;
    service_ticket_quantity: number;
}

export class STItemRow {

    public id: number;

    public item_id: number;

    public itemType: STItemsType;

    public quantity: number = 1;

    public kit_id: number;

    public part_number: string;

    public sell_price: number = 0;

    public sell_price_calc: number = 0;

    public primary_image: string;

    public name: string;

    public guid: string;

    public customer: number;

    public children: STItemRow[];

    public expanded:boolean = false;

    public order:number;

    public serial_number:string;

    public coverage_type:number;

    public billing_interval:number;

    public price:number;


    constructor(){
        this.guid = id();
    }
}

export class Email{

    public from: string;

    public to: string;

    public cc: string;

    public subject: string;

    public body: string;

}

export class EmailHistory{

    public created_on__date: string

    public dcount: number
}

export class STPdf{
    public item: ServiceTicket;
    public sections: Section[];
    public timesheets: TimeSheet[];
    public subtasks: STSubTask[];
    public total_price: string;
    public material_total: string;
    public timesheet_total: string;
    public balance_due: string;
    public tax_amount: string;
}

export class StCallHistory {
    sid: string
    start_time: string
    end_time: string
    parent_call_sid: any
    call_from: string
    call_to: string
    recording_url: string
    answered_by: any
    caller_name: any
    status: string
    direction: string
    date_created: string
    date_modified: string
    forwarded_from: any
    phone_number_sid: string
    duration: number
    price: number
    price_unit: string
    sub_org_id: number
    is_analyzed: boolean
    is_spam: boolean
    real_status: string
    is_voicemail: boolean
    answered_name_id: number
    comments: number
    child_call_to: any
    customer_name: string
    customer_id: number
    suborg_name: string
    phone_number_name: string
    forward_number: any
    forward_number_name: string
    forward_number_sid: string
    answered: string
    color: any
    blocked_status: boolean
    blocked_id: number
    spam_reason: any
    recording_url_list: string[]
    customer_contact_name: string
    customer_contact_id: string
    customer_contact_title: any
    tenant_customer_name: string
    tenant_customer_id: number
    customer_contact_location_name: any
    customer_contact_location_address: any
    customer_contact_location_city: any
    customer_contact_location_state: any
    customer_contact_location_zip: any
    customer_contact_phone: any
    customer_contact_mobile: string
    customer_contact_email: any
  }


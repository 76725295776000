export class HoldMusicResponse {

    public id: number;

    public name: string;

    public music_link: string;

    public customer_id: number;

    public created_user_id: number;

    public modified_user_id: number;

    public created_on: string;

    public modified_on: string;

    public is_upload: boolean;

    public is_delete: boolean;

    public is_active: boolean;

    public is_superadmin: boolean;

    public is_disabled: boolean;

    public created_by: string;

    public serial_number: number;

    public file_id: string;
    
}

export class MusicList {

    public id: number;

    public name: string;

}

export class holdMusicSettingsResp {

    public always_play_id: number;

    public always_play_name: string;

    public random_music: boolean = false;

    public music: MusicList[] = [];

}

export class HoldMusicPayload {

    public name: string;

    public music_link: string;

    public customer: number;

    public is_upload: boolean;

    public file_id: string;

    public is_disabled: boolean;

}
<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataFilter [searchFilters]="search"
                                     [newButton]="UIPermissions.create"
                                     [newButtonUrl]="['/ring2voice/incoming-routes/calls/new']" [expanded]="expanded"
                                     [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [module]="module"
                                     (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'customer'" let-row="row">
                            <app-customer-select class="ng-select-sm" [(value)]="row.value" (change)="dataFilter.controlValueChanged('customer')"></app-customer-select>
                        </ng-template>
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue">
                            </suborg-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                                       [rows]="rows"
                                       class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [scrollbarH]="scrollbarH"
                                       [selectionType]="SelectionType.single"
                                       [sorts]="[{prop:'name',dir:'asc'}]"
                                       [count]="page.count"
                                       [externalPaging]="true"
                                       [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit"
                                       [trackByProp]="'sid'"
                                       (select)="onSelect($event)"
                                       (page)="setPage($event)"
                                       (sort)="onSort($event)">

                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Incoming Routes" [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <ng-container *ngIf="row.forward_number">
                                        <span *ngFor="let num of row.forward_number.split(',') as numbers;let i = index">
                                            {{num | phoneNumber}} <span *ngIf="i != numbers.length - 1">,</span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="row.sip">
                                        <span *ngFor="let num of row.sip.split(',') as numbers;let i = index">
                                            {{num}} <span *ngIf="i != numbers.length - 1">,</span>
                                        </span>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Whisper" prop="whisper" [sortable]="false">
                                <ng-template let-data="value" ngx-datatable-cell-template>
                                    <div class="badge" [ngClass]="{'bg-light-success': data,'bg-light-danger': !data }">
                                        {{ data ? 'Active' : 'Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Announce" prop="announce" [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Is Caller Input" prop="gather"
                                                  [sortable]="false">
                                <ng-template let-data="value" ngx-datatable-cell-template>
                                    <div class="badge" [ngClass]="{'bg-light-success': data,'bg-light-danger': !data }">
                                        {{ data ? 'Active' : 'Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created On" prop="created_on">
                                <ng-template let-created_on="value" ngx-datatable-cell-template>
                                    {{created_on | dateTz}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : ' Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Type" prop="call_center" [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="badge bg-info" *ngIf="row.call_center" style="width: 87px;margin-bottom: 2px;">
                                        Call Center
                                    </div>
                                    <div class="badge bg-warning" *ngIf="row.global_route">
                                        Global Route
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg" [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.customer_name}}</div>
                                    <div>Sub org : {{row.suborg_name}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && !isSuperAdmin" name="Suborg" prop="suborg_name"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Action" prop="sid" [sortable]="false"
                                                  [canAutoResize]="false" [width]="80">
                                <ng-template let-sid="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="UIPermissions.viewInfo" class="w-100" (click)="onSelect({selected:[current]})"
                                                    ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>
                                            <a *ngIf="UIPermissions.edit"
                                               [routerLink]="['/ring2voice/incoming-routes/calls',sid,'edit']" class="w-100"
                                               ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button *ngIf="UIPermissions.delete" (click)="deleteForwardBin(sid)"
                                                    class="w-100 bg-light-danger" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                             let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                                     [pagerNextIcon]="'datatable-icon-skip'"
                                                     [page]="curPage" [size]="pageSize" [count]="rowCount"
                                                     [hidden]="!((rowCount / pageSize) > 1)"
                                                     (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>

            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">{{selected.name}}</h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a *ngIf="UIPermissions.edit"
                               [routerLink]="['/ring2voice/incoming-routes/',selected.sid,'edit']" ngbTooltip="Edit"
                               class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button *ngIf="UIPermissions.delete" class="btn btn-sm bg-light-danger ml-1"
                                    (click)="deleteForwardBin(selected.sid)">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row flex-column">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">

                                        <div class="group-header d-flex justify-content-between">
                                            Overview
                                            <div class="toggle_set">
                                                <div class="status-toggle-btn justify-content-end mb-2">
                                                    <img src="../../../../../assets/img/svg/info_icon.svg" class="mr-1" alt="" ngbTooltip="Turns on or off all the phone number lines assigned to this route">
                                                    <span class="title">Status :</span>
                                                    <div
                                                        class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                        <input type="checkbox" [(ngModel)]="selected.is_active" id="status"
                                                            [disabled]="selected.is_active ? !UIPermissions.deactivate:!UIPermissions.activate"
                                                            name="active"
                                                            class="custom-control-input"
                                                            (change)="changeStatus()">
                                                        <label class="custom-control-label" for="status">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="status-toggle-btn justify-content-end mb-2">
                                                    <img src="../../../../../assets/img/svg/info_icon.svg" class="mr-1" alt="" ngbTooltip="When on, this route can be used with other companies">
                                                    <span class="title">Global Route :</span>
                                                    <div
                                                        class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                        <input type="checkbox" [(ngModel)]="selected.global_route" id="global_route"
                                                            [disabled]="selected.global_route ? !UIPermissions.deactivate:!UIPermissions.activate"
                                                            name="active"
                                                            class="custom-control-input"
                                                            (change)="changeGlobalRouteStatus()">
                                                        <label class="custom-control-label" for="global_route">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="status-toggle-btn justify-content-end mb-2">
                                                    <img src="../../../../../assets/img/svg/info_icon.svg" class="mr-1" alt="" ngbTooltip="For more advanced settings">
                                                    <span class="title">Call Center :</span>
                                                    <div
                                                        class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                        <input type="checkbox" [(ngModel)]="selected.call_center" id="call_center"
                                                            [disabled]="selected.call_center ? !UIPermissions.deactivate:!UIPermissions.activate"
                                                            name="active"
                                                            class="custom-control-input"
                                                            (change)="changeCallcenterStatus()">
                                                        <label class="custom-control-label" for="call_center">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="group-body position-relative" style="bottom: 80px;">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-9 p-0">
                                                        <div [ngClass]="{'my-div': selected.call_center}">
                                                            <div class="ov-row" *ngIf="selected.maping_sid">
                                                                <label class="col-md-5 p-0 item-label">Internal ID</label>
                                                                <label class="item-label-value">{{selected.maping_sid}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.name">
                                                                <label class="col-md-5 p-0 item-label">Forward name</label>
                                                                <label class="item-label-value">{{selected.name}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.forward_number_list">
                                                                <label class="col-md-5 p-0 item-label">Forward number</label>
                                                                <label class="item-label-value">
                                                                    <span class="badge bg-light-secondary mr-1"
                                                                        *ngFor="let num of selected.forward_number_list">
                                                                        {{num | phoneNumber }}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.sip_list">
                                                                <label class="col-md-5 p-0 item-label">Sip</label>
                                                                <label class="item-label-value">
                                                                    <span class="badge bg-light-secondary mr-1"
                                                                        *ngFor="let sip of selected.sip_list">
                                                                        {{sip}}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div class="ov-row">
                                                                <label class="col-md-5 p-0 item-label">Whisper</label>
                                                                <div class="badge"
                                                                    [ngClass]="{'bg-light-success': selected.whisper,'bg-light-danger': !selected.whisper }">
                                                                    {{ selected.whisper ? 'Active' : 'Inactive' }}
                                                                </div>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.whisper_message">
                                                                <label class="col-md-5 p-0 item-label">Whisper Message</label>
                                                                <label class="item-label-value">{{selected.whisper_message}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.whisper_gender">
                                                                <label class="col-md-5 p-0 item-label">Whisper Voice</label>
                                                                <label class="item-label-value">{{voices[selected.whisper_gender] || selected.whisper_gender}}</label>
                                                            </div>
                                                            <div class="ov-row">
                                                                <label class="col-md-5 p-0 item-label">Announce</label>
                                                                <div class="badge"
                                                                    [ngClass]="{'bg-light-success': selected.announce,'bg-light-danger': !selected.announce }">
                                                                    {{ selected.announce ? 'Active' : 'Inactive' }}
                                                                </div>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.announce_gender">
                                                                <label class="col-md-5 p-0 item-label">Announce Voice</label>
                                                                <label class="item-label-value">{{voices[selected.announce_gender] || selected.announce_gender}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.announce_message">
                                                                <label class="col-md-5 p-0 item-label">Announce Message</label>
                                                                <label class="item-label-value">{{selected.announce_message}}</label>
                                                            </div>
                                                            <div class="ov-row">
                                                                <label class="col-md-5 p-0 item-label">Required Caller Input</label>
                                                                <div class="badge"
                                                                    [ngClass]="{'bg-light-success': selected.gather,'bg-light-danger': !selected.gather }">
                                                                    {{ selected.gather ? 'Active' : 'Inactive' }}
                                                                </div>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.gather_gender">
                                                                <label class="col-md-5 p-0 item-label">Auto Attendant Voice</label>
                                                                <label class="item-label-value">{{voices[selected.gather_gender] || selected.gather_gender }}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.no_of_loops">
                                                                <label class="col-md-5 p-0 item-label">Number of Loops</label>
                                                                <label class="item-label-value">{{selected.no_of_loops}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.gather_message">
                                                                <label class="col-md-5 p-0 item-label">Auto Attendant Message</label>
                                                                <label class="item-label-value">{{selected.gather_message}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.not_responding_message">
                                                                <label class="col-md-5 p-0 item-label">Not Responding Message</label>
                                                                <label
                                                                    class="item-label-value">{{selected.not_responding_message}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="selected.created_on">
                                                                <label class="col-md-5 p-0 item-label">Created On</label>
                                                                <label
                                                                    class="item-label-value">{{selected.created_on | dateTz}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="isSuperAdmin">
                                                                <label class="col-md-5 p-0 item-label">Tenant</label>
                                                                <label class="item-label-value">{{selected.customer_name}}</label>
                                                            </div>
                                                            <div class="ov-row" *ngIf="isSuperAdmin">
                                                                <label class="col-md-5 p-0 item-label">Sub Org</label>
                                                                <label class="item-label-value">{{selected.suborg_name}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="advancedSettings mt-3 mb-3" *ngIf="selected.call_center">
                                                <b>Advance Call Settings</b>
                                                <div class="row mt-2">
                                                    <div class="col-md-12">
                                                        <div class="whitebox d-flex align-items-center">
                                                            <b>Block Incoming Callers If They Are On Block List</b>
                                                            <div class="custom-switch custom-switch-success custom-control blockNumber mb-1 mb-xl-0 ml-3">
                                                                <input type="checkbox" [(ngModel)]="advancedSettings.block_list_enabled" class="custom-control-input" id="blockNumber">
                                                                <label class="custom-control-label" for="blockNumber">
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="outterVerticalline"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="whitebox pb-3">
                                                            <div class="ring-container mb-1 align-items-center">
                                                                <div class="row mt-2 mb-2 d-flex justify-content-between">
                                                                    <div class="col-5">
                                                                        <b>What Would You Like To Do When A Call Comes In ?</b>
                                                                        <ng-select class="form-select mt-1"
                                                                                placeholder="Select option"
                                                                                [(ngModel)]="advancedSettings.routing_option"
                                                                                [clearable]="false" [searchable]="false"
                                                                                (change)="routingOptionsSelect($event)">
                                                                            <ng-option *ngFor="let option of routingOptionSelect" [value]="option">{{ option }}</ng-option>
                                                                        </ng-select>
                                                                    </div>
                                                                    <div class="col-7" *ngIf="advancedSettings.routing_option=='Time Based Routing'">
                                                                        <label class="val-required mb-1 font-weight-bold">Select Timezone</label>
                                                                        <ng-select [items]="timezones" class="w-100" placeholder="Select timezone" required [(ngModel)]="advancedSettings.time_zone" name="timezone"></ng-select>
                                                                    </div>
                                                                    <div class="col-7 d-flex align-items-end" *ngIf="advancedSettings.routing_option=='Intelligent Menu'">
                                                                        <button (click)="openModal(menuSettingsModal,routingSection[0],1)" width="50" height="50" class="btn btn-sm d-flex align-items-center" style="background: #d52095;"><i style="font-size: large;" class="fa fa-cog text-white" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- first level routing -->
                                                            <div *ngFor="let time of routingSection;let index=index">
                                                                <div class="ring-execution-container ml-2 mr-2 p-2" style="border: 1px dotted #7627ff91">
                                                                    <div class="ring-section">
                                                                        <!-- select Time Frame -->
                                                                        <div class="form-group" *ngIf="advancedSettings.routing_option=='Time Based Routing'">
                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <label class="val-required">Time Frame</label>
                                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                                        <time-frame-select [suborgId]="selected.sub_org" [(value)]="time.time_frame_id" [addButton]="true"></time-frame-select>
                                                                                        <a (click)="removeTimeRow(index)" *ngIf="routingSection.length>1"><i class="ft-trash-2" style="color: red;"></i></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- select Ring Option (if Time Based Routing selected) -->
                                                                            <div class="row">
                                                                                <div class="col-4">
                                                                                    <label class="val-required mt-2">Choose what to do</label>
                                                                                    <ng-select class="form-select"
                                                                                            placeholder="Select option"
                                                                                            [(ngModel)]="time.ring_option"
                                                                                            [clearable]="false"
                                                                                            [searchable]="false"
                                                                                            (change)="ringOptionSelect()">
                                                                                        <ng-option *ngFor="let option of InnerRingOptionSelect" [value]="option">{{ option }}</ng-option>
                                                                                    </ng-select>
                                                                                </div>
                                                                                <div class="col-5 d-flex align-items-end" *ngIf="time.ring_option=='Intelligent Menu'">
                                                                                    <button (click)="openModal(menuSettingsModal,routingSection[index],1)" width="50" height="50" class="btn btn-sm d-flex align-items-center" style="background: #d52095;"><i style="font-size: large;" class="fa fa-cog text-white" aria-hidden="true"></i></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <section *ngIf="advancedSettings.routing_option=='Ring'||(advancedSettings.routing_option=='Time Based Routing'&&time.ring_option=='Ring')">
                                                                            <ring2voice-ring-settings [model]="time" [subOrg_id]="selected.sub_org"></ring2voice-ring-settings>
                                                                        </section>
                                                                        <!-- ring changed -->

                                                                        <!-- Time Frame Intelligent Menu -->
                                                                        <div class="form-group" *ngIf="advancedSettings.routing_option=='Time Based Routing'&&time.ring_option=='Intelligent Menu'||advancedSettings.routing_option=='Intelligent Menu'">
                                                                            <div class="row">
                                                                                <div class="col-12 col-md-6">
                                                                                    <div class="form-group">
                                                                                        <label class="val-required">AUTO ATTENDANT voice</label>
                                                                                        <voice-select [(value)]="time.gather_gender"></voice-select>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-6">
                                                                                    <div class="form-group">
                                                                                        <label class="val-required">HOW MANY LOOPS?</label>
                                                                                        <input type="number" name="no_of_loops" [(ngModel)]="time.no_of_loops"
                                                                                               class="form-control" min="1" max="5" required PreventSpecialCharacters>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-6">
                                                                                    <div class="form-group">
                                                                                        <label class="val-required">AUTO ATTENDANT</label>
                                                                                        <input type="text" name="gather_message" class="form-control" (input)="onInputChanged($event)"
                                                                                               [(ngModel)]="time.gather_message" required>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-6">
                                                                                    <div class="form-group">
                                                                                        <label class="val-required">IF CALLER DOES NOT PRESS ANY BUTTON</label>
                                                                                        <input type="text" name="not_responding_message"
                                                                                               [(ngModel)]="time.not_responding_message" class="form-control" required>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- ring section for Intelligent Menu -->
                                                                            <div  *ngFor="let menu of IntelligentMenuRouting;let Menuindex=index">
                                                                                <div class="form-group p-3 mb-0 mr-2 ml-2"  style="border: 1px dotted #f14341">
                                                                                    <label class="val-required">Press</label>
                                                                                    <a (click)="removeMenuRow(Menuindex)" *ngIf="IntelligentMenuRouting.length>1"><i class="ft-trash-2 float-right" style="color: red;"></i></a>
                                                                                    
                                                                                    <div class="d-flex ">
                                                                                        <ng-select class="form-select w-25"
                                                                                            placeholder="Select Number"
                                                                                            [searchable]="true"
                                                                                            [(ngModel)]="menu.input"
                                                                                            [clearable]="false" [ngModelOptions]="{standalone: true}">
                                                                                            <ng-option *ngFor="let number of menuNumber" [value]="number" [disabled]="isNumberSelected(number)">{{ number }}</ng-option>
                                                                                        </ng-select>
                                                                                        <div class="col-8 text-secondary">
                                                                                            <div *ngIf="showFullText; else shortText">
                                                                                                Auto Attendant : {{ time.gather_message }}
                                                                                                <a class="ml-1 text-info" (click)="toggleText()">Collapse</a>
                                                                                            </div>
                                                                                            <ng-template #shortText>
                                                                                                Auto Attendant : {{ time?.gather_message | slice:0:90 }} 
                                                                                                <a class="ml-1 text-info" *ngIf="time?.gather_message?.length>70" (click)="toggleText()">..Expand</a>
                                                                                            </ng-template>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                    <div class="row">
                                                                                        <div class="col-4 form-group">
                                                                                            <label class="val-required mt-2">What would you like to do</label>
                                                                                            <ng-select class="form-select"
                                                                                                        placeholder="Select option"
                                                                                                        [searchable]="false"
                                                                                                        [(ngModel)]="menu.nested_routing_option"
                                                                                                        [clearable]="false" [ngModelOptions]="{standalone: true}"
                                                                                                        (change)="firstNestedRouteSelect(Menuindex)">
                                                                                                <ng-option value="Ring">Ring</ng-option>
                                                                                                <ng-option value="Announcement">Announcement</ng-option>
                                                                                                <ng-option value="Voicemail">Voicemail</ng-option>
                                                                                                <ng-option value="Intelligent Menu">Intelligent Menu</ng-option>
                                                                                            </ng-select>
                                                                                        </div>
                                                                                        <div class="col-5 d-flex align-items-end form-group" *ngIf="menu.nested_routing_option=='Intelligent Menu'">
                                                                                            <button (click)="openModal(menuSettingsModal,IntelligentMenuRouting[Menuindex],2,Menuindex)" width="50" height="50" class="btn btn-sm d-flex align-items-center" style="background: #d52095;"><i style="font-size: large;" class="fa fa-cog text-white" aria-hidden="true"></i></button>
                                                                                        </div>
                                                                                    </div>

                                                                                    <section *ngIf="menu.nested_routing_option=='Ring'">
                                                                                        <ring2voice-ring-settings [model]="menu" [subOrg_id]="selected.sub_org"></ring2voice-ring-settings>
                                                                                    </section>

                                                                                    <section *ngIf="menu.nested_routing_option=='Announcement'">
                                                                                        <ring2voice-announcement-settings [model]="menu"></ring2voice-announcement-settings>
                                                                                    </section>
                                                                                    
                                                                                    <section *ngIf="menu.nested_routing_option=='Voicemail'">
                                                                                        <ring2voice-voicemail-settings [model]="menu" [subOrg_id]="selected.sub_org"></ring2voice-voicemail-settings>
                                                                                    </section>

                                                                                    <div *ngIf="menu.nested_routing_option=='Intelligent Menu'">
                                                                                        <div class="row">
                                                                                            <div class="col-12 col-md-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="val-required">AUTO ATTENDANT voice</label>
                                                                                                    <voice-select [(value)]="menu.gather_gender"></voice-select>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-md-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="val-required">HOW MANY LOOPS?</label>
                                                                                                    <input type="number" name="aot_no_of_loops" [(ngModel)]="menu.no_of_loops"
                                                                                                           class="form-control" min="1" max="5" required PreventSpecialCharacters>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-md-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="val-required">AUTO ATTENDANT</label>
                                                                                                    <input type="text" name="aot_gather_message" class="form-control" (input)="onInputChanged($event)"
                                                                                                           [(ngModel)]="menu.gather_message" required>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-md-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="val-required">IF CALLER DOES NOT PRESS ANY BUTTON</label>
                                                                                                    <input type="text" name="aot_not_responding_message"
                                                                                                           [(ngModel)]="menu.not_responding_message" class="form-control" required>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!-- 2nd level menu ring section -->
        
        
                                                                                        <div  *ngFor="let secondMenu of menu.call_router;let secondMenuindex=index">
                                                                                            <div class="form-group p-3 mb-0"  style="border: 1px dotted #F7BB1C">
                                                                                                <label class="val-required">Press</label>
                                                                                                <a (click)="remove2ndMenuRow(Menuindex,secondMenuindex)" *ngIf="menu.call_router.length>1"><i class="ft-trash-2 float-right" style="color: red;"></i></a>
                                                                                                <div class="d-flex">
                                                                                                    <ng-select class="form-select w-25"
                                                                                                                placeholder="Select Number"
                                                                                                                [searchable]="true"
                                                                                                                [(ngModel)]="secondMenu.input"
                                                                                                                [clearable]="false" [ngModelOptions]="{standalone: true}">
                                                                                                        <ng-option *ngFor="let number of menuNumber" [value]="number" [disabled]="isNumberSelectedin2ndlevel(Menuindex,number)">{{ number }}</ng-option>
                                                                                                    </ng-select>
                                                                                                    <div class="col-8 text-secondary">
                                                                                                        <div *ngIf="showFullText; else shortText">
                                                                                                            Auto Attendant : {{ menu?.gather_message }}
                                                                                                            <a class="ml-1 text-info" (click)="toggleText()">Collapse</a>
                                                                                                        </div>
                                                                                                        <ng-template #shortText>
                                                                                                            Auto Attendant : {{ menu.gather_message | slice:0:90 }} 
                                                                                                            <a class="ml-1 text-info" *ngIf="menu?.gather_message?.length>70" (click)="toggleText()">..Expand</a>
                                                                                                        </ng-template>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row">
                                                                                                    <div class="col-4">
                                                                                                        <label class="val-required mt-2">What would you like to do</label>
                                                                                                        <ng-select class="form-select"
                                                                                                                    placeholder="Select option"
                                                                                                                    [searchable]="false"
                                                                                                                    [(ngModel)]="secondMenu.nested_routing_option"
                                                                                                                    [clearable]="false" [ngModelOptions]="{standalone: true}"
                                                                                                                    (change)="nestedRouteSelect(Menuindex,secondMenuindex)">
                                                                                                            <ng-option value="Ring">Ring</ng-option>
                                                                                                            <ng-option value="Announcement">Announcement</ng-option>
                                                                                                            <ng-option value="Voicemail">Voicemail</ng-option>
                                                                                                            <ng-option value="Intelligent Menu">Intelligent Menu</ng-option>
                                                                                                        </ng-select>
                                                                                                    </div>
                                                                                                    <div class="col-5 d-flex align-items-end" *ngIf="secondMenu.nested_routing_option=='Intelligent Menu'">
                                                                                                        <button (click)="openModal(menuSettingsModal,menu.call_router[secondMenuindex],3,Menuindex,secondMenuindex)" width="50" height="50" class="btn btn-sm d-flex align-items-center" style="background: #d52095;"><i style="font-size: large;" class="fa fa-cog text-white" aria-hidden="true"></i></button>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <section *ngIf="secondMenu.nested_routing_option=='Ring'">
                                                                                                    <ring2voice-ring-settings [model]="secondMenu" [subOrg_id]="selected.sub_org"></ring2voice-ring-settings>
                                                                                                </section>

                                                                                                <section *ngIf="secondMenu.nested_routing_option=='Announcement'">
                                                                                                    <ring2voice-announcement-settings [model]="secondMenu"></ring2voice-announcement-settings>
                                                                                                </section>

                                                                                                <section *ngIf="secondMenu.nested_routing_option=='Voicemail'">
                                                                                                    <ring2voice-voicemail-settings [model]="secondMenu" [subOrg_id]="selected.sub_org"></ring2voice-voicemail-settings>
                                                                                                </section>

                                                                                                <div *ngIf="secondMenu.nested_routing_option=='Intelligent Menu'">
                                                                                                    <div class="row">
                                                                                                        <div class="col-12 col-md-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="val-required">AUTO ATTENDANT voice</label>
                                                                                                                <voice-select [(value)]="secondMenu.gather_gender"></voice-select>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-12 col-md-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="val-required">HOW MANY LOOPS?</label>
                                                                                                                <input type="number" name="3rd_no_of_loops" [(ngModel)]="secondMenu.no_of_loops"
                                                                                                                       class="form-control" min="1" max="5" required PreventSpecialCharacters>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-12 col-md-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="val-required">AUTO ATTENDANT</label>
                                                                                                                <input type="text" name="3rd_gather_message" class="form-control" (input)="onInputChanged($event)"
                                                                                                                    [(ngModel)]="secondMenu.gather_message" required>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-12 col-md-6">
                                                                                                            <div class="form-group">
                                                                                                                <label class="val-required">IF CALLER DOES NOT PRESS ANY BUTTON</label>
                                                                                                                <input type="text" name="3rd_not_responding_message"
                                                                                                                       [(ngModel)]="secondMenu.not_responding_message" class="form-control" required>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!-- 3rd level menu ring section -->
                                                                                                    <div  *ngFor="let thirdMenu of secondMenu.call_router;let thirdMenuindex=index">
                                                                                                        <div class="form-group p-2 mb-0"  style="border: 1px dotted #2351C9">
                                                                                                            <label class="val-required">Press</label>
                                                                                                            <a (click)="remove3rdMenuRow(Menuindex,secondMenuindex,thirdMenuindex)" *ngIf="secondMenu.call_router.length>1"><i class="ft-trash-2 float-right" style="color: red;"></i></a>
                                                                                                            <div class="d-flex">  
                                                                                                                <ng-select class="form-select w-25"
                                                                                                                            placeholder="Select Number"
                                                                                                                            [searchable]="true"
                                                                                                                            [(ngModel)]="thirdMenu.input"
                                                                                                                            [clearable]="false" [ngModelOptions]="{standalone: true}">
                                                                                                                    <ng-option *ngFor="let number of menuNumber" [value]="number" [disabled]="isNumberSelectedin3rdlevel(Menuindex,secondMenuindex,number)">{{ number }}</ng-option>
                                                                                                                </ng-select>
                                                                                                                <div class="col-8 text-secondary">
                                                                                                                    <div *ngIf="showFullText; else shortText">
                                                                                                                        Auto Attendant : {{ secondMenu?.gather_message }}
                                                                                                                        <a class="ml-1 text-info" (click)="toggleText()">Collapse</a>
                                                                                                                    </div>
                                                                                                                    <ng-template #shortText>
                                                                                                                        Auto Attendant : {{ secondMenu.gather_message | slice:0:90 }}
                                                                                                                        <a class="ml-1 text-info" *ngIf="secondMenu?.gather_message?.length>70" (click)="toggleText()">..Expand</a>
                                                                                                                    </ng-template>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <label class="val-required mt-2">What would you like to do</label>
                                                                                                            <ng-select class="form-select w-50"
                                                                                                                        placeholder="Select option"
                                                                                                                        [searchable]="false"
                                                                                                                        [(ngModel)]="thirdMenu.nested_routing_option"
                                                                                                                        [clearable]="false" [ngModelOptions]="{standalone: true}">
                                                                                                                <ng-option value="Ring">Ring</ng-option>
                                                                                                                <ng-option value="Announcement">Announcement</ng-option>
                                                                                                                <ng-option value="Voicemail">Voicemail</ng-option>
                                                                                                            </ng-select>

                                                                                                            <section *ngIf="thirdMenu.nested_routing_option=='Ring'">
                                                                                                                <ring2voice-ring-settings [model]="thirdMenu" [subOrg_id]="selected.sub_org"></ring2voice-ring-settings>
                                                                                                            </section>

                                                                                                            <section *ngIf="thirdMenu.nested_routing_option=='Announcement'">
                                                                                                                <ring2voice-announcement-settings [model]="thirdMenu"></ring2voice-announcement-settings>
                                                                                                            </section>

                                                                                                            <section *ngIf="thirdMenu.nested_routing_option=='Voicemail'">
                                                                                                                <ring2voice-voicemail-settings [model]="thirdMenu" [subOrg_id]="selected.sub_org"></ring2voice-voicemail-settings>
                                                                                                            </section>
                                                                                                        </div>
                                                                                                        <div class="third_verticalline" [hidden]="thirdMenuindex==secondMenu.call_router.length-1"></div>
                                                                                                    </div>
                                                                                                    <div class="third_verticalline"></div>
                                                                                                    <a class="mt-2" (click)="addNew3rdMenuRouting(Menuindex,secondMenuindex)" [ngClass]="secondMenu.call_router.length>=9?'disabled-link':'third_button'">
                                                                                                        ({{secondMenu.call_router.length}})<img src="assets/img/svg/automation_rules/add-circle.svg" class="ml-1" style="margin-top: -1px; margin-right: 3px;">
                                                                                                        Add New Number
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="second_verticalline" [hidden]="secondMenuindex==menu.call_router.length-1"></div>
                                                                                        </div>
                                                                                        <div class="second_verticalline"></div>
                                                                                        <a class="mt-2" (click)="addNew2ndMenuRouting(Menuindex)" [ngClass]="menu.call_router.length>=9?'disabled-link':'second_button'">
                                                                                            ({{menu.call_router.length}})<img src="assets/img/svg/automation_rules/add-circle.svg" class="ml-1" style="margin-top: -1px; margin-right: 3px;">
                                                                                            Add New Number
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="first_verticalline" [hidden]="Menuindex==IntelligentMenuRouting.length-1"></div>
                                                                            </div>
                                                                            <div class="first_verticalline" *ngIf="advancedSettings.routing_option=='Time Based Routing'||advancedSettings.routing_option=='Intelligent Menu'"></div>
                                                                            <a class="mt-2" (click)="addNewMenuRouting()" [ngClass]="IntelligentMenuRouting.length>=9?'disabled-link':'first_button'" *ngIf="advancedSettings.routing_option=='Time Based Routing'||advancedSettings.routing_option=='Intelligent Menu'">
                                                                                ({{IntelligentMenuRouting.length}})<img src="assets/img/svg/automation_rules/add-circle.svg" class="ml-1" style="margin-top: -1px; margin-right: 3px;">
                                                                                Add New Number
                                                                            </a>
                                                                        </div>

                                                                        <section *ngIf="advancedSettings.routing_option=='Time Based Routing'&&time.ring_option=='Announcement'||advancedSettings.routing_option=='Announcement'">
                                                                            <ring2voice-announcement-settings [model]="time"></ring2voice-announcement-settings>
                                                                        </section>

                                                                        <section *ngIf="advancedSettings.routing_option=='Time Based Routing'&&time.ring_option=='Voicemail'||advancedSettings.routing_option=='Voicemail'">
                                                                            <ring2voice-voicemail-settings [model]="time" [subOrg_id]="selected.sub_org"></ring2voice-voicemail-settings>
                                                                        </section>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="verticalline" [hidden]="index==routingSection.length-1"></div>
                                                            </div>

                                                            <div class="verticalline" *ngIf="advancedSettings.routing_option=='Time Based Routing'"></div>

                                                            <a class="mt-2" (click)="addNewRoutingSection()" [ngClass]="routingSection.length>=3?'disabled-link':'when'" *ngIf="advancedSettings.routing_option=='Time Based Routing'">
                                                                ({{routingSection.length}})<img src="assets/img/svg/automation_rules/add-circle.svg" class="ml-1" style="margin-top: -1px; margin-right: 3px;">
                                                                Add New Time Frame
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="outterVerticalline"  *ngIf="advancedSettings.routing_option=='Time Based Routing'"></div>
                                                <!-- All Other Times -->
                                                <div class="row"  *ngIf="advancedSettings.routing_option=='Time Based Routing'">
                                                    <div class="col-md-12">
                                                        <div class="whitebox form-group pb-3">
                                                            <div class="ring-container mb-1 align-items-center">
                                                                <b class="mr-2">All Other Times</b>&nbsp; 
                                                                <div class="row">
                                                                    <div class="col-4 form-group">
                                                                        <label for="ring-select" class="val-required">Choose what to do</label>
                                                                        <ng-select class="form-select"
                                                                                placeholder="Select option"
                                                                                [(ngModel)]="advancedSettings?.all_other_time.ring_option"
                                                                                [clearable]="false" [searchable]="false"
                                                                                (change)="AllOtherRingOptionsSelect()">
                                                                            <ng-option *ngFor="let option of allOtherTimeRingOption" [value]="option">{{ option }}</ng-option>
                                                                        </ng-select>
                                                                    </div>
                                                                    <div class="col-5 d-flex align-items-end form-group" *ngIf="advancedSettings?.all_other_time.ring_option=='Intelligent Menu'">
                                                                        <button (click)="openModal(menuSettingsModal,advancedSettings?.all_other_time.menu_settings,1,null,null,true)" width="50" height="50" class="btn btn-sm d-flex align-items-center" style="background: #d52095;"><i style="font-size: large;" class="fa fa-cog text-white" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="ring-execution-container ml-2 mr-2 p-3" style="border: 1px dotted #7627ff52">
                                                                <section *ngIf="advancedSettings.all_other_time.ring_option=='Ring'">
                                                                    <ring2voice-ring-settings [model]="advancedSettings.all_other_time" [subOrg_id]="selected.sub_org"></ring2voice-ring-settings>
                                                                </section>
                                                                
                                                                <div class="ring-section" *ngIf="advancedSettings.all_other_time.ring_option=='Intelligent Menu'">
                                                                    <!-- All Other Times Intelligent Menu -->
                                                                    <div class="row">
                                                                        <div class="col-12 col-md-6">
                                                                            <div class="form-group">
                                                                                <label class="val-required">AUTO ATTENDANT voice</label>
                                                                                <voice-select [(value)]="advancedSettings.all_other_time.gather_gender"></voice-select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-md-6">
                                                                            <div class="form-group">
                                                                                <label class="val-required">HOW MANY LOOPS?</label>
                                                                                <input type="number" name="aot_no_of_loops" [(ngModel)]="advancedSettings.all_other_time.no_of_loops"
                                                                                       class="form-control" min="1" max="5" required PreventSpecialCharacters>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-md-6">
                                                                            <div class="form-group">
                                                                                <label class="val-required">AUTO ATTENDANT</label>
                                                                                <input type="text" name="aot_gather_message" class="form-control" (input)="onInputChanged($event)"
                                                                                       [(ngModel)]="advancedSettings.all_other_time.gather_message" required>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-md-6">
                                                                            <div class="form-group">
                                                                                <label class="val-required">IF CALLER DOES NOT PRESS ANY BUTTON</label>
                                                                                <input type="text" name="aot_not_responding_message"
                                                                                       [(ngModel)]="advancedSettings.all_other_time.not_responding_message" class="form-control" required>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- ring section for Other Times Intelligent Menu -->
                                                                    <div  *ngFor="let OtherTimeMenu of OthertimeMenuRouting;let OtherTimeMenuindex=index">
                                                                        <div class="form-group p-3 mb-0 mr-2 ml-2"  style="border: 1px dotted #f14341">
                                                                            <label class="val-required">Press</label>
                                                                            <a (click)="removeOtherTimeMenuRow(OtherTimeMenuindex)" *ngIf="OthertimeMenuRouting.length>1"><i class="ft-trash-2 float-right" style="color: red;"></i></a>
                                                                            <div class="d-flex">
                                                                                <ng-select class="form-select w-25"
                                                                                            placeholder="Select Number"
                                                                                            [searchable]="true"
                                                                                            [(ngModel)]="OtherTimeMenu.input"
                                                                                            [clearable]="false" [ngModelOptions]="{standalone: true}">
                                                                                    <ng-option *ngFor="let number of menuNumber" [value]="number" [disabled]="isNumberSelectedinOtherTime(number)">{{ number }}</ng-option>
                                                                                </ng-select>
                                                                                <div class="col-8 text-secondary">
                                                                                    <div *ngIf="showFullText; else shortText">
                                                                                        Auto Attendant : {{ advancedSettings.all_other_time?.gather_message }}
                                                                                        <a class="ml-1 text-info" (click)="toggleText()">Collapse</a>
                                                                                    </div>
                                                                                    <ng-template #shortText>
                                                                                        Auto Attendant : {{ advancedSettings.all_other_time.gather_message | slice:0:90 }} 
                                                                                        <a class="ml-1 text-info" *ngIf="advancedSettings.all_other_time?.gather_message?.length>70" (click)="toggleText()">..Expand</a>
                                                                                    </ng-template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-4 form-group">
                                                                                    <label class="val-required mt-2">What would you like to do</label>
                                                                                    <ng-select class="form-select"
                                                                                                placeholder="Select option"
                                                                                                [searchable]="false"
                                                                                                [(ngModel)]="OtherTimeMenu.nested_routing_option"
                                                                                                [clearable]="false" [ngModelOptions]="{standalone: true}"
                                                                                                (change)="otherTimeFirstNestedRouteSelect(OtherTimeMenuindex)">
                                                                                        <ng-option value="Ring">Ring</ng-option>
                                                                                        <ng-option value="Announcement">Announcement</ng-option>
                                                                                        <ng-option value="Voicemail">Voicemail</ng-option>
                                                                                        <ng-option value="Intelligent Menu">Intelligent Menu</ng-option>
                                                                                    </ng-select>
                                                                                </div>
                                                                                <div class="col-5 d-flex align-items-end form-group" *ngIf="OtherTimeMenu.nested_routing_option=='Intelligent Menu'">
                                                                                    <button (click)="openModal(menuSettingsModal,OthertimeMenuRouting[OtherTimeMenuindex],2,OtherTimeMenuindex,null,true)" width="50" height="50" class="btn btn-sm d-flex align-items-center" style="background: #d52095;"><i style="font-size: large;" class="fa fa-cog text-white" aria-hidden="true"></i></button>
                                                                                </div>
                                                                            </div>
                                                                            <!-- otherTime nested routing options -->
                                                                            <section *ngIf="OtherTimeMenu.nested_routing_option=='Ring'">
                                                                                <ring2voice-ring-settings [model]="OtherTimeMenu" [subOrg_id]="selected.sub_org"></ring2voice-ring-settings>
                                                                            </section>

                                                                            <section *ngIf="OtherTimeMenu.nested_routing_option=='Announcement'">
                                                                                <ring2voice-announcement-settings [model]="OtherTimeMenu"></ring2voice-announcement-settings>
                                                                            </section>

                                                                            <section *ngIf="OtherTimeMenu.nested_routing_option=='Voicemail'">
                                                                                <ring2voice-voicemail-settings [model]="OtherTimeMenu" [subOrg_id]="selected.sub_org"></ring2voice-voicemail-settings>
                                                                            </section>
                                                                            <!-- otherTime 2nd level Intelligent menu -->
                                                                            <div *ngIf="OtherTimeMenu.nested_routing_option=='Intelligent Menu'">
                                                                                <div class="row">
                                                                                    <div class="col-12 col-md-6">
                                                                                        <div class="form-group">
                                                                                            <label class="val-required">AUTO ATTENDANT voice</label>
                                                                                            <voice-select [(value)]="OtherTimeMenu.gather_gender"></voice-select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-12 col-md-6">
                                                                                        <div class="form-group">
                                                                                            <label class="val-required">HOW MANY LOOPS?</label>
                                                                                            <input type="number" name="aot_no_of_loops" [(ngModel)]="OtherTimeMenu.no_of_loops"
                                                                                                   class="form-control" min="1" max="5" required PreventSpecialCharacters>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-12 col-md-6">
                                                                                        <div class="form-group">
                                                                                            <label class="val-required">AUTO ATTENDANT</label>
                                                                                            <input type="text" name="aot_gather_message" class="form-control" (input)="onInputChanged($event)"
                                                                                                   [(ngModel)]="OtherTimeMenu.gather_message" required>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-12 col-md-6">
                                                                                        <div class="form-group">
                                                                                            <label class="val-required">IF CALLER DOES NOT PRESS ANY BUTTON</label>
                                                                                            <input type="text" name="aot_not_responding_message"
                                                                                                   [(ngModel)]="OtherTimeMenu.not_responding_message" class="form-control" required>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <!-- otherTime 2nd level menu ring section -->
                                                                                <div  *ngFor="let otherTime_secondMenu of OtherTimeMenu.call_router;let secondOtherTimeMenuindex=index">
                                                                                    <div class="form-group p-3 mb-0"  style="border: 1px dotted #F7BB1C">
                                                                                        <label class="val-required">Press</label>
                                                                                        <a (click)="removeOtherTime2ndMenuRow(OtherTimeMenuindex,secondOtherTimeMenuindex)" *ngIf="OtherTimeMenu.call_router.length>1"><i class="ft-trash-2 float-right" style="color: red;"></i></a>
                                                                                        <div class="d-flex">
                                                                                            <ng-select class="form-select w-25"
                                                                                                        placeholder="Select Number"
                                                                                                        [searchable]="true"
                                                                                                        [(ngModel)]="otherTime_secondMenu.input"
                                                                                                        [clearable]="false" [ngModelOptions]="{standalone: true}">
                                                                                                <ng-option *ngFor="let number of menuNumber" [value]="number" [disabled]="isNumberSelectedin2ndOtherTime(OtherTimeMenuindex,number)">{{ number }}</ng-option>
                                                                                            </ng-select>
                                                                                            <div class="col-8 text-secondary">
                                                                                                <div *ngIf="showFullText; else shortText">
                                                                                                    Auto Attendant : {{ OtherTimeMenu?.gather_message }}
                                                                                                    <a class="ml-1 text-info" (click)="toggleText()">Collapse</a>
                                                                                                </div>
                                                                                                <ng-template #shortText>
                                                                                                    Auto Attendant : {{ OtherTimeMenu.gather_message | slice:0:90 }} 
                                                                                                    <a class="ml-1 text-info" *ngIf="OtherTimeMenu?.gather_message?.length>70" (click)="toggleText()">..Expand</a>
                                                                                                </ng-template>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row">
                                                                                            <div class="col-4 form-group">
                                                                                                <label class="val-required mt-2">What would you like to do</label>
                                                                                                <ng-select class="form-select"
                                                                                                            placeholder="Select option"
                                                                                                            [searchable]="false"
                                                                                                            [(ngModel)]="otherTime_secondMenu.nested_routing_option"
                                                                                                            [clearable]="false" [ngModelOptions]="{standalone: true}"
                                                                                                            (change)="OtherTimenestedRouteSelect(OtherTimeMenuindex,secondOtherTimeMenuindex)">
                                                                                                    <ng-option value="Ring">Ring</ng-option>
                                                                                                    <ng-option value="Announcement">Announcement</ng-option>
                                                                                                    <ng-option value="Voicemail">Voicemail</ng-option>
                                                                                                    <ng-option value="Intelligent Menu">Intelligent Menu</ng-option>
                                                                                                </ng-select>
                                                                                            </div>
                                                                                            <div class="col-5 d-flex align-items-end form-group" *ngIf="otherTime_secondMenu.nested_routing_option=='Intelligent Menu'">
                                                                                                --<button (click)="openModal(menuSettingsModal,OtherTimeMenu.call_router[secondOtherTimeMenuindex],3,OtherTimeMenuindex,secondOtherTimeMenuindex,true)" width="50" height="50" class="btn btn-sm d-flex align-items-center" style="background: #d52095;"><i style="font-size: large;" class="fa fa-cog text-white" aria-hidden="true"></i></button>
                                                                                            </div>
                                                                                        </div>

                                                                                        <section *ngIf="otherTime_secondMenu.nested_routing_option=='Ring'">
                                                                                            <ring2voice-ring-settings [model]="otherTime_secondMenu" [subOrg_id]="selected.sub_org"></ring2voice-ring-settings>
                                                                                        </section>

                                                                                        <section *ngIf="otherTime_secondMenu.nested_routing_option=='Announcement'">
                                                                                            <ring2voice-announcement-settings [model]="otherTime_secondMenu"></ring2voice-announcement-settings>
                                                                                        </section>

                                                                                        <section *ngIf="otherTime_secondMenu.nested_routing_option=='Voicemail'">
                                                                                            <ring2voice-voicemail-settings [model]="otherTime_secondMenu" [subOrg_id]="selected.sub_org"></ring2voice-voicemail-settings>
                                                                                        </section>

                                                                                        <div *ngIf="otherTime_secondMenu.nested_routing_option=='Intelligent Menu'">
                                                                                            <div class="row">
                                                                                                <div class="col-12 col-md-6">
                                                                                                    <div class="form-group">
                                                                                                        <label class="val-required">AUTO ATTENDANT voice</label>
                                                                                                        <voice-select [(value)]="otherTime_secondMenu.gather_gender"></voice-select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-12 col-md-6">
                                                                                                    <div class="form-group">
                                                                                                        <label class="val-required">HOW MANY LOOPS?</label>
                                                                                                        <input type="number" name="otherT_no_of_loops" min="1" max="5" [(ngModel)]="otherTime_secondMenu.no_of_loops"
                                                                                                               class="form-control" min="1" max="5" required PreventSpecialCharacters>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-12 col-md-6">
                                                                                                    <div class="form-group">
                                                                                                        <label class="val-required">AUTO ATTENDANT</label>
                                                                                                        <input type="text" name="otherT_gather_message" class="form-control" (input)="onInputChanged($event)"
                                                                                                               [(ngModel)]="otherTime_secondMenu.gather_message" required>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-12 col-md-6">
                                                                                                    <div class="form-group">
                                                                                                        <label class="val-required">IF CALLER DOES NOT PRESS ANY BUTTON</label>
                                                                                                        <input type="text" name="otherT_not_responding_message"
                                                                                                               [(ngModel)]="otherTime_secondMenu.not_responding_message" class="form-control" required>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!--otherTime 3rd level menu ring section -->
                                                                                            <div  *ngFor="let thirdMenu of otherTime_secondMenu.call_router;let thirdOtherTimeMenuindex=index">
                                                                                                <div class="form-group p-2 mb-0"  style="border: 1px dotted #2351C9">
                                                                                                    <label class="val-required">Press</label>
                                                                                                    <a (click)="removeOtherTime3rdMenuRow(OtherTimeMenuindex,secondOtherTimeMenuindex,thirdOtherTimeMenuindex)" *ngIf="otherTime_secondMenu.call_router.length>1"><i class="ft-trash-2 float-right" style="color: red;"></i></a>
                                                                                                    <div class="d-flex">
                                                                                                        <ng-select class="form-select w-25"
                                                                                                                    placeholder="Select Number"
                                                                                                                    [searchable]="true"
                                                                                                                    [(ngModel)]="thirdMenu.input"
                                                                                                                    [clearable]="false" [ngModelOptions]="{standalone: true}">
                                                                                                            <ng-option *ngFor="let number of menuNumber" [value]="number" [disabled]="isNumberSelectedin3rdOtherTime(OtherTimeMenuindex,secondOtherTimeMenuindex,number)">{{ number }}</ng-option>
                                                                                                        </ng-select>
                                                                                                        <div class="col-8 text-secondary">
                                                                                                            <div *ngIf="showFullText; else shortText">
                                                                                                                Auto Attendant : {{ otherTime_secondMenu?.gather_message }}
                                                                                                                <a class="ml-1 text-info" (click)="toggleText()">Collapse</a>
                                                                                                            </div>
                                                                                                            <ng-template #shortText>
                                                                                                                Auto Attendant : {{ otherTime_secondMenu.gather_message | slice:0:90 }} 
                                                                                                                <a class="ml-1 text-info" *ngIf="otherTime_secondMenu?.gather_message?.length>70" (click)="toggleText()">..Expand</a>
                                                                                                            </ng-template>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="row">
                                                                                                        <div class="col-4 form-group">
                                                                                                            <label class="val-required mt-2">What would you like to do</label>
                                                                                                            <ng-select class="form-select"
                                                                                                                        placeholder="Select option"
                                                                                                                        [searchable]="false"
                                                                                                                        [(ngModel)]="thirdMenu.nested_routing_option"
                                                                                                                        [clearable]="false" [ngModelOptions]="{standalone: true}">
                                                                                                                <ng-option value="Ring">Ring</ng-option>
                                                                                                                <ng-option value="Announcement">Announcement</ng-option>
                                                                                                                <ng-option value="Voicemail">Voicemail</ng-option>
                                                                                                            </ng-select>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <section *ngIf="thirdMenu.nested_routing_option=='Ring'">
                                                                                                        <ring2voice-ring-settings [model]="thirdMenu" [subOrg_id]="selected.sub_org"></ring2voice-ring-settings>
                                                                                                    </section>

                                                                                                    <section *ngIf="thirdMenu.nested_routing_option=='Announcement'">
                                                                                                        <ring2voice-announcement-settings [model]="thirdMenu"></ring2voice-announcement-settings>
                                                                                                    </section>

                                                                                                    <section *ngIf="thirdMenu.nested_routing_option=='Voicemail'">
                                                                                                        <ring2voice-voicemail-settings [model]="thirdMenu" [subOrg_id]="selected.sub_org"></ring2voice-voicemail-settings>
                                                                                                    </section>

                                                                                                </div>
                                                                                                
                                                                                                <div class="third_verticalline" [hidden]="thirdOtherTimeMenuindex==otherTime_secondMenu.call_router.length-1"></div>
                                                                                            </div>
                                                                                            <div class="third_verticalline"></div>
                                                                                            <a class="mt-2" (click)="addNew3rdOtherTimeMenu(OtherTimeMenuindex,secondOtherTimeMenuindex)" [ngClass]="otherTime_secondMenu.call_router.length>=9?'disabled-link':'third_button'">
                                                                                                ({{otherTime_secondMenu.call_router.length}})<img src="assets/img/svg/automation_rules/add-circle.svg" class="ml-1" style="margin-top: -1px; margin-right: 3px;">
                                                                                                Add New Number
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="second_verticalline" [hidden]="secondOtherTimeMenuindex==OtherTimeMenu.call_router.length-1"></div>
                                                                                </div>
                                                                                <div class="second_verticalline" *ngIf="advancedSettings.routing_option=='Time Based Routing'"></div>
                                                                                <a class="mt-2" (click)="addNew2ndOtherTimeMenu(OtherTimeMenuindex)" [ngClass]="OtherTimeMenu.call_router.length>=9?'disabled-link':'second_button'" *ngIf="advancedSettings.routing_option=='Time Based Routing'">
                                                                                    ({{OtherTimeMenu.call_router.length}})<img src="assets/img/svg/automation_rules/add-circle.svg" class="ml-1" style="margin-top: -1px; margin-right: 3px;">
                                                                                    Add New Number
                                                                                </a>
                                                                            </div>
                                                                            <!---------------------------->
                                                                        </div>
                                                                        <div class="first_verticalline" [hidden]="OtherTimeMenuindex==OthertimeMenuRouting.length-1"></div>
                                                                    </div>
                                                                    <div class="first_verticalline" *ngIf="advancedSettings.routing_option=='Time Based Routing'"></div>
                                                                    <a class="mt-2" (click)="addNewOtherTimeMenuRouting()" [ngClass]="OthertimeMenuRouting.length>=9?'disabled-link':'first_button'" *ngIf="advancedSettings.routing_option=='Time Based Routing'">
                                                                        ({{OthertimeMenuRouting.length}})<img src="assets/img/svg/automation_rules/add-circle.svg" class="ml-1" style="margin-top: -1px; margin-right: 3px;">
                                                                        Add New Number
                                                                    </a>
                                                                </div>

                                                                <section *ngIf="advancedSettings.all_other_time.ring_option=='Announcement'">
                                                                    <ring2voice-announcement-settings [model]="advancedSettings.all_other_time"></ring2voice-announcement-settings>
                                                                </section>
                                                                <!-- All Other Times Voicemail -->
                                                                <section *ngIf="advancedSettings.all_other_time.ring_option=='Voicemail'">
                                                                    <ring2voice-voicemail-settings [model]="advancedSettings.all_other_time" [subOrg_id]="selected.sub_org"></ring2voice-voicemail-settings>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-sm-row flex-column justify-content-end mt-2">
                                                    <button class="btn btn-secondary mr-2" (click)="closeDetailedView()">Cancel</button>
                                                    <button class="btn btn-secondary mr-2" (click)="getAdvancedCallSettings(selected.sid)">Reset</button>
                                                    <button class="btn gradient-pomegranate" (click)="onSaveClick()">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Phone Numbers</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                  <forward-bin-dids [forwardBinSid]="selected.sid" [globalRoute]="selected.global_route"></forward-bin-dids>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><ng-template #menuSettingsModal let-c="close" let-d="dismiss">
    <ring2voice-menu-settings [d]="d" [route]="selected_menu" [activeMenuInputs]="selectedMenuInputs"></ring2voice-menu-settings>
</ng-template>
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { InventoryModule } from '../inventory/inventory.module';
import { InvoiceMaterialGridComponent } from './sales-invoice/invoice-material-grid/invoice-material-grid.component';
import { FormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InvoiceComponent } from './sales-invoice/invoice/invoice.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InvoiceTimesheetGridComponent } from './sales-invoice/invoice-timesheet-grid/invoice-timesheet-grid.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceTicketModule } from '../service-ticket/service-ticket.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { CustomerInfoInvoiceComponent } from './sales-invoice/customer-info-invoice/customer-info-invoice.component';
import { TenantCustomersModule } from '../tenant-customers/tenant-customers.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ServiceTicketSelectModule } from '../service-ticket-select/service-ticket-select.module';
import { SalesOrdersComponent } from './sales-order/sales-order-view/sales-orders.component';
import { SoMaterialsGridComponent } from './sales-order/so-materials-grid/so-materials-grid.component';
import { ServiceTicketViewComponent } from './service-ticket/service-ticket-view/service-ticket-view.component';
import { StMaterialsGridComponent } from './service-ticket/st-materials-grid/st-materials-grid.component';
import { StTaskListFormComponent } from './service-ticket/st-task-list-form/st-task-list-form.component';
import { TimeSheetGridComponent } from './service-ticket/time-sheet-grid/time-sheet-grid.component';
import { CustomerInfoStComponent } from './service-ticket/customer-info-st/customer-info-st.component';
import { ServiceContractComponent } from './service-contract/service-contract-view/service-contract.component';
import { ScCommentsComponent } from './service-contract/sc-comments/sc-comments.component';
import { WarrantyComponent } from './warranty/warranty-view/warranty.component';
import { WarrantyCommentsComponent } from './warranty/warranty-comments/warranty-comments.component';
import { CustomerInfoScComponent } from './service-contract/customer-info-sc/customer-info-sc.component';
import { CustomerInfoWarrantyComponent } from './warranty/customer-info-warranty/customer-info-warranty.component';
import { CustomerInfoSoComponent } from './sales-order/customer-info-so/customer-info-so.component';
import { StCallhistoryComponent } from './service-ticket/st-callhistory/st-callhistory.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PaymentsModule } from '../payments/payments.module';



@NgModule({
  declarations: [
    InvoiceComponent,
    InvoiceMaterialGridComponent,
    InvoiceTimesheetGridComponent,
    CustomerInfoInvoiceComponent,
    SalesOrdersComponent,
    SoMaterialsGridComponent,
    ServiceTicketViewComponent,
    StMaterialsGridComponent,
    StTaskListFormComponent,
    TimeSheetGridComponent,
    CustomerInfoStComponent,
    ServiceContractComponent,
    ScCommentsComponent,
    WarrantyComponent,
    WarrantyCommentsComponent,
    CustomerInfoScComponent,
    CustomerInfoWarrantyComponent,
    CustomerInfoSoComponent,
    StCallhistoryComponent
    
  ],
  imports: [
    CommonModule,
    InventoryModule,
    FormsModule,
    RouterModule,
    SharedModule,
    NgxSummernoteModule,
    NgbModule,
    PaymentsModule,
    ServiceTicketSelectModule,
    DragDropModule,
    MatIconModule,
    TenantCustomersModule,
    NgSelectModule,
    NgxDatatableModule
  ],
  exports:[
    InvoiceComponent,
    InvoiceMaterialGridComponent,
    InvoiceTimesheetGridComponent,
    CustomerInfoInvoiceComponent,
    SalesOrdersComponent,
    SoMaterialsGridComponent,
    ServiceTicketViewComponent,
    CustomerInfoStComponent,
    ServiceContractComponent,
    ScCommentsComponent,
    WarrantyComponent,
    CustomerInfoScComponent,
    CustomerInfoWarrantyComponent,
    CustomerInfoSoComponent
  ],
  providers:[
    CurrencyPipe
  ]
})
export class SalesSharedModule { }

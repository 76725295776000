<ng-select [placeholder]="placeholder" [items]="options | async | orderBy:'first_name'" [appendTo]="'body'" bindLabel="first_name" bindValue="id" [multiple]="multiple" [(ngModel)]="selected" [readonly]="readonly" [closeOnSelect]="!multiple" (change)="onValueChanged($event)">

    <ng-template ng-label-tmp let-item="item">
        {{item.first_name}} {{item.last_name}}
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input *ngIf="multiple" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
        <span class="ml-1">{{item.first_name}} {{item.last_name}}</span>
    </ng-template>

</ng-select>
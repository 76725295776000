import { ChangeDetectorRef, ViewChild, ElementRef, Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { SCItemsList, SCKitsList, SCList } from '../../../sales/models/service-contract.models';
import { ServiceContractService } from '../../../sales/services/service-contract.service';
import { SalesService } from '../../../sales/services/sales.service';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';


@Component({
  selector: 'app-service-contract',
  templateUrl: './service-contract.component.html',
  styleUrls: ['./service-contract.component.scss']
})
export class ServiceContractComponent implements OnInit {

  @Input() item: SCList;

  @Input() showEditButton:boolean = true;

  @Input() showDeleteButton:boolean = true;

  @Output() onDelete = new EventEmitter<SCList>();

  @Output() onClose = new EventEmitter();

  public itemList: SCItemsList[];

  public kitList: SCKitsList[];

  public sc: SCList;

  public itemSlNo: number = 0;

  public subs: SubSink = new SubSink();

  public coverageType = { 1: "Meterial only", 2: "Labor only", 3: "Meterial & Labor" };

  title = 'htmltopdf';

  @ViewChild('pdfTable') pdfTable: ElementRef;

  public UIPermissions: UIPermission;

  constructor(private SCService: ServiceContractService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public salesService: SalesService,
    private ra: ResourceAccessService,
  ) {
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_WARRANTY, ResourceConstants.SALES_MODULE);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item.currentValue && changes.item.previousValue && changes.item.currentValue.id && changes.item.currentValue.id > 0 && changes.item.previousValue.id && changes.item.previousValue.id != changes.item.currentValue.id) {
      this.getItems();
      this.getKits();
    }
  }

  ngOnInit(): void {
    this.getItems();
    this.getKits();
  }



  getItems() {
    this.subs.sink = this.SCService.itemsList(this.item.id).subscribe(
      response => {
        this.itemList = response;
        this.cdr.markForCheck();

      },
      () => {
        this.toastr.error("Unable to get items");
      }
    );
  }

  getKits() {
    this.subs.sink = this.SCService.kitsList(this.item.id).subscribe(
      response => {

        //  window.alert("Got the response");
        this.kitList = response;
        this.cdr.markForCheck();

      },
      () => {
        this.toastr.error("Unable to get items");
      }
    );
  }


  updateSlNo() {
    this.itemSlNo = this.itemSlNo + 1;
  }

  openTerms() {

  }

  priceSummaryFunc(items: SCItemsList[], kits: SCKitsList[]) {
    let i = items?.map(row => row.price * row.quantity)?.reduce((a, b) => a + b, 0.00);
    let k = kits?.map(row => row.price * row.quantity)?.reduce((a, b) => a + b, 0.00);
    return Number((i + k)).toFixed(2);
  }

  getStatusClass(status: string) {

    let statusClass = "";
    switch (status) {
      case "Active": {
        statusClass = "active";
        break;
      }
      case "Pending": {
        statusClass = "pending"
        break;
      }
      case "Draft": {
        statusClass = "draft";
        break;
      }
      case "Expired": {
        statusClass = "expired";
        break;
      }
    }

    return statusClass;
  }

  trimTime(date: string) {
    if (date && date.length > 0) {
      let data = date.split('T');
      return data[0];
    }
    else {
      return '';
    }
  }

  addSpaceAfterComa(str: string) {
    var re = /,/gi;
    if (str) {
      return str.replace(re, ', ');
    }
    else {
      return str;
    }
  }

  deleteSC(item: any) {
    this.onDelete.emit(item)
  }

  closeDetailedView() {
    this.onClose.emit()
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

}

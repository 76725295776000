import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { DIDPurchaseRequest, DIDRequest, IAvailableDidResponse, IDidPurchaseResponse, IPhoneNumberAvail } from '../models/dids.models';
import { DataFilterGroupRequest, DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { AdvancedCallSettingsReqest, AdvancedCallSettingsResponse, ChatBinPayload, ChatRouteBin, ForwardBin } from '../models/forward-bin.models';
import { CallLog } from '../models/reports.models';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class Ring2voiceService extends BaseService {

    constructor(private http: HttpClient) { super() }

    getForwardBins(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/forwardbins/data/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<ForwardBin[]>>(url, request);
    }

    getGlobalForwardBins(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/forwardbins/data/global/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<ForwardBin[]>>(url, request);
    }

    getForwardBinById(sid: any) {
        return this.http.get<ForwardBin>(`${this.baseURL}/forwardbins/${sid}/`);
    }

    createForwardBin(model: ForwardBin) {
        return this.http.post<ForwardBin>(`${this.baseURL}/forwardbins/`, model);
    }

    updateForwardBin(sid: string, model: ForwardBin) {
        return this.http.put<ForwardBin>(`${this.baseURL}/forwardbins/${sid}/`, model);
    }

    updateForwardBinSmartSipGroup(sid: string, sipdetailsresponse: ForwardBin) {
        return this.http.patch(`${this.baseURL}/forwardbins/${sid}/`, sipdetailsresponse);
    }

    activateForwardBin(sid: string, body: { status: boolean }) {
        return this.http.post<{ status: boolean, message: string }>(`${this.baseURL}/forwardbins/${sid}/status/`, body);
    }

    activateGlobalRoute(sid: string, body: { global_route: boolean }) {
        return this.http.post<{ global_route: boolean, message: string }>(`${this.baseURL}/forwardbins/${sid}/global_route/`, body);
    }

    activateCallCenter(sid: string, body: { call_center: boolean }) {
        return this.http.post<{ callcenter: boolean, message: string, background: boolean }>(`${this.baseURL}/forwardbins/${sid}/call_centre_enabled/`, body);
    }

    availableDids(data: DIDRequest) {
        return this.http.post<IAvailableDidResponse>(`${this.baseURL}/did/available/`, data);
    }

    purchaseDids(data: DIDPurchaseRequest) {
        return this.http.post<IDidPurchaseResponse>(`${this.baseURL}/did/purchase/`, data);
    }

    deleteForwardBin(sid: string) {
        return this.http.delete<{ status: boolean, message: string }>(`${this.baseURL}/forwardbins/${sid}/`);
    }

    createAdvancedSettings(id: string, payload: AdvancedCallSettingsReqest) {
        return this.http.post<AdvancedCallSettingsResponse>(`${this.baseURL}/call_agent/${id}/settings/`, payload)
    }

    getAdvancedCallSettings(id: string) {
        return this.http.get<AdvancedCallSettingsResponse>(`${this.baseURL}/callcenter/settings/${id}`)
    }

    getAreaCodes() {
        return this.http.get<string[]>(`${this.baseURL}/area_codes/`);
    }

    getVoices() {
        return this.http.get<any[]>(`${this.baseURL}/voice/list/`);
    }

    getVoiceMails(request: DataFilterGroupRequest, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/voicemail/list/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<CallLog[]>>(url, request).pipe(
            catchError(() => of({ count: 0, result: [] }))
        );
    }

    voiceMailExport(request: DataFilterGroupRequest){

        return this.http.post(`${this.baseURL}/voicemail/list/?export=True`, request, { responseType: 'blob' });
    }

    deleteVoiceMail(id: string) {
        return this.http.delete<{ status: boolean, message: string }>(`${this.baseURL}/voicemail/${id}/`);
    }

    // chat bin apis
    getAllChatBin(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit){
        let url = `${this.baseURL}/message_route/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<ChatRouteBin[]>>(url, request);
    }

    getChatBinById(id: any) {
        return this.http.get<ChatRouteBin>(`${this.baseURL}/message_route/${id}`);
    }

    createChatBin(model: ChatBinPayload) {
        return this.http.post<ChatBinPayload>(`${this.baseURL}/message_route/`, model);
    }

    updateChatBin(id: string, model: ChatBinPayload) {
        return this.http.put<ChatBinPayload>(`${this.baseURL}/message_route/${id}`, model);
    }

    deleteChatBin(id: number) {
        return this.http.delete<{ message: string }>(`${this.baseURL}/message_route/${id}`);
    }

}

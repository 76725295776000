<div class="select-wrapper">
    <ng-select ngClass="ng-select-custom-footer"
        [placeholder]="placeholder"
        [items]="options | async"
        bindLabel="name" bindValue="id"
        [(ngModel)]="selected"
        [typeahead]="textInput"
        [minTermLength]="2"
        [ngClass]="{'ng-select-sm':size=='sm'}"
        [trackByFn]="trackByFn"
        typeToSearchText="Please enter 2 or more characters"
        appendTo="body"
        (change)="onChange($event)"
        (search)="onSearch()"
        (clear)="reset();onChange($event);onClear();"
        (open)="selectOpen()">
        
        <ng-template ng-label-tmp let-opt="item">
            <span [style.color]="opt.color">{{opt.name}}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <span [style.color]="item.color">{{item.name}}</span>&nbsp;<span *ngIf="item.is_global" class="badge bg-warning">Global</span>
        </ng-template>
        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
                <a *ngIf="addButton && hasPermission()" class="add-button text-info" (click)="openModal(content)">
                    <i class="icon icon-settings"></i> Add Priority
                </a>
            </div>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Priority</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <priority-form (success)="onDataSubmittedSuccess($event)" [suborg_id]="suborg_id" (error)="onDataError($event)" (cancel)="onCancel()">
            </priority-form>
        </div>
    </ng-template>
</div>

import { WarehouseSelectComponent } from './components/select/warehouse-select/warehouse-select.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { AssembledKitItemsComponent } from './components/assembled-kit-items/assembled-kit-items.component';
import { KitItemsComponent } from './components/kit-items/kit-items.component';
import { KitSelectComponent } from './components/select/kit-select/kit-select.component';
import { ItemSelectComponent } from './components/select/item-select/item-select.component';
import { WarehouseComponent } from './pages/warehouse/warehouse.component';
import { AssembleKitComponent } from './pages/assemble-kit/assemble-kit.component';
import { AssembledKitsComponent } from './pages/assembled-kits/assembled-kits.component';
import { StateTaxFormComponent } from './components/forms/state-tax-form/state-tax-form.component';
import { StateTaxComponent } from './pages/state-tax/state-tax.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ItemSupplierFormComponent } from './components/forms/item-supplier-form/item-supplier-form.component';
import { AddCategoryComponent } from './pages/add-category/add-category.component';
import { UnitFormComponent } from './components/forms/unit-form/unit-form.component';
import { ManufacturerFormComponent } from './components/forms/manufacturer-form/manufacturer-form.component';
import { ItemCategoryFormComponent } from './components/forms/item-category-form/item-category-form.component';
import { ItemTypesComponent } from './pages/item-types/item-types.component';
import { UnitTypesComponent } from './pages/unit-types/unit-types.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ManufacturerListComponent } from './pages/manufacturer-list/manufacturer-list.component';
import { SupplierListComponent } from './pages/supplier-list/supplier-list.component';
import { ItemCategoriesComponent } from './pages/item-categories/item-categories.component';
import { ItemListComponent } from './pages/item-list/item-list.component';
import { AddItemComponent } from './pages/add-item/add-item.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorPickerModule } from 'ngx-color-picker';



import { InventoryRoutingModule } from './inventory-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ItemTypeFormComponent } from './components/forms/item-type-form/item-type-form.component';
import { ItemCategorySelectComponent } from './components/select/item-category-select/item-category-select.component';
import { ItemTypeSelectComponent } from './components/select/item-type-select/item-type-select.component';
import { ManufacturerSelectComponent } from './components/select/manufacturer-select/manufacturer-select.component';
import { SupplierSelectComponent } from './components/select/supplier-select.component';
import { UnitTypeSelectComponent } from './components/select/unit-type-select/unit-type-select.component';
import { ItemViewComponent } from './components/item-view/item-view.component';
import { KitListComponent } from './pages/kit-list/kit-list.component';
import { SalesAccountsComponents } from './pages/sales-accounts/sales-accounts.component';
import { SalesAccountFormComponent } from './components/forms/sales-account-form/sales-account-form.component';
import { AddKitComponent } from './pages/add-kit/add-kit.component';
import { TaxSelectComponent } from './components/select/tax-select/tax-select.component';
import { SalesAccountSelectComponent } from './components/select/sales-account-select/sales-account-select.component';
import { KitViewComponent } from './components/kit-view/kit-view.component';
import { KitAssembleWarehouseComponent } from './components/kit-assemble-warehouse/kit-assemble-warehouse.component';
import { ItemStockComponent } from './components/item-stock/item-stock.component';
import { KitStockComponent } from './components/kit-stock/kit-stock/kit-stock.component';
import { BuildAssembleSelectComponent } from './components/select/build-assemble-select/build-assemble-select.component';
import { BuildAssembleSelectViewComponent } from './components/select/build-assemble-select-view/build-assemble-select-view.component';
import { ItemWarehouseSelectComponent } from './components/select/item-warehouse-select/item-warehouse-select.component';

import { LabelAddComponent } from './pages/label-add/label-add.component';
import { LableListComponent } from './pages/lable-list/lable-list.component';
import { LabelPagesListComponent } from './pages/label-pages-list/label-pages-list.component';
import { LabelPagesAddComponent } from './pages/label-pages-add/label-pages-add.component';
import { LabelPrintComponent } from './pages/label-print/label-print.component';
import { QRCodeModule } from 'angularx-qrcode';
import {MatTreeModule } from '@angular/material/tree';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { RolesModule } from '../roles/roles.module';
import { ProductLineFormComponent } from './components/forms/product-line-form/product-line-form.component';
import { MaterialFormComponent } from './components/forms/material-form/material-form.component';
import { ProductLinesComponent } from './pages/product-lines/product-lines.component';
import { MaterialsComponent } from './pages/materials/materials.component';
import { ProductLineSelectComponent } from './components/select/product-line-select/product-line-select.component';
import { MaterialSelectComponent } from './components/select/material-select/material-select.component';
import { SearchPipe } from 'src/app/shared/pipes/input-search.pipe';
import { LabelPrintPaginationComponent } from './components/label-print-pagination/label-print-pagination.component';
import { LabelCreateComponent } from './components/label-create/label-create.component';
import { ItemFormComponent } from './components/forms/item-form/item-form.component';
import { KitFormComponent } from './components/forms/kit-form/kit-form.component';
import { AdditionalFieldsListComponent } from './pages/settings/additional-fields-list/additional-fields-list.component';
import { AdditionalFieldFormComponent } from './components/forms/additional-field-form/additional-field-form.component';
import { CatalogueDesignComponent } from './pages/catalogue/component-design/design-product.component';
import { CurrencyPipe } from '@angular/common';
import { CatalogueSubnavComponent } from './components/catalogue-subnav/catalogue-subnav.component';
import { DesignCatalogComponent } from './pages/catalogue/catalog-design/design-catalog.component';
import { PageCanvasComponentComponent } from './pages/catalogue/_page-canvas-component/page-canvas-component.component';
import { CatalogueProductSelectComponent } from './components/select/catalogue-product-select/catalogue-product-select.component';
import { ListCatalogComponent } from './pages/catalogue/list-catalog/list-catalog.component';
import { CatalogViewComponent } from './components/catalog-view/catalog-view.component';
import { CatalogProductsComponent } from './components/catalog-products/catalog-products.component';
import { SuborgSelectComponent } from '../roles/components/suborg-select/suborg-select.component';
import { CatalogProductSelectCategoryComponent } from './components/select/catalogue-product-select/catalog-product-select-category/catalog-product-select-category.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AdditionalFieldSelectComponent } from './components/select/additional-field-select/additional-field-select.component';
import { CatAdditionalFieldAddComponent } from './components/cat-additional-field-add/cat-additional-field-add.component';
import { ListPageTemplateComponent } from './pages/catalogue/list-page-template/list-page-template.component';
import { ListProductTemplateComponent } from './pages/catalogue/list-product-template/list-product-template.component';
import { ProducttemplateViewComponent } from './components/producttemplate-view/producttemplate-view.component';
import { PagetemplateviewComponent } from './components/pagetemplate-view/pagetemplate-view.component';
import { RowColSelectorComponent } from './components/row-col-selector/row-col-selector.component';
import { AssocItemsTabComponent } from './components/assoc-items-tab/assoc-items-tab.component';
import {MatTableModule} from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [
    AddItemComponent,
    ItemListComponent,
    ItemCategoriesComponent,
    SupplierListComponent,
    ManufacturerListComponent,
    ItemCategorySelectComponent,
    SupplierSelectComponent,
    UnitTypesComponent,
    ItemTypesComponent,
    ItemTypeSelectComponent,
    UnitTypeSelectComponent,
    ManufacturerSelectComponent,
    ItemViewComponent,
    ItemTypeFormComponent,
    ItemCategoryFormComponent,
    ManufacturerFormComponent,
    UnitFormComponent,
    AddCategoryComponent,
    ItemSupplierFormComponent,
    KitListComponent,
    StateTaxComponent,
    StateTaxFormComponent,
    SalesAccountsComponents,
    SalesAccountFormComponent,
    AddKitComponent,
    TaxSelectComponent,
    SalesAccountSelectComponent,
    KitViewComponent,
    AssembledKitsComponent,
    AssembleKitComponent,
    WarehouseComponent,
    ItemSelectComponent,
    KitAssembleWarehouseComponent,
    KitSelectComponent,
    KitItemsComponent,
    AssembledKitItemsComponent,
    ContactsComponent,
    WarehouseSelectComponent,
    ItemStockComponent,
    KitStockComponent,
    BuildAssembleSelectComponent,
    BuildAssembleSelectViewComponent,
    ItemWarehouseSelectComponent,
    LabelAddComponent,
    LableListComponent,
    LabelPagesListComponent,
    LabelPagesAddComponent,
    LabelPrintComponent,
    ProductLineFormComponent,
    MaterialFormComponent,
    ProductLinesComponent,
    MaterialsComponent,
    ProductLineSelectComponent,
    MaterialSelectComponent,
    SearchPipe,
    LabelPrintPaginationComponent,
    LabelCreateComponent,
    ItemFormComponent,
    KitFormComponent,
    AdditionalFieldsListComponent,
    AdditionalFieldFormComponent,
    CatalogueDesignComponent,
    CatalogueSubnavComponent,
    DesignCatalogComponent,
    PageCanvasComponentComponent,
    ItemFormComponent,
    KitFormComponent,
    CatalogueProductSelectComponent,
    // CatalogueProductSelectComponent,
    ListCatalogComponent,
    CatalogViewComponent,
    CatalogProductsComponent,
    CatalogProductSelectCategoryComponent,
    AdditionalFieldsListComponent,
    AdditionalFieldFormComponent,
    AdditionalFieldSelectComponent,
    CatAdditionalFieldAddComponent,
    ListPageTemplateComponent,
    ListProductTemplateComponent,
    ProducttemplateViewComponent,
    PagetemplateviewComponent,
    RowColSelectorComponent,
    AssocItemsTabComponent
    
  ],
  imports: [
    InventoryRoutingModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    QRCodeModule,
    RolesModule,
    RolesModule,
    DragDropModule,
    ColorPickerModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule 
   

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    TaxSelectComponent,
    ItemSelectComponent,
    KitSelectComponent,
    PageCanvasComponentComponent,
    CatalogViewComponent,
    ManufacturerSelectComponent,
    ItemCategorySelectComponent,
    SupplierSelectComponent,
    SuborgSelectComponent
  ],
  providers: [CurrencyPipe,]

})
export class InventoryModule { }

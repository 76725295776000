import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-r2v-settings',
  templateUrl: './r2v-settings.component.html',
  styleUrls: ['./r2v-settings.component.scss']
})
export class R2vSettingsComponent implements OnInit {

  public expanded: boolean = true;

  @Input() selectedSettings: string = 'TimeFrames';

  constructor(private router: Router,
    private route: ActivatedRoute,
    public location: Location) { }

  ngOnInit(): void {}

  navigateSettings(selected:string) {
    this.selectedSettings = selected;
    if(selected=='TimeFrames'){
      this.router.navigate(['ring2voice/settings/time-frames']);
    }else if(selected=='HoldMusic'){
      this.router.navigate(['ring2voice/settings/music-on-hold']);
    }
  }

}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { addInvoice, Email, InvoiceDashBoardCounts, InvoiceList, ItemAdd, ItemRow, Section } from '../models/invoice.models';
import { TimeSheet } from '../../service-ticket/models/common.models';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseService{

  constructor(private http: HttpClient) {
    super();
}

getAll(option: any, offset: number = 0) {
    let url = `${this.baseURL}/sales_app/invoice/filter/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<{ count: number, result: InvoiceList[] }>(url, option).pipe(
        catchError(() => of({ count: 0, result: [] }))
    );
}

// listIN() {
//     let url = `${this.baseURL}/sales_order/`;
//     return this.http.get<SOList[]>(url);
// }

addIN(data) {
    let url = `${this.baseURL}/sales_app/invoice/`;
    return this.http.post<InvoiceList>(url, data);
}

viewIN(id: number) {
    let url = `${this.baseURL}/sales_app/invoice/` + id + `/`;
    return this.http.get<addInvoice>(url);
}

updateIN(data, id) {
    let url = `${this.baseURL}/sales_app/invoice/` + id + `/`;
    return this.http.put<{ data }>(url, data);
}

patchIN(request: any, id: number) {
    return this.http.patch(`${this.baseURL}/sales_app/invoice/${id}/`, request);
}

getDashboardCounts() {
    let url = `${this.baseURL}/sales_app/invoice/dashboard/`;
    return this.http.get<InvoiceDashBoardCounts>(url);
}



//Items and kits on single API

itemKitList(id:number){
    let url = `${this.baseURL}/sales_app/invoice/`+id+`/items/kits/`;
    return this.http.get<ItemRow[]>(url);
}

itemKitAdd(data:ItemAdd[]){
    let url = `${this.baseURL}/sales_app/invoice/items/kits/`;
    return this.http.post<{ data }>(url, data);
}

itemKitUpdate(data:ItemAdd[]){
    let url = `${this.baseURL}/sales_app/invoice/items/kits/get/update/detete/`;
    return this.http.put<{ data }>(url, data);
}

itemKitDelete(id: number) {
    const options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        body: {
            id: id,
        },
    };
    // let value={"id":id};
    let url = `${this.baseURL}/sales_app/invoice/items/kits/get/update/detete/`;
    return this.http.delete<{ data }>(url, options);
}

generate_pdf(item : InvoiceList, sections: Section[], timesheets?: TimeSheet[]){
    let url = `${this.baseURL}/sales_app/invoice/generate_pdf/`;
    return this.http.post(url, {"item":item, "sections": sections,"time_sheets": timesheets}, { responseType: 'blob' });
}

sendMail(email:Email, item : InvoiceList, sections: Section[], timesheets?: TimeSheet[]){
    let temp_email = JSON.parse(JSON.stringify(email));
    let url = `${this.baseURL}/sales_app/invoice/sendmail/`;
    return this.http.post(url, {"item":item, "sections": sections, "email": temp_email,"time_sheets": timesheets});
}

getEmailViewedHistory(unique_number: string){
    let url = `${this.baseURL}/automation/notification_history/${unique_number}/`;
    return this.http.get(url);
}

getTimeSheets(invoice_id: number) {
    return this.http.get<TimeSheet[]>(`${this.baseURL}/sales_app/get_invoice_timesheet/${invoice_id}/`);
  }

//sub section


// itemsAdd(data: SOItemAdd[]) {
//     let url = `${this.baseURL}/sales_order/items/`;
//     return this.http.post<{ data }>(url, data);
// }
// itemsUpdate(data: SOItemAdd[], id: number) {
//     let url = `${this.baseURL}/sales_order/items/edit/delete/`;
//     return this.http.put<{ data }>(url, data);
// }

// itemsList(warrantyId: number) {
//     let url = `${this.baseURL}/sales_order/` + warrantyId + `/items/`;
//     return this.http.get<SOItemsList[]>(url);
// }

// itemDelete(id: number) {
//     const options = {
//         headers: new HttpHeaders({
//             'Content-Type': 'application/json',
//         }),
//         body: {
//             id: id,
//         },
//     };
//     // let value={"id":id};
//     let url = `${this.baseURL}/sales_order/items/edit/delete/`;
//     return this.http.delete<{ data }>(url, options);
// }

// kitsAdd(data: SOKitsAdd[]) {
//     let url = `${this.baseURL}/sales_order/kits/`;
//     return this.http.post<{ data }>(url, data);
// }

// kitsUpdate(data: SOKitsAdd[], id) {
//     let url = `${this.baseURL}/sales_order/kits/edit/delete/`;
//     return this.http.put<{ data }>(url, data);
// }

// kitsList(warrantyId: number) {
//     let url = `${this.baseURL}/sales_order/` + warrantyId + `/kits/`;
//     return this.http.get<SOKitsList[]>(url);
// }

// kitsDelete(id: number) {
//     const options = {
//         headers: new HttpHeaders({
//             'Content-Type': 'application/json',
//         }),
//         body: {
//             id: id,
//         },
//     };
//     // let value={"id":id};
//     let url = `${this.baseURL}/sales_order/kits/edit/delete/`;
//     return this.http.delete<{ data }>(url, options);
// }


//Items and kits on single API




// Comments API


    // createComment(comments: SOComments[], id: number) {
    //     return this.http.post<SOComments[]>(`${this.baseURL}/sales_order/comment/`, comments);
    // }

    // updateComment(id: number, comment: SOComments) {
    //     return this.http.put(`${this.baseURL}/sales_order/comment/${id}/`, comment);
    // }

    // getComments(ticketId: number) {
    //     return this.http.get<SOComments[]>(`${this.baseURL}/sales_order/${ticketId}/comment/`);
    // }

    // deleteComment(id: number) {
    //     return this.http.delete(`${this.baseURL}/sales_order/comment/${id}/`);
    // }

// Comments API


}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SipOutBoundFunctionService } from '../../services/sipoutboundfunctionservice';
import { CallCenterService } from '../../services/call-center.service';
import { sipInboundFunctionService } from '../../services/sipinbound-function.service';

@Component({
  selector: 'app-current-call-list-panel',
  templateUrl: './current-call-list-panel.component.html',
  styleUrls: ['./current-call-list-panel.component.scss']
})
export class CurrentCallListPanelComponent implements OnInit {


  @Output() sel_IncomingCallId = new EventEmitter<string>();

  @Output() sel_OugoingCallInfo = new EventEmitter<any>();

  @Output() holdCall = new EventEmitter<boolean>();

 // @Input()  selectedCaller_Outbound = this.sipOutBoundService.currentCallDetails;

  public clearCallQ: boolean = false;

  public outgoingCallId: string;

  public sel_CallSection: any


  constructor( public sipOutBoundService :SipOutBoundFunctionService,
   public sipInboundFnService : sipInboundFunctionService) { }

  ngOnInit(): void {
  }

  getIncomingCallId(session){
    if(session.state!='Established'){
      this.clearCallQ = true;
    }
    this.sipInboundFnService.selectedCaller=session;
    if(session.request.headers['X-Signalwire-Callsid']){
      this.sel_IncomingCallId.emit(session.request.headers['X-Signalwire-Callsid'][0].raw);
    }else{
      this.sel_IncomingCallId.emit(null)
    } 
  }

  getSelOutgoingCallInfo(session){

    if(session.state!='Established'){
      this.clearCallQ = true;
    }
    this.sipOutBoundService.currentCallDetails.active_CallerSession = session
    this.sel_OugoingCallInfo.emit()
    this.sipOutBoundService.get_DetailsofNonCallCenterNumber()
    this.sel_OugoingCallInfo.emit(this.sipOutBoundService.currentCallDetails)
    this.sipOutBoundService.outBoundShowType = 'details'  


    //this.selectedCaller_Outbound=session;
   
      //add getIncomingCallDetailsApi(callId)
  }

  callerInfo(session){//return caller name/number
    return session.remoteIdentity.uri.user;
  }

  answeredCall(session){//check whether call ongoing
    return session.state=='Established';
  }

  onHoldCall()
  {
     this.holdCall.emit(true)
  }

}

import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { SuborgRoutingModule } from './suborg-routing.module';
import { CustomerModule } from '../customer/customer.module';
import { AddSubOrgComponent } from './pages/add-sub-org/add-sub-org.component';
import { SubOrgsComponent } from './pages/sub-orgs/sub-orgs.component';
import { UserModule } from '../user/user.module';
import { R2vSettingsComponent } from './components/r2v-settings/r2v-settings.component';
import { PaymentsModule } from '../payments/payments.module';
import { QbooksSettingsComponent } from './components/qbooks-settings/qbooks-settings.component';

@NgModule({
  declarations: [
    SubOrgsComponent,
    AddSubOrgComponent,
    R2vSettingsComponent,
    QbooksSettingsComponent
  ],
  imports: [
    SuborgRoutingModule,
    NgbModule,
    NgSelectModule,
    NgxDatatableModule,
    SharedModule,
    CustomerModule,
    UserModule,
    PaymentsModule
  ]
})
export class SuborgModule { }

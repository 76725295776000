import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ForwardBin } from '../../../../models/forward-bin.models';

@Component({
    selector: 'app-add',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.scss']
})
export class AddComponent {

    public sid: string;

    constructor(route: ActivatedRoute,
        private router:Router,
        private toastr: ToastrService,
        public location: Location) {

        this.sid = route.snapshot.paramMap.get('id');
    }

    onDataSuccess(result: { response: ForwardBin, message: string }): void {
        this.toastr.success('Success', result.message);
        this.router.navigate(['ring2voice/incoming-routes', result.response.sid,'view']);
    }

    onDataError(error: any): void {
        console.error(error);
        this.toastr.error('Failed', 'Operation failed');
    }

    onCancel(): void {
        this.location.back();
    }
}

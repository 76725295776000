import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-incoming-routes',
  templateUrl: './incoming-routes.component.html',
  styleUrls: ['./incoming-routes.component.scss']
})
export class IncomingRoutesComponent implements OnDestroy {

  public tabName: string;

  private $sub: Subscription;

  // constructor(route: ActivatedRoute, private location: Location) {
  //     this.$sub = route.params.subscribe(params => {
  //         this.tabName = params.tabname || 'calls'
  //     });
  // }

  constructor(private route: ActivatedRoute,private location: Location,private router:Router) {
    // Get the entire URL segments
    const urlSegments = this.route.snapshot.url;
    const index = urlSegments.findIndex(segment => segment.path === 'calls' || segment.path === 'chats');
    if (index !== -1 && index < urlSegments.length) {
      this.tabName = urlSegments[index].path;
    } else {
      this.tabName = 'calls';
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    console.log('parent url changed to: ',changeEvent.nextId);
    this.router.navigate([`ring2voice/incoming-routes/${changeEvent.nextId}`]);
  }

  ngOnDestroy(): void {
    console.log('parent ngOnDestroy()');
      this.$sub?.unsubscribe();
  }
}
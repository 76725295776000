import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HoldMusicPayload, HoldMusicResponse } from '../../models/hold-music.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { FilesService } from 'src/app/core/services/files.service';
import { SubSink } from 'subsink';
import { ToastrService } from 'ngx-toastr';
import { HoldMusicService } from '../../services/hold-music.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'r2v-hold-music-form',
  templateUrl: './hold-music-form.component.html',
  styleUrls: ['./hold-music-form.component.scss']
})
export class HoldMusicFormComponent implements OnInit {

  public subs: SubSink = new SubSink();

  public itemAudio: StaticFile[];

  public model: HoldMusicResponse;

  public nameError: boolean = false;

  private uploadModalRef: NgbModalRef;

  @Input() selected_Id: number;

  @Output() success = new EventEmitter<{ response: HoldMusicResponse, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  constructor(private modalService: NgbModal,
    private fileService: FilesService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private service: HoldMusicService) {
      // this.subs.sink = route.params.subscribe(params =>{ 
      //   this.selected_Id = +params.id || 0 
      // });
     }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe(params =>{ 
      this.selected_Id = +params.id || 0 
    });
    if (this.selected_Id>0) {
      this.getById(this.selected_Id);
    }
    else {
      this.model = new HoldMusicResponse();
    }
  }

  ngAfterViewInit(){
    // if (this.selected_Id>0) {
    //   this.getById(this.selected_Id);
    // }
    // else {
    //   this.model = new HoldMusicResponse();
    // }
  }

  getById(id:number){
    this.subs.sink = this.service.getById(id).subscribe(response=>{
      this.model = response;
      this.getAudio(this.model.id)
      if(!response.is_upload){
        this.model.music_link = response.music_link;
      }else{
        this.model.music_link = null;
      }
    })
  }

  getAudio(id:any): void {
    this.itemAudio=[];
    this.subs.sink = this.service._getHoldAudio(id).subscribe(response=>{
      if(response){
        this.itemAudio = response;
        // this.audioURLforDuration = this.fileURL+response[0].url;
      }
      this.cdr.markForCheck();
    });
  }

  uploadImg(fileUploader) {
    let thumbails: ThumbNail[] = fileUploader.getFiles();

    if (thumbails.length) {
        this.subs.sink = this.fileService.uploadFiles(thumbails, 'RING2VOICE_MUSIC_ON_HOLD').subscribe(
            response => {
                this.model.file_id = response[0].id;
                // this.audioURLforDuration = this.fileURL+response[0].url;
                this.itemAudio = response
                this.toastr.success('File uploaded');
                this.uploadModalRef?.close();
                this.cdr.markForCheck();
            },
            (error) =>{
                console.log(error);
            });
    }
  }

  onFileDelete(selected) {
    if (selected&&selected.file_id) {
      selected.file_id = null;
      this.subs.add(this.service.updateMusic(selected.id,selected).subscribe(resp => {
        this.itemAudio = [];
        this.model.file_id = null;
        this.toastr.success('File Deleted');
      }));
    }
  }

  dismissModal(modal: any) {
    modal.dismiss('closed');
  }

  openUploadModal(content: any,selected) {    
    this.uploadModalRef = this.modalService.open(content, { centered: true, scrollable: true, size: "lg" });
  }

  onSubmit(){
    if(this.model.is_upload&&!this.model.file_id){
      this.toastr.warning("Please upload audio");
      return;
    }
    let payload: HoldMusicPayload = new HoldMusicPayload();
    payload.customer = this.service.customerId;
    payload.music_link = this.model.is_upload?null:this.model.music_link;
    payload.name = this.model.name;
    payload.is_upload = this.model.is_upload;
    payload.file_id = this.model.is_upload?this.model.file_id:null;
    payload.is_disabled = this.model.is_disabled||false;
    
    let api = this.model.id?this.service.updateMusic(this.model.id,payload):this.service.createMusic(payload);
    this.subs.sink = api.subscribe(response=>{
      if(response.status){
        this.nameError = false;
        let msg = this.selected_Id ? `Music updated successfully` : `Music created successfully`;
        this.success.emit({ response: response.result, message: msg });
      }else if(response.message){
        this.nameError = true;
        this.cdr.markForCheck();
      }
    },
    (error)=>{
      this.error.emit(error);
    })
  }

  onCancelClicked(): void { this.cancel.emit(); }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
}

}

export const environment = {
  production: false,
  apiURL: 'https://dev.simplyfuse.com/workhorse/api',
  fileURL: 'https://dev.simplyfuse.com/media',
  imgURL:'https://dev.simplyfuse.com/workhorse/api/static/files/',
  webhookURL: 'https://webhookdev.simplyfuse.com/',
  siteUrl:'https://dev.simplyfuse.com/',
  callcenterSocketURL: 'wss://m3whle9ecb.execute-api.us-east-1.amazonaws.com/dev/',
  appVersion:"1.5.0",

  mapbox: {
    accessToken: 'pk.eyJ1Ijoic2ltcGx5ZnVzZSIsImEiOiJjbHI5YjNkd2kwMTZsMmxzNDF2cmlsMmRzIn0.Q8e-CC9o56m90HM-KEfHhg'
  },
  agentStatusURL:'https://8l7jk2g0c3.execute-api.us-east-1.amazonaws.com/Dev/dev_get_callcenter_log'
};

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StatusValueEnum } from 'src/app/core/enums/common.enum';
import { SipConnectionService } from './sip-connection.service';
import { WebSockectService } from './web-sockect.service';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { SubSink } from 'subsink';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { RegistererState } from 'sip.js';
import { sipInboundFunctionService } from './sipinbound-function.service';
import { SipOutBoundFunctionService } from './sipoutboundfunctionservice';
import { CallcenterSharedService } from './call-center-shared.service';


@Injectable({
  providedIn: 'root'
})
export class AgentStatusService {



  public sendMsgTimeOut:any;

  public subs:SubSink=new SubSink();

  public socketStatusSubs:SubSink=new SubSink();



  public internetJustGone:boolean=false;


  private socketConnection;

  private lastSocketPingTime: any;

  private internetJustCame:boolean=false;

  private lastPongTime:number=0;

  private lastStatusResponse:any;

  private processingStatusChangeStartedAt;

  private connectLostStatus:string="";
  
  public pageFirstLoad:boolean=true;

  public userMediaList: MediaDeviceInfo[] = [];

  public availableVideoDevices: any[] = [];

  public audioOutputDevices: any[] = [];

  public hasInputAudioPermission: boolean = false;

  public hasOutputAudioPermission: boolean = false;

  public hasInputVideoPermission: boolean = false;



  // worker: Worker;

  messageFromWorker: string;


  constructor(private sipConnectionService : SipConnectionService ,
     private sockectService : WebSockectService,
     private toastr: ToastrService,
     private sipInboundFnService: sipInboundFunctionService,
     public callcenterSharedService: CallcenterSharedService,
     private sipOutBoundFnService : SipOutBoundFunctionService ) { }


       /**
   * checkStatus
   * Its a one point function for managing agent status
   */
  checkStatus(status:string, msg='', manualChange=false){



    setTimeout(() => {


      
      console.warn('--Inside checkStatus--|'+status+'|'+msg);
      let change:boolean;
  
  
      if(status!=this.callcenterSharedService.agentStatus$.getValue()){
        change=true;
      }
      else{
        change=false;
      }
  
      if(change){
          this.callcenterSharedService.toBeStatus$.next(status);
      }
  
      if(this.callcenterSharedService.isInternetConnected$.getValue()||manualChange){
  
  
          if(this.sockectService.socketOpen$.getValue()&&this.callcenterSharedService.isSIPRegistered$.getValue()){
  
  
            this.callcenterSharedService.agentStatus$.next(status);
        
                  
            if(change||status!=StatusValueEnum.OFFLINE){
  
  
              this.changeAgentStatusApi(status);
  
            }
  
            if(this.callcenterSharedService.processingStatusChange$.getValue()){
              this.callcenterSharedService.processingStatusChange$.next(false);
            }
  
            if(status==StatusValueEnum.OFFLINE&&!this.internetJustGone){
  
              this.callcenterSharedService.connection.stop();
              this.callcenterSharedService.connection=null;
  
              this.sockectService.disconnect();
            }
        
          }
          else{
  
            let test1=this.sockectService.socketOpen$.getValue();
            let test2=this.callcenterSharedService.isSIPRegistered$.getValue();
  
            if(status==StatusValueEnum.OFFLINE){
  
                if(this.callcenterSharedService.isSIPRegistered$.getValue()&&this.callcenterSharedService.connection){
                 this.callcenterSharedService.connection.stop();
                 this.callcenterSharedService.connection=null;
                }
  
                if(this.sockectService.socketOpen$.getValue())
                  this.sockectService.disconnect();
  
              }
              else{
  
                if(!this.sockectService.connectingSocket$.getValue()&&!this.sockectService.socketOpen$.getValue()){
                  this.connectSocket2(true, 'From check status -'+this.callcenterSharedService.agentStatus$.getValue());
                }
                else if(!this.callcenterSharedService.connectingSIP$.getValue()&&!this.callcenterSharedService.isSIPRegistered$.getValue()){
                  this.connectSIP('from checkStatus else condition');
                }
  
              }
  
            this.callcenterSharedService.agentStatus$.next(StatusValueEnum.OFFLINE);
  
          }
      }
      else{
        
        this.callcenterSharedService.agentStatus$.next(StatusValueEnum.OFFLINE);
  
      }
  
  
  
      localStorage.setItem('statusAfterReload',this.callcenterSharedService.agentStatus$.getValue());
  
    //  this.cdr.markForCheck();
  
      
    }, 100);
  
  
  
  }
    /**
   * End of checkStatus function
   */

    
    /**
   * changeAgentStatusApi
   * function to change agent status
   */

  changeAgentStatusApi(status){
    if(this.sockectService.socketOpen$.getValue()){
      let msgBody:{userId:number, token:string, sip:string, userStatus:string};
      let token = localStorage.getItem(AppConstants.AUTH_TOKEN);
      let user = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))
  
          msgBody={
            userId:user.userid,
            token:token, 
            sip:"e0ef6fec-56f6-4d6c-bd9b-193d7f94a9a4", 
            userStatus:status}
          this.sendMsgTimeOut=setTimeout(() => {

            let resp= this.sockectService.send({"action":"statusChange", "message":msgBody});
            localStorage.setItem('statusAfterReload',status);
          }, 10);
  
    }else{
      this.connectSocket2(false, 'from changeAgentStatus API');
    } 
  }

  connectSocket2(triggerSIP=false, msg=''){

    // if(this.callcenterSharedService.isInternetConnected$.getValue()){
    //   this.callcenterSharedService.processingStatusChange$.next(true);
    // }
  
    this.callcenterSharedService.connectingSIP$.next(false);
  
    let connectToSIP=false;
  
    
  
    console.warn('inside connectSocket2:'+msg);
  
    if(this.sockectService.socketOpen$.getValue()&&triggerSIP&&!this.callcenterSharedService.isSIPRegistered$.getValue()&&!this.callcenterSharedService.connectingSIP$.getValue()){
  
        this.connectSIP('from connectSocket2 socket not connected');
        connectToSIP=true;
  
    }
    else if(!this.sockectService.socketOpen$.getValue()&&!this.sockectService.connectingSocket$.getValue()){
  
      
    

      this.socketConnection=this.sockectService.connect();
  
        connectToSIP=true;
  
        this.subs.sink = this.sockectService.socketMsg$.subscribe(
          resp=>{
  
            let now=moment.utc();
            console.log(resp, 'Message catched');
            if(resp&&resp?.type&&resp?.type=='pong'){
              this.lastSocketPingTime = moment();
              if(this.callcenterSharedService.isInternetConnected$.getValue()==false){
                this.callcenterSharedService.isInternetConnected$.next(true);
                this.internetJustCame=true;
                this.toastr.success('Connection restored');  
              }
              this.sockectService.pongTimeStamp=moment.utc();
               console.log(this.sockectService.pongTimeStamp.format('YYYY-MM-DD HH:mm:ss'), 'Pong in UTC');
               console.log(moment().utc().format('YYYY-MM-DD HH:mm:ss'), 'local in UTC');
  
              //  let time=moment().format('YYYY-MM-DD HH:mm:ss');
  
  
               let diff=now.diff(this.sockectService.pongTimeStamp, 'seconds');
  
               console.log('Local diff-> '+diff);
  
               this.lastPongTime=0;
  
              
  
            }
            else if(resp&&resp?.type&&resp?.type=="statusChange"&&!this.callcenterSharedService.processingStatusChange$.getValue()&&this.lastStatusResponse){
              
  
                let d=now.diff(this.lastStatusResponse, 'seconds')
  
                if(d>=6){
                  this.checkStatus(resp.status, '', true);
                   console.log('going to change status to ->', resp.status)
  
                }
  
                this.lastStatusResponse=moment.utc();
  
            }
    
          }
        );
    }
  
  
  }

  connectSIP(testMsg=''){

  

    // if(!this.callcenterSharedService.processingStatusChange$.getValue()){
    //   this.callcenterSharedService.processingStatusChange$.next(true);
    // }

    this.callcenterSharedService.connectingSIP$.next(true);

    console.warn("==== Connecting SIP =====")
    console.warn(testMsg)

    if(this.sockectService.socketOpen$.getValue()){
      setTimeout(() => {
        navigator.mediaDevices.getUserMedia({ 'audio': {deviceId: this.callcenterSharedService.selectedAudioInput ? {exact: this.callcenterSharedService.selectedAudioInput} : undefined} })
          .then(stream => {
              try{
               this.sipConnectionService.initialiseConnection().then((state)=>{

                if (state === RegistererState.Registered) {

                      localStorage.setItem('isOnCall','false');
                      this.callcenterSharedService.isSIPRegistered$.next(true)
                      this.callcenterSharedService.sip_details.status = StatusValueEnum.READY;
                      this.callcenterSharedService.connectingSIP$.next(false);
                      this.callcenterSharedService.processingStatusChange$.next(false);
                      // console.log("*************** SIP Successfully registered ***************");
                      console.warn("*************** SIP Successfully registered ***************");
                    } else if (state === RegistererState.Unregistered) {
            
                      this.callcenterSharedService.connectingSIP$.next(false);
                      this.callcenterSharedService.isSIPRegistered$.next(false)
                      this.callcenterSharedService.sip_details.status = StatusValueEnum.OFFLINE;
                      console.warn('>>>>>>> SIP Successfully unregistered! <<<<<<<<<');
            
                      this.socketStatusSubs.unsubscribe();
                    } else if (state === RegistererState.Terminated) {
                      console.warn('>>>>>>> SIP Registration terminated <<<<<<<<<<');
                      // if(!this.processingStatusChange&&this.connection){
                      //   this.connectSocket(true,StatusValueEnum.OFFLINE);
                      // }
                      // this.checkStatus(StatusValueEnum.OFFLINE, 'From SIP Terminated');
                      this.callcenterSharedService.connectingSIP$.next(false);
                      
                      this.callcenterSharedService.isSIPRegistered$.next(false)
                      this.callcenterSharedService.sip_details.status = StatusValueEnum.OFFLINE;
                    }else{
                      console.log('else part');
                      
                    }
                     this.sipInboundFnService.inviteCall()

               })
              }
              catch(e){
                this.checkStatus(StatusValueEnum.OFFLINE,'from connectSIP catch 1')
                this.toastr.error('Failed to initialize sip connection');
              }
          })
          .catch(err => {
            this.checkStatus(StatusValueEnum.OFFLINE,'from checkUserMedia catch 2')
            this.toastr.error('Check microphone permission and try again.');
          });
      }, 1300);
    }
    else{
      this.connectSocket2(true, 'From connect SIP');
    }


    
  }

  watchForChanges()
  {
    this.subs.sink=this.callcenterSharedService.processingStatusChange$.subscribe(val=>{
      if(val==true){
        this.processingStatusChangeStartedAt=moment();
      }
    });
  
    //Processing Status Change Subscription
    //Connecting SIP Subscription
  
    this.subs.sink=this.callcenterSharedService.connectingSIP$.subscribe(val=>{
      if(val==true&&this.callcenterSharedService.processingStatusChange$.getValue()==false&&this.callcenterSharedService.isInternetConnected$.getValue()){
  
        this.callcenterSharedService.processingStatusChange$.next(true);
      }
      else if(!val&&this.callcenterSharedService.processingStatusChange$.getValue()){
        this.callcenterSharedService.processingStatusChange$.next(false);
      }
    });
  
    //Connecting SIP Subscription
  
  
  
    //Connecting Socket Subscription
  
    this.subs.sink=this.sockectService.connectingSocket$.subscribe(val=>{
        if(val&&this.callcenterSharedService.processingStatusChange$.getValue()==false&&this.callcenterSharedService.isInternetConnected$.getValue()){
          this.callcenterSharedService.processingStatusChange$.next(true);
        }
    });
  
    //Connecting Socket Subscription
  
  
    //IsRegistered Subscription
  
    this.subs.sink=this.callcenterSharedService.isSIPRegistered$.subscribe(val=>{
      if(val){
  
          console.warn('====isRegistered turned to '+val+'=====');
  
          if(this.connectLostStatus!=''){
            if(this.callcenterSharedService.isInternetConnected$.getValue()){
              this.checkStatus(this.connectLostStatus, 'From SIP Registered - connection lost status');
            }
  
            setTimeout(() => {
              this.connectLostStatus='';
            }, 100);
          }
          else{
            this.checkStatus(this.callcenterSharedService.toBeStatus$.getValue(), 'From SIP Registered');
          }
  
          // setTimeout(() => {
          //   this.connectLostStatus=""
          // }, 2000);
        }
  
        this.callcenterSharedService.processingStatusChange$.next(false);
    });
  
    //IsRegistered Subscription
  
  
  
    //SocketOpen Subscription
  
    this.subs.sink=this.sockectService.socketOpen$.subscribe(val=>{

     
        if(val==true){
  
          let time=moment().format('YYYY-MM-DD HH:mm:ss');
          this.sockectService.pongTimeStamp=moment.utc(time, 'YYYY-MM-DD HH:mm:ss');
    
           if(this.callcenterSharedService.agentStatus$.getValue()!=StatusValueEnum.OFFLINE){
             this.connectSIP('from connectSocket2 subscribe');
           }
           else if(this.connectLostStatus!=StatusValueEnum.OFFLINE){
            this.connectSIP('from connectSocket2 subscribe - 2');
           }
          
        }
        else if(val==false){
          if(!this.pageFirstLoad){
            this.processingStatusChangeStartedAt=moment();
          
    
            if(this.callcenterSharedService.agentStatus$.getValue()!=StatusValueEnum.OFFLINE){
              this.checkStatus(StatusValueEnum.OFFLINE, 'from socketOpen Subs->False')
            }
      
            if(this.callcenterSharedService.processingStatusChange$.getValue()){
              this.callcenterSharedService.processingStatusChange$.next(false);
            }
          }
  
    
        }
      
  
    });
  
    //SocketOpen Subscription
  
  
    //isInternetConnected Subscription
  
    this.subs.sink=this.callcenterSharedService.isInternetConnected$.subscribe(val=>{
      if(val){
        this.internetJustGone=false;
  
        this.internetJustCame=true;
        
        console.warn('==== Has Internet connection ====');
  
        if(this.connectLostStatus!=''&&this.connectLostStatus!=StatusValueEnum.OFFLINE){
  
          localStorage.setItem('statusAfterReload',this.connectLostStatus)
          this.checkStatus(this.connectLostStatus, 'from Internet');
  
  
  
  
        }
      }
      else{
  
        this.connectLostStatus=this.callcenterSharedService.agentStatus$.getValue();
    
        this.internetJustGone=true;
  
        this.internetJustCame=false;
  
        console.warn('==== No Internet connection ====');
  
        if(this.internetJustGone){
  
          console.warn("====Internet is just gone & disconnecting connections====")
  
          if(this.callcenterSharedService.isSIPRegistered$.getValue()&&this.callcenterSharedService.connection){
            this.callcenterSharedService.connection.stop();
            this.callcenterSharedService.connection=null;
          }
    
          if(this.sockectService.socketOpen$.getValue())
            this.sockectService.disconnect();
  
        }
  
  
  
        // this.checkStatus(StatusValueEnum.OFFLINE, 'from isInternetConnect Subs->False')
  
      }
    });
  
    //isInternetConnected Subscription
  }

    /**
   *  PingSocket  V2
   *  Method pings the socket on every given time limit 
   *  and maintain constant communication with socket
   */

  pingSocket(){

    let justNow=moment();

    let loadingDelay=justNow.diff(this.processingStatusChangeStartedAt,'seconds');

    this.lastPongTime+=2;

    this.checkOffline();

    
    if(this.sockectService.socketOpen$.getValue()&&!this.sockectService.connectingSocket$.getValue()){




        // Ping the socket
        let msgBody:{userId:number, token:string, sip:string, userStatus:string, timeStamp:string};
        let token = localStorage.getItem(AppConstants.AUTH_TOKEN);
        let user = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))
      
          msgBody={
                  userId:user.userid,
                  token:token, 
                  sip:"e0ef6fec-56f6-4d6c-bd9b-193d7f94a9a4", 
                  userStatus:this.callcenterSharedService.agentStatus$.getValue(), 
                  timeStamp:moment().toString()}
                  console.log('-->sending msg')
          this.sockectService.send({"action":"ping", "message":msgBody});
        // Ping the socket



    }
    else if(loadingDelay>=15&&this.callcenterSharedService.processingStatusChange$.getValue()&&this.callcenterSharedService.agentStatus$.getValue()!=StatusValueEnum.OFFLINE){
      this.callcenterSharedService.processingStatusChange$.next(false);
      this.toastr.error('Sorry, Unable to connect to server. Please try again')
      this.checkStatus(StatusValueEnum.OFFLINE,'From ping socket1');
      //this.cdr.markForCheck();
    }
    else{

        // let status=localStorage.getItem('statusAfterReload');
        
        if(this.connectLostStatus!=""&&this.connectLostStatus!=StatusValueEnum.OFFLINE){
            this.connectSocket2(true, 'From ping socket');
        }
    }

    if(!this.callcenterSharedService.isInternetConnected$.getValue()&&
    this.callcenterSharedService.agentStatus$.getValue()!=StatusValueEnum.OFFLINE&&
    !this.callcenterSharedService.processingStatusChange$.getValue()){
      this.callcenterSharedService.agentStatus$.next(StatusValueEnum.OFFLINE);
      //  this.toastr.error('Sorry, Facing connectivity issue')
    }
  }

    /**
   * End of pingSocket()
   */


    /**
   * checkOffline()
   *  Method check last ping response exceed certain delay 
   *  and considered the user is offline
   */

    checkOffline(){

      let now=moment().utc();

      this.internetJustGone=false;

      if(this.sockectService.pongTimeStamp){


        if(this.callcenterSharedService.processingStatusChange$.getValue()){
          let time=moment().format('YYYY-MM-DD HH:mm:ss');
          this.sockectService.pongTimeStamp=moment.utc(time, 'YYYY-MM-DD HH:mm:ss');
        }

        let pongTime=this.sockectService.pongTimeStamp;

        console.log('checkOffline - pong -> '+this.sockectService.pongTimeStamp.format('YYYY-MM-DD HH:mm:ss'))
        console.log('checkOffline - local ->'+now.format('YYYY-MM-DD HH:mm:ss'))

        let diff=now.diff(pongTime, 'seconds');

        console.log('difference in seconds->', diff)

        if(this.lastPongTime>15){

          console.error("Internet:"+this.callcenterSharedService.isInternetConnected$.getValue())

        if(this.callcenterSharedService.isInternetConnected$.getValue()&&this.callcenterSharedService.agentStatus$.getValue()!=StatusValueEnum.OFFLINE){


            
            
            this.callcenterSharedService.isInternetConnected$.next(false);
            
            setTimeout(() => {
              this.checkStatus(StatusValueEnum.OFFLINE, 'From checkOffline')
              
            }, 100);
            this.toastr.error('Issue connecting the server !')

          }

        }
        else{
          // this.internetJustCame=false;
        }
      }
    }

    /**
     * #### End of checkOffline() ####
     */

    checkUserMedia(){
      if (!navigator.mediaDevices?.enumerateDevices){
        console.log("enumerateDevices() not supported.");
      } 
      else{
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            this.userMediaList=devices;
            this.userMediaList.forEach((device) => {
              if(device.kind === 'audioinput'){
                this.callcenterSharedService.availableAudioInputDevices.push({
                  label:device.label,
                  deviceId:device.deviceId
                })

                // this.selectedAudioInput=device.deviceId;
                // console.log(this.selectedAudioInput,'this.selectedAudioInput');
                
              }
              if(device.deviceId=='default'&&device.kind === 'audioinput'){
                this.callcenterSharedService.selectedAudioInput=device.deviceId;
                this.sipOutBoundFnService.selectedAudioInput = device.deviceId
                if(device.label&&device.label!=''){
                  this.hasInputAudioPermission=true;
                }
              }
  
              if(device.kind === 'audiooutput'){
                if(device.deviceId=='default'){
                  this.callcenterSharedService.selectedOutputAudio=device.deviceId;
                  this.sipOutBoundFnService.selectedOutputAudio = device.deviceId
                  if(device.label&&device.label!=''){
                    this.hasOutputAudioPermission=true;
                  }
                }

                this.audioOutputDevices.push({
                  label:device.label,
                  deviceId:device.deviceId
                })
                this.callcenterSharedService.selectedOutputAudio=device.deviceId;
              }

              if(device.kind === 'videoinput'){
                if(device.label&&device.label!=''){
                  this.hasInputVideoPermission=true;
                }
                this.availableVideoDevices.push({
                  label:device.label,
                  deviceId:device.deviceId
                });
                this.callcenterSharedService.selectedVideoSource=device.deviceId;
                this.sipOutBoundFnService.selectedVideoSource = device.deviceId
              }
            });
            let status=localStorage.getItem('statusAfterReload');
            if(status){
              this.checkStatus(status,'from checkUserMedia if');
            }
            else{
              this.checkStatus(StatusValueEnum.OFFLINE, 'from checkUserMedia else');
            }
            // localStorage.getItem('statusAfterReload')?this.checkStatus(localStorage.getItem('statusAfterReload'),true):this.checkStatus('Offline',true)
          })
          .catch((err) => {
            this.checkStatus(StatusValueEnum.OFFLINE,'from checkUserMedia catch')
            // this.statusEvtChange('Offline',true)
            alert(`${err.name}: ${err.message}`);
          });
        }
  }

  onDestroy(): void {
    this.subs?.unsubscribe();
  }
    
}

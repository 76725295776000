import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ChatBinPayload, ChatRouteBin } from 'src/app/modules/ring2voice/models/forward-bin.models';

@Component({
  selector: 'app-add-chat-routes',
  templateUrl: './add-chat-routes.component.html',
  styleUrls: ['./add-chat-routes.component.scss']
})
export class AddChatRoutesComponent implements OnInit {

  public sid: string;

  constructor(route: ActivatedRoute,
    private router:Router,
    private toastr: ToastrService,
    public location: Location) { 
      this.sid = route.snapshot.paramMap.get('id');
    }

  ngOnInit(): void {
  }

  onDataSuccess(result: { response: ChatBinPayload, message: string }): void { //check
    this.toastr.success('Successfully', result.message);
    this.router.navigate(['ring2voice/incoming-routes/chats/', result.response.id,'view']);
  }

  onDataError(error: any): void {
      console.error(error);
      this.toastr.error('Failed', 'Operation failed');
  }

  onCancel(): void {
      this.location.back();
  }

}

<div class="div" #fullScreen [ngClass]="{'fullscreen-container' : isFullscreen}">
    <div class="g-0" aria-expanded='true'>
        <main>
            <div class="display-area">
                <section class="pt-0 mt-0  mb-3">
                    <div class="row  d-flex flex-row">
                        <div class="col-12 label-box p-3">
                            <div class="d-flex justify-content-between mb-1 header-bar">
                                <div class="mt-1">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#"
                                                    [routerLink]="'/sales/catalog'">Catalogs</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">
                                                {{catalogTitle|uppercase}}</li>
                                        </ol>
                                    </nav>
                                </div>
                             
                                <!-- {{(filterAdditionalFields$|async)|json}} -->
    
                                <div class="d-flex justify-content-end btn-area">
                                    <div>
                                        <button (click)="showFilter=!showFilter" class="btn btn-sm mr-1 bg-light-secondary text-nowrap filter-button"><i  class="ft-filter mr-1"></i> {{ showFilter?'Hide Filter':'Show Filter' }}</button>
    
                                        <button class="btn btn-sm btn-primary order-btn"
                                            (click)="showCanvas = cartItems?.length > 0? !showCanvas:showCanvas"> <i
                                                class="ft-shopping-cart"></i> Order
                                            <span class="badge badge-pill badge-light">{{cartItems?.length}}</span></button>
    
                                            
                                    </div>
    
                                </div>
    
                            </div>
                            <div class="mb-3 filter-div" *ngIf="showFilter">
                                <div class="d-flex flex-wrap">
                                    <div class="filter-options">
                                            <ng-select 
                                            [items]="categoryList$ | async"
                                            placeholder="Select category"
                                            bindLabel="category_name"
                                            bindValue="id"
                                            [multiple]="true"
                                            class="ng-select-sm"
                                            [(ngModel)]="filterCategories"
                                            (clear)="clearFilterCategories()"
                                            (change)="getFilterAdditionalFields()">
    
                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" style="border:1px solid red;">
                                                    <div class="ng-value" *ngIf="items.length > 0" >
                                                        <span class="ng-value-label">{{items.length}} category(s) selected &nbsp;&nbsp;&nbsp;</span>
                                                    </div>
                                                </ng-template>
    
                                                </ng-select>
                                    </div>
    
                                    <div class="filter-options" *ngIf="materialData.length > 0">
                                        <ng-select 
                                        [items]="filterMaterialList$ | async"
                                        placeholder="Select material"
                                        bindLabel="material_name"
                                        bindValue="material_id"
                                        [multiple]="true"
                                        class="ng-select-sm"
                                        [(ngModel)]="filterMaterials">
    
                                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" style="border:1px solid red;">
                                                <div class="ng-value" *ngIf="items.length > 0" >
                                                    <span class="ng-value-label">{{items.length}} material(s) selected &nbsp;&nbsp;&nbsp;</span>
                                                </div>
                                            </ng-template>
    
                                            </ng-select>
                                </div> 
                                    <!-- <div class="d-flex justify-content-start filter-options"> -->
                                   
                                    <div *ngFor="let values of (filterAdditionalFields$|async)" class="filter-options">
                                        <ng-select 
                                            [items]="values.category_value_list"
                                            bindLabel="field_value"
                                            bindValue="id"
                                            class="ng-select-sm"
                                            [(ngModel)]="values.selected"
                                            [placeholder]="values.additional_field_name"
                                            [multiple]="false">
    
                                        <!-- <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngIf="items.length > 0">
                                                <span class="ng-value-label">{{items.length}} <strong>{{values.additional_field_name}}</strong> selected</span>
                                            </div>
                                        </ng-template> -->
    
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <div class="ng-value" >
                                                <span class="ng-value-label">{{values.additional_field_name}} &nbsp;&nbsp;</span>
                                            </div>
                                        </ng-template>
    
                                        </ng-select>
                            </div> 
                                <!-- <div class="d-flex justify-content-start filter-options"> -->
                               
                                <div *ngFor="let values of (filterAdditionalFields$|async)" class="filter-options">
                                    <ng-select 
                                        [items]="values.category_value_list"
                                        bindLabel="field_value"
                                        bindValue="id"
                                        class="ng-select-sm"
                                        [(ngModel)]="values.selected"
                                        [placeholder]="values.additional_field_name"
                                        [multiple]="false">

                                    <!-- <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                        <div class="ng-value" *ngIf="items.length > 0">
                                            <span class="ng-value-label">{{items.length}} <strong>{{values.additional_field_name}}</strong> selected</span>
                                        </div>
                                    </ng-template> -->

                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <div class="ng-value" >
                                            <span class="ng-value-label">{{findValueName(values.category_value_list, item.id)}} &nbsp;&nbsp;</span>
                                        </div>
                                    </ng-template>

                                    </ng-select>
                                </div> 

                            </div>
                            </div>
                            <div class="d-flex flex-row justify-content-end">
                                <!-- <button class="btn btn-sm mr-1 btn-secondary" (click)="resetFilter()">Reset</button> -->
                                <a class="text-warning mr-2 mt-1" (click)="resetFilter()">Reset</a>
                                <button class="btn btn-sm btn-info" [disabled]="filterCategories.length==0" (click)="applyFilter()">Apply Filter</button>
                            </div>
                            <div class="view-section">
                                <div class="row h-50" [hidden]="!canvasLoaded" >
                                    <div class="col-md-10">
                                        <div class="canvas-container" >
                                           <!-- filtered prod count-{{catalogProducts[0]?.length}} -->
        
                                            <!-- {{catalogPages|json}} -->
        
        
                                            <div class="flip-book"  
                                                [ngStyle]="{'width': width * 96 * newZoom + 'px', 'height': height * 96 * newZoom + 'px'}"
                                                [ngClass]="{'right-margin':lastFlippedIndex >  0 }">
        
                                                <div  *ngFor='let item of catalogPages ; let i=index '
                                                [ngStyle]="{'z-index': item.flipped == 0 ? catalogPages.length - i : i+1}"
                                                    [ngClass]="{'flip':item.flipped == 0 ,'rotate-page':item.flipped == 1}" >
        
        
                                                    <div *ngIf="catalogPages.length % 2 == 0 ? i * 2 < catalogPages.length : i * 2 <= catalogPages.length " class="front">
        
                                                        <!-- {{i*2+1}},{{catalogPages.length}}, {{i!=0?catalogPages[((i)*2)-1].page:''}} -->
        
                                                        <page-canvas-component #canvas1 
                                                            [id]="i * 2 + 0" [width]="width"
                                                            [height]="height" 
                                                            [zoom]="canvasZoom?canvasZoom/100:1"
                                                            [enableControls]="false" 
                                                            [objectSelectable]="false"
                                                            [customerView]="true"
                                                            *ngIf="canShowCanvasFront(i * 2)"
                                                            >
                                                        </page-canvas-component>
        
                                                      <div [id]="i * 2 + 0" *ngIf="isNoProductFoundPage(i,true)" class="no-records-page">
                                                            <h5>No products found</h5>
                                                        </div>
        
                                                        <!--  <div [id]="i * 2 + 1" *ngIf="isBlankPage(i,true)" class="no-records-page">
                                                            <h5>-BLANK PAGE-</h5>
                                                        </div> -->
        
                                                    </div>
                                                    <div *ngIf="catalogPages.length % 2 == 0 ? (i * 2 + 1) <= (catalogPages.length + 1) : (i * 2 + 1) <= catalogPages.length" class="back">
                                                        <!-- filter mode:{{isFilterMode}}
                                                        product length:{{catalogProducts.length}} -->
                                                        <!-- {{(i+1)*2}},{{catalogPages.length}}, {{catalogPages[((i+1)*2)-1].page}} -->
                                                        <page-canvas-component #canvas1 
                                                            [id]="i * 2 + 1" 
                                                            [width]="width"
                                                            [height]="height" 
                                                            [zoom]="canvasZoom?canvasZoom/100:1"
                                                            [enableControls]="false" 
                                                            [objectSelectable]="false"
                                                            [customerView]="true"
                                                            *ngIf="canShowCanvasBack((i * 2 + 1),item)"
                                                            >
                                                        </page-canvas-component>
                                                        <div [id]="i * 2 + 1" *ngIf="isNoProductFoundPage(i)" class="no-records-page">
                                                            <h5>BLANK PAGE</h5>
                                                        </div>
                                                            <!-- <div [id]="'end-page' + (i * 2 + 1)" *ngIf="!canShowCanvasBack((i * 2 + 1),item)" class="no-records-page">
                                                                <h5>-END-</h5>
                                                            </div> -->
                                                    
                                                    </div>
        
        
                                                   
        
                                                        <div class="front" *ngIf="catalogPages.length % 2 == 0 && i * 2 == catalogPages.length">
                                                            <div [id]="'end-page' + (i * 2 + 1)"  class="no-records-page">
                                                                <h5>-END-</h5>
                                                            </div>
                                                        </div>
        
                                                        <div class="back" *ngIf="catalogPages.length % 2 == 0 && (i * 2 + 1) == (catalogPages.length + 1)">
                                                            <div [id]="'end-page' + (i * 2 + 1)"  class="no-records-page">
                                                                <h5>-END-</h5>
                                                            </div>
                                                        </div>
        
                                                        <div class="back" *ngIf="catalogPages.length % 2 != 0 &&  (i * 2 + 1) == catalogPages.length">
                                                            <div [id]="'end-page' + (i * 2 + 1)"  class="no-records-page">
                                                                <h5>-END-</h5>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="row mt-3">
                                            <div class="col-6">
                                                <div class="nav-back-btn">
                                                    <button [disabled]="lastFlippedPageIndex < 0"
                                                        class="btn btn-info btn-icon round" (click)="prev()" type="button">
                                                        <i class="ft-chevron-left"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="nav-next-btn">
                                                    <!-- <button [disabled]="catalogPages.length == ((lastFlippedPageIndex * 2) + 2)"
                                                        class="btn btn-info btn-icon round" (click)="next()" type="button">
                                                        <i class="ft-chevron-right"></i>
                                                    </button> -->
                                                   
                                                    <button [disabled]="catalogPages.length % 2 == 0 ? catalogPages.length + 1 == ((lastFlippedPageIndex * 2) + 1):
                                                    catalogPages.length == (lastFlippedPageIndex * 2) + 1"
                                                    class="btn btn-info btn-icon round"  (click)="next()" type="button">
                                                    <i class="ft-chevron-right"></i>
                                                </button>
                                                </div>
                                                <!-- <label for="">Last Flipped Index : {{lastFlippedIndex}}</label><br>
                                                <label for="">Page Length: {{catalogPages.length}}</label><br> -->
                                                <!-- <label for="">Last Flipped Page Index : {{lastFlippedPageIndex}}</label> -->
        
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="col-md-2 p-0" *ngIf = "!hideTbIndexView" >
                                        <app-side-section-index-view [_showPanel] = "showSectionTableOfContent"
                                        (_pageIndex) = "flipFromSectionView($event)" [showMode] = 'showMode'
                                         [sectionInfo] = "sectionInfo"></app-side-section-index-view>
                                       
        
                                    </div>
        
                                    <div class="col-md-2 p-0" *ngIf = "!hideThumbnailView"  >
                                        <app-section-thumb-view [_showPanel] = "showSectionThumbnail"
                                        (_pageIndex) = "flipFromSectionView($event)" [showMode] = 'showMode'
                                         [sectionInfo] = "sectionInfo"></app-section-thumb-view>
                                       
        
                                    </div>
                                </div>
                                <app-side-cart-view [showCanvas]="showCanvas"  (cartClosed)="cartClosed()" ></app-side-cart-view>
                            </div>
                            
                        </div>
                    </div>
                </section>
                <div class="button-view-container" style="position: relative;">
                    <div class="row">
                        <div class="col-12 justify-content-center">
                            <div class="btn-view">
                                <button class="btn-primary btn-zoom-plus" (click) = "zoomChanged(canvasZoom + 3)"><i class="fa fa-search-plus"></i></button>
                                <button class="btn-primary btn-zoom-minus"  (click) = "zoomChanged(canvasZoom - 3)"><i class="fa fa-search-minus"></i></button>
                                <button class="btn-primary btn-zoom-minus" (click) = "openFullscreen()" *ngIf="!isFullscreen"><i class="fa fa-expand"></i></button>
                                <button class="btn-primary btn-zoom-minus" (click) = "exitFullScreen()" *ngIf="isFullscreen"><i class="fa fa-compress"></i></button>
                                <button class="btn-primary btn-zoom-minus" (click) = "onThumbnailViewBtnClick()"><i class="fa fa-th-large"></i></button>
                                <button class="btn-primary btn-hide-side" (click) = "onSectionIndexViewBtnClick()"><i class="fa fa-list"></i></button>
                            </div>
                        </div>
    
                    </div>
                
                </div>
            </div>
           
    
            
        </main>
    
    </div>
</div>


<!-- <ng-template #content let-modal *ngIf="showProductDetails == true">
    
</ng-template> -->
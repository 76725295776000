import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ServiceTicket } from 'src/app/modules/service-ticket/models/service-ticket';
import { ServiceTicketService } from 'src/app/modules/service-ticket/services/service-ticket.service';
import { CustomerContact } from 'src/app/modules/tenant-customers/models/tenant-customer';
import { SubSink } from 'subsink';
import { CallCenterService } from '../../services/call-center.service';
import { BlockListService } from '../../services/block-list.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { BlockListRequest } from '../../models/block-list.model';
import * as popup from 'src/app/core/utils/popup.functions';
import { UserStatusUpdateRequest } from '../../models/call-center.models';
import { Location } from '@angular/common';
import { Web } from 'sip.js';
import { BehaviorSubject, of } from 'rxjs';
import { addSO } from 'src/app/modules/sales/models/sales-orders.models';
import { DataFilterGroupRequest, GFColumnTypes, GFilterGroup, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import * as moment from 'moment';
import { CallLog } from '../../models/reports.models';
import { DidsService } from '../../services/dids.service';
import { catchError } from 'rxjs/operators';
import { SipOutBoundFunctionService } from '../../services/sipoutboundfunctionservice';
import { OutboundKeypadComponent } from '../outbound-keypad/outbound-keypad.component';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { sipInboundFunctionService } from '../../services/sipinbound-function.service';
import { SipConnectionService } from '../../services/sip-connection.service';
import { CallcenterSharedService } from '../../services/call-center-shared.service';

@Component({
  selector: 'app-outbound-call-center-info',
  templateUrl: './outbound-call-center-info.component.html',
  styleUrls: ['./outbound-call-center-info.component.scss']
})
export class OutboundCallCenterInfoComponent implements OnInit {

  
  @ViewChild('FormModal') modalContent: any;

  @ViewChild('newContactForm') newContactForm: any;

  @Input() session;
  
  @Input() audioInputSource;

  @Input() call_Details : any;

  @Input() wh_CallId;

  @Input() clearCallQ;

  @Input() isActiveCall;

  @Input() acceptedCallQ: any[] = [];

  @Output() AddAcceptedCallsIDs = new EventEmitter<string>();

  @Output() callEnded = new EventEmitter();

  @Output() closeModal = new EventEmitter();

  @Output() checkWhichSectionTobeSelected = new EventEmitter();

  @Output() holdStatusTitle = new EventEmitter();

  @Input() block_id;

  @Input() isOnHold;

  @Output() AddRejectedCallsIDs = new EventEmitter<string>();

  @Output() movetoAcceptedCallsQ = new EventEmitter<any>();

  @Input() callDuration = new BehaviorSubject('');

  @Input() iscallcenterEnabled;

  @Input() agentDetails;

  @Output() clearEndedWhileonHoldCall: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() newPanelOpened : EventEmitter<boolean> = new EventEmitter<boolean>();

  page: Pagination = { count: 0, limit: 5, offset: 0, pageNumber: '0-0' };

  public acceptTone = new Audio();

  public declineTone = new Audio();

  public onCall: boolean = true;

  public blockCall: boolean = false;

  public modelServiceTicket: ServiceTicket = new ServiceTicket();

  public isNewServiceTicket: boolean = false;

  public isNewSalesOrder: boolean = false;

  public showCallHistory: boolean = false;

  private subs: SubSink = new SubSink();

  public customerIms;

  public serviceTicketId: number = 0;

  public soId: number = 0;

  public bindingModel: CustomerContact = new CustomerContact();

  private modalRef: NgbModalRef;

  public permission = PermissionConstants;

  onGoingStMap = new Map<String, ServiceTicket>();

  onGoingSoMap = new Map<String, addSO>();

  onGoingST_OpenState = new Map<String,boolean>()

  onGoingSO_OpenState = new Map<String,boolean>()

  private currentPlayer: HTMLAudioElement;

  public modelSo:addSO=new addSO();

  private modalRefNew: NgbModalRef;

  public filter: GFilterParam[];

  public sort: GSortParam[] = [{ colname: 'date_created', direction: 'desc' }];

  public callHistoryList: {count : number,data :CallLog[]} = {count : 0, data : []}

  public keyInputs

  public from_Number_name: string;

  private musicTrack: MediaStreamTrack | null = null; // Music track to play while on hold

  constructor(
    public location: Location,
    public modal: NgbModal,
    private service: ServiceTicketService,
    private toastr: ToastrService,
    public callCenterService: CallCenterService,
    public sipInboundFnService: sipInboundFunctionService,
    public blockListService: BlockListService,
    private cdr: ChangeDetectorRef,
    private ra: ResourceAccessService,
    private didService: DidsService,
    public sipOutBoundFunctionService: SipOutBoundFunctionService,
    private phoneNumberPipe: PhoneNumberPipe,
    private sipConnectionService: SipConnectionService,
    private callcenterSharedService:CallcenterSharedService
  ) {

  }

  ngOnInit(): void {
    

  }
  ngOnChanges(changes: SimpleChanges): void {

    console.log('currentagentcalldet',changes?.call_Details?.currentValue )

    if(!this.session.customValues.agentCall)
    {
      if( changes?.call_Details?.currentValue?.call_to !=  changes.call_Details?.previousValue?.call_to)
      {
      //console.log('changes');
      this.checkOnGoingStSo(changes.call_Details?.previousValue?.call_to, changes.call_Details?.currentValue?.call_to);
      
      }
    }
    

  }

  getCallLogHistory(filters: GFilterGroup[] = [], offset: number = 0) {

    let historyFilter : GFilterParam[] = [
      {
        displayName: "Calls This Week", colname: 'date_created_between', condition: 'date_between',
        value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
        operator: 'AND'
      },
        {
          displayName: "From Number",
          colname: "call_from",
          condition: "is",
          value: this.call_Details.call_to,
          operator: "OR",
          type: GFColumnTypes.phone
        },
        {
          displayName: "To Number",
          colname: "call_to",
          condition: "is",
          value: this.call_Details.call_to,
          operator: "OR",
          type:  GFColumnTypes.phone
        }
    ]

    filters.push({'conditions': historyFilter, 'operator': 'AND' })

    let request: DataFilterGroupRequest = {
      base_filters: [],
      filters: filters,
      sort: this.sort
    };
    this.subs.sink = this.didService.getCallReport(request, offset).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.callHistoryList.data = response.result;
      this.callHistoryList.count = response.count;

      this.showCallHistory = true
      this.cdr.markForCheck()
    });

  }
  sortFunc(a, b) {
    if (a.created_on < b.created_on) {
      return -1;
    }
    if (a.created_on > b.created_on) {
      return 1;
    }
    return 0;
  }
  openModal(content: any) {
    this.modal.open(content, { scrollable: true, size: 'xl', backdrop: false });
  }

  showEndButton(session) { //condition for showing end button

    console.log('session in end', session)
    return session.state == 'Established';
  }

  // showRejectButton(session){ //condition for showing end button
  //   return session.state=='Initial'||session.state=='Establishing'; 
  // }

  openForm() {
    this.modalRef = this.modal.open(this.newContactForm, { size: 'lg', scrollable: true, centered: true });
  }

  documentEdit(document) {

    if (document.type == 'SERVICE TICKET') {

      this.isNewSalesOrder = false;

      this.serviceTicketId = document.id;
      this.subs.sink = this.service.getById(this.serviceTicketId).subscribe(serviceTicket => {
        this.modelServiceTicket = serviceTicket;
        this.isNewServiceTicket = true;

      });

    }

    if (document.type == 'SALES ORDER') {

      this.isNewServiceTicket = false;

      this.soId = document.id;
      this.isNewSalesOrder = true;

    }
  }
  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.R2V_BLOCK_LIST, [permission]);
  }
  hasStPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.SERVICE_TICKETS, [permission], ResourceConstants.SERVICE_TICKET_MODULE);
  }
  
  hasSoPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.SALES_SALES_ORDERS, [permission], ResourceConstants.SALES_MODULE);
  }
  editContact(contact_id: number) {

    this.subs.sink = this.callCenterService.getContactDetails(contact_id).subscribe(data => {
      this.bindingModel = data;
    });

    this.openModal1();

  }
  checkOnGoingStSo(prev_call_id, current_call_id){

    /*console.log('checkOnGoingStSo');
    console.log(this.onGoingStMap,'onGoingStMap++');
    console.log(this.onGoingSoMap,'onGoingSoMap++');*/
  
    if(this.isNewServiceTicket){
  
      //console.log('ongoingST');
      //console.log(this.model,'model++');
      this.onGoingStMap.set(prev_call_id,this.modelServiceTicket);

      this.closeAddSalesOrder();
    }
    if(this.isNewSalesOrder){
  
      //console.log('ongoingSO');
      //console.log(this.modelSo,'model++');
      this.onGoingSoMap.set(prev_call_id,this.modelSo);
  
      this.closeAddSalesOrder();
    }
  
    let st_q = this.onGoingStMap.get(current_call_id)
    let st_open_state = this.onGoingST_OpenState.get(current_call_id)

   if(st_q && st_open_state == true){
      this.modelServiceTicket = st_q;
      this.isNewServiceTicket = true;
      //this.session.customValues.isOnGoingSt=false;
      this.onGoingStMap.delete(current_call_id)
    }
  
    let so_q = this.onGoingSoMap.get(current_call_id)
    let so_open_state = this.onGoingSO_OpenState.get(current_call_id)

    if(so_q && so_open_state == true){
      this.modelSo = so_q;
      this.isNewSalesOrder = true;
      //this.session.customValues.isOnGoingSo=false;
      this.onGoingSoMap.delete(current_call_id)
    }
    
  
  }
  openModal1() {
    this.modalRef = this.modal.open(this.modalContent, { size: 'lg', scrollable: true, centered: true });
  }

  closeNewPanels() {

    if(this.modelServiceTicket && this.isNewServiceTicket)
    {
      this.onGoingStMap.set(this.call_Details.call_to,this.modelServiceTicket);
      this.onGoingST_OpenState.set(this.call_Details.call_to,false)
    }
  
    if(this.modelSo && this.isNewSalesOrder)
    {
      this.onGoingSoMap.set(this.call_Details.call_to,this.modelSo);
      this.onGoingSO_OpenState.set(this.call_Details.call_to,false)
    }
    

    this.isNewSalesOrder = false;
    this.isNewServiceTicket = false;
    this.showCallHistory = false;

    this.session.customValues.isOnGoingSt=false;
    this.session.customValues.isOnGoingSo=false;
  
    this.clearEndedWhileonHoldCall.emit();
    this.newPanelOpened.emit(false)

  }
  addNewServiceTicket() {

    if (this.hasStPermission(PermissionConstants.CREATE)) {

    
      this.isNewSalesOrder = false;
      this.from_Number_name = this.phoneNumberPipe.transform(this.call_Details.call_to) + ' - ' + this.call_Details.contact_name;

      this.onGoingST_OpenState.set(this.call_Details.call_to,true)
      let st_q = this.onGoingStMap.get(this.call_Details.call_to)
      if(st_q){
        this.modelServiceTicket = st_q;
        this.onGoingStMap.delete(this.call_Details.call_to)
      }
      else
      {
        this.modelServiceTicket = this.service.createModel(ServiceTicket);
        this.modelServiceTicket.tenant_customer=this.call_Details.tenant_customer_id;
        this.modelServiceTicket.tenant_customer_contact = this.call_Details.contacts_id;
        this.modelServiceTicket.tenant_customer_location = this.call_Details.location_id;

        this.modelServiceTicket.call_to_sid = this.call_Details.call_to_sid;
        this.modelServiceTicket.call_to_did = this.call_Details.call_to;
        this.modelServiceTicket.call_from = this.call_Details.call_from;
        this.modelServiceTicket.suborg = this.call_Details.call_from_suborg_id;
        this.modelServiceTicket.reason_for_call = null
        this.modelServiceTicket.task = null

      }
      this.serviceTicketId = 0;
      this.isNewServiceTicket = true;
      this.session.customValues.isOnGoingSt=true;
      this.session.customValues.isOnGoingSo=false;
      this.newPanelOpened.emit(true)

    } else {
      this.toastr.error("You don't have permission to create Service Ticket!")
    }
  }

  afterServiceSTSave()
{
  this.onGoingStMap.delete(this.call_Details.call_to)
  this.onGoingST_OpenState.delete(this.call_Details.call_to)
  this.modelServiceTicket = null
  this.isNewServiceTicket = false
  this.session.customValues.isOnGoingSt=false;
}
  rejectCall(session) { //hangup ongoing call

    console.log('rejectSession', session)
    this.AddRejectedCallsIDs.emit(this.session.id);
    if (session.state == 'Establishing') {
      session.cancel().then(() => {
        this.callDeclineTone();
      }).catch(() => {
        alert('Failed to reject call')
      })
    }
  }

  endCall(session) { //hangup ongoing call

    if (session.state == 'Established') {
      session.bye().then(() => {
        this.callDeclineTone();
        this.callEnded.emit();
        this.checkWhichSectionTobeSelected.emit();
      }).catch(() => {
        alert('Failed to end call')
      })
    }
  }
  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play();
  }



  addNewSalesOrder() {
    if (this.hasSoPermission(PermissionConstants.CREATE)) {

      this.isNewServiceTicket = false;

      this.onGoingSO_OpenState.set(this.call_Details.call_to,true)
      let so_q = this.onGoingSoMap.get(this.call_Details.call_to)
      if(so_q){
        this.modelSo = so_q;
        this.onGoingSoMap.delete(this.call_Details.call_to)
      }
      else
      {
        this.modelSo=new addSO();
      }
   
      this.soId = 0;
      this.isNewSalesOrder = true;
      this.session.customValues.isOnGoingSo=true;
      this.session.customValues.isOnGoingSt=false;
      this.newPanelOpened.emit(true)
    }else{
      this.toastr.error("You don't have permission to create Sales Order!")
    }
  }

  afterSalesSOSave()
{
  this.onGoingSoMap.delete(this.call_Details.call_to)
  this.onGoingSO_OpenState.delete(this.call_Details.call_to)
  this.modelSo = null
  this.isNewSalesOrder = false
  this.session.customValues.isOnGoingSo=false;
}

  closeAddSalesOrder() {

    this.isNewServiceTicket = false;
    this.isNewSalesOrder = false;

  }
  muteCall() {
    this.sipOutBoundFunctionService.isMuted = !this.sipOutBoundFunctionService.isMuted;
    this.cdr.markForCheck();
    let peer = this.session.sessionDescriptionHandler.peerConnection;
    let senders = peer.getSenders();
    if (!senders.length) return;
    let that = this;
    senders.forEach(function (sender) {
      if (sender.track) sender.track.enabled = !that.sipOutBoundFunctionService.isMuted;
    });
  }

  addToBlockList(): void {

    let payload = new BlockListRequest();
    payload.number = Number(this.call_Details.call_from);
    payload.created_user = this.blockListService.userId;

    let opt = {
      title: `Are you sure you want to Block?`,
      text: '',
      ButtonText: "Yes",
    }

    popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.blockListService.create(payload).subscribe(
          resp => {
            if (resp.status) {
              this.block_id = resp.id;
              this.blockCall = true;
              this.toastr.success("Number Blocked successfully");
              this.cdr.markForCheck();
            } else if (resp.Message) {
              this.toastr.info(resp.Message);
            }
          },
          () => {
            this.toastr.error('Failed to change status');
            this.blockCall = !this.blockCall;
            this.cdr.markForCheck();
          });
      } else {
        this.blockCall = !this.blockCall;
        this.cdr.markForCheck();
      }
    });
  }
  unblockNumber() {
    let opt = {
      title: `Are you sure you want to Unblock?`,
      text: '',
      ButtonText: "Yes",
    }
    popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
        this.blockListService.delete(this.block_id).subscribe(
          resp => {
            if (resp.status) {
              this.blockCall = false;
              this.toastr.success(`Number Unblocked Successfully`);
              this.block_id = null;
              this.cdr.markForCheck();
            }
          },
          error => {
            this.toastr.error("Failed to Unblock Phone Number")
          }
        )
      };
    });
  }
  autoOpenToggled() {
    let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    payload.auto_open = this.callCenterService.isalwaysOpen;

    this.callCenterService.update(payload).subscribe(responds => {
      if (responds['auto_open'] == true) {
        this.toastr.success("Call log will open automatically when a call comes")
      } else {
        this.toastr.warning("Call log will not open automatically when a call comes")
      }
    },
      () => {
        this.toastr.error("Failed to change status")
        this.callCenterService.isalwaysOpen = !this.callCenterService.isalwaysOpen;
        this.cdr.markForCheck();
      })

  }
  onDataSubmittedSuccess(result: { response: CustomerContact, message: string }): void {

    this.toastr.success('Customer Contact Updated.');
    this.closeModal1();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal1();
  }

  checkInboundCallIsActive()
  {
    if(this.sipInboundFnService.selectedCaller)
    {
       if(this.sipInboundFnService.selectedCaller?.state == "Established" 
       && this.sipInboundFnService.selectedCaller?.customValues.isCallOnHold == false)
       {
        this.toastr.error('Please hold current incoming call');
        return true
       }
       else
       {
        return false
       }
    }
    else
    {
      return false
    }
  }

  holdCall()
  {
    const re_value = !this.session.customValues.isCallOnHold;

    if (!re_value) // if unhold call
    {
      if (this.checkInboundCallIsActive() == true) {
        return
      }
    }
    this.session.customValues.isCallOnHold = re_value;


    if (!re_value) {
      this.session.customValues.isOnGoingSt = false;
      this.session.customValues.isOnGoingSo = false;
      this.session.customValues.isUnholded = true;
    }



    const options = {
      sessionDescriptionHandlerModifiers: this.session.customValues.isCallOnHold ? [Web.holdModifier] : []
    } 
    if (this.session.state == 'Established') {
      this.session.invite(options).then(() => {
        console.log(`Call ${this.session.customValues.isCallOnHold ? 'held' : 'unheld'}.`)
        if (this.session.customValues.isCallOnHold) {//put on hold
          let payload = {
            call_sid: this.session.customValues.call_id,
            sip:this.callcenterSharedService.sip_details.sip_uri,
            to_number: this.call_Details.call_to
          };
          this.subs.sink = this.callCenterService.putCallOnHold(payload,'outbound').subscribe(response => {
            console.log('Response onhold', response);

          },
            (error) => {
              console.log("Error occurred");
            });
        }
        this.session.customValues.isCallOnHold = re_value;
      })
         .catch((error) => {
          console.error(`Failed to ${this.session.customValues.isCallOnHold ? 'hold' : 'unhold'} call. Error:`, error);
        });
    } else {//release from hold
   
      if (!this.session.customValues.isCallOnHold) {
        let payload = {
          call_sid:this.session.customValues.call_id,
          to_number: this.call_Details.call_to,
          // to_number: this.session.request.headers['X-Dialed-Number'][0].raw,
          sip_username: 'sip:' + this.agentDetails.sip_uri,
          call_center_enabled: this.iscallcenterEnabled
        };
        this.subs.sink = this.callCenterService.releaseCallFromHold(payload,'outbound').subscribe((response) => {
          console.log('releare Hold', response);

          this.callCenterService.isOnGoingStForHold = this.isNewServiceTicket;
          this.callCenterService.isOnGoingSoForHold = this.isNewSalesOrder;
          if (response.call_ended) {

            if (this.isNewServiceTicket || this.isNewSalesOrder) {
              this.session.customValues.isUnholded = false;
              this.session.customValues.isOnGoingSt = this.isNewServiceTicket;
              this.session.customValues.isOnGoingSo = this.isNewSalesOrder;
            } else {
              this.clearEndedWhileonHoldCall.emit(response.call_ended);
            }

            this.toastr.warning('Caller hung up');
          }
        },
          (error) => {
            console.log('error', error);

          });
        console.log(this.session.customValues, "value after function");
      }
    }
  }

 

  // holdCall() {
    
  //   debugger
    
  //   this.hideUnholdButton(this.session)

  //   const re_value = !this.session.customValues.isCallOnHold;

  //   if(!re_value) // if unhold call
  //   {
  //     if(this.checkInboundCallIsActive() == true)
  //     {
  //       return
  //     }
  //   }
  //   this.session.customValues.isCallOnHold = re_value;

  //   if(!re_value){
  //     this.session.customValues.isOnGoingSt = false;
  //     this.session.customValues.isOnGoingSo = false;
  //     this.session.customValues.isUnholded = true;
  //   }


  //   if(this.session.state=='Established'){

  //     const peerConnection = this.session.sessionDescriptionHandler.peerConnection;
  //     peerConnection.getSenders().forEach(sender => {

  //       debugger
  //       // sender.track.enabled = !re_value
  //       // sender.replaceTrack(this.musicTrack);

  //       if (sender.track) sender.track.enabled = !re_value;
  //     });
  //   }
  //   else
  //   {

  //     if(!this.session.customValues.isCallOnHold){

  //       if(this.isNewServiceTicket || this.isNewSalesOrder){
  //         this.session.customValues.isUnholded = false;
  //         this.session.customValues.isOnGoingSt = this.isNewServiceTicket;
  //         this.session.customValues.isOnGoingSo = this.isNewSalesOrder;
  //       }
  //     }

  //   }
  //   this.cdr.markForCheck()
 
  // }




  stringify(obj) {
    let cache = [];
    let str = JSON.stringify(obj, function(key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null; // reset the cache
    return str;
  }
  closeModal1() { this.modalRef?.dismiss(); }

  onCancel(): void { this.closeModal1(); }

  openHistory() {

    if(!this.showCallHistory)
    {
      this.getCallLogHistory([{'conditions': this.filter, 'operator': 'AND'}] )
    }
    else
    {
      this.showCallHistory = false
    }
    this.isNewSalesOrder = false;
    this.isNewServiceTicket= false;
    this.newPanelOpened.emit(true)
  }
  openFormNew() {
    this.modalRefNew = this.modal.open(this.newContactForm, { size: 'lg', scrollable: true, centered: true });
  }
  onCancelNew()
  {

  }
  hideUnholdButton(session){

    let otherCallsActive : boolean = false
    const activeSession = this.acceptedCallQ?.filter(session => session.state=='Established' && session.customValues.isCallOnHold == false);

    if(activeSession.length>0 && activeSession[0].id !== session.id ) // check if active call not the same
    {
      otherCallsActive = true
    }
    return session.customValues.isCallOnHold && otherCallsActive

  }
  autoOpenAddContactToggled(){
    let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    payload.auto_open_add_contact=this.callCenterService.isalwaysOpenAddContact;
  
    this.callCenterService.update(payload).subscribe(responds=>{
      if (responds['auto_open_add_contact']==true) {
        this.toastr.success("Add New Contact Popup will automatically open when a call is accepted")
      }else{
        this.toastr.warning("Add New Contact Popup will not automatically open when a call is accepted")
      }
    },
    ()=>{
      this.toastr.error("Failed to change status")
      this.callCenterService.isalwaysOpen=!this.callCenterService.isalwaysOpen;
      this.cdr.markForCheck();
    })
    
  }

  audioPlayerPlay(audio: HTMLAudioElement) {
    if (this.currentPlayer && this.currentPlayer != audio) {
      this.currentPlayer.pause();
    }
    this.currentPlayer = audio;
  }

  openKeyPad()
  {
    const modalRef = this.modal
      .open(OutboundKeypadComponent, {
        size: "sm",
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
        windowClass: 'myCustomModalClass',
      });
    modalRef.componentInstance.session = this.session
    if(this.keyInputs)
    {
      modalRef.componentInstance.keyInputs = this.keyInputs
    }
    modalRef.result.then(
      (result) => {
        if (result) {
         this.keyInputs = result
        }
      },
      (reason) => {
      }

    );
    // this.ref.detectChanges()
  }

  onServiceTicketCancel()
  {
    this.isNewServiceTicket = false;
  }

  onSalesOrderCancel()
  {
    this.isNewSalesOrder = false
  }

  onMakeCall()
  {
    this.sipOutBoundFunctionService.outBoundShowType = "dialer"
   // this.showDialer.emit(true)
  }
}

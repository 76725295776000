import { Component, HostListener, Input, OnInit, ChangeDetectorRef, ViewChild, ElementRef, EventEmitter, Output, SecurityContext } from '@angular/core';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

    private baseURL: string = environment.apiURL;

    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('content') content: ElementRef;

    @Input() multiple: boolean = false;

    @Input() placeholder: string = "Image";

    @Input() autoFullViewImageIndex: number = -1;

    @Input() set files(value: StaticFile[]) {
        if (value && value.length) {
            this.setThumbNails(value);
        }
    };

    @Input() primary: string;

    @Input() limit: number = 5;

    @Input() isActive: boolean = true;

    @Input() readonly:boolean = false;

    @Input() upload_image_only = true;

    @Input() showPreview = true;

    public accept: string = "image/png,image/jpeg";

    @Input() set upload_all_files(value: boolean) {
        if (value) {
            this.accept = this.accept + ",video/mp4,application/pdf,application/vnd.ms-excel";
            this.placeholder = "files";
            this.upload_image_only = false;
        }
    };

    @Input() set upload_documents_only(value: boolean) {
        if (value) {
            alert("upload_documents_only")
            this.accept = "application/pdf,application/vnd.ms-excel";
            this.placeholder = "documents"
        }
    };

    @Input() set upload_Audio_only(value: boolean) {
        if (value) {
            this.accept = "audio/mp3,audio/wav";
            this.placeholder = "Audio";
        }
    };

    @Output() delete = new EventEmitter<string>()

    @Output() isAddedNewFile = new EventEmitter<boolean>();

    public thumbNails: ThumbNail[] = [];

    public preview: ThumbNail;

    public fullScreenPreview: ThumbNail;

    public findex: number;

    private currentPlayer: HTMLAudioElement;

    constructor(private cdr: ChangeDetectorRef, private modalService: NgbModal, 
        public sanitized: DomSanitizer) { }

    ngOnInit() { }


    setThumbNails(files: StaticFile[]) {
        this.thumbNails = files.map(file => {
            let thumb = new ThumbNail();
            thumb.data_id = file.id;
            thumb.uploaded = true;
            thumb.url = `${this.baseURL}/static/files/${file.url}`
            thumb.name = file.name;
            thumb.type = file.type;
            return thumb
        });
        this.preview = this.thumbNails[0];
        this.setPrimaryFile();


        if(this.autoFullViewImageIndex!=-1){

            this.findex = this.autoFullViewImageIndex;
            this.fullScreenPreview = this.thumbNails[this.autoFullViewImageIndex];
            this.modalService.open(this.content, { size: 'xl', centered: true });

        }
    }

    setPrimaryFile() {
        if (this.thumbNails.length <= 0 || !this.primary) {
            return;
        }
        for (let thumb of this.thumbNails) {
            if (thumb.data_id === this.primary) {
                thumb.isPrimary = true;
                this.preview = thumb;
                break;
            }
        }
    }

    @HostListener('dragover', ['$event']) onDragOver(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('drop', ['$event']) public ondrop(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.prepareFileList(event.dataTransfer.files);
    }

    fupOnChange(event: any) {
        this.isAddedNewFile.emit(true);
        this.prepareFileList(event.target.files);
    }

    prepareFileList(files: Array<any>) {
        if (files && files.length > 0) {
            for (let _file of files) {
                var reader: FileReader = new FileReader();
                reader.onload = ((file: any) => {
                    return (event: any) => {
                        let thumb = new ThumbNail();
                        thumb.url = event.target.result;
                        thumb.file = file;
                        thumb.name = file?.name;
                        thumb.type = file?.type;
                        this.preview = thumb;
                        this.thumbNails.push(thumb);
                        this.cdr.markForCheck();
                    }
                })(_file);
                reader.readAsDataURL(_file);
            }
        }
    }

    onImageCliked(thumb: ThumbNail, index: number) {
        this.preview = thumb;
        this.fullScreenPreview = this.preview;
        this.findex = index;
    }

    showPdf(thumb: ThumbNail) {
        const linkSource = thumb.url
        const downloadLink = document.createElement("a");
        downloadLink.setAttribute("target", "_blank")
        const fileName = "sample.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    deleteThumbNail() {

        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {

        if (this.preview.uploaded) {
            this.delete.emit(this.preview.data_id);
        }
        this.thumbNails = this.thumbNails.filter(t => t.id != this.preview.id);

        if (this.thumbNails.length) {
            this.preview = this.thumbNails[this.thumbNails.length - 1];
        }
        else {
            this.preview = undefined;
        }

        }
        });


    }

    getFiles(): ThumbNail[] {
        return this.thumbNails.filter(item => !item.uploaded);
    }

    getPrimary(): ThumbNail {
        return this.thumbNails.find(thumb => thumb.isPrimary);
    }

    setPrimary() {
        this.preview.isPrimary = !this.preview.isPrimary;
        for (let thumb of this.thumbNails) {
            if (thumb.id !== this.preview.id) {
                thumb.isPrimary = false;
            }
        }
    }

    openFullScreen(content: any) {
        this.fullScreenPreview = this.preview;
        this.modalService.open(content, { size: 'xl', centered: true });
    }

    next() {
        this.findex += 1;
        this.fullScreenPreview = this.thumbNails[this.findex];
    }

    prev() {
        this.findex -= 1;
        this.fullScreenPreview = this.thumbNails[this.findex];
    }

    audioPlayerPlay(audio: HTMLAudioElement) {
        if (this.currentPlayer && this.currentPlayer != audio) {
            this.currentPlayer.pause();
        }
        this.currentPlayer = audio;
    }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SipOutBoundFunctionService } from '../../services/sipoutboundfunctionservice';
import { CallCenterService } from '../../services/call-center.service';
import { DataFilterGroupRequest, GFilterGroup, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import moment from 'moment';
import { CallLog } from '../../models/reports.models';
import { CallCenterDialerComponent } from '../../components/call-center-dialer/call-center-dialer.component';
import { CallCenterSpec } from '../../models/call-center.models';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { CustomerContactsComponent } from 'src/app/modules/tenant-customers/components/customer-contacts/customer-contacts.component';
import { DidsService } from '../../services/dids.service';
import { SubSink } from 'subsink';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SipConnectionService } from '../../services/sip-connection.service';
import { CallcenterSharedService } from '../../services/call-center-shared.service';

@Component({
  selector: 'app-outbound-call',
  templateUrl: './outbound-call.component.html',
  styleUrls: ['./outbound-call.component.scss']
})
export class OutboundCallComponent implements OnInit {

  @ViewChild(CallCenterDialerComponent) dialler:CallCenterDialerComponent;  

  @ViewChild(CustomerContactsComponent) customerContact:CustomerContactsComponent;

  @Input() selectedAudioInput: string; //input

  @Input() selectedVideoSource: string; //input

  @Input() ringAudio: string; //input

  @Input() iscallcenterEnabled: boolean = true; //input

  @Output() newPanelOpened = new EventEmitter<boolean>(); //out

  public outActiveSubTab:number=1; 

  @Input() dynamicHeight;

  public subs: SubSink = new SubSink();

  public basicLogFilter: GFilterParam[] = [{
    displayName: "Calls This Week", colname: 'date_created_between', condition: 'date_between',
    value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
    operator: 'AND'
  }];

  public noAnswerLogList: {count : number,data :CallLog[]}= {count:0 ,data:[]}

  page: Pagination = { count: 0, limit: 5, offset: 0, pageNumber: '0-0' };

  public selectedLetter:string='ALL';

  public alphabets:string[]=['ALL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
  'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

  public missedLogFilter: GFilterParam[] = [
    {colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' },
    { displayName: 'Missed', colname: 'real_status', condition: 'equalto', value: 'no-answer', operator: 'WHEN' }
  ]

  public sort: GSortParam[] = [{ colname: 'date_created', direction: 'desc' }];

  constructor(public sipOutBoundFnService: SipOutBoundFunctionService,
     public callCenterService: CallCenterService,private cdr: ChangeDetectorRef,
     public sipConnectionService : SipConnectionService,
     public callcenterSharedService: CallcenterSharedService,
     private didService: DidsService) { }

  ngOnInit(): void {

    this.getMissedCallLog([{ 'conditions': this.basicLogFilter, 'operator': 'AND' }])
  }

  getMissedCallLog(filters: GFilterGroup[] = null, offset: number = 0) {

    let request: DataFilterGroupRequest = { 
      base_filters: [],
      filters: filters,
      sort: this.sort
    }; 
    this.subs.sink = this.didService.getCallReport(request, offset,true).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.noAnswerLogList.data = response.result
      this.noAnswerLogList.count = response.count
      console.log('noAnswerLogList', this.noAnswerLogList)
    });

  }

  onNewPanelOpened(event)
  {
    this.newPanelOpened.emit(true)
  }

  addForCall(number){

    this.dialler.dialNumber=number;   
    this.cdr.detectChanges();    
  }

  clearCompletedCallsfromCallQ(endedWhileOnHold: boolean = false) {
    this.sipOutBoundFnService.clearCompletedCallsfromCallQ(endedWhileOnHold);
    this.cdr.markForCheck();
  }
 

  filterContact(letter:string, contact){

    if(contact){

      this.selectedLetter=letter;

          let filter=[];

        
        if(letter!="ALL"){
          filter=[
            {displayName: "First Name", colname: "first_name", condition: "startswith", value: letter, operator: "OR"},
          // {displayName: "Last Name", colname: "last_name", condition: "startswith", value: letter, operator: "OR"}
        ];
        }


      
        contact.page.offset=0;
        contact.applyFilter(filter, false, true)
    }
  }

  searchInContacts(key, contact){ 

    this.selectedLetter="ALL";

      if(this.outActiveSubTab!=2){
        this.outActiveSubTab=2;
        this.cdr.detectChanges();
      }
    let filter=[];

    // let nav=this.nav;

      if(key!=""){
        filter=[
          {colname: "mobile", condition: "startswith", value: key, operator: "OR"},
          {colname: "phone", condition: "startswith", value: key, operator: "OR"},
            // {displayName: "Last Name", colname: "last_name", condition: "startswith", value: letter, operator: "OR"}
        ];

      }
      this.customerContact.page.offset=0;
      
      this.customerContact.applyFilter(filter, true, true)



  }


}

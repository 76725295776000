<div class="container-fluid">
    <div class="row">
        <div class="col-2 pr-0">
            <app-r2v-settings [selectedSettings]="'HoldMusic'"></app-r2v-settings>
        </div>
        <div class="col-10 pl-0">
            <section class="common-grid-view music_listing">
                <div class="list-parellax pt-2 height-fit">
                    <div class="d-flex flex-row flex-1 h-100">
                        <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}" style="overflow: auto; border-radius: 0px 5px 5px 0px;">
                            
                            <div class="" [ngClass]="{'card-header mb-2':expanded,'parellax-dt-header mb-2':!expanded}">
                                 <div class="container-fluid col-10 mb-3" *ngIf="!isSuperAdmin&&expanded">
                                    <div class="row align-items-center p-2" style="border: 1px dotted violet;background: #f5f0ff;">
                                        <div class="col-5 d-flex align-items-center justify-content-center text-style">
                                            <label for="always_play">Always Play : </label>
                                            <ng-select class="form-select ml-1 alwaysPlayInput"
                                                    placeholder="Select option"
                                                    [(ngModel)]="musicSettings.always_play_id"
                                                    [clearable]="false"
                                                    [searchable]="false"
                                                    [readonly]="musicSettings.random_music"
                                                    (change)="settingsOnChange('always_play')">
                                                <ng-option *ngFor="let digit of options" [value]="digit.id">{{ digit.name }}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-2 d-flex justify-content-center text-style">
                                            OR
                                        </div>
                                        <div class="col-5 d-flex justify-content-center">
                                            <label for="randomMusic" class="text-style">Randomize all music on hold entries : </label>
                                            <div class="d-flex align-items-center ml-1">
                                                <div class="custom-switch custom-switch-success custom-control">
                                                    <input type="checkbox" [(ngModel)]="musicSettings.random_music" name="randomise" (change)="settingsOnChange('randomize')" class="custom-control-input" id="randomise_switch" checked>
                                                    <label class="custom-control-label" for="randomise_switch">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                 </div>
                                 
                                <datagrid-filter #dataFilter [searchFilters]="searchFilters" [defaultFilters]="defaults"
                                                 [columns]="columns"
                                                 [expanded]="expanded"
                                                 [newButton]="true" 
                                                 [newButtonText]="'Add Music'" 
                                                 [dontDisableIfAllsuborg]="false"
                                                 (apply)="applyFilter($event)" 
                                                 [newButtonUrl]="['ring2voice/settings/music-on-hold/new']"
                                                 >
                                        <!-- <ng-template filterControl [column]="'customer'" let-row="row">
                                            <app-customer-select class="ng-select-sm"
                                                [(value)]="row.value"
                                                (change)="dataFilter.controlValueChanged('customer')">
                                            </app-customer-select>
                                    </ng-template> -->
                                </datagrid-filter>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <ngx-datatable #dataTable
                                                   [scrollbarH]="scrollbarH"
                                                   class="bootstrap core-bootstrap"
                                                   [columnMode]="'force'"
                                                   [headerHeight]="50"
                                                   [footerHeight]="50"
                                                   rowHeight="auto"
                                                   [selectionType]="SelectionType.single"
                                                   [rows]="rows"
                                                   [count]="page.count"
                                                   [externalPaging]="true"
                                                   [offset]="page.offset"
                                                   [externalSorting]="true"
                                                   [limit]="page.limit"
                                                   (select)="onSelect($event)"
                                                   (page)="setPage($event)" (sort)="onSort($event)"
                                                   [sorts]="[{prop:'created_on',dir:'desc'}]">
            
                                        <ngx-datatable-row-detail [rowHeight]="75" #rowdetails>
                                            <ng-template let-row="row" ngx-datatable-row-detail-template>
                                                <div class="row">
                                                    <div class="ml-2" [ngClass]="expanded?'col-3':'col-7'">
                                                        <div class="div">
                                                            <label  class="detail-head">Created By</label>
                                                        </div>
                                                        <div>{{row.created_by}}</div>
                                                    </div>
                                                    <div class="col-3" *ngIf="expanded">
                                                        <div class="div">
                                                            <label  class="detail-head">Created On</label>
                                                        </div>
                                                        <div class="div">
                                                            <label>{{row.created_on | date:'MM/dd/YYYY'}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-3" *ngIf="expanded">
                                                        <div class="div">
                                                            <label  class="detail-head">Duration</label>
                                                        </div>
                                                        <div CLASS="div">
                                                            <div *ngIf="row.duration">{{ row.duration }} seconds</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="div">
                                                            <label  class="detail-head ml-1">Status</label>
                                                        </div>
                                                        <div class="badge" [ngClass]="{'bg-light-danger': row.is_disabled,'bg-light-success': !row.is_disabled }">
                                                            {{ row.is_disabled?'Disabled':'Enabled' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-row-detail>
            
                                        <ngx-datatable-column name="#" prop="serial_number" [width]="20" [sortable]="false"></ngx-datatable-column>
                                        <ngx-datatable-column name="Name" prop="name" [width]="90">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                <div class="font-weight-bold" style="color: #da45a7;">
                                                    {{name}}<small style="color: rgb(122, 122, 122);">{{!isSuperAdmin&&row.is_superadmin?'(System)':''}}</small></div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Music" prop="music_link" [sortable]="false" [width]="350" >
                                            <ng-template let-src="value" ngx-datatable-cell-template>
                                                <div *ngIf="src">
                                                    <audio *ngIf="src" controls class="audio-control audio-element" #audioElement
                                                        (play)="audioPlayerPlay(audioElement)">
                                                    <source [src]="src" type="audio/wav">
                                                    not supported
                                                    </audio>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false"
                                                  [canAutoResize]="false">
                                            <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                                <div ngbDropdown container="body">
                                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                            (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                        <i class="ft-more-vertical text-primary"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <button *ngIf="UIPermissions.viewInfo" class="w-100" (click)="onSelect({selected:[current]})"
                                                                ngbDropdownItem>
                                                            <i class="ft-book-open mr-1"></i> View
                                                        </button>
                                                        <a *ngIf="UIPermissions.edit"
                                                        [routerLink]="['/ring2voice/settings/music-on-hold',id,'edit']" class="w-100"
                                                        ngbDropdownItem>
                                                            <i class="ft-edit mr-1"></i> Edit
                                                        </a>
                                                        <button *ngIf="UIPermissions.delete" (click)="deleteMusic(id)"
                                                                class="w-100 bg-light-danger" ngbDropdownItem>
                                                            <i class="ft-trash-2 mr-1"></i> Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-footer>
                                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                         let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                                <div class="page-count">
                                                    <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                                </div>
                                                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                                 [pagerRightArrowIcon]="'datatable-icon-right'"
                                                                 [pagerPreviousIcon]="'datatable-icon-prev'"
                                                                 [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                                 [size]="pageSize" [count]="rowCount"
                                                                 [hidden]="!((rowCount / pageSize) > 1)"
                                                                 (change)="dataTable.onFooterPage($event)">
                                                </datatable-pager>
                                            </ng-template>
                                        </ngx-datatable-footer>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                        <!-- expand -->


                        <div class="parellax-detail" *ngIf="!expanded && selected">
                            <div class="parellax-detail-content">
                                <div class="d-flex flex-row mt-2">
                                    <h5 class="item-title m-0">{{selected.name}}</h5>
                                    <div class="d-flex justify-content-end align-items-start">
                                        <a *ngIf="UIPermissions.edit"
                                           [routerLink]="['/ring2voice/settings/music-on-hold/',selected.id,'edit']" ngbTooltip="Edit"
                                           class="btn btn-sm bg-light-secondary">
                                            <i class="ft-edit"></i> Edit
                                        </a>
                                        <button *ngIf="UIPermissions.delete" class="btn btn-sm bg-light-danger ml-1"
                                                (click)="deleteMusic(selected.id)">
                                            <i class="ft-trash-2 mr-1"></i> Delete
                                        </button>
                                        <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                            <i class="ft-x"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row flex-column">
                                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                        <li ngbNavItem>
                                            <a ngbNavLink class="d-flex align-items-center">
                                                <span class="d-none d-sm-block">Overview</span>
                                            </a>
                                            <ng-template ngbNavContent class="tab-content-pd">
                                                <div class="group">
            
                                                    <div class="group-header d-flex justify-content-between">
                                                        Overview
                                                    </div>
                                                    
                                                    <div class="group-body">
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-9 p-0">
                                                                    <div class="ov-row" *ngIf="selected.name">
                                                                        <label class="col-md-5 p-0 item-label">Name</label>
                                                                        <label class="item-label-value">{{selected.name}}</label>
                                                                    </div>
                                                                    <div class="ov-row" *ngIf="selected.music_link">
                                                                        <label class="col-md-5 p-0 item-label">Music</label>
                                                                        <div class="item-label-value">
                                                                            <audio controls class="audio-control" #audioElement
                                                                                (play)="audioPlayerPlay(audioElement)">
                                                                            <source [src]="selected.music_link" type="audio/wav">
                                                                            not supported
                                                                            </audio>
                                                                        </div>
                                                                    </div>
                                                                    <div class="ov-row" *ngIf="selected.created_by">
                                                                        <label class="col-md-5 p-0 item-label">Created By</label>
                                                                        <label class="item-label-value">{{selected.created_by}}</label>
                                                                    </div>
                                                                    <div class="ov-row" *ngIf="selected.created_on">
                                                                        <label class="col-md-5 p-0 item-label">Created On</label>
                                                                        <label class="item-label-value">{{selected.created_on| date:'MM/dd/YYYY'}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </li>
                                    </ul>
                                    <div [ngbNavOutlet]="nav"></div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </div>
    </div>
</div>



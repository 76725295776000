import { AssembledKitsComponent } from './pages/assembled-kits/assembled-kits.component';
import { KitListComponent } from './pages/kit-list/kit-list.component';
import { SalesAccountsComponents } from './pages/sales-accounts/sales-accounts.component';
import { StateTaxComponent } from './pages/state-tax/state-tax.component';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { AddCategoryComponent } from './pages/add-category/add-category.component';
import { ItemTypesComponent } from './pages/item-types/item-types.component';
import { UnitTypesComponent } from './pages/unit-types/unit-types.component';
import { ManufacturerListComponent } from './pages/manufacturer-list/manufacturer-list.component';
import { SupplierListComponent } from './pages/supplier-list/supplier-list.component';
import { ItemCategoriesComponent } from './pages/item-categories/item-categories.component';
import { AddItemComponent } from './pages/add-item/add-item.component';
import { ItemListComponent } from './pages/item-list/item-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from 'src/app/core/gaurd/route.guard';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { AddKitComponent } from './pages/add-kit/add-kit.component';
import { AssembleKitComponent } from './pages/assemble-kit/assemble-kit.component';
import { WarehouseComponent } from './pages/warehouse/warehouse.component';
import { LabelAddComponent } from './pages/label-add/label-add.component';
import { LableListComponent } from './pages/lable-list/lable-list.component';
import { LabelPagesListComponent } from './pages/label-pages-list/label-pages-list.component';
import { LabelPagesAddComponent } from './pages/label-pages-add/label-pages-add.component';
import { LabelPrintComponent } from './pages/label-print/label-print.component';
import { ProductLinesComponent } from './pages/product-lines/product-lines.component';
import { MaterialsComponent } from './pages/materials/materials.component';
import { CatalogueDesignComponent } from './pages/catalogue/component-design/design-product.component';
import { DesignCatalogComponent } from './pages/catalogue/catalog-design/design-catalog.component';
import { ListCatalogComponent } from './pages/catalogue/list-catalog/list-catalog.component';
import { AdditionalFieldsListComponent } from './pages/settings/additional-fields-list/additional-fields-list.component';
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: 'items',
        component: ItemListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_ITEMS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Items'
        }
    },
    {
        path: 'items/:id/view',
        component: ItemListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_ITEMS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Inventory :  Item Info'
        }
    },
    {
        path: 'items/:id/edit',
        component: AddItemComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_ITEMS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.MODIFY],
            title: 'Inventory : Edit Item'
        }
    },
    {
        path: 'items/new',
        component: AddItemComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_ITEMS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : New Item'
        }
    },
    {
        path: 'items/:id/clone',
        component: AddItemComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_ITEMS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Clone Item',
        }
    },
    {
        path: 'assemblies',
        component: KitListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Assemblies'
        }
    },
    {
        path: 'assemblies/:id/view',
        component: KitListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Inventory : Assembly Info'
        }
    },
    {
        path: 'assemblies/:id/edit',
        component: AddKitComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.MODIFY],
            title: 'Inventory : Edit Assembly'
        }
    },
    {
        path: 'assemblies/new',
        component: AddKitComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : New Assembly'
        }
    },
    {
        path: 'assemblies/:id/clone',
        component: AddKitComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Clone Assembly'
        }
    },
    {
        path: 'assemblies/:kitid/assemble',
        component: AssembleKitComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS_ASSEMBLE,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Build Assembly'
        }
    },
    {
        path: 'assemblies/assembled',
        component: AssembledKitsComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS_ASSEMBLE,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Built Assemblies'
        }
    },
    {
        path: 'assemblies/assembled/:id',
        component: AssembleKitComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS_ASSEMBLE,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.MODIFY],
            title: 'Inventory : Edit Built Assembly'
        }
    },
    {
        path: 'assemblies/assembled/:id/view',
        component: AssembledKitsComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_KITS_ASSEMBLE,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Inventory : Built Assembly Info'
        }
    },
    {
        path: 'item-categories',
        component: ItemCategoriesComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_CATEGORIES,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Categories'
        }
    },
    {
        path: 'item-categories/new',
        component: AddCategoryComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_CATEGORIES,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : New Category'
        }
    },
    {
        path: 'item-categories/:id/edit',
        component: AddCategoryComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_CATEGORIES,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.MODIFY],
            title: 'Inventory : Edit Category'
        }
    },
    {
        path: 'suppliers',
        component: SupplierListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_SUPPLIERS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Suppliers'
        }
    },
    {
        path: 'manufacturers',
        component: ManufacturerListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_MANUFACTURERS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Manufacturers'
        }
    },
    {
        path: 'units',
        component: UnitTypesComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_UNITS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Units'
        }
    },
    {
        path: 'item-types',
        component: ItemTypesComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_ITEMTYPES,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Item Types'
        }
    },
    {
        path: 'states',
        component: StateTaxComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_STATE_TAX,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Tax Table'
        }
    },
    {
        path: 'sales-accounts',
        component: SalesAccountsComponents,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_SALES_ACCOUNTS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Sales Accounts'
        }
    },
    {
        path: 'warehouse',
        component: WarehouseComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_WAREHOUSE,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Warehouses'
        }
    },
    {
        path: 'labels',
        component: LableListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_LABELS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Labels'
        }
    },
    {
        path: 'add-labels',
        component: LabelAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_LABELS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Labels Create'
        }
    },
    {
        path: 'labels/:id/edit',
        component: LabelAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_LABELS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Labels Create'
        }
    },
    {
        path: 'labels/:id/view',
        component: LableListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_LABELS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Inventory : Labels design view'
        }
    },
    {
        path: 'labels/pages',
        component: LabelPagesListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_LABELS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Labels Create'
        }
    },
    {
        path: 'labels/pages/add',
        component: LabelPagesAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_LABELS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Labels Create'
        }
    },
    {
        path: 'labels/pages/:id/edit',
        component: LabelPagesAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_LABELS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Labels Create'
        }
    },
    {
        path: 'labels/print',
        component: LabelPrintComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_LABELS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Inventory : Labels Print'
        }
    },
    {
        path: 'catalog/list',
        component: ListCatalogComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.INV_CATALOG,
            resource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Catalog list'
        }
    },
    {
        path: 'catalog/design-component',
        component: CatalogueDesignComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.INV_CATALOG_COMPONENTS,
            resource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Catalogue Components'
        }
    },
    {
        path: 'catalog/design-component/:id/edit',
        component: CatalogueDesignComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.INV_CATALOG_COMPONENTS,
            resource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Catalogue Components'
        }
    },
    {
        path: 'catalog/design',
        component: DesignCatalogComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.INV_CATALOG,
            resource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.CREATE],
            title: 'Design catalog'
        }
    },
    {
        path: 'catalog/:id/view',
        component: ListCatalogComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            resource: ResourceConstants.INV_CATALOG,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Catalog Update'
        }
    },
    {
        path: 'catalog/:id/edit',
        component: DesignCatalogComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            resource: ResourceConstants.INV_CATALOG,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.MODIFY],
            title: 'Catalog Update'
        }
    },
    {
        path: 'product-lines',
        component: ProductLinesComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_PRODUCT_LINES,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Product Lines'
        }
    },
    {
        path: 'materials',
        component: MaterialsComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_MATERIALS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Materials'
        }
    },
    {
        path: 'additional-fields',
        component: AdditionalFieldsListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data: {
            resource: ResourceConstants.INV_ADDITIONAL_FIELDS,
            globalResource: ResourceConstants.INV_MODULE,
            permissions: [PermissionConstants.VIEW],
            title: 'Inventory : Additional Fields'
        }
    },
    {
      path: '**',
      component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InventoryRoutingModule { }

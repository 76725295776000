import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataFilterGroupRequest, DataFilterRequest, GFColumn, GFColumnTypes, GFilterGroup, GFilterParam, GSortParam, GridTab, Pagination } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import { DidsService } from '../../../services/dids.service';
import { CallLog } from '../../../models/reports.models';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { WINDOW } from 'src/app/core/services/window.service';
import { CallsDashBoardData } from '../../../models/dids.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { BlockListService } from '../../../services/block-list.service';
import { BlockListRequest } from '../../../models/block-list.model';
import * as popup from 'src/app/core/utils/popup.functions';
import { CallCenterService } from '../../../services/call-center.service';
import { SipOutBoundFunctionService } from '../../../services/sipoutboundfunctionservice';
import { SIP } from '../../../models/sip.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlertOptions } from 'sweetalert2';
import { filter, isArray } from 'lodash';
import { CallReportsDataHelper } from '../../../helpers/call-reports-data.helpers';

@Component({
    selector: 'app-call-reports',
    templateUrl: './call-reports.component.html',
    styleUrls: ['./call-reports.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CallReportsComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;
    @ViewChild('dataTable') dataTable: DatatableComponent;
    @ViewChild('commentsModal') commentModal: any;

    @Input() page: Pagination = { count: 0, limit: 20, offset: 0, pageNumber: '0-0' };

    @Input() rows: CallLog[] = []

    @Input() public rows2: {count : number,data :CallLog[]} = {count:0, data:[]}

    public selected: CallLog;

    public expanded: boolean = true;

    public sort: GSortParam[] = [{ colname: 'date_created', direction: 'desc' }];

    public columns: GFColumn[] = CallReportsDataHelper.FilterColumns;

    public search: GFilterParam[] = CallReportsDataHelper.SearchFilters;



    @Input() defaults: GFilterParam[] = CallReportsDataHelper.DefaultFilters;


    @Input() outboundDefaults: GFilterParam[]=[];
    
    @Input() set gridTabs(value: GridTab[]){

        if(value){
            this._gridTabs=value;

        }

    }

    public _gridTabs: GridTab[]=CallReportsDataHelper.TabItems;

    public gridtabFilters: GFilterParam[] = CallReportsDataHelper.TabFilters;


    private backgroundClasses: string[] = ["bg-primary", "bg-success", "bg-danger", "bg-warning", "bg-info"];

    public module = ModuleConstants.RING2VOICE_CALL_REPORTS;

    public childCalls: Observable<CallLog[]>;

    public subs: SubSink = new SubSink();

    public SelectionType = SelectionType;

    public scrollbarH: boolean = false;

    public isSuperAdmin: boolean;

    public suborg_id: number;

    public permission = PermissionConstants;

    public user_id: number;


    public blockStatus: boolean;

    is_call_center_subscribed: boolean = false;

    public showSearch:boolean = true
    
    public historyCount: number;

    public sortLookup = CallReportsDataHelper.SortLookup;

    @Input() showCount: boolean = true

    @Input() showGridFilter: boolean = true

    @Input() showExport: boolean = true

    @Input() showAdvancedFilter: boolean = true //to Show hide advanced filter on grid filter

    @Input() baseFilter: GFilterParam[] =  CallReportsDataHelper.BaseFilter;

    @Input() defaultFilter: GFilterParam[];

    public dateFilter = this.defaults.filter(item => item.displayName === 'Calls Today');

    @Input() enableViewDetails:boolean = true

    @Input() sipList: SIP[] = [];

    @Input() showSavedFilter:boolean = true

    @Input() set showDefaultFilter(value:boolean){
        this._showDefaultFilter=value;
    }

    @Input() set tableHeight(value: number) {
        if (value) {
          this._tableHeight = value;
      
          const newHeight = this._tableHeight;
          const datatableBody = this.elementRef.nativeElement.querySelector('.datatable-body');
          if (datatableBody) {
            datatableBody.style.maxHeight = `${newHeight}px`;
            datatableBody.style.overflowY = 'auto';
          }
        }
    }
      

    public _showDefaultFilter:boolean=true;

    public _tableHeight:number;

    public dashboardData: Observable<CallsDashBoardData>;

    public carddata: string;

    private currentPlayer: HTMLAudioElement;

    public selectedGridTabId = "all"

    public tabHeaderHeight: number = 50;

    public sIdForComment:string;
    temp_suborg_id: number;

    public reportTypeData:any = {

    type1Det :{
        id : "inbound" ,
        title : "Inbound",
        active : true,
    },

    type2Det : {
        id : "outbound",
        title : "Outbound",
        active : false
    }
    }

    public reportType:string = "inbound"

    public reportTypeTitle:string = "Inbound"

    @Input() showreportType:boolean =true

    @Input() isAllSuborg:boolean = false //to show reports from all suborg

    public pattern = /^[0-9\-]*$/;

    constructor(private cdr: ChangeDetectorRef,
        private elementRef:ElementRef,
        private didService: DidsService,
        private blockListService: BlockListService,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        public callCenterService: CallCenterService,
        public outboundService: SipOutBoundFunctionService,
        private modalService: NgbModal,
        @Inject(WINDOW) private window: Window) {

        this.isSuperAdmin = didService.isSuperUser;
        this.user_id = blockListService.userId;
        this.suborg_id = this.didService.suborgId;

        if (this.showAdvancedFilter) {

            if (this.isSuperAdmin && this.showAdvancedFilter) {
                this.columns.push(...[
                    { displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref },
                    { displayName: 'Sub Org  (Choose Tenant First)', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentColumn: 'customer' },
                    { displayName: 'Incoming Routes   (Choose Sub Org First)', columnName: 'forward_number_sid', columnType: GFColumnTypes.ref, dependentColumn: 'suborg' },
                ]);
            }
            else if (!this.didService.isAllSuborg && this.showAdvancedFilter) {
                this.columns.push({ displayName: 'Incoming Route', columnName: 'forward_number_sid', columnType: GFColumnTypes.ref })
            }
        }

    }

    public selectedGridTabFilter: any = [];

    ngOnInit(): void {

        this.is_call_center_subscribed = this.didService.userCallcenterSubscriptionStatus;
        //dFilter=this.defaults.filter(item => item.displayName === 'Calls Today');

        if(this.rows2.data.length > 0) {

            this.rows = [...this.rows2.data]
            this.setPagination(0, this.rows2.count);
        }
        else{

            this.getData2([{ 'conditions': this.dateFilter, 'operator': 'AND' }]);
            this.getDashboardData();
        }
        if (!this.showAdvancedFilter) {
            this.columns = undefined
        }
    }

    ngAfterViewInit(){
        if(this._tableHeight){
            const newHeight = this._tableHeight; // Set your desired height here
            this.elementRef.nativeElement
              .querySelector('.datatable-body')
              .setAttribute('style', `max-height: ${newHeight}px; overflow-y: auto;`);
        }
    }

    ngAfterViewChecked() {
        if (this.dataTable && this.dataTable.rowDetail) {
            this.dataTable.rowDetail.expandAllRows();
            this.cdr.detectChanges();
        }
    }


    getData2(filters: GFilterGroup[] = [], offset: number = 0) {


        if (this.showCount == true) {
            this.selectedDashBoardCard(filters);
        }
        
        // if(this.defaultFilter)
        // {
        //     if(filters == null)
        //     {
        //         filters = []
        //     }
        //     filters.push({ 'conditions': this.defaultFilter, 'operator': 'AND' })
        // }

        /**
         ** This one to make sure its applied the date based filtering
         */

        if(this.dateFilter&&filters.length==0)
        {
            
            filters.push({ 'conditions': this.dateFilter, 'operator': 'AND' })
        }


        if (this.selectedGridTabFilter.length > 0) {
            this.selectedGridTabFilter.forEach(condition => {

                filters.push({ conditions: [condition], operator: 'AND' })
            });

        }

        let request: DataFilterGroupRequest = {
            base_filters: this.selectedGridTabFilter.length == 0 ?this.baseFilter:[],
            filters: filters,
            sort: this.sort
        };
        if (filters != null) {

            let hasDirectioninFilter = filters.some(filter => filter.conditions.some((condition) =>
            condition?.colname === 'direction'));

            if (hasDirectioninFilter) {
                request.base_filters = [];
            }
        }

        console.log('req', request, 'offset', offset)


        this.subs.sink = this.didService.getCallReport(request, offset,this.isAllSuborg).pipe(
            catchError(err => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
           // this.rows2 =response.result;
            this.historyCount = response.count;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    selectedDashBoardCard(filters) {
        filters.forEach((filter) => {
            const conditions = filter.conditions;
            conditions.forEach((condition) => {
                const displayName = condition.displayName;
                if (displayName === 'Calls Today' ||
                    displayName === 'Calls Yesterday' ||
                    displayName === 'Calls This Week' ||
                    displayName === 'Calls Last Week' ||
                    displayName === 'Calls This Month' ||
                    displayName === 'Calls Last Month') {
                    this.carddata = displayName;
                    this.cdr.markForCheck();
                }
            });
        });
    }

    getDashboardData() {
        this.dashboardData = this.didService.getCallsDashboardData();
    }

    getOutBoundCall(inbound_sid: string) {
        if (inbound_sid) {
            let request: DataFilterRequest = {
                filters: [],
                base_filters: [
                    { colname: 'direction', condition: 'is', value: 'outbound-dial', operator: 'AND' },
                    { colname: 'parent_call_sid', condition: 'is', value: inbound_sid, operator: 'AND' },
                ],
                sort: null
            };
            this.childCalls = this.didService.getCallReport(request).pipe(
                catchError(err => of({ count: 0, result: [] })),
                map(resp => resp.result)
            );
        }
        else {
            this.childCalls = of([]);
        }
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        if(this.enableViewDetails == true)
        {
            let current: CallLog = selected[0];
            if (current) {
                this.selected = current;
                this.expanded = false;
                this.getOutBoundCall(current.sid);
                this.refreshDataTable();
            }
        }
    }

    addToBlockList(): void {
        let selected = this.selected
        let payload = new BlockListRequest();
        payload.number = selected.call_from;
        payload.created_user = this.user_id;

        let opt = {
            title: `Are you sure you want to ${selected.blocked_status ? 'Block From Number' : 'Unblock From Number'}?`,
            text: '',
            ButtonText: "Yes",
        }
        popup.ConfirmWithButtonText(opt, result => {
            if (result.isConfirmed) {
                if (selected.blocked_status) {
                    this.subs.sink = this.blockListService.create(payload).subscribe(
                        resp => {
                            if (resp.status) {
                                this.selected.blocked_id = resp.id;
                                this.toastr.success("Number Blocked successfully");
                                this.cdr.markForCheck();
                            }else if(resp.Message){
                                selected.blocked_status = !selected.blocked_status
                                this.cdr.markForCheck();
                                this.toastr.error(resp.Message);
                            }
                        },
                        () => {
                            this.toastr.error('Failed to change status');
                        });
                }
            } else {
                selected.blocked_status = !selected.blocked_status
                this.cdr.markForCheck();
            }
        });
    }

    unblockNumber() {
        let opt = {
            title: `Are you sure you want to Unblock?`,
            text: '',
            ButtonText: "Yes",
        }
        popup.ConfirmWithButtonText(opt, result => {
            if (result.isConfirmed) {
                this.blockListService.delete(this.selected.blocked_id).subscribe(
                    resp => {
                        if (resp.status) {
                            this.selected.blocked_status = false;
                            this.toastr.success(`Number Unblocked Successfully`);
                            this.cdr.markForCheck();
                        }
                    },
                    error => {
                        this.toastr.error("Failed to Unblock Phone Number")
                    }
                )
            };
        });
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.refreshDataTable();
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;

        let filter:GFilterGroup[]=[]

        if (this.showGridFilter) {

            filter=this.dataFilter.getDataFilters2();
        }

        this.getData2(filter, offset)

        this.scrollToTop()
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            let offset = event.offset * this.page.limit;
            this.page.offset = event.offset;
            this.getData2(this.dataFilter.getDataFilters2(), offset);
        }
    }

    applyFilter(params: GFilterParam[]) {


        // this.getData(params);
    }

    applyFilterGroup(groups: GFilterGroup[]) {

        this.getData2(groups);


    }

    bulkExport() {
        let request = {
            base_filters: [],
            filters: this.dataFilter.getDataFilters2(),
            sort: this.sort
        }
        request.base_filters.unshift({ colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' });
        // request.filters.unshift({conditions:[{ colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' }],operator:'AND'});

        this.subs.sink = this.didService.callReportExport(request,this.historyCount).subscribe(
            resp => {
                if(this.historyCount>2000){
                    this.showBackgoundAlert();
                }else{
                    var downloadLink = document.createElement("a");
                    downloadLink.href = URL.createObjectURL(resp);
                    downloadLink.download = `Ring2Voice_${moment().format('MM-DD-YYYY-hh_mm')}.csv`;
    
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
            },
            () => {
                this.toastr.error("Unable to export");
            }
        )
    }

    showBackgoundAlert(){
        let option: SweetAlertOptions = {
            title: 'Process Successful',
            text: 'You will receive an email with the results',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-success',
              footer: 'flex-row-reverse'
            },
            buttonsStyling: false
          };
          popup.OpenConfirmBox(option, result => {
            if (result.isConfirmed) {
                    return;
                }  
              });
    }

    scrollToTop() {
        this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    audioPlayerPlay(audio: HTMLAudioElement) {
        if (this.currentPlayer && this.currentPlayer != audio) {
            this.currentPlayer.pause();
        }
        this.currentPlayer = audio;
    }

    playAudio(event:any){
       
        this.audioPlayerPlay(event);
    }

    hasBinViewInfoPermission() {
        return this.ra.hasPermission(
            ResourceConstants.R2V_FORWARD_NUMBERS,
            [PermissionConstants.VIEW_INFO],
            ResourceConstants.R2V_MODULE
        );
    }

    setFilter(displayName: string) {
        const filter = this.defaults.find(item => item.displayName === displayName);
        if (filter) {
            this.dataFilter.setDefaultFilter(filter.colname, filter.value);
        }

        this.cdr.markForCheck();
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.R2V_BLOCK_LIST, [permission]);
    }

    randomDpBackground(row: CallLog) {
        row['bgClass'] = row['bgClass'] || this.backgroundClasses[Math.floor(Math.random() * this.backgroundClasses.length)];
        return row['bgClass'];
    }

    //sainul
    callBack(row) {
        const noToCall = row.direction == 'inbound' ? row.call_from : row.call_to;
        this.callCenterService.popupOpenStatus.next({ isOpen: true, selectedTab: 2, })
        this.outboundService.setOutBoundNumber(noToCall)
    }
    //end
    onSelectGridTabs(tabItem) {

        if(this.defaultFilter&&this.defaultFilter.length){
            this.selectedGridTabFilter = [...this.defaultFilter]
        }
        else{
            this.selectedGridTabFilter=[];

        }
        
        this.page.offset = 0
        this.showSearch = true
        this.reportType = tabItem?.id;

        switch (this.reportType) {

            case "all":
                this.getData2([], 0)
                this.selectedGridTabId = "all"
                break;

            case "inbound":
                let isMissed=this._gridTabs.filter(val=>val.id=="missed").length>0?true:false;
                let data;
                if(!isMissed){
                    data=[this.gridtabFilters.filter(x => x.displayName == tabItem.name)[0]];

                }
                else{
                    data=this.gridtabFilters.filter(x => x.displayName == tabItem.name);

                }


                if(isArray(data)){
                    this.selectedGridTabFilter=[...this.selectedGridTabFilter, ...data]; 
                }
                else{
                    this.selectedGridTabFilter=data;
                }

                this.getData2([], 0)
                // this.selectedGridTabId = "all"
                this.reportType = "inbound"
                break; 

            case "outbound":
               this.selectedGridTabFilter.push(this.gridtabFilters.filter(x => x.displayName == tabItem.name)[0]);
                this.getData2([], 0)
                // this.selectedGridTabId = "all"
                // this.reportType = "outbound"
                //this.reportTypeTitle = "Outbound"
                break;

            case "missed":
                this.selectedGridTabFilter.push( {colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' },
                { displayName: 'Missed', colname: 'real_status', condition: 'equalto', value: 'no-answer', operator: 'WHEN' })
                this.getData2([], 0)
                this.reportType='missed';
                // this.selectedGridTabId = "all"
                break

            // case "agent":
            //     this.selectedGridTabId = "agent"
            //     this.showSearch = false
            //     this.cdr.markForCheck()
            //     break;
        }
    }

    readComments(sid: string, suborg: number) {

        this.sIdForComment = sid;
        this.temp_suborg_id = suborg;
        if (sid) {
            const modalRef = this.modalService.open(this.commentModal, { centered: true, scrollable: true })

            modalRef.result.then((data) => {
          }, (reason) => {

              this.dataFilter.resetFilter();

          });
        }
      }

      onChangeReportType(event)
      {
        this.carddata = "Calls Today"
        this.reportType = event.id
        if(event.id == this.reportTypeData.type1Det.id)
        {
            this.reportTypeData.type1Det.active = true
            this.reportTypeData.type2Det.active = false
        }
        else if(event.id == this.reportTypeData.type2Det.id)
        {
            this.reportTypeData.type1Det.active = false
            this.reportTypeData.type2Det.active = true
        }
    }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}

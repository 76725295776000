<section *ngIf="model">
    <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && onSubmit()" novalidate>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="val-required">Incoming Routes name</label>
                    <input type="text" name="forwardbin_name"  class="form-control" [(ngModel)]="model.name" required>
                    <small class="danger mt-1" *ngIf="model.name && nameError">Name already exists</small>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
              <div class="form-group">
                <div class="d-flex">
                  <p class="badge badge-success">User</p>&nbsp;
                    <div class="form-group d-flex align-items-center">
                        <div class="custom-switch custom-switch-info custom-control userorgroup mb-1 mb-xl-0">
                            <input type="checkbox" [(ngModel)]="model.user_or_group" name="is_active" class="custom-control-input" id="user_or_group" checked>        
                            <label class="custom-control-label" for="user_or_group">
                                <span></span>
                            </label>
                        </div>
                    </div>
                  <p class="badge badge-info">User Group</p>
                </div>
                <div *ngIf="!model.user_or_group">
                    <user-select (valueChange)="model.user=$event" [value]="model.user"></user-select>
                </div>
                <div *ngIf="model.user_or_group">
                    <user-group-select [(value)]="model.usergroup" [smartSipGroup]="false"></user-group-select>
                </div>
              </div>
          </div>
        </div>

        <div class="d-flex flex-sm-row flex-column justify-content-end mt-3">
            <button type="submit" [disabled]="!addForm.form.valid"
                    class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                {{ id ? 'Save Changes' : 'Save'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</section>

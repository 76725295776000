import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterGroupRequest, DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { CallsDashBoardData, DIDNumber, DIDNumberReport, DIDRequest } from '../models/dids.models';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { CallLog } from '../models/reports.models';

@Injectable({
    providedIn: 'root'
})
export class DidsService extends BaseService {

    public currentStep: number = 1;

    constructor(private http: HttpClient) { super(); }

    getAll(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/did/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<DIDNumber[]>>(url, request);
    }

    getAllNumbers(sid: string,request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit, message_route_id:string|null, call_route_id:string|null) {
        let params = new HttpParams()
        .set('limit', limit.toString())
        .set('offset', offset.toString())
        if (message_route_id) {
            params = params.set('message_route_id', message_route_id);
        }else if (call_route_id) {
            params = params.set('call_route_id', call_route_id);
        }
        let url = `${this.baseURL}/global/numbers/`;
        return this.http.post<DataFilterResponse<DIDNumber[]>>(url, request,{ params:params });
    }

    getById(sid: string) {
        const request: DataFilterRequest = {
            base_filters: [{ colname: 'sid', condition: 'is', value: sid, operator: 'AND' }],
            filters: null,
            sort: null
        }
        return this.getAll(request, 0, 1).pipe(map(resp => resp.result.length ? resp.result[0] : null));
    }

    delete(sid: string) {
        return this.http.delete(`${this.baseURL}/did/${sid}/`);
    }

    searchDids(request: DIDRequest) {
        return this.http.post(`${this.baseURL}/did/available/`, request);
    }

    didExport(request: DataFilterRequest) {
        return this.http.post(`${this.baseURL}/did/filter/?export=true`, request, { responseType: 'blob' });
    }

    deleteDid(sid: string) {
        return this.http.delete<{ status: boolean, message: string }>(`${this.baseURL}/did/${sid}/delete/`);
    }

    reAssignForwardBin(sid: string, bin_sid: string) {
        return this.http.post<{
            status: boolean,
            sid: string,
            resolved_sid: string,
            name: string,
            forward_number: string,
            sip: string,
            message: string
        }>(`${this.baseURL}/did/${sid}/bin/reassign/`, { forward_bin_sid: bin_sid });
    }

    renameDid(sid: string, value: string) {
        return this.http.post<{ status: boolean, name: string, message: string }>(`${this.baseURL}/did/${sid}/rename/`, { name: value });
    }

    bulkRenameDid(sid: string[], value: string) {
        return this.http.post<{ status: boolean, name: string, message: string }>(`${this.baseURL}/did/bulk_update/`, { update_type: 'name_update', name: value, did_sid: sid });
    }

    bulkReassign(sid: string[], value: string) {
        return this.http.post<{ status: boolean, name: string, message: string }>(`${this.baseURL}/did/bulk_update/`, { update_type: 'bin_update', bin_sid: value, did_sid: sid });
    }

    didReportExport(request: DataFilterRequest) {
        return this.http.post(`${this.baseURL}/did/reports/filter/?export=true`, request, { responseType: 'blob' });
    }

    getDidReport(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/did/reports/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<DIDNumberReport[]>>(url, request).pipe(
            catchError(() => of({ count: 0, result: [] }))
        );
    }

    getCallReport(request: DataFilterGroupRequest, offset: number = 0,isAllSubOrg:boolean=false,  limit: number = this.dataLimit, hideLoading:boolean=false) {

        let headers=null;
        if(hideLoading){
            headers = new HttpHeaders({
                'Hideloading': 'true',
              });
        }

        let url;
        if(isAllSubOrg)
        {   
            url = `${this.baseURL}/calls/filter/?limit=${limit}&offset=${offset}&suborg=${-1}`;
        }
        else
        {
            url = `${this.baseURL}/calls/filter/?limit=${limit}&offset=${offset}`;
        }


        // let url = `${this.baseURL}/calls/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<CallLog[]>>(url, request, { headers: headers }).pipe(
            catchError(() => of({ count: 0, result: [] }))
        );
    }



    // getOutboundCallReport(request: DataFilterGroupRequest, offset: number = 0,isAllSubOrg : boolean = false,
    //      limit: number = this.dataLimit) {
    //         let url

    //         if(isAllSubOrg)
    //         {   
    //             //url = `${this.baseURL}/outbound_dial/filter/?limit=${limit}&offset=${offset}&suborg=${-1}`;
    //             url = `${this.baseURL}/outbound_dial/filter/?limit=${limit}&offset=${offset}&suborg=${-1}`;
    //         }
    //         else
    //         {
    //             //url = `${this.baseURL}/outbound_dial/filter/?limit=${limit}&offset=${offset}`;
    //             url = `${this.baseURL}/outbound_dial/filter/?limit=${limit}&offset=${offset}`;
    //         } 
    //     return this.http.post<DataFilterResponse<CallLog[]>>(url, request).pipe(
    //         catchError(() => of({ count: 0, result: [] }))
    //     );
    // }
//remove =0 after merging outbound 
    callReportExport(request: DataFilterGroupRequest,count:number=0) {
        return this.http.post(`${this.baseURL}/calls/filter/?export=true&export_count=${count}`, request, { responseType: 'blob' });
    }

    getDashboardData() {
        return this.http.get<{
            total: number,
            unassigned: number,
            local: number,
            toll_free: number
        }>(`${this.baseURL}/did/dashboard/`);
    }

    getCallsDashboardData() {
        return this.http.get<CallsDashBoardData>(`${this.baseURL}/calls/dashboard/`);
    }

    getOutboundCallsDashboardData() {
        return this.http.get<CallsDashBoardData>(`${this.baseURL}/outbound_calls/dashboard/`);
    }

    updateSmsOutboundStatus(id:string, body: { is_sms_outbound_active: boolean }){
        return this.http.patch(`${this.baseURL}/did/${id}/sms_outbound/`,body)
    }

    reAssignChatBin(id: string, bin_id: string) {
        return this.http.post<{
            status: boolean,
            message_route_name: string,
            message_route_usergroup_name: string,
            message_route_user: string,
            message_route_id: string,
            user_or_group: boolean
        }>(`${this.baseURL}/did/${id}/message_route/reassign/`, { message_route_id: bin_id });
    }
    
    bulkDelete(payload: string[]) {
        return this.http.post<any>(`${this.baseURL}/did/bulk_delete/`, {phone_numbers:payload});
    }
    
}

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { DIDNumber } from '../../models/dids.models';
import { DidsService } from '../../services/dids.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { WINDOW } from 'src/app/core/services/window.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { ForwardBinSelectComponent } from '../../components/forward-bin-select/forward-bin-select.component';   
import { DidsBulkUpdateComponent } from './dids-bulk-update/dids-bulk-update.component';

@Component({
    selector: 'app-dids',
    templateUrl: './dids.component.html',
    styleUrls: ['./dids.component.scss']
})
export class DidsComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    @ViewChild('frwdbin') frwdbin:ForwardBinSelectComponent;

    public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = [
        { displayName: "All Phone Numbers", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Unassigned Numbers", colname: 'forward_bin_sid_resolved', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Local Numbers", colname: 'did_type', condition: 'is', value: 'Local', operator: 'AND' },
        { displayName: "Toll Free Numbers", colname: 'did_type', condition: 'is', value: 'Toll-Free', operator: 'AND' },
        { displayName: "Deleted Numbers", colname: 'is_delete', condition: 'is', value: 'true', operator: 'AND' }
    ];

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Number or Name', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Phone Number', colname: 'did', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                { displayName: 'Phone Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' }
            ],
        },
        { displayName: 'Incoming Routes Name', colname: 'forward_bin_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Incoming Routes', colname: 'forward_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
        { displayName: 'Area Code', colname: 'area_code', condition: 'contains', value: '', operator: 'OR' },
    ];

    public columns: GFColumn[] = [
        { displayName: 'Number', columnName: 'did', columnType: GFColumnTypes.phone },
        { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
        {
            displayName: 'Number Type',
            columnName: 'did_type',
            columnType: GFColumnTypes.select,
            optionValueType: GFColumnTypes.ref,
            options: [
                { displayName: 'Local', value: 'Local' },
                { displayName: 'Toll-Free', value: 'Toll-Free' },
            ]
        },
        { displayName: 'Area Code', columnName: 'area_code', columnType: GFColumnTypes.ref },
        { displayName: 'Purchased on', columnName: 'purchased_on', columnType: GFColumnTypes.date }
    ];

    public sort: GSortParam[] = [{ colname: 'purchased_on', direction: 'desc' }];

    public sortLookup = {
        did: 'did',
        name: 'name',
        area_code: 'area_code',
        purchased_on: 'purchased_on',
        forward_bin_name: 'forward_bin_name',
        suborg_name: 'suborg_name'
    };

    public SelectionType = SelectionType;

    // public bulk_name:string ='';

    public rows: DIDNumber[] = [];

    public uniqeId: string;

    public expanded: boolean = true;

    public selected: DIDNumber;

    public subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public didname_input: any;

    public forwardBinSid: string;

    public chatBinSid: string;

    public isSuperAdmin: boolean;

    private ngbModalRef: NgbModalRef;

    public module = ModuleConstants.RING2VOICE_DIDS;

    public UIPermissions: UIPermission;

    public deletedRecords: boolean = false;

    public totalPhoneNumbers: number = 0;

    public countofPhoneNumberstoUpdate: number=0;

    public countofSelectedPhoneNumbers: number=0;

    public dashValues: { total: number, unassigned: number, local: number, toll_free: number };

    constructor(private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private device: DeviceDetectorService,
        public didService: DidsService,
        private modalService: NgbModal,
        private ra: ResourceAccessService,
        @Inject(WINDOW) private window: Window,
        private phoneNumberPipe: PhoneNumberPipe) {

        this.isSuperAdmin = didService.isSuperUser;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.R2V_PHONE_NUMBERS, ResourceConstants.R2V_MODULE,this.isSuperAdmin);
        this.addFilterColumns();
    }

    addFilterColumns() {
        if (this.isSuperAdmin) {
            this.columns.push(...[
                { displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref },
                { displayName: 'Sub Org   (Choose Tenant First)', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentColumn: 'customer' },
                { displayName: 'Incoming Routes   (Choose Sub Org First)', columnName: 'forward_bin_sid_resolved', columnType: GFColumnTypes.ref, dependentColumn: 'suborg' },
            ]);
        }
        else if (!this.didService.isAllSuborg) {
            this.columns.push({ displayName: 'Incoming Routes', columnName: 'forward_bin_sid_resolved', columnType: GFColumnTypes.ref },
                              { displayName: 'Unassigned Numbers', columnName: 'is_bin_unassigned', columnType: GFColumnTypes.boolean })
        }
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
        this.getData();
        this.getDashboardData();
    }

    getData(filters: GFilterParam[] = null, offset: number = 0) {

        let request = {
            base_filters: [{ colname: 'is_delete', condition: 'is', value: this.deletedRecords.toString(), operator: 'AND' }],
            filters: filters,
            sort: this.sort
        }

        this.subs.sink = this.didService.getAll(request, offset, this.page.limit).pipe(
            catchError(err => of({ total_count:0, count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.totalPhoneNumbers = response.total_count;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    getDashboardData() {
        this.subs.sink = this.didService.getDashboardData().subscribe(resp => {
            this.dashValues = resp;
            this.cdr.markForCheck();
        })
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        let current: DIDNumber = selected[0];
        if (current && this.UIPermissions.viewInfo) {
            this.uniqeId = current.sid;
            this.selected = current;
            this.expanded = false;
            this.didname_input = current.name;
            this.forwardBinSid = current.forward_bin_sid_resolved;
            this.chatBinSid = current.message_route_id;
            this.refreshDataTable();
        }
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        const filters = this.getFilters();
        this.getData(filters, offset);
        this.scrollToTop()
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            const filters = this.getFilters();
            this.getData(filters);
        }
    }

    applyFilter(params: GFilterParam[]) {
        const filters = this.getFilters(params);
        this.getData(filters);
    }

    getFilters(params?: GFilterParam[]) {
        let filters = params || this.dataFilter.getDataFilters();
        let del_filter = filters?.find(fil => fil.colname === 'is_delete');
        this.deletedRecords = del_filter ? del_filter.value : false;
        return filters.filter(fil => fil.colname !== 'is_delete');
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.refreshDataTable();
    }

    didDelete(sid: string) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.didService.deleteDid(sid).subscribe(
                    resp => {
                        if (resp.status) {
                            this.toastr.success(`Successfully deleted Phone Number`);
                            this.closeDetailedView();
                            this.applyFilter(null);
                        }
                        else {
                            if (resp.message === 'DATE_ERROR') {
                                this.toastr.warning('Number can only be deleted 16 days after it was purchased')
                            }
                            else if (resp.message === "SIGNALWIRE_API_ERROR") {
                                this.toastr.error(`Unable to delete Phone Number`);
                            }
                        }
                    },
                    error => {
                        this.toastr.error("Failed to delete Phone Number")
                    }
                )
            }
        });
    }

    bulkexport() {
        let request = {
            base_filters: [{ colname: 'is_delete', condition: 'is', value: this.deletedRecords.toString(), operator: 'AND' }],
            filters: this.getFilters(),
            sort: this.sort
        }
        this.subs.sink = this.didService.didExport(request).subscribe(
            resp => {
                var downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(resp);
                downloadLink.download = `Ring2Voice_${moment().format('MM-DD-YYYY-hh_mm')}.csv`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },
            () => {
                this.toastr.error("Unable to export");
            }
        )
    }

    open(content: any) {
        this.ngbModalRef = this.modalService.open(content, { centered: true, backdrop: 'static' });
    }

    openBulkUpdateModal(content: any){
        this.ngbModalRef = this.modalService.open(content, { centered: true, scrollable: true, size:'xl', backdrop: 'static', windowClass:'bulk_update_modal' });
    }

    reAssignBin() {

        if (!this.forwardBinSid || this.forwardBinSid === this.selected.forward_bin_sid) return;

        this.didService.reAssignForwardBin(this.selected.sid, this.forwardBinSid).subscribe(
            resp => {
                if (resp.status) {
                    this.toastr.success(`Successfully reassigned Incoming Routes`);
                    this.ngbModalRef?.dismiss();
                    this.selected.forward_bin_sid = resp.sid;
                    this.selected.forward_bin_sid_resolved = resp.resolved_sid;
                    this.selected.forward_number = resp.forward_number;
                    this.selected.sip = resp.sip;
                    this.selected.forward_bin_name = resp.name;
                    this.refreshDataTable();
                }
                else if (resp.message === "SIGNALWIRE_API_ERROR") {
                    this.toastr.error(`Failed to reassign Incoming Routes, Retry after some time`);
                }
            },
            error => this.toastr.error(`Failed to reassign Incoming Routes`)
        )
    }

    reAssignChatBin() {

        if (!this.chatBinSid || this.chatBinSid === this.selected.message_route_id) return;

        this.didService.reAssignChatBin(this.selected.sid, this.chatBinSid).subscribe(
            resp => {
                if (resp.status) {
                    this.toastr.success(`Successfully reassigned Incoming Routes`);
                    this.ngbModalRef?.dismiss();
                    this.selected.message_route_id = resp.message_route_id;
                    this.selected.message_route_name = resp.message_route_name;
                    this.selected.message_route_user_or_group = resp.user_or_group;
                    this.selected.message_route_user_name = resp.message_route_user;
                    this.selected.message_route_user_group_name = resp.message_route_usergroup_name;
                    this.refreshDataTable();
                }
            },
            error => this.toastr.error(`Failed to reassign Incoming Routes`)
        )
    }

    changeName() {
        if (!this.didname_input) {
            this.didname_input = this.phoneNumberPipe.transform(this.selected.did);
        }else if(this.didname_input.length>255){
            return 
        }
        this.subs.sink = this.didService.renameDid(this.selected.sid, this.didname_input).subscribe(
            resp => {
                if (resp.status) {
                    this.toastr.success(`Successfully updated the name`);
                    this.selected.name = resp.name
                    this.refreshDataTable();
                }
                else if (resp.message === "SIGNALWIRE_API_ERROR") {
                    this.toastr.error(`Unable to change name, Retry after some time`);
                }
            },
            error => {
                this.toastr.error(`Resource not found`);
            }
        )
    }

    changeSmsOutboundStatus(): void {
        let status = this.selected.is_sms_outbound_active;
        let opt = {
          title: `Are you sure you want to ${status ? 'Activate SMS Outbound' : 'Deactivate SMS Outbound'}?`,
          text: this.selected.did,
          current: status,
        }
        popup.ConfirmActivate(opt, result => {
          if (result.isConfirmed) {
            this.subs.sink = this.didService.updateSmsOutboundStatus(this.selected.sid,{is_sms_outbound_active:status}).subscribe(
              resp => { 
                if(resp['is_outbound_active']){
                    this.toastr.success("SMS Outbound Activated Successfully")
                }else{
                    this.toastr.success("SMS Outbound Deactivated Successfully")
                }
              },
              () => {
                this.selected.is_sms_outbound_active = !status;
                this.cdr.markForCheck();
                this.toastr.error('Failed to change status');
              }
            );
          }
          else {
            this.selected.is_sms_outbound_active = !status;
            this.cdr.markForCheck();
          }
        });
      }

    canDelete(purchased_on: any) {
        return moment().isAfter(moment(purchased_on).add(16, 'days')) && this.UIPermissions.delete;
    }

    scrollToTop() {
        this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    dismissModal(modal: any) {
        this.forwardBinSid = this.selected.forward_bin_sid_resolved;
        this.chatBinSid = this.selected.message_route_id;
        modal.dismiss('closed');
    }

    dismissModalBulk(modal: any) {
        setTimeout(() => {
            this.ngOnInit();
        }, 3500);
        modal.dismiss('closed');
    }

    hasBinViewInfoPermission() {
        return (this.selected.forward_bin_suborg_id==this.didService.suborgId || this.selected.message_route_suborg_id==this.didService.suborgId || this.didService.isAllSuborg) 
                && (this.didService.isSuperUser || this.ra.hasPermission(
                    ResourceConstants.R2V_FORWARD_NUMBERS,
                    [PermissionConstants.VIEW_INFO],
                    ResourceConstants.R2V_MODULE
                ));
    }

    setFilter(colname: string, value: any) {
        this.dataFilter.setDefaultFilter(colname, value);
    }

    getTotalCounttoUpdate(count:any){
        this.countofSelectedPhoneNumbers = 0;
        this.countofPhoneNumberstoUpdate = count;
        this.cdr.markForCheck();
    }

    getSelectedCounttoUpdate(count:any){
        this.countofSelectedPhoneNumbers = count;
        this.cdr.markForCheck();
    }

    onBulkDeteClose(bulkDeleteComp){
        bulkDeleteComp.closeBulkupdateModaltoDelete?.emit();
        bulkDeleteComp.didService.currentStep=1
    }

    ngOnDestroy(): void { this.subs.unsubscribe(); }
    
}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat } from 'lodash';
import { GFColumn, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { SalesOrderDataHelper } from 'src/app/modules/sales/helpers/sales-orders-data.helper';
import { SOList, SOStatus } from 'src/app/modules/sales/models/sales-orders.models';
//import { SalesOrdersComponent } from 'src/app/modules/sales/pages/sales-orders/sales-orders.component';
import { SalesOrdersService } from 'src/app/modules/sales/services/sales-orders.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'customer-info-so',
  templateUrl: './customer-info-so.component.html',
  styleUrls: ['./customer-info-so.component.scss']
})
export class CustomerInfoSoComponent implements OnInit{


  @Input() set customerId(value:number){

      this._customerId=value;

  }

  public _customerId:number;

  public salesOrders:SOList[]=[];

  public search:string='';

  public customerContact:number;

  public customerLocation:number;

  public showFilter:boolean;

  public filterActive:boolean;

  public subs:SubSink=new SubSink();

  public searchOffset:number=0;

  public resultTotalCount:number;

  public resultCount:number;

  public searchData: GFilterParam[] = SalesOrderDataHelper.search[0].children;

  public soStatus=SOStatus;

  public statusClass=SalesOrderDataHelper.statusClass;


  public columns: GFColumn[] = SalesOrderDataHelper.columns;


  public statusList=[];

  public status:number;

  public shippingMethod:number;

  public selected: SOList= null


  constructor(private cdr:ChangeDetectorRef, private soService:SalesOrdersService,private modalService:NgbModal) { }


  ngOnInit(): void {

    this.getSO();

    this.getStatusList();
  }

  getStatusClass(id){

    let statusClass=SalesOrderDataHelper.statusClass;

    return statusClass[id];

  }

  getStatusString(id){
  const re = /_/gi;
   let status= this.soStatus[id].replace(re,' ');

   return status;
  }

  getStatusList(){
    this.columns.forEach((val, index)=>{

      if(val.displayName=='Status'){

        this.statusList=val.options;
      }
    })
  }


  getSO(plainSearch=false,loadMore=false){

    console.log('here');


    let request={ sort:null, filters: null };

    let searchData=[];

    let tmp;


    if(this.search!=''){
      this.searchData.forEach((val, index)=>{
        tmp={...val};
        tmp.value=this.search.toString();
        searchData.push(tmp);
      })
    }


    if(plainSearch==false){


      if(this.customerContact){
        searchData.push({colname: 'tenant_customer_contact', condition: 'equalto', value: this.customerContact, operator: 'AND' });
      }

      if(this.customerLocation){
        searchData.push({colname: 'tenant_customer_location', condition: 'equalto', value: this.customerLocation, operator: 'AND' });
      }

      if(this.shippingMethod){
        searchData.push({colname: 'shipment_method', condition: 'equalto', value: this.shippingMethod, operator: 'AND' });
      }


      if(this.status){
        searchData.push({colname: 'status', condition: 'equalto', value: this.status, operator: 'AND' });
      }



    }

    searchData.push({colname: 'tenant_customer', condition: 'equalto', value: this._customerId, operator: 'WHEN' });



    request.filters=searchData;
    request.sort = [{colname: "created_on", direction: "desc"}]

    if(loadMore){
      this.searchOffset+=50;
    }




   this.subs.sink= this.soService.getAll(request, this.searchOffset).subscribe(
      response=>{
          this.salesOrders=response.result;

          this.checkFilterActive();
          if(loadMore){
            this.salesOrders=response.result=concat(response.result, this.salesOrders);


          }
          else{
            this.salesOrders=response.result;


          }

          this.resultTotalCount=response.count;

          this.resultCount=response.result.length;

          this.cdr.markForCheck();

      }
    );

    return false;


  }

  customerContactChange(event){

    if(event&&event.id){
      this.customerContact=event?.id;
    }
    else{
      this.customerContact=null;
    }


  }

  customerLocationChange(event){

    if(event&&event.id){
      this.customerLocation=event?.id;

    }
    else{
      this.customerLocation=null;
    }

  }


  shippingMethodChange(event){


    if(event&&event.id){
      this.shippingMethod=event?.id;

    }
    else{
      this.shippingMethod=null;
    }
  }

  clearSearchInput(){

    this.search='';
    this.getSO(true);

  }

  resetFilter(){

    this.customerContact=null;
    this.customerLocation=null;
    this.search='';
    this.showFilter=false;
    this.shippingMethod=null;

    this.status=null;


    this.getSO(false);

    this.checkFilterActive();



  }

  checkFilterActive(){

    if(this.shippingMethod||this.status||this.customerContact||this.customerLocation){
      this.filterActive=true;
    }
    else{
      this.filterActive=false;
    }
  }

  toggleFilter(){
    this.showFilter=!this.showFilter;
    this.cdr.markForCheck();
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

  openSalesOrderView(modal_content: any){
    this.modalService.open(modal_content, { centered: true, scrollable: true, size: "xl" })
  }
  onCloseInvoiceView(){
    this.modalService.dismissAll()
  }

}

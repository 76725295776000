

<!-- <section class="invoice-template"> -->
  <!-- <div> -->
    <div class="d-flex flex-row mt-2 justify-content-between">
      <h5 class="item-title m-0 text-left">{{item?.unique_number}}</h5>
      <div class="d-flex justify-content-end align-items-start">
          <!-- <button class="btn btn-sm btn-primary mr-2" (click)="download(true)">
              <i class="fa ft-download"></i>Download
          </button>
          <button class="btn btn-sm btn-primary mr-2" (click)="download(false)">
              <i class="fa ft-printer"></i>Print
          </button> -->
          
          <button *ngIf="UIPermissions.edit && showEditButton" [routerLink]="['/sales/service-contracts', item.id, 'edit']"
             ngbTooltip="Edit"  placement="top" container="body"
           class="btn btn-sm bg-light-secondary">
              <i class="ft-edit"></i> Edit
          </button>
      
          <button *ngIf="UIPermissions.delete && showDeleteButton" class="btn btn-sm bg-light-danger ml-1"
          (click)="deleteSC(item)" ngbTooltip="Delete" placement="top" container="body">
              <i class="ft-trash-2 mr-1"></i> Delete
          </button>
          <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
              <i class="ft-x"></i>
          </button>
      </div>
  </div>

  <!-- </div> -->
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
        <a ngbNavLink class="d-flex align-items-center">
            <span class="d-none d-sm-block">Overview</span>
        </a>
        <ng-template ngbNavContent class="tab-content-pd">
            <div class="group mt-2 invoice-template">
              <div class="ribbon3" [class]="getStatusClass(item.service_contract_status)" >
                <span>{{item.service_contract_status}}  </span><small class="expiring-soon" *ngIf="item.expired_in_90_days">(Expiring soon)</small>
              </div>
              <!-- <p class="text-danger expiring-soon" *ngIf="item.expired_in_90_days">Expiring soon</p> -->
            
            
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-content p-3">
                      <div id="invoice-template" class="card-body pb-0" id="pdfTable" #pdfTable>
                        <!-- Invoice Company Details starts -->
                        <div id="invoice-company-details" class="row">
            
                            <div class="col-12 text-center">
                            <h4 class="text-muted text-center" style="margin:auto;text-transform: uppercase; font-size: 16px; margin-bottom: 35px;">{{item.title}}</h4>
            
                            <!-- <p class="text-muted mb-1">Org/Suborg</p> -->
                          </div>
                          <div class="col-md-6 col-12">
                            <br/>
                            <div class="col-12 text-left">
                              <p class="text-muted mb-1">Bill To</p>
                            </div>
                            <div class="col-md-6 col-12">
                              <ul class="m-0 list-unstyled">
                                <li class="text-bold-800">{{item.tenant_customer_contact_name}}</li>
                                <li class="text-bold-800">{{item.tenant_customer_name}}</li>
                                <li>{{item.tenant_customer_location_name}}</li>
                              </ul>
                            </div>
            
                            <!-- <p class="text-muted mb-1">Org/Suborg</p>
            
                            <ul class="m-0 list-unstyled">
                              <li class="text-bold-800">{{item.tenant_customer_name}}</li>
                            </ul> -->
                          </div>
            
            
                          <div class="col-md-6 col-12 text-right">
                            <h2 class="primary text-uppercase">Service Contract</h2>
                            <p class="pb-3">{{item.unique_number}}</p>
            
                          </div>
                        </div>
                        <!-- Invoice Company Details ends -->
                        <!-- Invoice Customer Details starts -->
                        <div id="invoice-customer-details" class="row">
                          <div class="col-12 text-left">
                            
                            <!-- <p class="text-muted mb-1">Bill To</p> -->
                          </div>
                          <div class="col-md-6 col-12">
                            <!-- 
                            <ul class="m-0 list-unstyled">
                              <li class="text-bold-800">{{item.tenant_customer_contact_name}}</li>
                              <li class="text-bold-800">{{item.tenant_customer_name}}</li>
                              <li>{{item.tenant_customer_location_name}}</li>
                            </ul>
                          -->
                          </div> 
            
                          <div class="col-md-6 col-12 text-right">
            
                            <!-- <p><span class="text-muted">Created on :</span> {{item.created_on | date:'medium'}}</p> -->
                            <p><span class="text-muted">Type :</span> <span *ngIf="item.coverage_type!=0">{{coverageType[item.coverage_type]}}</span>
                            <span *ngIf="item.coverage_type==0">Line by Line</span>
                            </p>
                            <p><span class="text-muted">Billing :</span> <span *ngIf="item.billing_interval==1">Monthly</span>
                              <span *ngIf="item.billing_interval==2">Yearly</span>
                              </p>
                              <p class="" *ngIf="item.starting_date"><span class="text-muted">Starts from :</span> {{salesService.trimTime(item.starting_date)|date:'mediumDate'}}</p>
                              <p class="m-0" *ngIf="item.ending_date"><span class="text-muted">Ends at :</span> {{salesService.trimTime(item.ending_date)|date:'mediumDate'}}</p>
            
                            <div class="row"></div>
                          </div>
                        </div>
                        <!-- Invoice Customer Details ends -->
                        <!-- Invoice Items Details starts -->
                        <div id="invoice-items-details">
                          <div class="row" >
                            <div class="table-responsive col-12">
                              <br/>
                              <table class="table mt-3">
                                <thead>
                                  <tr>
                                    <th width="40"></th>
                                    <th class="text-right" width="150">Part number</th>
                                    <th>Item &amp; Description</th>
                                    <th>Qty</th>
                                    <th>Serial number</th>
                                    <th *ngIf="item?.coverage_type==0">Coverage type</th>
                                    <th>Unit Price</th>
                                    <th >Sub Total</th>
                                    <!-- <th class="text-right">SKU</th> -->
                                    <!-- <th class="text-right">Quantity</th> -->
                                    <!-- <th width="30"></th> -->
                                  </tr>
                                </thead>
                                <tbody *ngIf="itemList&&itemList.length>0">
                                  <tr *ngFor="let item1 of itemList; index as i;">
                                    <td>
                                      <!-- <img *ngIf="row.image_link" width="30" height="30" [src]="fileUrl + '/' + row.image_link" /> -->
                                      <img *ngIf="!item1.image_link" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                                  </td>
                                    <td class="text-right">{{item1.part_number}}</td>
            
                                    <td>
                                      <p class="item-name">{{item1.item_name}}</p>
                                      <!-- <p class="text-muted">Silent Buttons, 2.4 GHz with USB Mini Receiver, 1000 DPI Optical Tracking, 18-Month Battery Life, Ambidextrous PC/Mac/Laptop - Charcoal Grey</p> -->
                                    </td>
                                    <td class="">{{item1.quantity}}</td>
                                    <td class="">{{addSpaceAfterComa(item1.serial_number)}}</td>
                                    <td *ngIf="item?.coverage_type==0">{{coverageType[item1?.coverage_type]}}</td>
                                    <td>{{item1.price|currency}}</td>
                                    <td>{{item1.price*item1.quantity|currency}}</td>
                                  </tr>
                                </tbody>
                                <tbody *ngIf="kitList&&kitList.length>0">
                                  <tr *ngFor="let item2 of kitList; index as i;">
                                    <td>
                                      <!-- <img *ngIf="row.image_link" width="30" height="30" [src]="fileUrl + '/' + row.image_link" /> -->
                                      <img  width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                                  </td>
                                    <td class="text-right">{{item2.internal_number}}</td>
            
                                    <td>
                                      <p class="item-name">{{item2.kit_name}}</p>
                                      <!-- <p class="text-muted">Silent Buttons, 2.4 GHz with USB Mini Receiver, 1000 DPI Optical Tracking, 18-Month Battery Life, Ambidextrous PC/Mac/Laptop - Charcoal Grey</p> -->
                                    </td>
                                    <td class="">{{item2.quantity}}</td>
                                    <td class="">{{addSpaceAfterComa(item2.serial_number)}}</td>
                                    <td *ngIf="item?.coverage_type==0" >{{coverageType[item2?.coverage_type]}}</td>
                                    <td>{{item2.price|currency}}</td>
                                    <td>{{item2.price*item2.quantity|currency}}</td>
                                  </tr>
                                </tbody>
                                <tbody *ngIf="itemList&&itemList.length==0&&kitList&&kitList.length==0">
                                  <h1>ItemList</h1>
                                  <tr>
                                    <td colspan="3">
                                        <p class="text-center">No item to display</p>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr class="summary-tr" *ngIf="itemList && itemList.length > 0">
                                    <td [attr.colspan]="item?.coverage_type_selection==1?'5':'6'" >
                                        <!-- <span *ngIf="itemList && itemList.length > 0">
                                            <b>{{item1.length+item2?.length}}</b> {{item1.length+item2?.length == 1 ? 'item' : 'items'}} selected
                                        </span> -->
                                    </td>
                                    <td>
                                        <div class="w-100 text-right">
                                            Total($):
                                        </div>
                                    </td>
                                    <td colspan="2" width="60">
                                        <span class="summary-title">{{priceSummaryFunc(itemList, kitList)|currency}}</span>
                                    </td>
                                </tr>
          
                                </tfoot>
            
                              </table>
                            </div>
                            <!-- {{itemList|json}} -->
                          </div>
                          <br/>
                          <br/>
                          <br/>
                        </div>
                        <!-- Invoice Items Details ends -->
                        <!-- Invoice Footer starts -->
                        <div id="invoice-footer">
                          <div class="row mt-2 mt-sm-0">
                            <div class="col-md-6 col-12 d-flex align-items-center">
                              <div class="terms-conditions mb-2">
                                <h6>Terms & Condition</h6>
                                <p>{{item.conditions}} 
                                  <!-- <a class="text-primary" (click)="openTerms()">More</a> -->
                                </p>
                                <br/>
                                <p><span class="text-muted">Created by :</span> {{item.created_by}}</p>
                                <p><span class="text-muted">Created on :</span> {{salesService.trimTime(item.created_on)|date:'mediumDate'}}</p>
            
                              </div>
                            </div>
                            <div class="col-md-6 col-12">
                              <!-- <div class="signature text-center">
                                <p>Authorized person</p>
                                <h6 class="mt-4">Amanda Orton</h6>
                                <p class="text-muted">Managing Director</p>
                              </div> -->
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 text-center text-sm-right">
                              <!-- <button type="button" class="btn btn-primary btn-print mt-2 mt-md-1" (click)="downloadAsPDF()"><i class="ft-printer mr-1"></i>Print Warranty</button> -->
                            </div>
                          </div>
                        </div>
                        <!-- Invoice Footer ends -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </ng-template>
    </li>
    <li ngbNavItem>
        <a ngbNavLink class="d-flex align-items-center">
            <span class="d-none d-sm-block">Comments</span>
        </a>
        <ng-template ngbNavContent class="tab-content-pd">
            <div style="max-width: 500px;">
                <sc-comments [scId]="item.id"></sc-comments>
            </div>
        </ng-template>
    </li>
    <li ngbNavItem>
        <a ngbNavLink class="d-flex align-items-center">
            <span class="d-none d-sm-block">History</span>
        </a>
        <ng-template ngbNavContent class="tab-content-pd">
            <div style="max-width: 500px;">
                <history-view [module]="'SERVICE_CONTRACT'" [uniqueId]="item.id"></history-view>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav" ></div>


 
  <!-- </section> -->
import { ChangeDetectorRef, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import * as popup from 'src/app/core/utils/popup.functions';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { WINDOW } from 'src/app/core/services/window.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { HoldMusicService } from '../../services/hold-music.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HoldMusicResponse, holdMusicSettingsResp } from '../../models/hold-music.model';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ring2voice-hold-music',
  templateUrl: './hold-music.component.html',
  styleUrls: ['./hold-music.component.scss']
})
export class HoldMusicComponent implements OnInit {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild('dataTable') dataTable: DatatableComponent;

  public SelectionType = SelectionType;

  public musicSettings: holdMusicSettingsResp = new holdMusicSettingsResp();

  public selected: HoldMusicResponse;

  public selected_id: number;

  public rows: HoldMusicResponse[]=[];

  public expanded: boolean = true;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public isSuperAdmin: boolean;

  fileURL: string = environment.imgURL;
  
  public UIPermissions: UIPermission;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = [
    { displayName: "All Music", colname: '', condition: 'is', value: null, operator: 'AND' },
  ];

  public searchFilters: GFilterParam[] = [
    { displayName: 'Name', colname: 'musicName', condition: 'contains', value: '', operator: 'AND' }
  ];

  public columns: GFColumn[] = [
    { displayName: 'Duration', columnName: 'duration', columnType: GFColumnTypes.number },
    { displayName: 'Created on', columnName: 'created_on', columnType: GFColumnTypes.date }

  ];

  public sort: GSortParam[] = [];

  public sortLookup = {
    name: 'name',
    duration: 'duration',
    created_on : 'created_on'
  };

  public autoFullViewImageIndex:number;

  private currentPlayer: HTMLAudioElement;

  public options: { id: number, name: string }[] = [];

  public user_id: number;

  public selectedSid: any;

  // public audioURLforDuration: any;

  constructor(private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private device: DeviceDetectorService,
    private ra: ResourceAccessService,
    private service: HoldMusicService,
    public location: Location,
    private route: ActivatedRoute,
    @Inject(WINDOW) private window: Window,) {
      this.isSuperAdmin = service.isSuperUser;
      this.user_id = service.userId;
      this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.R2V_MUSIC_ON_HOLD, ResourceConstants.R2V_MODULE);
     }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe(params =>{ 
      this.selected_id = +params.id || 0 
    });
    this.scrollbarH = this.device.isMobile();
    this.getData();
    if(!this.isSuperAdmin){
      this.getSettings();
    }
    if(this.selected_id){
      this.getMusicDetailsbyId(this.selected_id);
    }
  }

  ngAfterViewChecked() {
    if (this.dataTable && this.dataTable.rowDetail) {
        this.dataTable.rowDetail.expandAllRows();
        this.cdr.detectChanges();
    }
  }

  getData(filters: GFilterParam[] = null, offset: number = 0) {

    let request = { filters: filters, sort: this.sort }

    this.subs.sink = this.service.getAll(request, offset, this.page.limit).pipe(
        catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
        this.rows = response.result;
        this.rows = this.rows.map(music => {
          // Check if is_upload is true and modify the music_link accordingly
          if (music.is_upload) {
              music.music_link = this.fileURL + music.music_link;
          }
          return music;
      });
        this.setPagination(offset, response.count);
        this.cdr.markForCheck();
    });
  }

  getSettings(){
    this.subs.sink = this.service.getSettings(this.user_id).subscribe(response=>{
      this.musicSettings = response;
      const options = response.music.map(music => ({ id: music.id, name: music.name }));
      this.options = options;
    })
  }

  getMusicDetailsbyId(id:number){
    this.subs.sink = this.service.getById(id).subscribe(response=>{
      this.selected = response;
      this.expanded = false;
      if(response.is_upload){
        this.selected.music_link = this.fileURL + response.music_link;
      }
      this.refreshDataTable();
    })
  }

  audioPlayerPlay(audio: HTMLAudioElement) {
    console.log('duration of audio : ',audio.duration)
    if (this.currentPlayer && this.currentPlayer != audio) {
        this.currentPlayer.pause();
    }
    this.currentPlayer = audio;
  }

  scrollToTop() {
    this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  onSelect({ selected }) {
    let current: HoldMusicResponse = selected[0];
    this.selectedSid = current.id;

    if (current && this.UIPermissions.viewInfo) {
        this.getMusicDetailsbyId(current.id)
        this.expanded = false;
        this.location.go(`/ring2voice/settings/music-on-hold/${current.id}/view`);
        this.refreshDataTable();
        this.cdr.markForCheck();
    }
    this.cdr.markForCheck();
}

  refreshDataTable() {
    setTimeout(() => {
        this.rows = [...this.rows];
        this.cdr.markForCheck();
    });
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter.getDataFilters(), offset);
    this.scrollToTop()
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
        let current = event.sorts[0];
        if (!this.sortLookup[current.prop]) return;
        let sort = new GSortParam();
        sort.colname = this.sortLookup[current.prop];
        sort.direction = current.dir;
        this.sort = [sort];
        this.getData(this.dataFilter.getDataFilters());
    }
  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go(`/ring2voice/settings/music-on-hold`);
    this.refreshDataTable();
    this.cdr.markForCheck();

  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
        upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  settingsOnChange(settings:string){
    let msg = `${this.musicSettings.random_music?'Hold Music Will Be Selected Randomly':'Selected Music Will Play As Hold Music'}`;
    let payload = { always_play: this.musicSettings.always_play_id, random_music: this.musicSettings.random_music };
    this.subs.sink = this.service.SaveSettings(this.user_id,payload).subscribe(
      resp => {
        this.toastr.success(msg);
      },
      (error) => {
        this.toastr.error('Error Occurred');
        if(settings=='randomize'){
          this.musicSettings.random_music = !this.musicSettings.random_music;
        }
      });
  }

  deleteMusic(id:number){
    if(this.musicSettings.always_play_id == id){
      this.toastr.warning("The music you are tring to delete is selected as always play, Please change and try again.");
      return;
    }
    let opt = {
      title: `Are you sure you want to Delete?`,
      text: '',
      ButtonText: "Yes",
    }
    popup.ConfirmWithButtonText(opt, result => {
        if (result.isConfirmed) {
          this.subs.sink = this.service.delete(id).subscribe(()=>{
            this.toastr.success("Deleted Successfully");
            // this.rows = this.rows.filter(row => row.id != id);
            this.options = this.options.filter(option=> option.id!=id);
            this.getData(this.dataFilter.getDataFilters());
            this.cdr.markForCheck();
            this.closeDetailedView();
          },
          ()=>{
            this.toastr.error("Error Occurred");
          })
        };
    });
    
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.R2V_MUSIC_ON_HOLD, [
        PermissionConstants.CREATE,PermissionConstants.MODIFY
    ]);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}

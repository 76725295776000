import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataFilterRequest, GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { ToastrService } from 'ngx-toastr';
import { WINDOW } from 'src/app/core/services/window.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import * as popup from 'src/app/core/utils/popup.functions';
import { TimeFrameDetailsResponse, WorkingTimeResponse, workingTimes } from '../../models/time-frame.model';
import { ActivatedRoute } from '@angular/router';
import { UIPermission } from 'src/app/core/models/common.models';
import { TimeFrameService } from '../../services/time-frame.service';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-time-frames',
  templateUrl: './time-frames.component.html',
  styleUrls: ['./time-frames.component.scss']
})
export class TimeFramesComponent implements OnInit {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  public selected_tfdetails: TimeFrameDetailsResponse[];

  public workingTimes:workingTimes[]=[];

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public expanded: boolean = true;

  public module = ModuleConstants.RING2VOICE_TIME_FRAME;

  public scrollbarH: boolean = false;

  public SelectionType = SelectionType;

  public suborg_id: number;

  public rows: WorkingTimeResponse[] = []

  public selected: WorkingTimeResponse;

  public selected_Id:number;

  private subs: SubSink = new SubSink();

  public UIPermissions: UIPermission;

  public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

  public columns: GFColumn[] = [
    { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
    // { displayName: 'Start Date', columnName: 'date_created', columnType: GFColumnTypes.date },
    // { displayName: 'End Date', columnName: 'date_created', columnType: GFColumnTypes.date },
    // { displayName: 'Date Between', columnName: 'date_created_between', columnType: GFColumnTypes.date_range },
    { displayName: 'Created By', columnName: 'created_by', columnType: GFColumnTypes.string },
    {
        displayName: 'Type',
        columnName: 'type',
        columnType: GFColumnTypes.select,
        optionValueType: GFColumnTypes.ref,
        options: [
            { displayName: 'Days/Time', value: 'Days/Time' },
            { displayName: 'Specific Date/Ranges', value: 'Specific Date/Ranges' },
            { displayName: 'Always', value: 'Always' }
        ]
    }
  ];

  public defaults: GFilterParam[] = [
    { displayName: "All Time Frames", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Always Time Frames", colname: 'type', condition: 'is', value: 'Always', operator: 'AND' },
    { displayName: "Days/Time Time Frames", colname: 'type', condition: 'is', value: 'Days/Time', operator: 'AND' },
    { displayName: "Specific Date Time Frames", colname: 'type', condition: 'is', value: 'Specific Date/Ranges', operator: 'AND' }
  ];

  public search: GFilterParam[] = [
    {
        displayName: 'Combo Search', colname: '', condition: '', value: '',
        children: [
            { displayName: 'Name', colname: 'phone_number_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
            { displayName: 'Created By', colname: 'call_from', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string }
        ]
    },
    { displayName: 'Name', colname: 'phone_number_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
    { displayName: 'Created By', colname: 'call_from', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone }
  ];

  public sortLookup = {
    created_on: 'created_on',
    type: 'type',
    name: 'name',
    created_by: 'created_by'

};

  constructor(private cdr: ChangeDetectorRef,
    route: ActivatedRoute,
    public location: Location,
    device: DeviceDetectorService,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private timeFrameService: TimeFrameService,
    @Inject(WINDOW) private window: Window){ 
      this.subs.sink = route.params.subscribe(params =>{ 
        this.selected_Id = +params.id || 0
    });
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.R2V_TIME_FRAME, ResourceConstants.R2V_MODULE, true);
    this.scrollbarH = device.isMobile();
  }

    
  ngOnInit(): void {

    this.getData();
    if (this.selected_Id) {
      this.getById(this.selected_Id);
      this.cdr.markForCheck();
  }
  }

  
getData(filters: GFilterParam[] = null, offset: number = 0) {

  let request: DataFilterRequest = { filters: filters, sort: this.sort }

  this.subs.sink = this.timeFrameService.getTimeFrames(request, offset).pipe(
      catchError(() => of({ count: 0, result: [] }))
  ).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
  });
}

getById(id:number){
  this.subs.sink = this.timeFrameService.getById(id).subscribe(resp => {
    this.selected = resp;
    this.expanded = false;
    this.selected.time_frame.forEach(tf => {
        const v = new workingTimes(); // Create a new instance inside the loop
        const startTimeParts = tf.start_time?.split(":");
        const endTimeParts = tf.end_time?.split(":");
        v.startTime.hour = Number(startTimeParts[0]);
        v.startTime.minute = Number(startTimeParts[1]);
        v.endTime.hour = Number(endTimeParts[0]);
        v.endTime.minute = Number(endTimeParts[1]);
        v.startDate = tf.start_date;
        v.endDate = tf.end_date;
        this.workingTimes.push(v); // Push the new instance into the array
    });
    this.cdr.markForCheck();
  });
  this.getTimeFrameDetailsById(id);
}

getTimeFrameDetailsById(id:number){
  this.subs.sink = this.timeFrameService.getTimeFrameDetails(id).subscribe(resp=>{
    this.selected_tfdetails = resp;
    this.cdr.markForCheck();
  },
  (error)=>{}
  )
}

deleteTimeFrame(id: number) {
  popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
          this.subs.sink = this.timeFrameService.deleteTimeFrame(id).subscribe(
              () => {
                      popup.CompleteDelete('Time Frame has been deleted.');
                      this.rows = this.rows.filter(x => x.id !== id);
                      this.cdr.markForCheck();
                      this.closeDetailedView();
                      // this.toastr.error(resp.message);
              },
              () => this.toastr.error('Unable to delete Time Frame')
          );
      }
  });
}
  
  applyFilter(param:GFilterParam[]){
    this.getData(param); 
}


setPagination(offset: number, total: number) {
  this.page.count = total;
  let upperLimit = offset + this.page.limit;
  if (upperLimit > total) {
      upperLimit = total;
  }
  this.page.pageNumber = offset + '-' + upperLimit;
}


onSelect({ selected }) {
  let current: WorkingTimeResponse = selected[0];
  this.selected_Id = current.id;

  if (current && this.UIPermissions.viewInfo) {
      this.getById(current.id);
      this.expanded = false;
      this.location.go(`/ring2voice/settings/time-frames/${current.id}/view`);
      this.refreshDataTable();
  }
  this.cdr.markForCheck();
}

setPage(pageInfo: any) {
  let offset = pageInfo.offset * this.page.limit;
  this.page.offset = pageInfo.offset;
  let params = this.dataFilter?.getDataFilters() || [];
  this.getData(params, offset);
  this.scrollToTop()
}

scrollToTop() {
  this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}

onSort(event: any) {
  if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
          return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      let offset = event.offset * this.page.limit;
      this.page.offset = event.offset;
      this.getData(params, offset);
  }
}

refreshDataTable() {
  setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
  });
}
  
closeDetailedView() {
  this.expanded = true;
  this.selected = null;
  this.location.go(`/ring2voice/settings/time-frames`);
  this.refreshDataTable();
}


}
















<section *ngIf="model">
    <div class="col-8">
        <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && onSubmit()" novalidate>
            <div class="form-group">
                <label for="musicName" class="val-required">Name</label>
                <input type="text" id="musicName" [(ngModel)]="model.name" placeholder="Enter name" name="name" class="form-control" required>
                <small class="danger mt-1" *ngIf="model.name && nameError">Name already exists</small>
            </div>
            <div class="form-group">
                <div class="d-flex align-items-center">
                    <label for="toggle" class="badge badge-info mb-0 mr-2">URL</label>
                    <div class="custom-switch custom-switch-activator custom-switch-secondary urlorupload custom-control">
                        <input type="checkbox" [(ngModel)]="model.is_upload" name="toggle" class="custom-control-input" id="url_or_upload" checked>
                        <label class="custom-control-label" for="url_or_upload">
                            <span></span>
                        </label>
                    </div>
                    <label for="toggle" class="badge badge-secondary mb-0 ml-2">Upload Music</label>
                </div>
            </div>
            <div class="form-group" *ngIf="!model.is_upload else upload">
                <label for="musicUrl" class="val-required">URL</label>
                <input type="text" id="musicUrl" [(ngModel)]="model.music_link" placeholder="Enter URL" name="link" class="form-control mb-3" required>
            </div>
            <ng-template #upload>
                <div *ngIf="model.file_id" class="d-flex justify-content-center selectedAudio">
                    <img src="../../../../../assets/img/gallery/audio-icon.png" (click)="openUploadModal(uploadContent,model)" class="" alt="icon" ngbTooltip="Click to view or edit" placement="right">
                </div>
                <div *ngIf="!model.file_id" class="upload-music" (click)="openUploadModal(uploadContent,model)" >
                    <img src="../../../../../assets/img/svg/upload.svg" class="anime-image mt-3">
                    <p>Upload Music</p>
                </div>
            </ng-template>
        
            <div class="d-flex flex-sm-row flex-column justify-content-end mt-3">
                <button type="submit" [disabled]="!addForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                    {{ selected_Id ? 'Save Changes' : 'Save'}}
                </button>
                <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
            </div>
        </form>
    </div>
</section>

<!-- modal -->
<ng-template #uploadContent let-uploadModal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Attachments</h5>
        <button type="button" class="close" aria-label="Close" (click)="uploadModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form role="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && uploadImg(fileUploader)" novalidate>
            <app-file-uploader #fileUploader [upload_Audio_only]="true" [showPreview]="false" [files]="itemAudio" (delete)="onFileDelete(model)">
            </app-file-uploader>

            <button (click)="uploadModal.dismiss('Cross click')"  type="button" class="btn btn-secondary" style="float:right">Cancel</button>
            <button type="submit" [disabled]="!formElement.form.valid"
                        class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2" style="float:right;padding-bottom: 8px;">
                    Save
                </button>    
        </form>
    </div>
</ng-template>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceTicketModule } from '../service-ticket/service-ticket.module';
import { CustomerInfoTabComponent } from './components/customer-info-tab/customer-info-tab.component';
import { CustomerInfoStComponent } from '../sales-shared/service-ticket/customer-info-st/customer-info-st.component';
import { TenantCustomersModule } from '../tenant-customers/tenant-customers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomerInfoInvoiceComponent } from '../sales-shared/sales-invoice/customer-info-invoice/customer-info-invoice.component';
import { SalesModule } from '../sales/sales.module';
import { RouterModule } from '@angular/router';
//import { CustomerInfoSoComponent } from './components/customer-info-so/customer-info-so.component';
// import { CustomerInfoScComponent } from './components/customer-info-sc/customer-info-sc.component';
// import { CustomerInfoWarrantyComponent } from './components/customer-info-warranty/customer-info-warranty.component';
import { SalesSharedModule } from '../sales-shared/sales-shared.module';




@NgModule({
  declarations: [
    CustomerInfoTabComponent,
    //CustomerInfoSoComponent,
    // CustomerInfoScComponent,
    // CustomerInfoWarrantyComponent
  ],
  imports: [
    CommonModule,
    ServiceTicketModule,
    TenantCustomersModule,
    SharedModule,
    NgbModule,
    NgSelectModule,
    RouterModule,
    SalesSharedModule
  ],
  exports:[
    CustomerInfoTabComponent,
    //CustomerInfoSoComponent,
    // CustomerInfoScComponent,
    // CustomerInfoWarrantyComponent

  ]
})
export class TenantCustomerInfoModule { }

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log=()=>{};
  window.console.warn=()=>{};
}

platformBrowserDynamic().bootstrapModule(AppModule).then(()=>{
  if('serviceWorker' in navigator){
    console.warn('Has worker in Navigator')
    navigator.serviceWorker.register('modules/ring2voice/web.worker.ts')
  }
})
  .catch(err => console.error(err));
